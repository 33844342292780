import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { WarningIcon } from "@platformx/shared/static-assets";
import { navigateBasedRole } from "@platformx/utilities";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom-v6";
import "./AccessDenied.css";

const AccessDeniedBox = styled("div")(({ theme }) => ({
  color: theme?.palette?.customColor?.SECONDARY,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "calc(100vh)",
}));
const AccessDenied = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleBack = () => {
    //navigate("/dashboard");
    navigateBasedRole(navigate);
  };
  return (
    <AccessDeniedBox>
      <Box className='cardbox'>
        <Box className='imageicon'>
          <img src={WarningIcon} alt='Access Denie Icon' />
        </Box>
        <Typography variant='h2medium'>{t("access_denied")}</Typography>
        <Typography variant='p3regular'>{t("access_denied_subTitle")}</Typography>
        <Button variant='primaryButton' onClick={handleBack}>
          {t("back_to_home")}
        </Button>
      </Box>
    </AccessDeniedBox>
  );
};

export default AccessDenied;
