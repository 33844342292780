import { Box, Button, Typography } from "@mui/material";
import { AmountWithCurrency, checkWarrantyAvailable, dateFormat } from "../HelperFunction";

const ProductCard = ({ productItem, buttonName, onItemClick, classname, addOns = false }: any) => {
  const isDisabled = checkWarrantyAvailable(
    productItem?.deliveryDate,
    productItem?.attributes?.warrantyDuration,
  );
  return (
    <Box className={`${classname} productCard`}>
      <Box display='flex'>
        <Box width={200} height={200} className='productCardImage'>
          <img src={productItem?.lineItemImageUrl} alt='product' />
        </Box>
        <Box p={2}>
          <Typography
            variant='p4regular'
            sx={{ textTransform: "uppercase", marginBottom: 0 }}
            gutterBottom>
            {productItem?.attributes?.category}
          </Typography>
          <Typography variant='p3regular' sx={{ marginBottom: 0 }} gutterBottom>
            {productItem?.name}
          </Typography>
          {addOns ? (
            <Typography variant='labelregular' sx={{ paddingTop: 2.5 }}>
              +{AmountWithCurrency(productItem.price)}
            </Typography>
          ) : (
            <Typography variant='labelregular' sx={{ paddingBottom: 0 }}>
              {productItem?.deliveryStatus === "Delivered"
                ? `Delivered on ${dateFormat(productItem?.deliveryDate)}`
                : `Ordered on ${dateFormat(productItem?.deliveryDate)}`}
            </Typography>
          )}
          {!addOns && (
            <Typography variant='p4regular' sx={{ marginTop: 0 }} gutterBottom>
              Serial Number: {productItem?.productSequenceNumber}
            </Typography>
          )}
          <Box>
            <Button
              variant='primaryButton2'
              disabled={isDisabled}
              onClick={() => {
                onItemClick(productItem);
              }}
              sx={{
                border: "1px solid #AA1A2D",
                color: "#AA1A2D",
                "&:hover": {
                  backgroundColor: "#AA1A2D",
                  color: "white",
                },
              }}>
              {isDisabled ? "Warranty Expired" : buttonName}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
ProductCard.defaultProps = {
  classname: "", // Set an empty string as the default value
};

export default ProductCard;
