import graphqlInstance from "../../config/graphqlConfig";
import { UserManagementQueries } from "../../graphQL/queries/userManagementQueries";
import { ApiResponse } from "../../utils/types";
import { PROJECT_CATCH_ERROR_LIST } from "@platformx/utilities";

const userManagementAPI = {
  validateEmailExist: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: UserManagementQueries.EMAIL_VALIDATION,
        variables: input,
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:validateEmailExist`, err);
      throw err;
    }
  },
  fetchUsers: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: UserManagementQueries.FETCH_USER_LIST,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:fetchUsers`, err);
      throw err;
    }
  },
  fetchRoles: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: UserManagementQueries.FETCH_ROLE,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:fetchRoles`, err);
      throw err;
    }
  },
  getUsersDetails: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: UserManagementQueries.GET_USER_DETAILED_LIST,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:getUsersDetails`, err);
      throw err;
    }
  },
  editUserDetails: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: UserManagementQueries.EDIT_USER,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:editUserDetails`, err);
      throw err;
    }
  },
  inviteEndUsers: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: UserManagementQueries.INVITE_END_USER,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:inviteEndUsers`, err);
      throw err;
    }
  },
};
export default userManagementAPI;
