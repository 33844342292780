import { Box, ImageList, useMediaQuery } from "@mui/material";
import { NoSearchResult } from "@platformx/utilities";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentTypeCard from "./contentTypeCard/ContentTypeCard";
import useContentGlleryStyle from "./contentTypeCard/DamContentGllery.style";
import LoaderComponent from "./LoaderComponent";

const DamContentCard = ({
  fetchMoreData,
  isLazyLoad,
  isLoading,
  data,
  setImageData,
  folderMenuOpen,
}) => {
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);

  const handleSelectCard = (index) => {
    setSelectedCardIndex(index);
  };
  const classes = useContentGlleryStyle();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Box className={classes.noresultfoundtypo} id='scrollablegallerydiv'>
      {!isLoading && data?.collectionItem?.length === 0 ? (
        <NoSearchResult />
      ) : (
        <Box className={classes.infinitescroll}>
          <InfiniteScroll
            loader={<LoaderComponent folderMenuOpen={folderMenuOpen} />}
            next={fetchMoreData}
            hasMore={isLazyLoad}
            dataLength={data.collectionItem.length}
            scrollableTarget='scrollablegallerydiv'>
            <ImageList variant='masonry' cols={isSmallScreen ? 1 : 4} gap={10}>
              {data.collectionItem?.map((item, index) => (
                <ContentTypeCard
                  key={index}
                  content={item}
                  isLoading={isLoading}
                  selectedCardIndex={selectedCardIndex}
                  onSelectCard={handleSelectCard}
                  setImageData={setImageData}
                />
              ))}
            </ImageList>
          </InfiniteScroll>
        </Box>
      )}
    </Box>
  );
};

export default DamContentCard;
