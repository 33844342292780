/* eslint-disable @typescript-eslint/no-unused-vars */
import { CardMedia } from "@mui/material";
import { fallBackImage } from "@platformx/shared/static-assets";
import {
  breakpointsdata as BREAKPOINTS,
  formCroppedUrlInCrop,
  getSingleCropBreakpoint,
  IMAGE_FALLBACK_PRELEM,
  ratios as RATIOS,
} from "@platformx/utilities";
import React from "react";

const PictureComponent = (props: any = {}) => {
  const {
    croppedImages = [],
    imgOrder = {
      1440: "hero",
      1280: "landscape",
      1024: "card2",
      768: "square",
      600: "card1",
      320: "portrait",
    },
    extension,
    isCrop,
    prelemId,
    i,
  } = props;

  return (
    <CardMedia
      component='picture'
      sx={{
        aspectRatio: {
          xs: RATIOS[imgOrder["320"]],
          sm: RATIOS[imgOrder["600"]],
          md: RATIOS[imgOrder["768"]],
          em: RATIOS[imgOrder["1024"]],
          lg: RATIOS[imgOrder["1280"]],
          xl: RATIOS[imgOrder["1440"]],
        },
        height: "inherit",
        borderRadius: "inherit",
      }}>
      {(isCrop ? BREAKPOINTS : getSingleCropBreakpoint(croppedImages?.[0]?.croppedImages)).map(
        ({ breakpoint, ratio }, _key) => {
          const img =
            croppedImages.find(
              (x: { aspect_ratio: any }) => x.aspect_ratio === (imgOrder[breakpoint] || ratio),
            ) || IMAGE_FALLBACK_PRELEM;
          const { folder_path } = img || {};

          return (
            <>
              <source
                media={`(min-width:${breakpoint}px)`} //webp images for all breakpoints
                srcSet={formCroppedUrlInCrop(folder_path, "webp")}
              />
              <source
                media={`(min-width:${breakpoint}px)`} //images with original extension
                srcSet={formCroppedUrlInCrop(folder_path, extension)}
              />
            </>
          );
        },
      )}
      <img
        alt='cropped-img'
        src={fallBackImage}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "inherit",
        }}
      />
    </CardMedia>
  );
};

export default React.memo(PictureComponent);
