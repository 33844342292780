import { TextField } from "@mui/material";
import ErrorMessageHandle from "../Error/ErrorMessageHandle";

const HiddenTextBox = ({ name, value, error, required, register }: any) => {
  return (
    <>
      <TextField
        type='hidden'
        variant='filled'
        name={name}
        id={name}
        sx={{
          ".Mui-error.Platform-x-FilledInput-root": { border: "none" },
        }}
        error={error}
        value={value}
        {...register(name, {
          required: required,
        })}
      />
      <ErrorMessageHandle error={error} />
    </>
  );
};

export default HiddenTextBox;
