import graphqlInstance from "../../config/graphqlConfig";
import { CommentQueries } from "../../graphQL/queries/commentsQueries";
import { ApiResponse } from "../../utils/types";
import { PROJECT_CATCH_ERROR_LIST } from "@platformx/utilities";

const commentsApi = {
  createOrUpdateComment: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: CommentQueries.CREATE_OR_UPDATE_COMMENT,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:createOrUpdateComment`, err);
      throw err;
    }
  },
  getComment: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: CommentQueries.GET_COMMENT,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:fetchSuggestions`, err);
      throw err;
    }
  },
};
export default commentsApi;
