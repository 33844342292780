import {
  DraftIcon,
  EditorIcon,
  PublisherIcon,
  ReviewerIcon,
} from "@platformx/shared/static-assets";

export const icons: { [index: string]: string } = {
  request_review: DraftIcon,
  review: EditorIcon,
  approval: ReviewerIcon,
  publish: PublisherIcon,
};
