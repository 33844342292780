import { ShowToastError } from "@platformx/utilities";
import { useTranslation } from "react-i18next";
import channels from "../../services/channels/channels.api";
import { channelResponse } from "./useChannels.types";

export function useChannels() {
  const { t } = useTranslation();

  const getChannelsList = async (props): Promise<channelResponse> => {
    try {
      const response: any = await channels.getChannelsList({
        pagination: props,
      });
      if (response?.community_fetchHostListing?.records?.length >= 0) {
        return {
          data: response?.community_fetchHostListing?.records,
        };
      } else {
        ShowToastError(t("api_error_toast"));
        return {
          data: [],
        };
      }
    } catch (err: any) {
      const statusCode = err?.networkError?.statusCode || 0;
      ShowToastError("INTERNAL SERVER ERROR", statusCode);
      return {
        data: [],
      };
    }
  };

  const getChannelDetails = async (props): Promise<channelResponse> => {
    try {
      const response: any = await channels.getChannelDetails(props);
      if (response?.community_fetchHostEventDetails) {
        return {
          data: response?.community_fetchHostEventDetails,
        };
      } else {
        ShowToastError(t("api_error_toast"));
        return {
          data: {},
        };
      }
    } catch (err: any) {
      const statusCode = err?.networkError?.statusCode || 0;
      ShowToastError("INTERNAL SERVER ERROR", statusCode);
      return {
        data: {},
      };
    }
  };
  return {
    getChannelsList,
    getChannelDetails,
  };
}
