import { Box, Skeleton } from "@mui/material";

const FormSkelton = () => {
  return (
    <Box className={"formSection"}>
      <Skeleton variant='text' width='60%' height={40} />
      <Skeleton variant='text' width='80%' />
      <Skeleton variant='rectangular' width='100%' height={56} style={{ margin: "16px 0" }} />
      <Skeleton variant='rectangular' width='100%' height={56} style={{ margin: "16px 0" }} />
      <Skeleton variant='text' width='60%' height={40} />
      <Skeleton variant='text' width='80%' />
      <Skeleton variant='rectangular' width='100%' height={56} style={{ margin: "16px 0" }} />
      <Skeleton variant='rectangular' width='100%' height={56} style={{ margin: "16px 0" }} />
      <Skeleton variant='rectangular' width='120px' height={36} />
    </Box>
  );
};
export default FormSkelton;
