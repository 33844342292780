import { Box, Grid, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      fontSize: "28px",
      fontWeight: "700",
    },
    "& .Platform-x-FilledInput-root": {
      border: "none !important",
      "& input": {
        paddingLeft: "0px",
        fontWeight: 700,
        fontFamily: "Inter", // <== need to change in future
      },
    },
  },
});

export default function Title({ state, setState }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleTitleChange = (e) => {
    const newVal = e.target.value;
    setState({
      ...state,
      CommonFields: {
        ...state.CommonFields,
        title: newVal,
        settings: { ...state.CommonFields.settings, socialog_title: newVal },
        structure_data: "",
      },
    });
  };

  return (
    <Box
      sx={{
        fontSize: "28px",
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: "white",
      }}>
      <Grid container>
        <Grid item xs={8} md={10}>
          <TextField
            classes={{ root: classes.customTextField }}
            variant='filled'
            margin='normal'
            required
            fullWidth
            inputProps={{
              style: { fontSize: "28px" },
              maxLength: 350,
            }}
            id='title'
            name='title'
            autoComplete='title'
            autoFocus
            onChange={handleTitleChange}
            placeholder={t("enter_title")}
            InputProps={{
              disableUnderline: true, // <== added this
            }}
            value={state?.CommonFields?.title || ""}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
