import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    mainStyleWrapper: {
      position: "relative",
      maxWidth: "820px",
      margin: "auto",
      "& .textFiled": {
        marginBottom: "40px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "30px",
        },
      },
      "& .leftFiledLast": {
        paddingRight: "55px",
        [theme.breakpoints.down("md")]: {
          paddingRight: "10px",
        },
        [theme.breakpoints.down("sm")]: {
          marginBottom: "10px",
          paddingRight: "0px",
        },
      },
      "& .bottomLinksWp": {
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
      },
      "& .blueLink": {
        color: "#4B9EF9",
        cursor: "pointer",
        textTransform: "capitalize",
      },
      "& .seoLinkDevider": {
        width: "1px",
        height: "21px",
        flexGrow: 0,
        transform: "rotate(-180deg)",
        backgroundColor: "#89909a",
        marginRight: "30px",
        marginLeft: "30px",
        [theme.breakpoints.down("md")]: {
          marginRight: "5px",
          marginLeft: "5px",
        },
      },
    },
  };
});
