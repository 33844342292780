import { useLazyQuery } from "@apollo/client";
import { AUTH_INFO, ShowToastError } from "@platformx/utilities";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom-v6";
import AssetApi from "../../services/assetsApi/assets.api";

const COMMUNITY = {
  collections: [],
  subcommunities: [],
};
const COLLECTION_ITEM = {
  collectionItem: [],
  page: {},
};

const useAsset = () => {
  const [assetData, setAssetData] = useState<any>(COMMUNITY);
  const [collectionItem, setCollectionItem] = useState<any>(COLLECTION_ITEM);
  const [folderLoading, setFolderLoading] = useState(false);
  const { t } = useTranslation();
  const [getData, { data, loading: assetLoading }] = useLazyQuery(AssetApi.FETCH_COLLECTION_ITEM);
  const [getCommunityData] = useLazyQuery(AssetApi.FETCH_COMMUNITY_COLLECTION);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [isLazyLoad, setIsLazyLoad] = useState<boolean>(true);

  const ROWS = 16;
  const [searchParams] = useSearchParams();
  const pathName = window.location.pathname.split("/");
  const assetType = pathName[pathName.length - 1] || "images";
  const assetUUID = {
    images: AUTH_INFO.dspaceImagesUuid,
    videos: AUTH_INFO.dspaceVideosUuid,
  };

  const uuid1 = searchParams.get("uuid1") || assetUUID[assetType];

  const fetchCommunityCollect = async () => {
    try {
      setFolderLoading(true);
      const {
        data: { authoring_getAssets },
      } = await getCommunityData({
        variables: {
          uuid: uuid1,
          start: 0,
          rows: 20,
          search: "",
        },
      });
      if (authoring_getAssets) {
        const { collections = [], subcommunities = [] } = authoring_getAssets;
        if (collections?.[0]?.uuid) {
          setIsLazyLoad(true);
        } else {
          setIsLazyLoad(false);
        }
        setAssetData({
          collections,
          subcommunities,
        });
      }
      // Return the response
      return authoring_getAssets;
    } catch (err: any) {
      const statusCode = err?.networkError?.statusCode || 0;
      ShowToastError(t("api_error_toast"), statusCode);
    } finally {
      setFolderLoading(false);
    }
  };

  const fetchCollectionAsset = () => {
    getData({
      variables: {
        uuid: assetData.collections?.[0]?.uuid,
        start: 0,
        rows: ROWS,
        search: "",
        entityType: assetType?.toLowerCase() === "images" ? "Image" : "Video",
      },
    });
  };

  const fetchMoreData = async (index = 0) => {
    try {
      const { authoring_getAssets = {} } = await AssetApi.fetchCollectionItem({
        uuid: assetData.collections?.[0] ? assetData.collections?.[0]?.uuid : "",
        start: index,
        rows: ROWS,
        search: "",
        entityType: assetType?.toLowerCase() === "images" ? "Image" : "Video",
      });
      if (authoring_getAssets) {
        const { collectionItem: item = [], page = {} } = authoring_getAssets;
        if (item.length < ROWS) {
          setIsLazyLoad(false);
        } else {
          setIsLazyLoad(true);
        }

        setCollectionItem((prev) => ({
          collectionItem: [...prev.collectionItem, ...item],
          page: page,
        }));
      }
    } catch (err: any) {
      const statusCode = err?.networkError?.statusCode || 0;
      ShowToastError(t("api_error_toast"), statusCode);
    }
  };

  useEffect(() => {
    if (assetData.collections?.[0]?.uuid) {
      fetchCollectionAsset();
    } else {
      setCollectionItem(COLLECTION_ITEM);
    }
  }, [assetData?.collections?.[0]?.uuid]);

  useEffect(() => {
    setAssetData(COMMUNITY);
    setCollectionItem(COLLECTION_ITEM);
    fetchCommunityCollect();
    setStartIndex(0);
  }, [uuid1]);

  useEffect(() => {
    const { authoring_getAssets } = data || {};
    if (authoring_getAssets) {
      const { collectionItem: item = [], page = {} } = authoring_getAssets;
      if (item.length < ROWS) {
        setIsLazyLoad(false);
      } else {
        setIsLazyLoad(true);
      }
      setCollectionItem({
        collectionItem: item,
        page: page,
      });
    }
  }, [data]);

  return {
    assetData,
    collectionItem,
    folderLoading,
    isLazyLoad,
    fetchMoreData,
    startIndex,
    setStartIndex,
    assetLoading,
    fetchCommunityCollect,
    fetchCollectionAsset,
  };
};

export default useAsset;
