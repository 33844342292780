import { Box, Typography } from "@mui/material";
import DOMPurify from "isomorphic-dompurify";
import { useEffect, useState } from "react";
import { useCustomStyle } from "./CommonDescription.style";
import Editor from "./Editor";

type CommonDescriptionProps = {
  analytics: any;
  id?: string;
  content: any;
  variant?: any;
  color?: string;
  className?: string;
  secondaryArgs: any;
  prelemType?: string;
};
const CommonDescription = ({
  color = "",
  content = {},
  secondaryArgs,
  analytics = {},
  className = "",
  id = "Description",
  variant = "p3regular",
  prelemType = "prelemType1",
}: CommonDescriptionProps) => {
  const { editState = false, isEditorJSEnable = false } = secondaryArgs;
  const { [id]: value } = content;
  const styleProps = {
    prelemType: "prelemType1",
    fontFamily: "secondary",
  };
  const classes = useCustomStyle(styleProps);

  const [textHtml, setTextHtml] = useState("");

  /**
   * description html passing from child component
   * @param textHtmlData string
   */
  const passingToHtml = (textHtmlData = "") => {
    setTextHtml(textHtmlData);
  };

  useEffect(() => {
    setTextHtml(value);
  }, [value]);

  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });

  return (
    <Box
      id={`${analytics?.prelemID}_editor`}
      color={color}
      className={`${className} ${classes.quilleditor}`}>
      {isEditorJSEnable ? (
        <>
          {editState ? (
            <Editor
              color={color}
              analytics={analytics}
              description={content?.[id] || ""}
              passingToHtml={passingToHtml}
              className={className}
              prelemType={prelemType}
            />
          ) : null}
          <Typography
            variant={variant}
            id={id}
            className={className}
            // className='ql-editor' //incase editor design not printed enable and check this class
            sx={{
              wordBreak: "break-word",
              display: editState ? "none !important" : "block",
            }}
            color={color}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(textHtml || value),
            }}
          />
        </>
      ) : (
        <Typography variant={variant} id={id} className={className} color={color}>
          {content?.[id] || ""}
        </Typography>
      )}
    </Box>
  );
};

export default CommonDescription;
