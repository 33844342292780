import { PROJECT_CATCH_ERROR_LIST } from "@platformx/utilities";
import graphqlInstance from "../../config/graphqlConfig";
import {
  CREATE_COLLECTION,
  CREATE_COMMUNITY,
  DELETE_ASSET,
  DELETE_COMMUNITY,
  FETCH_ASSETS,
  FETCH_COLLECTION_ITEM,
  FETCH_COMMUNITY_COLLECTION,
  FETCH_CONTENT,
  FETCH_SELECTED_COLLECTION_ITEM,
  FETCH_SELECTED_COLLECTION_ITEM_DETAIL,
  GET_FACET,
  GET_WORKFLOW_TASK_TITLE,
  PUBLISH_ASSET,
} from "../../graphQL/queries/assetQueries";

const assetsApi = {
  FETCH_COMMUNITY_COLLECTION,
  fetchCommunityCollection: async <T>(input: T, reload: boolean): Promise<any> => {
    try {
      const { data } = await graphqlInstance.query({
        query: FETCH_COMMUNITY_COLLECTION,
        variables: input,
        fetchPolicy: reload ? "network-only" : "cache-first",
      });
      return data;
    } catch (err: any) {
      console.error(
        `${PROJECT_CATCH_ERROR_LIST.authoring}:fetchCommunityCollection`,
        err?.graphQLErrors,
      );
      throw err;
    }
  },

  fetchAssets: async <T>(input: T, reload: boolean): Promise<any> => {
    try {
      const { data } = await graphqlInstance.query({
        query: FETCH_ASSETS,
        variables: input,
        fetchPolicy: reload ? "network-only" : "cache-first",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:fetchAssets`, err?.graphQLErrors);
      throw err;
    }
  },
  deleteCommunity: async <T>(input: T): Promise<any> => {
    try {
      const { data } = await graphqlInstance.mutate({
        mutation: DELETE_COMMUNITY,
        variables: input,
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:deleteCommunity`, err);
      throw err;
    }
  },
  deleteAsset: async <T>(input: T): Promise<any> => {
    try {
      const { data } = await graphqlInstance.mutate({
        mutation: DELETE_ASSET,
        variables: input,
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:deleteAsset`, err);
      throw err;
    }
  },

  createCollection: async <T>(input: T): Promise<any> => {
    try {
      const { data } = await graphqlInstance.mutate({
        mutation: CREATE_COLLECTION,
        variables: input,
        // fetchPolicy: '',
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:createCollection`, err);
      throw err;
    }
  },

  createCommunity: async <T>(input: T): Promise<any> => {
    try {
      const { data } = await graphqlInstance.mutate({
        mutation: CREATE_COMMUNITY,
        variables: input,
        // fetchPolicy: '',
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:createCommunity`, err);
      throw err;
    }
  },

  fetchDAMContent: async <T>(input: T): Promise<any> => {
    try {
      const { data } = await graphqlInstance.query({
        query: FETCH_CONTENT,
        variables: input,
        //  fetchPolicy: 'network-only',
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:fetchDAMContent`, err);
      throw err;
    }
  },
  FETCH_COLLECTION_ITEM,
  fetchCollectionItem: async <T>(input: T): Promise<any> => {
    try {
      const { data } = await graphqlInstance.query({
        query: FETCH_COLLECTION_ITEM,
        variables: input,
        //fetchPolicy: reload ? 'network-only' : 'cache-first',
        fetchPolicy: "network-only",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:fetchCollectionItem`, err);
      throw err;
    }
  },

  fetchFacet: async <T>(input: T): Promise<any> => {
    try {
      const { data } = await graphqlInstance.query({
        query: GET_FACET,
        variables: input,
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:fetchFacet`, err);
      throw err;
    }
  },
  publishAsset: async <T>(input: T): Promise<any> => {
    try {
      const { data } = await graphqlInstance.mutate({
        mutation: PUBLISH_ASSET,
        variables: input,
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:publishAsset`, err);
      throw err;
    }
  },
  getTaskTitle: async <T>(input: T): Promise<any> => {
    try {
      const { data } = await graphqlInstance.query({
        query: GET_WORKFLOW_TASK_TITLE,
        variables: input,
        fetchPolicy: "network-only",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:getTaskTitle`, err);
      throw err;
    }
  },
  fetchSelectedCollectionItem: async <T>(input: T): Promise<any> => {
    try {
      const { data } = await graphqlInstance.query({
        query: FETCH_SELECTED_COLLECTION_ITEM,
        variables: input,
        //fetchPolicy: reload ? 'network-only' : 'cache-first',
        fetchPolicy: "network-only",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:fetchCollectionItem`, err);
      throw err;
    }
  },
  fetchSelectedCollectionItemDetail: async <T>(input: T): Promise<any> => {
    try {
      const { data } = await graphqlInstance.query({
        query: FETCH_SELECTED_COLLECTION_ITEM_DETAIL,
        variables: input,
        fetchPolicy: "network-only",
      });
      return data;
    } catch (err: any) {
      console.error(`fetchSelectedCollectionItemDetail:fetchSelectedCollectionItemDetail`, err);
      throw err;
    }
  },
};

export default assetsApi;
