import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useCustomStyle = makeStyles((theme: Theme) => ({
  headerItem: {
    "&.header": {
      borderBottom: `1px solid  ${theme.palette.customColor.LINE}`,
      padding: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& .link": {
        color: theme.palette.customColor.SECONDARY,
        textDecoration: "none",
        whiteSpace: "nowrap",
        marginLeft: "10px",
        fontSize: "12px",
        "&:hover": {
          color: theme.palette.customColor.TERTIARY,
          textDecoration: "underline",
        },
      },
    },
  },
}));
