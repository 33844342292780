import { LOGOUT_URL, getSelectedSite, getSubDomain } from "@platformx/utilities";
import axiosInstance from "./restApiConfig";

const handleLogout = () => {
  const keycloakURL = LOGOUT_URL;
  localStorage.removeItem("userSession");
  localStorage.removeItem("selectedSite");
  localStorage.removeItem("imageUuid");
  localStorage.removeItem("videoUuid");
  window.location.replace(keycloakURL);
};

export const getRequest = async (url) => {
  try {
    const res = await axiosInstance.get(process.env.NX_PUBLIC_API_URI + url, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        sitename: getSelectedSite(),
        site_host: getSubDomain(),
      },
      withCredentials: true,
    });
    return res?.data?.result ? res?.data?.result : res?.data;
  } catch (err: any) {
    if (err?.response?.data?.code === 401 && !url.includes("verify")) {
      handleLogout();
    }
    return err;
  }
};
export const getRequestFromDelivery = async (url) => {
  try {
    const res = await axiosInstance.get(process.env.NX_PUBLIC_DELIVERY_URI + url, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        sitename: getSelectedSite(),
        site_host: getSubDomain(),
      },
      withCredentials: true,
    });
    return res?.data?.result ? res?.data?.result : res?.data;
  } catch (err: any) {
    if (err?.response?.data?.code === 401 && !url.includes("verify")) {
      handleLogout();
    }
    return err;
  }
};

export const putRequest = async (url, payload) => {
  try {
    const res = await axiosInstance.put(process.env.NX_PUBLIC_API_URI + url, payload, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        sitename: getSelectedSite(),
        site_host: getSubDomain(),
      },
      withCredentials: true,
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err: any) {
    if (err?.response?.data?.code === 401) {
      handleLogout();
    }
    return err;
  }
};

export const postRequest = async (url, payload = {}) => {
  try {
    const res = await axiosInstance.post(process.env.NX_PUBLIC_API_URI + url, payload, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        sitename: getSelectedSite(),
        site_host: getSubDomain(),
      },
      withCredentials: true,
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err: any) {
    if (err?.response?.data?.code === 401) {
      handleLogout();
    }
    return err;
  }
};

export const commonPostApiCall = (url, payload = {}) => {
  try {
    return axiosInstance.post(url, payload, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        sitename: getSelectedSite(),
        site_host: getSubDomain(),
      },
    });
  } catch (err: any) {
    return err.response;
  }
};

export const commonPutApiCall = (url, payload = {}) => {
  try {
    return axiosInstance.put(url, payload, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        sitename: getSelectedSite(),
        site_host: getSubDomain(),
      },
    });
  } catch (err: any) {
    return err.response;
  }
};
