import { Box, Typography } from "@mui/material";
import DateAndTimePicker from "../DateAndTimePicker/DateAndTimePicker";

const FormDateAndTimePicker = ({
  item,
  register,
  clearErrors,
  errors,
  selectedElement,
  setSelectedElement,
}) => {
  return (
    <Box className='formDateTimepicker elementWrapper' mt={1}>
      <Typography variant='p4regular' className='form-label' mt={1} mb={1}>
        {item?.title}
      </Typography>
      <DateAndTimePicker
        field={item}
        error={errors}
        register={register}
        state={selectedElement}
        updateState={setSelectedElement}
        clearErrors={clearErrors}
      />
    </Box>
  );
};

export default FormDateAndTimePicker;
