import {
  NEW_LOGOUT_URL,
  getCurrentLang,
  getSelectedSite,
  useAccess,
  useUserSession,
} from "@platformx/utilities";
import { ReactNode } from "react";
import MainLayout from "../layouts/Dashboardlayout/component/MainLayout";

type ProtectedRouteProps = {
  children: ReactNode;
  category: string;
  subCategory: string;
  isHeader?: boolean;
  isSideBar?: boolean;
  hasSearch?: boolean;
  hasLogo?: boolean;
};

export const ProtectedRoute = ({
  children,
  category,
  subCategory,
  isHeader = true,
  isSideBar = true,
  hasSearch = true,
  hasLogo = false,
}: ProtectedRouteProps) => {
  const [getSession, updateSession] = useUserSession();
  const { isActive, permissions } = getSession();
  const { canAccessContent } = useAccess();

  if (!isActive || permissions?.length === 0) {
    updateSession(null);
    window.location.href = NEW_LOGOUT_URL;
  }

  if (isActive && !canAccessContent(category, subCategory)) {
    const selectedSite = getSelectedSite();
    window.location.replace(`/${selectedSite}/${getCurrentLang()}/access-denied`);
    return null;
  }

  return (
    <MainLayout hasSearch={hasSearch} isHeader={isHeader} isSideBar={isSideBar} hasLogo={hasLogo}>
      {children}
    </MainLayout>
  );
};
