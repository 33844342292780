import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    languageDropdownmenuWrapper: {
      height: "20px",
      overflow: "hidden",
      cursor: "pointer",
      "& img": {
        objectFit: "cover",
        width: "auto",
        height: "20px",
      },
    },
    languageDropDownMenuMain: {
      [theme.breakpoints.up("xs")]: {
        marginTop: "10px",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "20px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "35px",
      },
      "& .languageDropDownMenu": {
        boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
        overflow: "visible",
        "& ul": {
          position: "relative",
          padding: 0,
          "& li": {
            minWidth: "251px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: "52px",
            padding: "14px 18px",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "#F7F7FC",
            },
            "&.activeCls": {
              backgroundColor: "#F7F7FC",
            },
            "& .dropdownmenuItemImgWrap": {
              display: "flex",
              alignItems: "center",
              "& .imgFlagIcon": {
                width: "auto",
                height: "20px",
                overflow: "hidden",
                marginRight: "10px",
                "& img": {
                  objectFit: "cover",
                  width: "auto",
                  height: "20px",
                },
              },
            },
            "& .rightTickIcon": {
              display: "flex",
              alignItems: "center",
            },
            "&:first-child": {
              borderRadius: "5px 5px 0 0",
            },
            "&:last-child": {
              borderRadius: "0 0 5px 5px",
            },
          },
          "&:after": {
            content: '""',
            position: "absolute",
            top: "-8px",
            right: "10px",
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderRight: "8px solid transparent",
            borderLeft: "8px solid transparent",
            borderBottom: "8px solid #ffffff",
            borderTop: "0",
            [theme.breakpoints.down("sm")]: {
              right: "10px",
            },
          },
        },
      },
    },
  };
});
