import { authAPI, getGlobalDataWithHeader, multiSiteApi } from "@platformx/authoring-apis";
import {
  AUTH_INFO,
  AUTH_URL,
  getSelectedSite,
  usePlatformAnalytics,
  useUserSession,
} from "@platformx/utilities";
// import { useLocation, useSearchParams } from "react-router-dom-v6";
import { RootState, handleUpdateRoutes } from "@platformx/authoring-state";
import { useDispatch, useSelector } from "react-redux";
import { existingRoutes } from "../router/existingRoutes";
import { fetchRoutes } from "../router/fetchRoutes";
import { createSession } from "../utils/helper";

export const useAuthentication = () => {
  const [handleImpression] = usePlatformAnalytics();
  const [getSession, updateSession] = useUserSession();
  const dispatch = useDispatch();
  const { routes = [] } = useSelector((state: RootState) => state?.routes);

  const fetchDynamicRoutes = async () => {
    if (!routes?.length) {
      const dynamicRoutesList = await fetchRoutes();
      dispatch(handleUpdateRoutes([...existingRoutes, ...dynamicRoutesList]));
    }
  };

  const handleSignIn = async (authCode) => {
    const payload = {
      code: authCode,
      client_id: AUTH_INFO.clientId,
      grant_type: AUTH_INFO.grantType,
      redirect_uri: AUTH_INFO.redirectUri,
      tenant_id: AUTH_INFO.realm,
    };

    try {
      const response = await authAPI.signIn("auth/session", payload);

      if (response && response.data) {
        fetchDynamicRoutes();
        const userDetails = { ...response.data, isActive: "true" };
        const { roles, selected_site } = response.data;

        // Determine user role based on selected site
        const userRole = roles?.find(
          (obj) => obj.site?.toLowerCase() === selected_site?.toLowerCase(),
        )?.name;

        // Update session with user data
        updateSession(createSession(response.data, true, userRole));

        // Send login user info to Analytics End
        handleImpression(userDetails.eventType, userDetails);

        // Fetch global data if the selected site is not 'system'
        if (selected_site?.toLowerCase() !== "system") {
          await getGlobalDataWithHeader(selected_site);
        }

        // Store the selected site in local storage
        localStorage.setItem("selectedSite", response.data.selected_site);

        // Determine language preference
        const lang = response.data.preferred_sites_languages?.[selected_site] || "en";

        // Determine the redirect path based on the selected site
        const redirectPath =
          selected_site?.toLowerCase() === "system" ? `sites/site-listing` : `dashboard`;

        // Redirect the user to the appropriate path
        window.location.replace(
          `${process.env.NX_PUBLIC_BASE_URL}/${selected_site}/${lang}/${redirectPath}`,
        );
      }
    } catch (error: any) {
      // Handle sign-in errors
      console.error("Error signing in:", error);
    }
  };

  const verifySession = async () => {
    try {
      // Send session verification request
      const response = await authAPI.verifySession("auth/verify-session");
      if (response?.data) {
        fetchDynamicRoutes();
        const { active } = response.data || { userDetails: {} };

        const currentSelectedSite = getSelectedSite();
        const storedSelectedSite = localStorage.getItem("selectedSite");

        if (currentSelectedSite === storedSelectedSite) {
          // Update session with active status
          updateSession({
            ...getSession(),
            isActive: active || false,
          });
        } else {
          // Update selected site in local storage
          localStorage.setItem("selectedSite", currentSelectedSite);
          // Fetch permissions for the current selected site
          const res = await multiSiteApi.getPermissions(currentSelectedSite);

          // Update session with new permissions and user info
          updateSession({
            ...getSession(),
            isActive: active || false,
            permissions: res.data?.data?.permissions,
            userInfo: res.data?.data,
            role: res.data?.data?.roles?.find(
              (obj) => obj.site?.toLowerCase() === res.data?.data?.selected_site?.toLowerCase(),
            )?.name,
          });

          const isSystemSite = currentSelectedSite.toLowerCase() === "system";
          const hasUuidValues =
            !localStorage.getItem("imageUuid") || !localStorage.getItem("videoUuid");

          // Fetch global data if necessary
          if (hasUuidValues && !isSystemSite) {
            await getGlobalDataWithHeader(currentSelectedSite);
          }
        }
      } else {
        // Clear session and redirect to authentication URL
        updateSession(null);
        window.location.replace(AUTH_URL);
      }
    } catch (error) {
      // Handle errors as needed
      console.error("Error verifying session:", error);
    }
  };

  return { handleSignIn, verifySession };
};
