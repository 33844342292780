import { Box, Divider, TextField, Typography } from "@mui/material";

const FormTextBoxSetting = ({ selectedElement, updateElement, renderValidation }) => {
  /**
   * Update the textBox placeholder/Title of the selected element.
   * updateElement is a function that updates the selected element with the new value.
   */
  const handleChange = (field, value) => {
    updateElement(selectedElement.id, { [field]: value });
  };

  return (
    <Box className='formTextBox elementWrapper'>
      <Typography variant='h5semibold'>Text Box Setting</Typography>
      <Box mt={1.5} mb={1.5}>
        <Divider />
      </Box>
      <Box mt={1}>
        <Typography variant='p4regular'>Title Text</Typography>
        <TextField
          fullWidth
          margin='dense'
          variant='outlined'
          value={selectedElement?.title}
          onChange={(e) => handleChange("title", e?.target?.value)}
        />
      </Box>
      <Box mt={1}>
        <Typography variant='p4regular'>Placeholder Text</Typography>
        <TextField
          fullWidth
          margin='dense'
          variant='outlined'
          value={selectedElement?.placeholder}
          onChange={(e) => handleChange("placeholder", e?.target?.value)}
        />
      </Box>
      {selectedElement?.validations?.length > 0 && (
        <Box className='validationSection' mt={2}>
          <Typography variant='h6semibold'>Validation</Typography>
          <Box mt={1.5} mb={1.5}>
            <Divider />
          </Box>
          {selectedElement?.validations?.map((validationType, index) => (
            <Box key={index}>{renderValidation(validationType)}</Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default FormTextBoxSetting;
