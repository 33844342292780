import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import { Delivered, Orderconfirmed, Outfordelivery, Shipped } from "../Image";
import StepperItem from "../Stepper/Stepper";

// Define the steps for the order status
const steps = [
  {
    text: "Order Confirmed",
    Icon: Orderconfirmed,
  },
  {
    text: "Shipped",
    Icon: Shipped,
  },
  {
    text: "Delivered",
    Icon: Delivered,
  },
  {
    text: "Returned",
    Icon: Outfordelivery,
  },
];

const OrderDetail = ({ product, onClickBack, authoringHelper }) => {
  // Function to determine the active step based on shipment status
  const getActiveItem = (status) => {
    switch (status) {
      case "Ready":
      case "Pending":
      case "Partial":
      case "Delayed":
        return 1;
      case "Shipped":
        return 2;
      case "Delivered":
        return 3;
      case "Returned":
      case "Back in stock":
      case "Unusable":
        return 4;
      default:
        return 1;
    }
  };

  // Get the active step index
  const activeItem = getActiveItem(
    product?.lineItem[0]?.lineItemShipmentState
      ? product?.lineItem[0]?.lineItemShipmentState
      : product?.shipmentState,
  );

  return (
    <Container
      className={
        authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
      }>
      <Box className='orderDetail'>
        {/* Header with back button and title */}
        <Grid container alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Grid item>
            <IconButton onClick={() => onClickBack()}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant='h3semibold'>Order Details</Typography>
          </Grid>
          <Grid item xs />
        </Grid>

        {/* Order details section */}
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='h6regular'>Order ID</Typography>
            <Typography variant='p4regular' className='marginTopZero' sx={{ maxWidth: "220px" }}>
              {product?.id}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='h6regular'>Contact Details</Typography>
            <Typography variant='p4regular' className='marginTopZero' sx={{ maxWidth: "220px" }}>
              {product?.shippingAddress?.firstName} {product?.shippingAddress?.lastName}{" "}
              {product?.shippingAddress?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='h6regular'>Delivery Address</Typography>
            <Typography variant='p4regular' className='marginTopZero' sx={{ maxWidth: "220px" }}>
              {product?.shippingAddress?.city} {product?.shippingAddress?.state},{" "}
              {product?.shippingAddress?.postalCode} {product?.shippingAddress?.country}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='h6regular'>Your Rewards</Typography>
            <Typography variant='p4regular' className='marginTopZero' sx={{ maxWidth: "220px" }}>
              {/* Replace with actual rewards data if available */}
              {product?.rewards || "No rewards available"}
            </Typography>
          </Grid>
        </Grid>
        <Box className='hr' sx={{ mt: 4, mb: 6 }} />

        {/* Iterate through line items and display each */}
        {product?.lineItem?.map((item, index) => (
          <Grid container sx={{ mb: 2 }} key={index}>
            <Grid item xs={12} md={6}>
              <Box>
                <Box className='productWrapper' sx={{ mb: 2 }}>
                  <Box component='img' src={item?.lineItemImageUrl} alt='Product' />
                </Box>
                <Box>
                  <Typography variant='h6regular' sx={{ textTransform: "capitalize" }}>
                    {item?.name}
                  </Typography>
                  <Typography variant='p4regular'>
                    {product?.attributes?.Brand ? `Brand: ${product.attributes.Brand}` : ""}
                  </Typography>
                  <Typography variant='p4regular'>
                    Serial Number: {item?.productSequenceNumber}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <StepperItem activeItem={activeItem} steps={steps} />
            </Grid>
          </Grid>
        ))}

        {/* <Box className='hr' sx={{ mt: 4, mb: 2 }} /> */}

        {/* Links section */}
        {/* <Grid container alignItems='center'>
          <Grid item xs>
            <Link href='#' variant='p4regular' className='customLink'>
              Purchase Extended Warranty
            </Link>
          </Grid>
          <Grid item>
            <Link href='#' variant='p4regular' className='customLink' sx={{ mr: 2 }}>
              Rate Product
            </Link>
          </Grid>
          <Grid item>
            <Link href='#' variant='p4regular' className='customLink'>
              Need Help?
            </Link>
          </Grid>
        </Grid> */}
      </Box>
    </Container>
  );
};

export default OrderDetail;
