import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { AccountCircle } from "@mui/icons-material";
import "./Styleguide.css";
import BasicButton from "../BasicButton/BasicButton";
import { CounterNumberShowcase } from "../../prelems";
import DropDown from "../DropDown/Dropdown";
import { genderData } from "@platformx/utilities";

const StyleGuide = () => {
  const theme = useTheme();
  const [stateManage, setStateManage] = useState<any>({
    dob: "",
    title: "",
    gender: "",
    country: "",
    emailID: "",
    lastName: "",
    language: "",
    firstName: "",
    description: "",
    mobileNumber: "",
    defaultLanguage: "en",
    ISD: "(IN)  +91",
    timezone: "Asia/Calcutta 22:27:58 GMT+0530 (IST)",
  });

  const handleInputChange = (event: any) => {
    event.preventDefault && event.preventDefault();
    const { name = "", value = "" } = event?.target || {};
    setStateManage({
      ...stateManage,
      [name]: value,
    });
  };
  const [value, setValue] = React.useState("female");

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <Box sx={{ padding: "40px" }}>
      <section className='headings'>
        <h1>Heading H1</h1>
        <Typography variant='weblarge'>PlatX - weblarge</Typography>
        <Divider />
        <Typography variant='h1largebold'>
          The future is in our hands to shape.- h1largebold
        </Typography>
        <Typography variant='h1largesemibold'>
          The future is in our hands to shape.- h1largesemibold
        </Typography>
        <Typography variant='h1largeregular'>
          The future is in our hands to shape.- h1largeregular
        </Typography>
        <Typography variant='h1largemedium'>
          The future is in our hands to shape.- h1largemedium
        </Typography>

        <Divider />
        <h1>Heading H1</h1>
        <Divider />
        <Typography variant='h1bold'>The future is in our hands to shape.- h1bold</Typography>
        <Typography variant='h1semibold'>
          The future is in our hands to shape.- h1semibold
        </Typography>
        <Typography variant='h1regular'>The future is in our hands to shape.- h1regular</Typography>
        <Typography variant='h1medium'>The future is in our hands to shape.- h1medium</Typography>

        <Divider />
        <h1>Heading H2</h1>
        <Divider />

        <Typography variant='h2bold'>The future is in our hands to shape.- h2bold</Typography>
        <Typography variant='h2semibold'>
          The future is in our hands to shape.- h2semibold
        </Typography>
        <Typography variant='h2regular'>The future is in our hands to shape.- h2regular</Typography>
        <Typography variant='h2medium'>The future is in our hands to shape.- h2medium</Typography>

        <Divider />
        <h1>Heading H3</h1>
        <Divider />
        <Typography variant='h3bold'>The future is in our hands to shape.- h3bold</Typography>
        <Typography variant='h3semibold'>
          The future is in our hands to shape.- h3semibold
        </Typography>
        <Typography variant='h3regular'>The future is in our hands to shape.- h3regular</Typography>
        <Typography variant='h3medium'>The future is in our hands to shape.- h3medium</Typography>

        <Divider />
        <h1>Heading H4</h1>
        <Divider />

        <Typography variant='h4bold'>The future is in our hands to shape.- h4bold</Typography>
        <Typography variant='h4semibold'>
          The future is in our hands to shape.- h4semibold
        </Typography>
        <Typography variant='h4regular'>The future is in our hands to shape.- h4regular</Typography>
        <Typography variant='h4medium'>The future is in our hands to shape.- h4medium</Typography>

        <Divider />
        <h1>Heading H5</h1>
        <Divider />

        <Typography variant='h5bold'>The future is in our hands to shape.- h5bold</Typography>
        <Typography variant='h5semibold'>
          The future is in our hands to shape.- h5semibold
        </Typography>
        <Typography variant='h5regular'>The future is in our hands to shape.- h5regular</Typography>
        <Typography variant='h5medium'>The future is in our hands to shape.- h5medium</Typography>

        <Divider />
        <h1>Heading H6</h1>
        <Divider />
        <Typography variant='h6bold'>The future is in our hands to shape.- h6bold</Typography>
        <Typography variant='h6semibold'>
          The future is in our hands to shape.- h6semibold
        </Typography>
        <Typography variant='h6regular'>The future is in our hands to shape.- h6regular</Typography>
        <Typography variant='h6medium'>The future is in our hands to shape.- h6medium</Typography>
      </section>
      {/* paragraphs */}
      <section className='paragraphs'>
        <Divider />
        <h1>Paragraph Variant 1</h1>
        <Divider />
        <Typography variant='p1bold'>
          p1bold: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
        <Typography variant='p1semibold'>
          p1semibold: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
        <Typography variant='p1medium'>
          p1medium: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
        <Typography variant='p1regular'>
          p1regular: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>

        <Divider />
        <h1>Paragraph Variant 2</h1>
        <Divider />
        <Typography variant='p3bold'>
          p3bold: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
        <Typography variant='p2semibold'>
          p2semibold: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
        <Typography variant='p2medium'>
          p2medium: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
        <Typography variant='p2regular'>
          p1regular: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>

        <Divider />
        <h1>Paragraph Variant 3</h1>
        <Divider />
        <Typography variant='p3bold'>
          p3bold: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
        <Typography variant='p3semibold'>
          p3semibold: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
        <Typography variant='p3medium'>
          p3medium: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
        <Typography variant='p3regular'>
          p1regular: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
        <Divider />
        <h1>Paragraph Variant 4</h1>
        <Divider />
        <Typography variant='p4bold'>
          p4bold: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
        <Typography variant='p4semibold'>
          p4semibold: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
        <Typography variant='p4medium'>
          p4medium: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
        <Typography variant='p4regular'>
          p1regular: It is a long established fact that a reader will be distracted by the readable
          content
        </Typography>
      </section>
      <section className='buttons'>
        <Box sx={{ background: theme?.palette?.prelemType1.BACKGROUND }}>
          <Divider />
          <h1>Primary Buttons(white BG)</h1>
          <Divider />
          <Button variant='primaryButton1'>primaryButton1</Button>
          <BasicButton
            variant='primaryButton2'
            buttonDataObj={{ Button_Value: "primaryButton2" }}
          />
          <BasicButton
            variant='primaryButton3'
            buttonDataObj={{ Button_Value: "primaryButton3" }}
          />
        </Box>
        <Divider />
        <h1>Secondary Buttons (Black BG)</h1>
        <Divider />
        <Box sx={{ background: theme?.palette?.prelemType2?.BACKGROUND }}>
          <BasicButton
            variant='secondaryButton1'
            buttonDataObj={{ Button_Value: "secondaryButton1" }}
          />
          <BasicButton
            variant='secondaryButton2'
            buttonDataObj={{ Button_Value: "secondaryButton2" }}
          />
          <BasicButton
            variant='secondaryButton3'
            buttonDataObj={{ Button_Value: "secondaryButton3" }}
          />
        </Box>
        <Divider />
        <h1>Tertiary Buttons (Grey BG)</h1>
        <Divider />
        <Box sx={{ background: theme?.palette?.prelemType3?.BACKGROUND }}>
          <BasicButton
            variant='tertiaryButton1'
            buttonDataObj={{ Button_Value: "tertiaryButton1" }}
          />
          <BasicButton
            variant='tertiaryButton2'
            buttonDataObj={{ Button_Value: "tertiaryButton2" }}
          />
          <BasicButton
            variant='tertiaryButton3'
            buttonDataObj={{ Button_Value: "tertiaryButton3" }}
          />
        </Box>
      </section>
      <section className='Forms'>
        <Divider />
        <h1>TextBox and dropdown</h1>
        <Divider />
        <Grid container>
          <h5>variant: filled and standard </h5>
          <Grid container gap={1}>
            <Grid item sm={12} md={3}>
              <TextField
                fullWidth
                margin='normal'
                label='Input Label'
                variant='filled'
                helperText='Example Input Message for success or error'
                className='primary iconLeft'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CloseIcon className='textfield-close-icon' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <TextField
                fullWidth
                margin='normal'
                label='Input Label'
                variant='filled'
                helperText='Example Input Message for success or error'
                className='primary iconLeft'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span className='textfield-icon-left'>
                        <AccountCircle />
                      </span>
                      <CloseIcon className='textfield-close-icon' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <TextField
                fullWidth
                disabled
                margin='normal'
                label='Input Label'
                variant='filled'
                helperText='Example Input Message for success or error'
                className='primary iconLeft'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span className='textfield-icon-left'>
                        <AccountCircle />
                      </span>
                      <CloseIcon className='textfield-close-icon' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <h5>variant: filled and standard success state</h5>
          <Grid container gap={1}>
            <Grid item sm={12} md={3}>
              <TextField
                fullWidth
                margin='normal'
                label='Input Label'
                variant='filled'
                helperText='Example Input Message for success or error...'
                className='success'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CloseIcon className='textfield-close-icon' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <TextField
                fullWidth
                margin='normal'
                label='Input Label'
                variant='filled'
                helperText='Example Input Message for success or error'
                className='success iconLeft'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span className='textfield-icon-left'>
                        <AccountCircle />
                      </span>
                      <CloseIcon className='textfield-close-icon' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <TextField
                fullWidth
                disabled
                margin='normal'
                label='Input Label'
                variant='filled'
                helperText='Example Input Message for success or error'
                className='success iconLeft'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span className='textfield-icon-left'>
                        <AccountCircle />
                      </span>
                      <CloseIcon className='textfield-close-icon' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <h5>variant: filled and standard error state</h5>
          <Grid container gap={1}>
            <Grid item sm={12} md={3}>
              <TextField
                fullWidth
                margin='normal'
                label='Input Label'
                variant='filled'
                helperText='Example Input Message for success or error'
                className='error'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CloseIcon className='textfield-close-icon' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <TextField
                fullWidth
                margin='normal'
                label='Input Label'
                variant='filled'
                helperText='Example Input Message for success or error'
                className='error iconLeft'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span className='textfield-icon-left'>
                        <AccountCircle />
                      </span>
                      <CloseIcon className='textfield-close-icon' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <TextField
                fullWidth
                disabled
                margin='normal'
                label='Input Label'
                variant='filled'
                helperText='Example Input Message for success or error'
                className='error iconLeft'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span className='textfield-icon-left'>
                        <AccountCircle />
                      </span>
                      <CloseIcon className='textfield-close-icon' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <h5>variant: Selectbox filled and standard error state</h5>
          <Grid container gap={1}>
            <DropDown
              name='gender'
              arrayData={genderData()}
              label='Gender'
              cssClass='input-control-selectbox'
              value={stateManage.gender}
              parentHandler={handleInputChange}
              stateValue={stateManage.gender}></DropDown>
            <FormControl>
              <FormLabel id='demo-controlled-radio-buttons-group'>Gender</FormLabel>
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={value}
                onChange={handleChangeRadio}>
                <FormControlLabel value='female' control={<Radio />} label='Female' />
                <FormControlLabel value='male' control={<Radio />} label='Male' />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </section>
      <CounterNumberShowcase />
    </Box>
  );
};
export default StyleGuide;
