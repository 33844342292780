import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useSitesMenuStyle = makeStyles((theme: Theme) => ({
  box: {
    display: "flex",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("xs")]: {
      flexDirection: "row-reverse",
    },
  },
  dropdowncontain: {
    display: "flex",
    justifyContent: "space-between",
    height: "44px",
    borderRadius: "5px",
    background: theme.palette.customColor.ACCENTS.ACCENT_6,
    padding: "10px 6px",
    cursor: "pointer",
    marginBottom: "20px",
    marginTop: "5px",
    alignItems: "center",
    "& svg": {
      color: theme.palette.customColor.LABEL,
    },
  },
  dropdowntypo: {
    marginTop: "2px",
    overflow: "hidden",
    textTransform: "capitalize",
    textOverflow: "ellipsis",
  },
  dropNewTypo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& .avatarWp": {
      backgroundColor: theme.palette.customColor.BGLIGHT,
      width: "24px",
      height: "24px",
      borderRadius: "5px",
      border: `0.5px solid ${theme.palette.customColor.ACCENTS.ACCENT_5}`,
      textTransform: "uppercase",
      marginRight: "6px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& p": {
        color: theme.palette.customColor.ACCENTS.ACCENT_3,
      },
    },
    "& .textMaxChar": {
      maxWidth: "148px",
    },
  },
  dropdownimg: {
    width: "11px",
  },
}));

export default useSitesMenuStyle;
