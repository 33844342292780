import Tooltip from "@mui/material/Tooltip";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

interface PropType {
  component: JSX.Element;
  doAccess: boolean;
  position?: any;
  className?: string;
  tooltipMsg?: string;
}

export const ErrorTooltip: FC<PropType> = ({
  component: Component,
  doAccess,
  position = "left",
  className,
  tooltipMsg = "",
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Tooltip
      className={className}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: theme?.palette?.customColor?.PRIMARY,
            color: theme?.palette?.customColor?.LIGHT,
            fontSize: theme?.fontSize?.fontSize_14,
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            width: "200px",
            textAlign: "center",
            padding: "5px 20px",
            border: "0.1px solid rgba(0, 0, 0, 0.1)",
            marginRight: "5px",
          },
        },
      }}
      title={doAccess ? (tooltipMsg !== "" ? tooltipMsg : t("error_tooltip_msg")) : ""}
      placement={position}>
      <span style={{ cursor: "pointer" }}>{Component}</span>
    </Tooltip>
  );
};
