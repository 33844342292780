import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    autoTextAreaWrapper: {
      "&.autoTextArea": {
        width: "100%",
        resize: "none",
        padding: "14px",
        borderRadius: theme.borderRadius.value,
        fontFamily: theme.fontFamily.primary,
        fontSize: "14px",
        "&:focus": {
          border: `${theme.palette.borderFocusedColor} !important`,
        },
        "&::placeholder": {
          color: theme.palette.customColor.PLACEHOLDER,
        },
      },
    },
  };
});
