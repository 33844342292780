import { Box, Button, Dialog, Grid, TextField, Typography } from "@mui/material";
import { PopupImage } from "@platformx/shared/static-assets";
import { ShowToastError } from "@platformx/utilities";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useCustomStyle } from "./CreateNewForm.styles";

const CreateNewForm = ({ isDialogOpen, closeButtonHandle }) => {
  const { t } = useTranslation();
  const classes = useCustomStyle();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (formData.title) {
      navigate("/content/create-formbuilder", { state: { ...formData } });
    } else {
      ShowToastError("Title is required");
    }
  };
  return (
    <Dialog
      fullWidth
      open={isDialogOpen}
      onClose={closeButtonHandle}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={`${classes.formbuilderBgWrapper} formbuilderBg`}
      sx={{
        ".Platform-x-Dialog-paper": {
          padding: 0,
          width: { xs: "100%" },
          borderRadius: "5px",
          maxWidth: { sx: "100%", md: "700px", em: "800px" },
          margin: "15px",
        },
      }}>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box className='createPagePopupLeft'>
              <Box>
                <Box className='createPagePopuprowBox'>
                  <Typography id='alert-dialog-title' component='h2' variant='h3semibold'>
                    Form Title
                  </Typography>
                </Box>
                <Box className='createPagePopuprowBox'>
                  <Typography variant='h5regular'>Please enter the form title below*</Typography>
                  <TextField
                    autoFocus
                    placeholder='Enter form title'
                    margin='dense'
                    id='formTitle'
                    type='text'
                    fullWidth
                    variant='filled'
                    autoComplete='off'
                    name='title'
                    value={formData.title}
                    onChange={handleChange}
                    sx={{
                      ".Platform-x-Input-root:after": {
                        borderBottom: `1px solid primary`,
                      },
                    }}
                  />
                </Box>
                <Box className='createPagePopuprowBox'>
                  <Typography variant='h5regular'>
                    Please enter the form description below
                  </Typography>
                  <TextField
                    margin='dense'
                    id='formDescription'
                    type='text'
                    placeholder='Enter form description'
                    fullWidth
                    variant='filled'
                    autoComplete='off'
                    name='description'
                    value={formData.description}
                    onChange={handleChange}
                    sx={{
                      ".Platform-x-Input-root:after": {
                        borderBottom: `1px solid primary`,
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box className='createPagePopupButtonWp'>
                <Button
                  disableElevation
                  variant='secondaryButton'
                  onClick={() => closeButtonHandle()}
                  data-testid='form-close-button'>
                  {t("close")}
                </Button>
                <Button variant='primaryButton' disableElevation type='submit'>
                  Done
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className='createPagePopupRight'>
            <Box className='popupRightImage'>
              <img src={PopupImage} alt='Create Page Popup' />
            </Box>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default CreateNewForm;
