import { FormControlLabel, Radio, Typography, useTheme } from "@mui/material";

export const RadioLabelWithSubheading = ({ value, label = "", subTitle = "" }: any) => {
  const theme = useTheme();
  return (
    <>
      <FormControlLabel
        data-testid='radiobutton'
        sx={{
          ".Platform-x-FormControlLabel-label": {
            fontSize: theme?.fontSize?.fontSize_16,
            fontWeight: 500,
          },
          marginRight: "30px",
        }}
        value={value}
        control={<Radio />}
        label={label}
      />
      <Typography
        variant='h6regular'
        sx={{
          color: theme?.palette.customColor?.LABEL,
          paddingLeft: "30px",
          display: "block",
          marginTop: "-8px",
        }}>
        {subTitle}
      </Typography>
    </>
  );
};
