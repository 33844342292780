import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import "../../Style.css";
import { getOrderHistoryData } from "./HelperFunction";
import OrderDetail from "./OrderDetail/OrderDetail";
import OrderHistory from "./OrderHistory/OrderHistory";
import { useCustomStyle } from "./ZaggEcommerce.style";

const ZaggEcommerce = ({ authoringHelper, secondaryArgs }) => {
  const classes = useCustomStyle(); // Custom styles for the component
  const [loader, setLoader] = useState(false); // State to manage the loading state
  const [products, setProducts] = useState<any>([]); // State to store the fetched products
  const [selectedProduct, setSelectedProduct] = useState(null); // State to store the selected product details
  const [page, setPage] = useState(0); // State to track the current page
  const [hasMore, setHasMore] = useState(false); // State to track if there are more products to load

  // Function to fetch order history data
  const apiCalls = async () => {
    const url = secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq;
    const siteHost = secondaryArgs?.sitename;
    // eslint-disable-next-line no-console
    console.log("secondaryArgs", secondaryArgs);
    setLoader(true); // Set loader to true while data is being fetched
    // Fetch order history data
    const productsData = await getOrderHistoryData(url, siteHost, "", false);
    // Access the correct data structure
    const fetchedProducts = productsData?.data?.publish_getOrderHistory?.data || [];
    const fetchedProductsTotal = productsData?.data?.publish_getOrderHistory?.total;
    const fetchedProductsTotalRows = productsData?.data?.publish_getOrderHistory?.rows;
    setProducts(fetchedProducts); // Update the products state with fetched data

    if (fetchedProductsTotal > fetchedProductsTotalRows) {
      setHasMore(true); // Update page for next fetch
    } else {
      setHasMore(false); // No more data to load
    }
    setLoader(false); // Set loader to false after data is fetched
  };

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    apiCalls();
  }, []);

  // Function to handle order click and fetch order details
  const onClickOrder = async (orderId) => {
    const url = secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq;
    const siteHost = secondaryArgs?.sitename;
    setLoader(true); // Set loader to true while data is being fetched
    const productsData = await getOrderHistoryData(url, siteHost, orderId, false);
    // Access the correct data structure
    const fetchedProducts = productsData?.data?.publish_getOrderHistory?.data[0] || [];
    setSelectedProduct(fetchedProducts); // Update the selected product state with fetched data
    setLoader(false); // Set loader to false after data is fetched
  };
  // Function to handle search button click
  const handleSearchClick = async (orderId) => {
    const url = secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq;
    const siteHost = secondaryArgs?.sitename;
    setLoader(true); // Set loader to true while data is being fetched
    // Fetch order history data
    const productsData = await getOrderHistoryData(url, siteHost, orderId, true);
    // Access the correct data structure
    const fetchedProducts = productsData?.data?.publish_getOrderHistory?.data || [];
    setHasMore(false);
    setProducts(fetchedProducts); // Update the selected product state with fetched data
    setLoader(false); // Set loader to false after data is fetched
  };
  // Function to handle back button click and reset selected product
  const onClickBack = () => {
    setSelectedProduct(null); // Reset the selected product state
  };
  // Function to load more orders for infinite scroll
  const fetchMoreData = async () => {
    const nextPage = page + 10;
    const row = nextPage + 10;
    const url = secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq;
    const siteHost = secondaryArgs?.sitename;
    const moreProducts = await getOrderHistoryData(url, siteHost, "", false, nextPage, row);
    const fetchMoreProducts = moreProducts?.data?.publish_getOrderHistory?.data || [];
    const fetchedProductsTotal = moreProducts?.data?.publish_getOrderHistory?.total;

    if (row > fetchedProductsTotal) {
      setHasMore(false); // No more data to load
    } else {
      setProducts((prevProducts) => [...prevProducts, ...fetchMoreProducts]);
      setPage(nextPage); // Update page for next fetch
      setHasMore(true);
    }
  };
  return (
    <Box className={`${classes.zaggEcommerceWarpper} zaggEcommerceContainer`}>
      {/* Conditional rendering based on whether a product is selected or not */}
      {!selectedProduct ? (
        <OrderHistory
          products={products}
          loader={loader}
          onClickOrder={onClickOrder}
          authoringHelper={authoringHelper}
          handleSearchClick={handleSearchClick}
          callResetApi={apiCalls}
          loadMoreOrders={fetchMoreData} // Pass loadMoreOrders function to OrderHistory
          hasMoreOrders={hasMore} // Pass hasMore state to OrderHistory
        />
      ) : (
        <OrderDetail
          product={selectedProduct}
          onClickBack={onClickBack}
          authoringHelper={authoringHelper}
        />
      )}
    </Box>
  );
};

export default ZaggEcommerce;
