import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  contentRowContainer: {
    display: "flex",
    alignItems: "center",
    padding: "15px",
    borderBottom: `1px solid  ${theme.palette.customColor.LINE}`,
    justifyContent: "space-between",
    "&:last-child": {
      borderBottom: 0,
    },
  },
  contentRowText: {
    cursor: "pointer",
    paddingRight: "25px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    wordBreak: "break-all",
    "&:hover": {
      textDecoration: "underline",
      fontWeight: 600,
    },
  },
  arrowIcon: {
    position: "relative",
    display: "inline-block",
    width: "24px",
    height: "24px",
    overflow: "hidden",
    transition: "transform 0.3s ease",
    "& .wraperIcon": {
      marginTop: 0,
      transition: "all 0.8s",
      cursor: "pointer",
      "& svg": {
        fill: theme.palette.customColor.PLACEHOLDER,
      },
    },
    "&:hover": {
      "& .wraperIcon": {
        marginTop: "-30px",
        "& svg": {
          fill: theme.palette.customColor.PRIMARY,
        },
      },
    },
  },
}));
