import { Box, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAccess from "../../hooks/useAccess/useAccess";
import { ErrorTooltip } from "../ErrorTooltip/ErrorTooltip";
import { useStyles } from "./XMenu.styles";

const XMenuItem = ({ category, subCategory, action, menuCallBack, menuAction, icon, label }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { canAccessAction } = useAccess();
  const handleMenu = () => {
    menuCallBack(menuAction);
  };
  return (
    <ErrorTooltip
      component={
        <MenuItem
          disableRipple
          disabled={!canAccessAction(category, subCategory, action)}
          onClick={handleMenu}
          className={classes.menuItemHover}>
          <Box className={classes.menuItemContainer}>
            {icon && <img src={icon} alt='img' />}
            {label && <Typography variant='p2regular'>{t(label)}</Typography>}
          </Box>
        </MenuItem>
      }
      doAccess={!canAccessAction(category, subCategory, action)}
    />
  );
};

export default XMenuItem;
