import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderTop: `1px solid ${theme?.palette?.customColor?.LINE}`,
    margin: "auto",
    padding: "30px 0",
    [theme.breakpoints.up("xs")]: {
      width: "90%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "77%",
    },
  },
  TableHead: {
    backgroundColor: theme?.palette?.customColor?.BGWEAK,
  },
  tableContainer: {
    border: `1px solid ${theme?.palette?.customColor?.LINE}`,
    boxShadow: "none",
  },
}));
