import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Loader } from "@platformx/utilities";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import StringOnBlurTextBox from "../../../components/TextBox/StringTextBoxComponent/StringOnBlurTextBox";
import { AmountWithCurrency, dateFormat } from "../HelperFunction";
import OrderListSkeleton from "./OrderListSkeleton";

// Component to display order history
const OrderHistory = ({
  products,
  loader,
  onClickOrder,
  authoringHelper,
  handleSearchClick,
  callResetApi,
  loadMoreOrders, // New prop to load more orders
  hasMoreOrders, // New prop to indicate if there are more orders to load
}) => {
  const [searchTerm, setSearchTerm] = useState(""); // State to manage search term
  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Callback function to be used by StringOnBlurTextBox
  const handleClearText = () => {
    callResetApi(); // Call the reset API
    setSearchTerm(""); // Clear the search term
  };

  return (
    <>
      {loader ? (
        // Show loader if data is still loading
        <Loader />
      ) : (
        // Show order history if data is loaded
        <Container
          className={
            authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
          }>
          <Box className='orderHistory'>
            <Box>
              {/* Header section with title and search box */}
              <Grid container alignItems='center' sx={{ mb: 2 }}>
                <Grid item>
                  <Typography variant='h3semibold'>Purchase History</Typography>
                </Grid>
                <Grid item xs />
                <Grid item>
                  <Box sx={{ width: 550 }}>
                    <StringOnBlurTextBox
                      maxLength={50}
                      name='companyName'
                      label='Search Product'
                      cssClass='input-control-textbox'
                      value={searchTerm}
                      handleChange={handleSearchChange} // Handle search input change
                      errorMessage={""}
                      onCloseIconClick={handleClearText}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => handleSearchClick(searchTerm)} // Handle search button click
                    variant='primaryButton1'
                    sx={{ m: 0, ml: 2 }}>
                    Search Orders
                  </Button>
                </Grid>
              </Grid>
              {/* Infinite Scroll container to display order list */}
              <Box className='orderlist' id='scrollableDiv'>
                {products && products.length > 0 ? (
                  <InfiniteScroll
                    dataLength={products?.length} // Current length of products array
                    next={loadMoreOrders} // Function to load more orders
                    hasMore={hasMoreOrders} // Indicates if there are more orders to load
                    loader={<OrderListSkeleton />} // Loader component to show while loading more orders
                    scrollableTarget='scrollableDiv'>
                    {/* Table to display order list */}
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {/* Loop through orders and display each order's line items */}
                          {products.map((order) => (
                            <React.Fragment key={order?.id}>
                              {order?.lineItem.map((lineItem) => (
                                <TableRow key={lineItem?.productSequenceNumber}>
                                  {/* Display product image */}
                                  <TableCell sx={{ pl: 0, width: 120 }}>
                                    <Box className='productWrapper'>
                                      <Box
                                        component='img'
                                        src={lineItem?.lineItemImageUrl}
                                        alt={lineItem?.name}
                                      />
                                    </Box>
                                  </TableCell>
                                  {/* Display product name and brand */}
                                  <TableCell>
                                    <Typography
                                      variant='h5semibold'
                                      sx={{ textTransform: "capitalize" }}>
                                      {lineItem?.name}
                                    </Typography>
                                    <Typography variant='p4regular' className='marginTopZero'>
                                      {lineItem?.attributes?.Brand
                                        ? `Brand: ${lineItem?.attributes?.Brand}`
                                        : ""}
                                    </Typography>
                                  </TableCell>
                                  {/* Display order status and date */}
                                  <TableCell align='right'>
                                    <Typography variant='h5regular'>
                                      {order?.shipmentState === "Delivered"
                                        ? `Delivered on ${dateFormat(order?.lastModifiedAt)}`
                                        : `Ordered on ${dateFormat(order?.createdAt)}`}
                                    </Typography>
                                    <Typography
                                      variant='p4regular'
                                      color='textSecondary'
                                      className='marginTopZero'>
                                      {lineItem?.lineItemShipmentState
                                        ? lineItem?.lineItemShipmentState
                                        : order?.shipmentState}
                                    </Typography>
                                  </TableCell>
                                  {/* Display product price and rate link */}
                                  <TableCell align='right'>
                                    <Typography variant='h4semibold'>
                                      {AmountWithCurrency(lineItem?.price)}
                                    </Typography>
                                    <Typography
                                      variant='p4regular'
                                      color='#AA1A2D'
                                      className='marginTopZero'
                                      onClick={() => onClickOrder(lineItem?.productSequenceNumber)}>
                                      <span
                                        style={{ textDecoration: "underline", cursor: "pointer" }}>
                                        View Detail
                                      </span>
                                    </Typography>
                                  </TableCell>
                                  {/* Display button to view order details */}
                                  <TableCell align='right'>
                                    <IconButton
                                      onClick={() => onClickOrder(lineItem?.productSequenceNumber)}>
                                      <ArrowRightAltIcon
                                        fontSize='large'
                                        sx={{ color: "#14142B" }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </InfiniteScroll>
                ) : (
                  <Typography variant='h4semibold' align='center'>
                    No data found
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
};

export default OrderHistory;
