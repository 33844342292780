import { axiosInstance } from "@platformx/authoring-apis";
import { CROP_TYPE, getSelectedSite, getSubDomain } from "@platformx/utilities";
import { useState } from "react";
import { IMAGE_CROP_API_END_PATH } from "../utils/constants";

export const usePostImageCrop = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const cropRequest = async (
    type: string,
    payload: any,
    callback: any,
    selectedImg: any,
    ratioName?: any,
  ) => {
    const path = `${IMAGE_CROP_API_END_PATH}${type}`;
    try {
      setIsLoading(true);
      const res = await axiosInstance.post(process.env.NX_PUBLIC_API_URI + path, payload, {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          sitename: getSelectedSite(),
          site_host: getSubDomain(),
        },
        withCredentials: true,
      });
      let { data } = res;

      if (type === CROP_TYPE.NOCROP) {
        data = {
          ...data,
          images: [
            {
              aspect_ratio: ratioName,
              folder_path: data?.original_image_relative_path,
            },
          ],
        };
      }

      callback(data, selectedImg);
      return data;
    } catch (err: any) {
      setError(err);
      callback(err?.response?.data, selectedImg);
    } finally {
      setIsLoading(false);
    }
  };

  return { cropRequest, isLoading, error };
};
