/* eslint-disable wrap-regex */
import { Box, Container, Grid, Typography } from "@mui/material";
import {
  Analytics,
  AuthoringHelper,
  SecondaryArgs,
  formCroppedUrlString,
  handleHtmlTags,
} from "@platformx/utilities";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import BasicButton from "../../components/BasicButton/BasicButton";
import ImageRender from "../../components/ImageRender";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import prelemTypes from "../../globalStyle";
import { useCustomStyle } from "./FeatureInfoCards.style";

// ts-ignore
const FeatureInfoCards = ({
  content,
  analytics,
  authoringHelper,
  secondaryArgs,
}: //secondaryArgs,
FeatureInfoCardsProp) => {
  const classes = useCustomStyle();
  const globalClasses = prelemTypes();
  const ButtonObj1 = {
    Button_Name: "Button1_Name",
    Button_RedirectURL: "Button1_RedirectURL",
    Button_Type: "Button1_Type",
    Button_Value: "Button1_Value",
    Button_Action: "Button1_Action",
    Button_Content: "Button1_Content",
  };
  const ButtonDataObj1 = {
    Button_Name: content?.Button1_Name,
    Button_RedirectURL: content?.Button1_RedirectURL,
    Button_Type: content?.Button1_Type,
    Button_Value: content?.Button1_Value,
    Button_Action: content?.Button1_Action,
    Button_Content: content?.Button1_Content,
  };
  const ButtonObj2 = {
    Button_Name: "Button2_Name",
    Button_RedirectURL: "Button2_RedirectURL",
    Button_Type: "Button2_Type",
    Button_Value: "Button2_Value",
    Button_Action: "Button2_Action",
    Button_Content: "Button2_Content",
  };
  const ButtonDataObj2 = {
    Button_Name: content?.Button2_Name,
    Button_RedirectURL: content?.Button2_RedirectURL,
    Button_Type: content?.Button2_Type,
    Button_Value: content?.Button2_Value,
    Button_Action: content?.Button2_Action,
    Button_Content: content?.Button2_Content,
  };
  const ButtonObj3 = {
    Button_Name: "Button3_Name",
    Button_RedirectURL: "Button3_RedirectURL",
    Button_Type: "Button3_Type",
    Button_Value: "Button3_Value",
    Button_Action: "Button3_Action",
    Button_Content: "Button3_Content",
  };
  const ButtonDataObj3 = {
    Button_Name: content?.Button3_Name,
    Button_RedirectURL: content?.Button3_RedirectURL,
    Button_Type: content?.Button3_Type,
    Button_Value: content?.Button3_Value,
    Button_Action: content?.Button3_Action,
    Button_Content: content?.Button3_Content,
  };
  const firstRender = useRef(true);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const defaultStructureData = () => {
    let FeatureInfoCardsStructureData;
    const { original_image_relative_path, ext }: any =
      content?.ImageCompound?.ImageCompound_1?.original_image || {};
    const img = formCroppedUrlString(
      secondaryArgs?.gcpUrl,
      secondaryArgs?.bucketName,
      original_image_relative_path,
      ext,
    ).src;

    try {
      FeatureInfoCardsStructureData = {
        "@context": "https://schema.org/",
        "@type": "ContactPage",
        description: handleHtmlTags(content?.description1),
        image: img,
        name: content?.title1,
      };
    } catch (e) {
      FeatureInfoCardsStructureData = {};
    }

    return FeatureInfoCardsStructureData;
  };

  const generateStructureData = () => {
    let FeatureInfoCardsStructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData);

    if (firstRender.current === true) {
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        FeatureInfoCardsStructureData = JSON.parse(tempSD);
      } else {
        FeatureInfoCardsStructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      FeatureInfoCardsStructureData = defaultStructureData();
    }
    return FeatureInfoCardsStructureData;
  };

  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = generateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content?.description1,
    content?.ImageCompound?.ImageCompound_1?.original_image,
    content?.title1,
  ]);

  usePrelemImpression(analytics, inView, secondaryArgs);

  /* AnalyticsEnabled dependency added as many times we are not getting analytics provider*async call)
  1. we are first checking in publish app if analytics provider is avaiable or not
  2. if its available we are setting AnalyticsEnabled to true
  3. if its not available we are setting false
*/
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.FeatureInfoCardsWrapper} ${globalClasses.prelemType1} prelem prelemType1 FeatureInfoCardsBg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
        }
        ref={ref}>
        <Box className='FeatureInfoCards'>
          <Grid className='mainBox' container>
            <Grid xs={12} em={4}>
              <Box className='cardBox card1'>
                <Box className='leftcontent'>
                  <Box>
                    <Typography variant='h3semibold' id='title1'>
                      {content?.title1}
                    </Typography>
                    <Typography variant='p3regular' id='description1'>
                      {content?.description1}
                    </Typography>
                  </Box>
                  <BasicButton
                    openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
                    isAuthoring={analytics?.isAuthoring}
                    currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
                    variant='secondaryButton1'
                    analyticsEnabled={analytics?.isAnalyticsEnabled}
                    ButtonObj={ButtonObj1}
                    isEditing={authoringHelper?.isEditing}
                    buttonDataObj={ButtonDataObj1}
                    secondaryArgs={secondaryArgs}
                    analytics={analytics}
                  />
                </Box>
                <Box className='imgbox'>
                  <ImageRender
                    originalImage={content?.ImageCompound?.ImageCompound_1?.original_image}
                    publishedImages={content?.ImageCompound?.ImageCompound_1?.published_images}
                    secondaryArgs={secondaryArgs}
                    imgOrder={{
                      1440: "portrait",
                      1280: "portrait",
                      1024: "portrait",
                      768: "portrait",
                      600: "portrait",
                      320: "portrait",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} em={4}>
              <Box className='cardBox card2'>
                <Box className='leftcontent'>
                  <Box>
                    <Typography variant='h3semibold' id='title2'>
                      {content?.title2}
                    </Typography>
                    <Typography variant='p3regular' id='description2'>
                      {content?.description2}
                    </Typography>
                  </Box>
                  <BasicButton
                    openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
                    isAuthoring={analytics?.isAuthoring}
                    currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
                    variant='secondaryButton1'
                    analyticsEnabled={analytics?.isAnalyticsEnabled}
                    ButtonObj={ButtonObj2}
                    isEditing={authoringHelper?.isEditing}
                    buttonDataObj={ButtonDataObj2}
                    secondaryArgs={secondaryArgs}
                    analytics={analytics}
                  />
                </Box>
                <Box className='imgbox'>
                  <ImageRender
                    originalImage={content?.ImageCompound?.ImageCompound_2?.original_image}
                    publishedImages={content?.ImageCompound?.ImageCompound_2?.published_images}
                    secondaryArgs={secondaryArgs}
                    imgOrder={{
                      1440: "portrait",
                      1280: "portrait",
                      1024: "portrait",
                      768: "portrait",
                      600: "portrait",
                      320: "portrait",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} em={4}>
              <Box className='cardBox card3'>
                <Box className='leftcontent'>
                  <Box>
                    <Typography variant='h3semibold' id='title3'>
                      {content?.title3}
                    </Typography>
                    <Typography variant='p3regular' id='description3'>
                      {content?.description3}
                    </Typography>
                  </Box>
                  <BasicButton
                    openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
                    isAuthoring={analytics?.isAuthoring}
                    currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
                    variant='secondaryButton1'
                    analyticsEnabled={analytics?.isAnalyticsEnabled}
                    ButtonObj={ButtonObj3}
                    isEditing={authoringHelper?.isEditing}
                    buttonDataObj={ButtonDataObj3}
                    secondaryArgs={secondaryArgs}
                    analytics={analytics}
                  />
                </Box>
                <Box className='imgbox'>
                  <ImageRender
                    originalImage={content?.ImageCompound?.ImageCompound_3?.original_image}
                    publishedImages={content?.ImageCompound?.ImageCompound_3?.published_images}
                    secondaryArgs={secondaryArgs}
                    imgOrder={{
                      1440: "portrait",
                      1280: "portrait",
                      1024: "portrait",
                      768: "portrait",
                      600: "portrait",
                      320: "portrait",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

interface FeatureInfoCardsProp {
  content: Content;
  analytics: Analytics;
  authoringHelper?: AuthoringHelper;
  secondaryArgs: SecondaryArgs;
}

interface Content {
  title1?: string;
  description1?: string;
  Button1_Action?: string;
  Button1_Content?: string;
  Button1_Name?: string;
  Button1_RedirectURL?: string;
  Button1_RestEndPonit?: string;
  Button1_Type?: string;
  Button1_Value?: string;

  title2?: string;
  description2?: string;
  Button2_Action?: string;
  Button2_Content?: string;
  Button2_Name?: string;
  Button2_RedirectURL?: string;
  Button2_RestEndPonit?: string;
  Button2_Type?: string;
  Button2_Value?: string;

  title3?: string;
  description3?: string;
  Button3_Action?: string;
  Button3_Content?: string;
  Button3_Name?: string;
  Button3_RedirectURL?: string;
  Button3_RestEndPonit?: string;
  Button3_Type?: string;
  Button3_Value?: string;
  TagName?: string;
  ImageCompound: {
    ImageCompound_1: {
      published_images: Image[];
      original_image?: object;
    };
    ImageCompound_2: {
      published_images: Image[];
      original_image?: object;
    };
    ImageCompound_3: {
      published_images: Image[];
      original_image?: object;
    };
  };
}
interface Image {
  aspect_ratio: string;
  bucket_path: string;
  folder_path: string;
  visibility: string;
  ext: string;
}

FeatureInfoCards.defaultProps = {
  content: {
    Button1_Action: "External",
    Button1_Content: "",
    Button1_Name: "Button1_Name",
    Button1_RedirectURL: "www.google.com",
    Button1_RestEndPoint: "RestEndPoint 1",
    Button1_Type: "Button1_Type",
    Button1_Value: "Read More",
    Button2_Action: "External",
    Button2_Content: "",
    Button2_Name: "Button2_Name",
    Button2_RedirectURL: "www.google.com",
    Button2_RestEndPoint: "RestEndPoint 2",
    Button2_Type: "Button2_Type",
    Button2_Value: "Read More",

    Button3_Action: "External",
    Button3_Content: "",
    Button3_Name: "Button3_Name",
    Button3_RedirectURL: "www.google.com",
    Button3_RestEndPoint: "RestEndPoint 2",
    Button3_Type: "Button3_Type",
    Button3_Value: "Read More",
    TagName: "SiteComponents",
    title1: "Health Package",
    title2: "men",
    title3: "woman",
    description1: "woman",
    description2: "woman",
    description3: "woman",
    PrelemContentType: ["Select"],
    ImageCompound: {
      ImageCompound_1: {
        original_image: {
          original_image_relative_path:
            "machine_assets/1690282743892/public/png/ExpertiseShowcase2",
          visibility: "public",
          ext: "png",
          bitStreamId: "",
          auto: true,
          MetaFields: {
            AltText: "Imagecard3",
            Name: "Imagecard3",
            Title: "Imagecard3",
            Description: "This is for Imagecard3",
            Attribution: false,
          },
        },
        published_images: [
          {
            aspect_ratio: "portrait",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-portrait",
          },
          {
            aspect_ratio: "card1",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card1",
          },
          {
            aspect_ratio: "landscape",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-landscape",
          },
          {
            aspect_ratio: "hero",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-hero",
          },
          {
            aspect_ratio: "square",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-square",
          },
          {
            aspect_ratio: "card2",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card2",
          },
        ],
      },
      ImageCompound_2: {
        original_image: {
          original_image_relative_path:
            "machine_assets/1690282794843/public/png/ExpertiseShowcase4",
          visibility: "public",
          ext: "png",
          bitStreamId: "",
          auto: true,
          MetaFields: {
            AltText: "Imagecard4",
            Name: "Imagecard4",
            Title: "Imagecard4",
            Description: "This is for Imagecard4",
            Attribution: false,
          },
        },
        published_images: [
          {
            aspect_ratio: "landscape",
            folder_path: "machine_assets/1690282794843/public/png/ExpertiseShowcase4-landscape",
          },
          {
            aspect_ratio: "square",
            folder_path: "machine_assets/1690282794843/public/png/ExpertiseShowcase4-square",
          },
          {
            aspect_ratio: "portrait",
            folder_path: "machine_assets/1690282794843/public/png/ExpertiseShowcase4-portrait",
          },
          {
            aspect_ratio: "hero",
            folder_path: "machine_assets/1690282794843/public/png/ExpertiseShowcase4-hero",
          },
          {
            aspect_ratio: "card1",
            folder_path: "machine_assets/1690282794843/public/png/ExpertiseShowcase4-card1",
          },
          {
            aspect_ratio: "card2",
            folder_path: "machine_assets/1690282794843/public/png/ExpertiseShowcase4-card2",
          },
        ],
      },
      ImageCompound_3: {
        original_image: {
          original_image_relative_path:
            "machine_assets/1690282743892/public/png/ExpertiseShowcase2",
          visibility: "public",
          ext: "png",
          bitStreamId: "",
          auto: true,
          MetaFields: {
            AltText: "Imagecard4",
            Name: "Imagecard4",
            Title: "Imagecard4",
            Description: "This is for Imagecard4",
            Attribution: false,
          },
        },
        published_images: [
          {
            aspect_ratio: "portrait",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-portrait",
          },
          {
            aspect_ratio: "card1",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card1",
          },
          {
            aspect_ratio: "landscape",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-landscape",
          },
          {
            aspect_ratio: "hero",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-hero",
          },
          {
            aspect_ratio: "square",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-square",
          },
          {
            aspect_ratio: "card2",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card2",
          },
        ],
      },
    },
  },
  authoringHelper: {
    innerRef: null,
    sendStructureDataToAuthoringCB: () => {},
    sendDefaultStructureDataForResetToAuthoringCB: () => {},
    openButtonEditWindowInAuthoringCB: () => {},
    selectedButtonNameForEditing: "",
    isEditing: false,
    buttonRef: null,
    buttonContentEditable: false,
    lastSavedStructuredData: "",
    isEditPage: false,
  },

  analytics: {
    isAnalyticsEnabled: true,
    isSeoEnabled: false,
    isAuthoring: false,
    position: 0,
    pageId: 1234,
    prelemId: 2345,
    pageTitle: "Prelem Title",
    pageDesc: "Prelem Description",
    pageTags: "Page Tags1, page tagg2",
    prelemTags: "Prelem Tags1, Prelem tagg2",
  },
  secondaryArgs: {
    gcpUrl: "https://storage.googleapis.com",
    bucketName: "cropped_image_public",
    prelemBaseEndpoint: {
      deliveryEndPoint: "https://platx-delivery-dev.fanuep.com/platform-x/",
      APIEndPoint: "https://platx-api-dev.fanuep.com/platform-x/",
      device: "window",
      buttonBaseUrl: "https://platx-publish-dev.fanuep.com/",
    },
    editState: false,
    multiSlot: {},
  },
};

export default FeatureInfoCards;
