const hideFilterArray = ["Community", "tag"];
export const CONTENT_CONSTANTS = {
  ALL: "ALL",
  PUBLISHED: "PUBLISHED",
  DRAFT: "DRAFT",
  UNPUBLISHED: "UNPUBLISHED",
};

/**
 * pass contentType
 * if u want hide in filter in content type add in into array
 * @param type
 */
export const contentTypeBasedHideFilter = (type = "") => {
  return !hideFilterArray.some((ele) => ele === type);
};

export const contentTypeSearchBox = (type) => {
  return ["tag"].includes(type);
};
