import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { IconButton, useTheme } from "@mui/material";

export const BackArrow = ({ handleReturn, styles = {} }) => {
  const theme = useTheme();

  return (
    <IconButton
      aria-label='west'
      size='medium'
      onClick={handleReturn}
      disableRipple
      sx={{ paddingLeft: "0px", marginRight: "5px", ...styles }}
      disableFocusRipple>
      <KeyboardBackspaceRoundedIcon sx={{ color: theme.palette.customColor.PRIMARY }} />
    </IconButton>
  );
};
