import { Box, Grid, Typography, useTheme } from "@mui/material";
import { RootState } from "@platformx/authoring-state";
import { ContentTypeCard } from "@platformx/prelems";
import { CircleActionsSuccess, NoResults } from "@platformx/shared/static-assets";
import { AUTH_INFO, NoContentFound } from "@platformx/utilities";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { ContentProps } from "./utils/contentGalleryTypes";

const GalleryItems = ({
  galleryObj,
  loading,
  isLazyLoad,
  multi_select,
  fetchMoreData,
  multiSelectedHandle,
  singleSelectedHandle,
  single_select,
}) => {
  const { routes = [] } = useSelector((state: RootState) => state?.routes);
  const [selectedItem, setSelectedItem] = useState<ContentProps>({});
  const [onHover, setOnHover] = useState(null);
  const theme = useTheme();

  const secondaryArgs = {
    gcpUrl: AUTH_INFO.gcpUri,
    bucketName: AUTH_INFO.gcpBucketName,
  };
  const isError = false;

  const handleSelectedItem = (item, index) => {
    if (multi_select) {
      multiSelectedHandle(item, index); //passing data to parent
    } else if (single_select) {
      singleSelectedHandle(item, index);
    } else {
      if (selectedItem === item) {
        setSelectedItem({});
      } else {
        setSelectedItem(item);
      }
    }
  };
  const getContentType = (item, index) => {
    return (
      <Grid
        container
        xs={6}
        sm={4}
        md={4}
        lg={3}
        onMouseEnter={() => setOnHover(index)}
        onMouseLeave={() => setOnHover(null)}
        sx={{
          opacity: item?.is_checked ? 0.5 : 1,
          position: "relative",
          float: "left",
          width: "100%",
          cursor: single_select || multi_select ? "pointer" : "default",
          height: "auto",
          aspectRatio: "1/1",
          ":hover": {
            ".hoverOverlay": {
              backgroundColor: "rgba(0,0,0,0.5)",
            },
          },
        }}
        onClick={() => handleSelectedItem(item, index)}
        key={index}
        p={1}>
        <ContentTypeCard
          content={item}
          secondaryArgs={secondaryArgs}
          isLoading={false}
          onHover={onHover}
          index={index}
          contentTypeList={routes}
        />
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            m: "8px",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: "linear-gradient(rgba(20, 20, 43, 0), rgba(0, 0, 0, .6))",
          }}
          p={1}>
          <Box>{item?.is_checked ? <img src={CircleActionsSuccess} alt='img' /> : null}</Box>
        </Box>
      </Grid>
    );
  };
  return (
    <div>
      {isError ? (
        <Box
          sx={{
            marginTop: "200px",
            marginBottom: "100px",
            textAlign: "center",
          }}>
          <img src={NoResults} alt='' />
          <Typography variant='h3semibold'>Failed to fetch results</Typography>
        </Box>
      ) : (
        <Box
          sx={{
            height: "calc(100vh - 160px)",
            overflowY: "scroll",
          }}
          id='scrollablegallerydiv'>
          {galleryObj && galleryObj?.length === 0 && !loading ? (
            <Box
              sx={{
                width: "100%",
                height: "75%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <NoContentFound />
            </Box>
          ) : (
            <Grid
              container
              sx={{
                backgroundColor: theme?.palette?.customColor?.BGLIGHT,
                borderRadius: theme?.borderRadius.value,
                ".infinite-scroll-component__outerdiv": {
                  width: "inherit",
                },
              }}>
              <InfiniteScroll
                dataLength={galleryObj.length}
                next={fetchMoreData}
                hasMore={isLazyLoad}
                loader={null}
                scrollableTarget='scrollablegallerydiv'>
                {galleryObj?.map((item, index) => {
                  return getContentType(item, index);
                })}
              </InfiniteScroll>
            </Grid>
          )}
        </Box>
      )}
    </div>
  );
};

export default GalleryItems;
