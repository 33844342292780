import AddLinkIcon from "@mui/icons-material/AddLink";
import Close from "@mui/icons-material/Close";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import { Box, Theme } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { relativeImageURL } from "@platformx/utilities";
import React from "react";
import "./BlogContent.css";

const useStyles = makeStyles((theme: Theme) => ({
  buttonArea: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    border: `1px solid ${theme.palette.customColor.LINE}`,
    borderRadius: 2,
    backgroundColor: theme.palette.customColor.BGLIGHT,
    marginLeft: "10px",
    height: "42px",
    "& .MuiButtonBase-root ": {
      borderRadius: "0px !important",
      width: "40px ",
      fontSize: "18px",
      height: "40px ",
      "& .Platform-x-SvgIcon-root ": {
        fontSize: "18px",
      },
      "&:hover, &.Mui-focusVisible ": {
        backgroundColor: theme.palette.customColor.BGLIGHT,
        color: theme.palette.customColor.PRIMARY,
        borderRadius: "0px !important ",
      },

      ":disabled ": { color: `${theme.palette.customColor.SECONDARY} !important` },
    },
  },
  button: {
    border: `1px solid ${theme.palette.customColor.LINE} !important`,
    color: `${theme.palette.customColor.PLACEHOLDER} !important`,
    height: "40px !important ",
    "&:hover, &.Mui-focusVisible ": { backgroundColor: theme.palette.customColor.PLACEHOLDER },
  },
  saveDisabled: {
    border: `1px solid ${theme.palette.customColor.LINE} !important`,
    color: `${theme.palette.customColor.PLACEHOLDER} !important`,
    height: "40px !important ",
    fontSize: `${theme.fontSize?.fontSize_14} !important`,
  },
  publishDisabled: {
    border: `1px solid ${theme.palette.customColor.LINE} !important`,
    backgroundColor: `${theme.palette.customColor.PLACEHOLDER} !important`,
    color: `${theme.palette.customColor.PLACEHOLDER} !important`,
    height: "40px !important ",
    fontSize: `${theme.fontSize?.fontSize_14} !important`,
  },
  publishActive: {
    backgroundColor: `${theme.palette.customColor.PRIMARY} !important`,
    color: theme.palette.customColor.LIGHT,
    height: "40px !important ",
    padding: "8px 15px",
    minWidth: "110px",
    "&:hover": {
      color: theme.palette.customColor.LIGHT,
    },
  },
  saveActive: {
    color: `${theme.palette.customColor.PRIMARY} !important`,
    border: `1px solid ${theme.palette.customColor.LINE} !important`,
    height: "40px !important ",
    padding: "8px 15px",
    minWidth: "110px",
  },
}));

const BlogContentTypeWeb = (_props: any) => {
  const {
    isStar = false,
    isCode = false,
    contentItem = {},
    selectedVideo = {},
    selectedImage = {},
    isQuoteOpen = false,
    showGallery = () => {},
    onRemoveImage = () => {},
    onRemoveVideo = () => {},
    starClickHandel = () => {},
    codeClickHandel = () => {},
    quoteClickHandel = () => {},
    onRemoveContentType = () => {},
    linkClickHandle,
    isLinkOpen,
  } = _props;

  const { Thumbnail: imgThumbnail = "" } = selectedImage;
  const { Thumbnail: videoThumbnail = "" } = selectedVideo;
  const {
    Thumbnail: { Url: contentThumbnail = "" } = {},
    background_content: { Color = "" } = {},
  } = contentItem;
  const classes = useStyles();
  const tempHide = false;

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}>
      <Box
        className={classes.buttonArea}
        sx={{ mr: 2, "@media (width:1280px)": { marginRight: "8px" } }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "",
          }}>
          {/* image */}
          {imgThumbnail ? (
            <Box className='imageThumbnailWrapper'>
              <IconButton onClick={onRemoveImage} data-testid='blog-remove-img-web'>
                <Close />
              </IconButton>
              <img
                className='img-background'
                alt='thumbImg1'
                src={relativeImageURL(imgThumbnail)}
              />
            </Box>
          ) : (
            <Box>
              <IconButton
                disabled={isQuoteOpen}
                onClick={() => showGallery("Images")}
                data-testid='blog-show-img-gallery-web'>
                <InsertPhotoOutlinedIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Divider orientation='vertical' flexItem />

        {/* video */}
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "",
          }}>
          {videoThumbnail ? (
            <Box className='videoThumbnailWrapper'>
              <IconButton onClick={onRemoveVideo} data-testid='blog-remove-video-web'>
                <Close />
              </IconButton>
              <img
                className='img-background'
                alt='thumbImg1'
                src={relativeImageURL(videoThumbnail)}
              />
            </Box>
          ) : (
            <Box>
              <IconButton
                disabled={isQuoteOpen}
                onClick={() => showGallery("Videos")}
                data-testid='blog-show-video-gallery-web'>
                <PlayCircleOutlineIcon />
              </IconButton>
            </Box>
          )}
        </Box>

        <Divider orientation='vertical' flexItem />

        {/* contentType */}
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "",
          }}>
          {contentThumbnail ? (
            <Box className='contentThumbnailWrapper'>
              <IconButton onClick={onRemoveContentType} data-testid='blog-remove-content-web'>
                <Close />
              </IconButton>
              {Color ? (
                <Box sx={{ backgroundColor: Color, height: "100%", width: "100%" }}></Box>
              ) : (
                <img className='img-background' alt='thumbImg1' src={contentThumbnail} />
              )}
            </Box>
          ) : (
            <Box>
              <IconButton
                disabled={isQuoteOpen}
                onClick={() => showGallery("content")}
                data-testid='blog-show-content-gallery-web'>
                <CollectionsOutlinedIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>

      <Box className={classes.buttonArea}>
        {tempHide && (
          <IconButton
            onClick={codeClickHandel}
            data-testid='blog-code-handle-web'
            sx={{
              backgroundColor: isCode ? "#e6eaed" : "#fff",
              borderRadius: isCode ? "0px" : "",
            }}
            disabled={isQuoteOpen}>
            <UnfoldMoreOutlinedIcon className='rotateIcon90' />
          </IconButton>
        )}

        {/* <Divider orientation='vertical' flexItem /> */}
        <IconButton
          onClick={quoteClickHandel}
          data-testid='blog-quote-handle-web'
          sx={{
            backgroundColor: isQuoteOpen ? "#e6eaed" : "#fff",
            borderRadius: isQuoteOpen ? "0px" : "",
          }}
          disabled={imgThumbnail || videoThumbnail || contentThumbnail ? true : false}>
          <FormatQuoteOutlinedIcon />
        </IconButton>
        <Divider orientation='vertical' flexItem />
        <IconButton
          onClick={starClickHandel}
          data-testid='blog-start-handle-web'
          sx={{
            backgroundColor: isStar ? "#e6eaed" : "#fff",
            borderRadius: isStar ? "0px" : "",
          }}>
          <StarOutlineOutlinedIcon />
        </IconButton>
        <Divider orientation='vertical' flexItem />
        <IconButton
          onClick={linkClickHandle}
          data-testid='blog-link-handle-web'
          sx={{
            backgroundColor: isLinkOpen ? "#e6eaed" : "#fff",
            borderRadius: isLinkOpen ? "0px" : "",
          }}>
          <AddLinkIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
export default React.memo(BlogContentTypeWeb);
