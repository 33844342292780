import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { assetsApi } from "@platformx/authoring-apis";
import { ArrowDownBlack, FolderBlackLarge } from "@platformx/shared/static-assets";
import { AUTH_INFO } from "@platformx/utilities";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ASSETS, AUTHOR, SUBCOMMUNITIES } from "../../utils/constants";
import AssetFilter from "./AssetFilter";
import { AuthorMenu } from "./AuthorMenu";
import useDamContent from "./DamContent.style";
import DamContentCard from "./DamContentCard";
import DamContentLeftSidebar from "./DamContentLeftSidebar";
import DamContentTopHeading from "./DamContentTopHeading";
import "./DamGallery.css";
import { FilterDropdown } from "./FilterDropdown";
import { SortByMenu } from "./SortByMenu";
import TagMenu from "./TagMenu";

type DamContentGalleryProps = {
  assetType: "Image" | "Video";
  handleImageSelected?: any;
  handleSelectedVideo?: any;
  toggleGallery: any;
  keyName?: string;
  id?: any;
  processing?: boolean;
  dialogOpen?: any;
  isCrop?: boolean;
};

// Initial state for collection items and pagination
const COLLECTION_ITEM = {
  collectionItem: [],
  page: {},
};

export const DamContentGallery = (_props: DamContentGalleryProps) => {
  const {
    dialogOpen,
    assetType,
    handleImageSelected,
    handleSelectedVideo,
    toggleGallery,
    keyName,
    processing,
  } = _props;
  const { t } = useTranslation();
  const { fetchFacet, fetchDAMContent } = assetsApi;
  const [data, setData] = useState(COLLECTION_ITEM);
  const [menuData, setMenuData] = useState(COLLECTION_ITEM);
  const [loading, setLoading] = useState(true);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [filterVal, setFilter] = useState("default");
  const [sortBy, setSortBy] = useState<"ASC" | "DESC">("DESC");
  const ROWS = 16;
  const [isLazyLoad, setIsLazyLoad] = useState<boolean>(true);
  const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
  const [label, setLabel] = useState<{ author: { label: string }[]; tag: { label: string }[] }>({
    author: [],
    tag: [],
  }); // Ensure correct initialization
  const classes = useDamContent();
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const [uuid, setUuid] = useState<any>(
    assetType === "Image" ? AUTH_INFO.dspaceImagesUuid : AUTH_INFO.dspaceVideosUuid,
  );
  const [author, setAuthor] = useState("");
  const [tag, setTag] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<any>("");
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [searchItem, setSearchTerm] = useState<any>("");
  const [scheduleNewDateVal, setScheduleNewDateVal] = useState(null);
  const [scheduleEndDateVal, setScheduleEndDateVal] = useState(null);
  const [imageData, setImageData] = useState({
    Thumbnail: "",
    Title: "",
    Description: "",
    Author: "",
    bitStreamId: "",
    Url: "",
    bundlesUrl: "",
  });

  const [open, setOpen] = useState(false);

  // Function to toggle the drawer
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const menuOpenStates = {
    folder: filterVal === "folder",
    date: filterVal === "date",
    tag: filterVal === "tag",
    author: filterVal === "author",
  };

  const {
    folder: folderMenuOpen,
    date: dateMenuOpen,
    tag: tagMenuOpen,
    author: authorMenuOpen,
  } = menuOpenStates;

  const removetag = () => {
    setAuthor("");
    setTag([]);
  };
  // Function to get data from DAM based on index and search term
  const getFacet = async () => {
    try {
      const { authorList, tagList } = await fetchFacet({
        scope_id: uuid,
        facet_name: AUTHOR,
      });
      setLabel({ author: authorList, tag: tagList });
    } catch (error) {
      //console.log(error);
    }
  };

  // Fetch DAM content based on index and filter
  const getDAMData = async (index, filter, searchTerm = "") => {
    try {
      const tags: any = {};
      if (filter.author) tags.author = filter.author;
      if (filter.tag?.length > 0) tags.label = filter.tag;
      const { authoring_getAssets = {} } = await fetchDAMContent({
        uuid: uuid,
        start: index,
        rows: ROWS,
        search: searchTerm,
        entityType: assetType,
        assetType: ASSETS,
        tags: tags,
        sortBy: sortBy,
      });
      if (authoring_getAssets?.collectionItem?.length > 0) {
        setData({
          collectionItem: authoring_getAssets.collectionItem || [],
          page: authoring_getAssets.page || {},
        });
        if (authoring_getAssets?.collectionItem?.length < 16) {
          setIsLazyLoad(false);
        } else {
          setIsLazyLoad(true);
        }
      } else {
        setData({
          collectionItem: [],
          page: {},
        });
        setIsLazyLoad(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsLazyLoad(false);
    }
  };

  // Fetch additional DAM content for infinite scrolling
  const fetchMoreDAMData = async (index, searchTerm = "") => {
    try {
      const { authoring_getAssets = {} } = await fetchDAMContent({
        uuid: uuid,
        start: index,
        rows: ROWS,
        search: searchTerm,
        entityType: assetType,
        assetType: ASSETS,
        tags: { author: author },
        sortBy: sortBy,
      });
      let newCollectionItem: any = [];
      if (authoring_getAssets?.collectionItem?.length < 16) {
        setIsLazyLoad(false);
      }
      if (
        authoring_getAssets.collectionItem &&
        typeof authoring_getAssets.collectionItem[Symbol.iterator] === "function"
      ) {
        newCollectionItem = [...data.collectionItem, ...authoring_getAssets.collectionItem];
      } else if (authoring_getAssets.collectionItem) {
        newCollectionItem = [...data.collectionItem, authoring_getAssets.collectionItem];
      } else {
        newCollectionItem = [...data.collectionItem];
        setIsLazyLoad(false);
      }
      setData({
        ...data,
        collectionItem: newCollectionItem,
        page: { ...data.page, ...authoring_getAssets.page },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsLazyLoad(false);
    }
  };
  // Function to get DAM content based on index and search term

  const getDAMcontent = async (index, filter, searchTerm = "") => {
    setLoading(true);
    getDAMData(index, filter, searchTerm);
  };

  // Function to fetch more DAM content

  const fetchMoreDAMcontent = async (index, searchTerm = "") => {
    setIsLazyLoad(true);
    fetchMoreDAMData(index, searchTerm);
  };

  // Function to get DAM menu content based on search term

  const getDamMenucontent = async (searchTerm = "") => {
    setCategoryLoading(true);
    try {
      const { authoring_getAssets = {} } = await fetchDAMContent({
        uuid: assetType === "Image" ? AUTH_INFO.dspaceImagesUuid : AUTH_INFO.dspaceVideosUuid,
        start: 0,
        rows: 16,
        search: searchTerm,
        entityType: "ALL",
        assetType: SUBCOMMUNITIES,
        tags: {},
        sortBy: "DESC",
      });

      setMenuData({
        collectionItem: authoring_getAssets.subcommunitiesList || [],
        page: authoring_getAssets.page || {},
      });

      setCategoryLoading(false);
    } catch (error) {
      setCategoryLoading(false);
    }
  };
  /**
   * pagination infinity scroll
   */

  const fetchMoreData = () => {
    const nextIndex = startIndex + 1;
    setStartIndex(() => nextIndex);
    fetchMoreDAMcontent(nextIndex, searchItem);
  };
  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    const filter = {
      author,
      tag,
    };
    getDAMcontent(0, filter, searchTerm);
  };

  const handleDoneClick = async () => {
    if (assetType === "Image") {
      handleImageSelected(imageData, keyName);
      // if (!isCrop) toggleGallery(false, "done", keyName);
    } else {
      try {
        handleSelectedVideo({ ...imageData, Url: imageData.bundlesUrl });
      } catch (error) {
        //console.log(error);
      }
      toggleGallery(false, "done", keyName);
    }
  };

  // Initial fetch for menu content

  useEffect(() => {
    getDamMenucontent();
  }, []);
  // Initial fetch for DAM content

  useEffect(() => {
    const nextIndex = 0;
    const filter = {
      author: "",
      tag: [],
    };
    removetag();
    setStartIndex(() => nextIndex);
    getDAMcontent(nextIndex, filter, searchItem);
  }, [uuid]);

  useEffect(() => {
    const nextIndex = 0;
    const filter = {
      author: author,
      tag: tag,
    };
    setStartIndex(() => nextIndex);
    getDAMcontent(nextIndex, filter, searchItem);
  }, [author, sortBy]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const nextIndex = 0;
      const filter = {
        author: author,
        tag: tag,
      };
      setStartIndex(() => nextIndex);
      getDAMcontent(nextIndex, filter, searchItem);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [tag]);

  useEffect(() => {
    getFacet();
  }, [uuid]);

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent className={classes.dampadding}>
        <Box className='damcontent_container'>
          <DamContentTopHeading /*Component for the top heading, search bar, and action buttons*/
            onSearch={handleSearch}
            inputValue={inputValue}
            setInputValueHandle={setInputValue}
            assetType={assetType}
            toggleGallery={toggleGallery}
            handleDoneClick={handleDoneClick}
            imageData={imageData}
            menuData={menuData}
            toggleDrawer={toggleDrawer}
            loading={processing}
          />
          <Typography variant='h3semibold' className={classes.typographyshow}>
            {assetType === "Image" ? t("showing_all_images") : t("showing_all_videos")}
          </Typography>
          <Grid container className={classes.parent}>
            <Grid item>
              <Box
                className={classes.filterbox}
                onClick={(e) => setAnchorE2(e.currentTarget)}
                data-testid='set-anchor'>
                <img src={FolderBlackLarge} style={{ filter: "brightness(0)" }} alt='folder' />
                <Typography variant='p3regular' className={classes.typoText}>
                  Search By: {filterVal || "Default"}
                </Typography>
                <img
                  style={{ transform: folderMenuOpen ? "none" : "rotate(270deg)" }}
                  src={ArrowDownBlack}
                  alt='icon'
                />
              </Box>
              <FilterDropdown /*filter dropdown*/
                setFilter={setFilter}
                anchorE2={anchorE2}
                setAnchorE2={setAnchorE2}
                filter={filterVal}
                setSearchQuery={setSearchQuery}
              />
            </Grid>
            <Grid item>
              <SortByMenu
                setSortBy={setSortBy}
                sortBy={sortBy} /*sort filter menu for new and old images*/
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={0}>
            {folderMenuOpen && (
              <Grid
                container
                className='leftsidebar-scroll'
                item
                xs={12}
                em={3}
                lg={2.5}
                sx={{
                  display: { xs: "none", em: "block" },
                  borderRight: { xs: "none", em: `1px solid ${theme.palette.customColor.LINE}` },
                }}>
                {filterVal !== "default" && (
                  <Grid item>
                    <Box className='damcontentfolder'>
                      <AssetFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    </Box>
                  </Grid>
                )}

                <DamContentLeftSidebar /* Left sidebar*/
                  toggleDrawer={toggleDrawer}
                  loading={categoryLoading}
                  menuData={menuData}
                  setUuid={setUuid}
                  assetType={assetType}
                  uuid={uuid}
                  searchQuery={searchQuery}
                />
              </Grid>
            )}
            {dateMenuOpen && (
              <Grid
                container
                className='leftsidebar-scroll'
                item
                xs={12}
                em={3}
                lg={2.5}
                sx={{
                  display: { xs: "none", em: "block" },
                  borderRight: { xs: "none", em: `1px solid ${theme.palette.customColor.LINE}` },
                }}>
                <Box className={classes.datepickerbox}>
                  <Typography variant='h5semibold'>Start Date</Typography>
                  <Box className={classes.topboxmargin}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        inputFormat='DD/MMM/YYYY'
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                          />
                        )}
                        value={scheduleNewDateVal}
                        onChange={(newValue) => {
                          setScheduleNewDateVal(newValue); // Update start date value
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box className={classes.datepickerbox}>
                  <Typography variant='h5semibold'>End Date</Typography>
                  <Box className={classes.topboxmargin}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        inputFormat='DD/MMM/YYYY'
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                          />
                        )}
                        value={scheduleEndDateVal}
                        onChange={(newValue) => {
                          setScheduleEndDateVal(newValue); // Update end date value
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Grid>
            )}
            {tagMenuOpen && (
              <Grid
                container
                className='leftsidebar-scroll'
                item
                xs={12}
                em={3}
                lg={2.5}
                sx={{
                  display: { xs: "none", em: "block" },
                  borderRight: { xs: "none", em: `1px solid ${theme.palette.customColor.LINE}` },
                }}>
                {filterVal !== "default" && (
                  <Grid item>
                    <Box className='damcontentfolder'>
                      <AssetFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    </Box>
                  </Grid>
                )}
                <TagMenu
                  label={label}
                  tag={tag}
                  setTag={setTag}
                  searchQuery={searchQuery.toLocaleLowerCase()}
                />
              </Grid>
            )}
            {authorMenuOpen && (
              <Grid
                container
                className='leftsidebar-scroll'
                item
                xs={12}
                em={3}
                lg={2.5}
                sx={{
                  display: { xs: "none", em: "block" },
                  borderRight: { xs: "none", em: `1px solid ${theme.palette.customColor.LINE}` },
                }}>
                {filterVal !== "default" && (
                  <Grid item>
                    <Box className='damcontentfolder'>
                      <AssetFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    </Box>
                  </Grid>
                )}

                <AuthorMenu /* Author Menu for selecting the author from the menubar*/
                  setAuthor={setAuthor}
                  label={label}
                  author={author}
                  searchQuery={searchQuery.toLowerCase()}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              em={filterVal === "default" ? 12 : 9}
              lg={filterVal === "default" ? 12 : 9.5}
              className={` right-topbar-container`}>
              <DamContentCard /* getting the images*/
                data={data}
                isLazyLoad={isLazyLoad}
                fetchMoreData={fetchMoreData}
                isLoading={loading}
                setImageData={setImageData}
                folderMenuOpen
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
