import { makeStyles } from "@mui/styles";

export const useTagMenuStyle = makeStyles(() => ({
  boxscrolltag: {
    overflowY: "scroll",
    height: "425px",
    marginTop: "25px",
  },

  tagboxn: {
    width: "100%",
  },
  tagaccordiandeatail: {
    display: "flex",
    gap: "8px",
    flexWrap: "wrap",
    maxHeight: "305px",
    overflowY: "auto",
  },

  tagmockval: {
    gap: "5px !important",
  },
  typospace: {
    marginLeft: "10px",
  },
  boxshadow: {
    boxShadow: "none !important",
  },
  tagbtn: {
    fontSize: "14px !important",
    fontWeight: 400,
  },
}));
