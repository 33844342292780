import { fallBackImage } from "@platformx/shared/static-assets";
import { formCroppedUrl } from "@platformx/utilities";

type HeaderLogoType = {
  data: any;
  width: string | undefined;
  height: string | undefined;
  gcpUrl: string | undefined;
  bucketName: string | undefined;
  handleIconRedirect: (e?: any) => void;
};
const HeaderLogo = ({
  data = {},
  width = "",
  height = "",
  gcpUrl = "",
  bucketName = "",
  handleIconRedirect = () => {},
}: HeaderLogoType) => {
  const splitExt: any = data?.header_logo?.split(".") || [];

  return (
    <picture>
      <source
        onClick={handleIconRedirect}
        data-testid='header-icon-redirect'
        srcSet={formCroppedUrl(gcpUrl, bucketName, splitExt?.[0], "webp")}
        type='image/webp'
      />
      <source
        onClick={handleIconRedirect}
        srcSet={formCroppedUrl(gcpUrl, bucketName, splitExt?.[0], splitExt?.[1])}
      />
      <img
        src={formCroppedUrl(gcpUrl, bucketName, splitExt?.[0], "webp")}
        onError={(e: any) => {
          if (e.target.src !== fallBackImage) {
            e.target.onerror = null;
            e.target.src = fallBackImage;
          }
        }}
        className='pointer'
        width={width}
        height={height}
        alt='logo'
        style={{ objectFit: "cover", display: "flex" }}
      />
    </picture>
  );
};

export default HeaderLogo;
