import Dialog from "@mui/material/Dialog";
import DynamicContentGallery from "./DynamicContentGallery";

export default function ContentGalleryModal({
  isDialogOpen,
  onClose,
  handleSelectedContent,
  selectedItem,
  multi_select,
  single_select,
  name,
  allowed_content,
  allowed_collection,
}: any) {
  return (
    <Dialog
      fullWidth
      open={isDialogOpen}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{
        ".Platform-x-Dialog-paper": {
          maxWidth: "100%",
          width: "100%",
          maxHeight: "100vh",
          height: "100vh",
          margin: "0px",
          bottom: { xs: 0 },
          borderBottomLeftRadius: { xs: 0, md: 4 },
          borderBottomRightRadius: { xs: 0, md: 4 },
        },
      }}>
      <DynamicContentGallery
        handleSelectedContent={handleSelectedContent}
        onToggleContentGallery={onClose}
        selectedFilters={{}}
        multi_select={multi_select}
        selectedItem={selectedItem}
        single_select={single_select}
        name={name}
        allowed_content={allowed_content}
        allowed_collection={allowed_collection}
      />
    </Dialog>
  );
}
