import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function MyStorySkeleton() {
  const skeletonItems = [1, 2, 3]; // An array to iterate over and generate skeletons

  return (
    <Grid container spacing={2} paddingTop={2}>
      {skeletonItems.map((_, index) => (
        <Grid item xs={12} lg={4} px='15px' key={index}>
          <Stack spacing={1} paddingBottom='10px'>
            <Skeleton
              variant='rectangular'
              sx={{ height: "390px", width: "100%", borderRadius: "8px" }}
            />
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}
