import { LOGOUT_URL, PROJECT_CATCH_ERROR_LIST } from "@platformx/utilities";
import graphqlInstance from "../../config/graphqlConfig";
import { AuthQueries } from "../../graphQL/queries/authQueries";
import axiosInstance from "../../config/restApiConfig";
import { ApolloError } from "@apollo/client";

const handleLogout = () => {
  localStorage.removeItem("path");
  window.location.replace(LOGOUT_URL);
};
const authAPI = {
  verifySession: async (url: string) => {
    try {
      const res = await axiosInstance.get(process.env.NX_PUBLIC_API_URI + url, {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        withCredentials: true,
      });
      return res.data;
    } catch (err: any) {
      if (err?.response?.data?.code === 401 && !url.includes("verify")) {
        handleLogout();
      }
      return err;
    }
  },
  signIn: async (url: string, payload = {}) => {
    try {
      const res = await axiosInstance.post(process.env.NX_PUBLIC_API_URI + url, payload, {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        withCredentials: true,
      });
      return res.data.result ? res.data.result : res.data;
    } catch (err: any) {
      if (err?.response?.data?.code === 401) {
        handleLogout();
      }
      return err;
    }
  },
  fetchRoles: async (input: any): Promise<any> => {
    try {
      const { data } = await graphqlInstance.query({
        query: AuthQueries.FETCH_PERMISSIONS,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data?.authoring_permissionList || null;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:fetchRoles`, err);
      if (err instanceof ApolloError) throw err;
    }
  },
};
export default authAPI;
