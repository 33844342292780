import {
  Box,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

const OrderListSkeleton = () => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {Array.from(new Array(2)).map((_, index) => (
            <TableRow key={index}>
              {/* Skeleton for product image */}
              <TableCell sx={{ pl: 0, width: 120 }}>
                <Box className='productWrapper'>
                  <Skeleton variant='rectangular' width={100} height={100} />
                </Box>
              </TableCell>
              {/* Skeleton for product name and brand */}
              <TableCell>
                <Grid container direction='column'>
                  <Grid item>
                    <Skeleton variant='text' width={150} height={30} />
                  </Grid>
                  <Grid item>
                    <Skeleton variant='text' width={100} height={20} />
                  </Grid>
                </Grid>
              </TableCell>
              {/* Skeleton for order status and date */}
              <TableCell align='right'>
                <Grid container direction='column' alignItems='flex-end'>
                  <Grid item>
                    <Skeleton variant='text' width={150} height={30} />
                  </Grid>
                  <Grid item>
                    <Skeleton variant='text' width={100} height={20} />
                  </Grid>
                </Grid>
              </TableCell>
              {/* Skeleton for product price and rate link */}
              <TableCell align='right'>
                <Grid container direction='column' alignItems='flex-end'>
                  <Grid item>
                    <Skeleton variant='text' width={100} height={30} />
                  </Grid>
                  <Grid item>
                    <Skeleton variant='text' width={100} height={20} />
                  </Grid>
                </Grid>
              </TableCell>
              {/* Skeleton for the view order details button */}
              <TableCell align='right'>
                <Skeleton variant='circular' width={40} height={40} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderListSkeleton;
