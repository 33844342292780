import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    FeatureInfoCardsWrapper: {
      "&.FeatureInfoCardsBg": {
        background: theme.palette.prelemType1.BACKGROUND,
        width: "100%",
        padding: "0",
        "& .mainBox": {
          marginRight: "-10px",
          marginLeft: "-10px",
          [theme.breakpoints.down("md")]: {
            marginRight: "0px",
            marginLeft: "0px",
          },
          "& .cardBox": {
            margin: "10px",
            display: "flex",
            borderRadius: theme.borderRadius.value,
            overflow: "hidden",
            boxShadow: theme.palette.prelemType1.CARDS.VARIANT1.BOX_SHADOW,
            position: "relative",
            minHeight: "272px",
            [theme.breakpoints.down("md")]: {
              margin: "10px 0px",
            },
            "& .leftcontent": {
              padding: "25px 15px",
              width: "60%",
              display: "flex",
              flexDirection: "column",
              alignItems: " flex-start",
              justifyContent: "space-between",
              "& h3": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                marginBottom: 0,
              },
              "& p": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
                margin: "0px 0 20px 0",
              },
            },
            "& .imgbox": {
              position: "absolute",
              right: 0,
              width: "40%",
              height: "100%",
              objectFit: "cover",
            },
          },
          "& .card1": {
            background: theme.palette.prelemType1.ACCENTS.VARIANT2.BACKGROUND,
          },
          "& .card2": {
            background: theme.palette.prelemType1.ACCENTS.VARIANT3.BACKGROUND,
          },
          "& .card3": {
            background: theme.palette.prelemType1.ACCENTS.VARIANT4.BACKGROUND,
          },
        },
      },
    },
  };
});
