import { Box, Button, Grid, Typography } from "@mui/material";
import { EmbedDesktopTabCard } from "@platformx/prelems";
import {
  EmbedcardLeftSkeltonLoader,
  EmbedcardRightSkeltonLoader,
  getSubDomain,
} from "@platformx/utilities";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./CardContent.styles";

const CardContent = ({ selectedItem, contentType }: any) => {
  const { t, i18n } = useTranslation();
  const [copyStatus, setICopyStatus] = useState<boolean>(false);
  const pageURL = `${getSubDomain()}/${i18n.language}/embed/${contentType === "vod" ? "video" : contentType}/${selectedItem?.Page}`;
  const landingPageURL = `${getSubDomain()}/${i18n.language}/${contentType === "vod" ? "video" : contentType}/${selectedItem?.Page}`;
  const classes = useStyles();

  const content = {
    Title: selectedItem?.Title,
    Description: selectedItem?.Description,
    Thumbnail: selectedItem?.Thumbnail,
    Author: selectedItem?.Author,
    creationDate: selectedItem?.lastModifiedDate,
    Page: pageURL,
    LandingPage: landingPageURL,
    colorCode: selectedItem?.colorCode,
  };
  const mobileEmbededCode = `
 <iframe src="${content?.Page}" width="360" height="203" style="border:none;overflow:hidden"></iframe>
`;
  const desktopEmbededCode = `
 <iframe src="${content?.Page}" width="480" height="270" style="border:none;overflow:hidden"></iframe>
`;
  const copyEmbededCode = (code: any) => {
    navigator.clipboard.writeText(code);
    setICopyStatus(true);
  };

  return (
    <Box className={classes.cardContemWpMain}>
      <Grid container>
        <Grid item xs={12} em={7} className='leftColWp'>
          <Box
            className='boxLeft'
            sx={{
              display: selectedItem && selectedItem?.Thumbnail ? "flex" : "block",
            }}>
            {selectedItem && selectedItem?.Thumbnail ? (
              <EmbedDesktopTabCard content={content} />
            ) : (
              <Box sx={{ width: "100%" }}>
                <EmbedcardLeftSkeltonLoader maxWidth='100%' maxHeight='444px' />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} em={5} className='rightColWp'>
          <Box className='RightBox'>
            {selectedItem && selectedItem?.Thumbnail ? (
              <>
                <Box
                  sx={{
                    marginBottom: { md: "25px", lg: "0" },
                    padding: { xs: "0 0 10px 0", md: "0" },
                  }}>
                  <Box>
                    <Typography variant='h3semibold' sx={{ textTransform: "capitalize" }}>
                      {t("embed_title")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='p3regular'>{t("embed_subtitle")}</Typography>
                  </Box>
                </Box>
                <Box
                  className='mobileEmbededCode'
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}>
                  <Typography variant='h6regular'>{mobileEmbededCode}</Typography>
                </Box>
                <Box
                  className='desktopEmbededCode'
                  sx={{
                    display: { xs: "none", md: "block" },
                  }}>
                  <Typography variant='h6regular'>{desktopEmbededCode}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}>
                  <Button
                    variant='primaryButton'
                    sx={{
                      display: { xs: "none", md: "flex" },
                    }}
                    onClick={() => copyEmbededCode(desktopEmbededCode)}
                    data-testid='copy-embedded-code-desktop'>
                    {copyStatus ? t("copied") : t("copy_code")}
                  </Button>
                  <Button
                    variant='primaryButton'
                    sx={{
                      minWidth: { xs: "100%" },
                      display: { xs: "flex", md: "none" },
                      marginTop: "25px",
                    }}
                    onClick={() => copyEmbededCode(mobileEmbededCode)}
                    data-testid='copy-embedded-code-mobile'>
                    {copyStatus ? t("copied") : t("copy_code")}
                  </Button>
                </Box>
              </>
            ) : (
              <EmbedcardRightSkeltonLoader maxWidth='100%' maxHeight='500px' />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CardContent;
