import { Button } from "@mui/material";
import { IconTimerSvg } from "@platformx/shared/static-assets";
import { ToolTip } from "@platformx/utilities";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useStyles } from "./Timer.styles";

const Timer = ({ lastmodifiedDate }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <ToolTip
      className={classes.buttonWrapper}
      component={
        <Button className='iconBtn'>
          <img src={IconTimerSvg} alt='' />
        </Button>
      }
      Title={`${t("last_edit")}: 
        ${lastmodifiedDate ? format(new Date(lastmodifiedDate), "hh:mm a") : null}`}
      position='bottom'
    />
  );
};
export default Timer;
