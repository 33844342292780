import { FREEFORM } from "@platformx/utilities";

export const linkCopy = "linkCopy";
export const errorRequest = "errorRequest";

export const SINGLE_CROP_POINTS = [{ breakpoint: 320, ratio: FREEFORM }];

export const breakpoints = [
  { breakpoint: 1440, ratio: "landscape" },
  { breakpoint: 1280, ratio: "landscape" },
  { breakpoint: 1024, ratio: "portrait" },
  { breakpoint: 768, ratio: "portrait" },
  { breakpoint: 600, ratio: "square" },
  { breakpoint: 320, ratio: "square" },
];

export const CONTENT_TYPE_WITH_ABSOLUTEURL = ["VOD", "Course", "Product", "Community"];
