const ThemeConstants = {
  // New Font sizes
  FONTSIZE_28: "28px",
  FONTSIZE_24: "24px",
  FONTSIZE_20: "20px",
  FONTSIZE_18: "18px",
  FONTSIZE_16: "16px",
  FONTSIZE_14: "14px",
  FONTSIZE_12: "12px",
  // Font Weight
  FONTWEIGHT_LIGHT: 300,
  FONTWEIGHT_REGULAR: 400,
  FONTWEIGHT_MEDIUM: 500,
  FONTWEIGHT_SEMIBOLD: 600,
  FONTWEIGHT_BOLD: 700,
  //Line Height
  LINEHEIGHT_58: "58px",
  LINEHEIGHT_46: "46px",
  LINEHEIGHT_38: "38px",
  LINEHEIGHT_36: "36px",
  LINEHEIGHT_34: "34px",
  LINEHEIGHT_32: "32px",
  LINEHEIGHT_30: "30px",
  LINEHEIGHT_28: "28px",
  LINEHEIGHT_26: "26px",
  LINEHEIGHT_24: "24px",
  LINEHEIGHT_22: "22px",
  LINEHEIGHT_20: "20px",
  LINEHEIGHT_18: "18px",
  // Breakpoints
  XS: 320,
  SM: 600,
  MD: 768,
  EM: 1024,
  LG: 1280,
  XL: 1440,

  /***== New Design System Implementation ==***/

  /*  Typography */
  TYPOGRAPHY: {
    HEADING: {
      H1: {
        MARGIN: {
          SMTOP: "5px",
          LGTOP: "5px",
          SMBOTTOM: "5px",
          LGBOTTOM: "5px",
        },
      },
      H2: {
        MARGIN: {
          SMTOP: "3px",
          LGTOP: "3px",
          SMBOTTOM: "3px",
          LGBOTTOM: "3px",
        },
      },
      H3: {
        MARGIN: {
          SMTOP: "2px",
          LGTOP: "2px",
          SMBOTTOM: "2px",
          LGBOTTOM: "2px",
        },
      },
    },
    PARAGRAPH: {
      P1: {
        MARGIN: {
          SMTOP: "0px",
          LGTOP: "0px",
          SMBOTTOM: "0px",
          LGBOTTOM: "0px",
        },
      },
      P2: {
        MARGIN: {
          SMTOP: "0px",
          LGTOP: "0px",
          SMBOTTOM: "0px",
          LGBOTTOM: "0px",
        },
      },
      P3: {
        MARGIN: {
          SMTOP: "0px",
          LGTOP: "0px",
          SMBOTTOM: "0px",
          LGBOTTOM: "0px",
        },
      },
      P4: {
        MARGIN: {
          SMTOP: "0px",
          LGTOP: "0px",
          SMBOTTOM: "0px",
          LGBOTTOM: "0px",
        },
      },
    },
  },
  ELEMENT: {
    TEXTBOX: {
      BORDER_RADIUS: "5px",
      PADDING: {
        sm: "14px",
        md: "14px",
      },
      PADDINGV2: {
        sm: "14px",
        md: "14px",
      },
      PADDINGV3: {
        sm: "20px 12px 15px 12px",
        md: "20px 12px 15px 12px",
      },
    },
    BUTTON: {
      BORDER_RADIUS: "5px",
      MEDIUM_HEIGHT: "47px",
      SMALL_HEIGHT: "38px",
      LARGE_HEIGHT: "63px",
      XLARGE_HEIGHT: "66px",
      MIN_WIDTH: "90px",
      MIN_WIDTH_SMALL: "90px",
      PADDING: {
        sm: "9px 16px",
        md: "12px 24px",
        lg: "20px 32px",
        xl: "20px 32px",
      },
      MARGIN: {
        TOP: "0px", //"12px"
        RIGHT: "0px", //"12px"
        BOTTOM: "0px", //"12px"
        LEFT: "0px",
        SMMARGIN: "0", //"10px 10px 10px 0px",
        MDMARGIN: "0", //"10px 10px 10px 0px"
      },
    },
    SELECT: {
      BORDER_RADIUS: "5px",
      HEIGHT: "60px",
      PADDING: {
        sm: "14px 12px 14px 12px",
        md: "14px 12px 14px 12px",
      },
    },
    ACCORDIAN: {
      BORDER_RADIUS: "5px",
      BORDER: "2px",
      PADDING1: "6px 24px 6px 24px",
      PADDING2: "16px 24px 16px 24px",
      MARGINBOTTOM: "32px",
    },
    LABEL: {
      PADDING: {
        SMTOP: "10px",
        SMBOTTOM: "10px",
        LGTOP: "12px",
        LGBOTTOM: "12px",
      },
    },
    CARD: {
      TYPE_1: {
        BORDER_RADIUS: "8px",
        PADDING_CONTENT: "12px 20px 20px 20px",
        PADDING_IMAGE: "12px 20px 20px 20px",
        ELEVATION4: "0px 10px 15px 0px rgba(0, 0, 0, 0.03);",
        GRADIENT: "linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%)",
      },
    },
  },
  OVERYLAY: {
    EDITOVERLAY: "50, 130, 250, 0.9",
    BGOVERLAY: "255, 255, 255, 0.3",
    CARDOVERLAY: "0, 0,0, 0.6",
  },
  RADIUS: {
    VALUE1: "5px",
    VALUE2: "8px",
  },
  BUTTON: {
    PRIMARY: {
      COLOR: "#FFFFFF",
      COLOR_HOVER: "#FFFFFF",
      BACKGROUND_COLOR: "#14142B",
      BACKGROUND_COLOR_HOVER: "#4C476D",
      BORDER: "solid 1px  #4C476D",
      BORDER_HOVER: "solid 1px  #4C476D",
    },
    SECONDARY: {
      COLOR: "#14142B",
      COLOR_HOVER: "#FFFFFF",
      BACKGROUND_COLOR: "#FFFFFF",
      BACKGROUND_COLOR_HOVER: "#14142B",
      BORDER: "solid 1px  #14142B",
      BORDER_HOVER: "solid 1px  #14142B",
    },
    PRIMARYLIGHT: {
      COLOR: "#14142B",
      COLOR_HOVER: "#FFFFFF",
      BACKGROUND_COLOR: "#FFFFFF",
      BACKGROUND_COLOR_HOVER: "#4B9EF9",
      BORDER: "solid 1px  #FFFFFF",
      BORDER_HOVER: "solid 1px  #4B9EF9",
    },
    SECONDARYLIGHT: {
      COLOR: "#FFFFFF",
      COLOR_HOVER: "#14142B",
      BACKGROUND_COLOR: "transparent",
      BACKGROUND_COLOR_HOVER: "#FFFFFF",
      BORDER: "solid 1px  #FFFFFF",
      BORDER_HOVER: "solid 1px  #FFFFFF",
    },
    TERTIARY: {
      COLOR: "#14142B",
      COLOR_HOVER: "#FFFFFF",
      BACKGROUND_COLOR: "#FFFFFF",
      BACKGROUND_COLOR_HOVER: "#14142B",
      BORDER: "solid 1px  #14142B",
      BORDER_HOVER: "solid 1px  #14142B",
    },
    QUATERNARY: {
      COLOR: "#fff",
      COLOR_HOVER: "#FFFFFF",
      BACKGROUND_COLOR: "#4B9EF9",
      BACKGROUND_COLOR_HOVER: "#4B9EF9",
      BORDER: "solid 1px  #4B9EF9",
      BORDER_HOVER: "solid 1px  #4B9EF9",
    },
  },
  LABEL: {
    PRIMARY: {
      COLOR: "#6E7191",
    },
  },
  LINK: {
    PRIMARY: {
      COLOR: "#14142B",
      HOVER_COLOR: "#4B9EF9",
      TEXT_DECORATION: "underline",
    },
    SECONDARY: {
      COLOR: "#4B9EF9",
      HOVER_COLOR: "#14142B",
      TEXT_DECORATION: "underline",
    },
  },
  DIVIDER: {
    PRIMARY: {
      COLOR: "#D9DBE9",
    },
  },
  INPUT: {
    PLACEHOLDER: "transparent",
    TEXT: "#14142B",
    TEXT_ACTIVE: "#14142B",
    LABEL: "#6E7191",
    BORDER: "solid 1px #D9DBE9",
    BORDER_ACTIVE: "solid 1px #14142B",
    BORDER_ERROR: "solid 1px #F33E41",
    BORDER_SUCCESS: "solid 1px #0FA069",
    BACKGROUND_FOCUSED: "transparent",
    BACKGROUND_ERROR: "#FFEBEE",
    BACKGROUND_ERROR_TEXT: "#F33E41",
    BACKGROUND_SUCCESS: "#DEF5D9",
    BACKGROUND_SUCCESS_TEXT: "#0FA069",
    DISABLED: "#EFF0F6",
  },
  SELECT: {
    PLACEHOLDER: "transparent", //Selectbox bg
    LABEL: "#6E7191", //animated tex of focus
    TEXT: "#6E7191", //dropdownlist popup default text
    TEXT_HOVER: "#14142B", //dropdownlist popup hover text color
    TEXT_SELECTED: "#14142B", //dropdownlist selected text color
    TEXT_BACKGROUND_HOVER: "#FCFCFC", //dropdownlist popup hover text background
    BORDER: "solid 1px #D9DBE9",
    BORDER_ACTIVE: "solid 1px #14142B", //focused border color
  },
  CHECKBOX: {
    BOX_COLOR: "#6E7191",
  },
  RADIO: {
    BOX_COLOR: "#14142B",
    BOX_COLOR_ACTIVE: "#14142B",
    SIZE: "24px",
  },
  ACCORDIAN: {
    BACKGROUND: "#fff",
    COLOR: "#14142B",
    BORDER_COLOR: "#D9DBE9",
  },
  CARDS: {
    VARIANT1: {
      BACKGROUND: "#FFFFFF",
      ALTERNET_BACKGROUND: "#F7F7FC",
      TITLE: "#14142B",
      PARAGRAPH: "#4E4B66",
      LABEL: "#6E7191",
      BORDER_COLOR: "#D9DBE9",
      BORDER_COLOR_HOVER: "#14142B",
      BOX_SHADOW: "0px 10px 15px 0px rgba(0, 0, 0, 0.03)",
    },
    VARIANT2: {
      BACKGROUND: "#F5F5FF",
      ALTERNET_BACKGROUND: "#F7F7FC",
      TITLE: "#14142B",
      PARAGRAPH: "#4E4B66",
      LABEL: "#6E7191",
      BORDER_COLOR: "#D9DBE9",
      BORDER_COLOR_HOVER: "#14142B",
      BOX_SHADOW: "0px 10px 15px 0px rgba(0, 0, 0, 0.03)",
    },
  },
  COLOR: {
    PRIMARY: "#14142B",
    SECONDARY: "#4E4B66",
    TERTIARY: "#4B9EF9",
    LABEL: "#6E7191",
    PLACEHOLDER: "#A0A3BD",
    LINE: "#D9DBE9",
    INPUT: "#EFF0F6",
    LIGHT: "#FFFFFF",
    BG: "#FCFCFC",
    BGWEAK: "#F7F7FC",
    BGLIGHT: "#FFFFFF",
    SUCCESS: "#0FA069",
    ERROR: "#F33E41",
    BACKGROUND_ERROR: "FFEBEE",
    ACCENTS: {
      ACCENT_1: "#0049B2", //Secondary-Strong
      ACCENT_2: "#0F5FDC", //Secondary-Default
      ACCENT_3: "#4B9EF9", //Secondary-Weak
      ACCENT_4: "#8CC8FA", //Secondary-BG-strong
      ACCENT_5: "#D7ECFD", //Secondary-BG-Default
      ACCENT_6: "#F3FAFF", //Secondary-BG-Weak
    },
  },
  NOTIFICATION: {
    SUCCESS: {
      BACKGROUND: "#0EA069",
      COLOR: "#fff",
      ACTIVE_COLOR: "#0EA069",
      LIGHT_BACKGROUND: "#DEF5D9",
      LIGHT_TEXT: "#0FA069",
    },
    WARNING: {
      BACKGROUND: "#FFCC40",
      COLOR: "#fff",
      ACTIVE_COLOR: "#FFCC40",
      LIGHT_BACKGROUND: "#FFF2D9",
      LIGHT_TEXT: "#E2AB30",
    },
    ERROR: {
      BACKGROUND: "#F33E41",
      COLOR: "#fff",
      ACTIVE_COLOR: "#F33E41",
      LIGHT_BACKGROUND: "#FFEBEE",
      LIGHT_TEXT: "#D32F2F",
    },
    INFO: {
      BACKGROUND: "#009DDF",
      COLOR: "#fff",
      ACTIVE_COLOR: "#009DDF",
      LIGHT_BACKGROUND: "#FFF2D9",
      LIGHT_TEXT: "#E2AB30",
    },
  },
  POPUP: {
    BACKGROUND: "#ffffff",
    COLOR: "#14142B",
  },
  TAB: {
    VARIANT1: {
      TITLE: "#A0A3BD",
      TITLE_ACTIVE: "#14142B",
      TITLE_BACKGROUND: "#F7F7FC",
      TITLE_ACTIVE_BACKGROUND: "#14142B",
      PARAGRAPH: "#4E4B66",
      LABEL: "#6E7191",
      LINE: "#D9DBE9",
      BACKGROUND_COLOR: "#F7F7FC",
      BACKGROUND_COLOR_ACTIVE: "#F7F7FC",
      BORDER_COLOR: "#D9DBE9",
      BORDER_COLOR_ACTIVE: "#14142B",
    },
  },
  CHIP: {
    MIN_HEIGHT: "30px",
    PADDING: "5px 9px",
    BORDER_RADIUS: "5px",
    MARGIN: "0px 3px",
    DRAFT: {
      COLOR: "#FFCD41",
      COLOR_HOVER: "#f0ad4e",
      BACKGROUND_COLOR: "#FFFFFF",
      BACKGROUND_COLOR_HOVER: "#FFFFFF",
      BORDER: "solid 1px  #f0ad4e",
      BORDER_HOVER: "solid 1px  #f0ad4e",
    },
    PUBLISHED: {
      COLOR: "#0FA069",
      COLOR_HOVER: "#0FA069",
      BACKGROUND_COLOR: "#FFFFFF",
      BACKGROUND_COLOR_HOVER: "#FFFFFF",
      BORDER: "solid 1px  #0FA069",
      BORDER_HOVER: "solid 1px  #0FA069",
    },
    PENDING: {
      COLOR: "#4B9EF9",
      COLOR_HOVER: "#4B9EF9",
      BACKGROUND_COLOR: "#FFFFFF",
      BACKGROUND_COLOR_HOVER: "#FFFFFF",
      BORDER: "solid 1px  #4B9EF9",
      BORDER_HOVER: "solid 1px  #4B9EF9",
    },
  },
  TOOLTIP: {
    PRIMARY: {
      COLOR: "#FFFFFF",
      BACKGROUNDCOLOR: "#14142B",
    },
  },
  FONTNAMEPRIMARY: "Inter",
  FONTNAMESECONDARY: "Inter",
};

export default ThemeConstants;
