import { Box, Typography } from "@mui/material";
//import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SyncIcon from "@mui/icons-material/Sync";
import LinearProgress from "@mui/material/LinearProgress";
import { UploadCheck } from "@platformx/shared/static-assets";
import { useTranslation } from "react-i18next";
import { useAssetUpload } from "./AssetUpload.style";

// Component to show selected asset in file upload screen
const AssetCard = ({ file, index, assetType, uploadState, retryUpload, isUploadInProgess }) => {
  const { t } = useTranslation();
  const classes = useAssetUpload();

  return (
    <Box className='modalassetbox'>
      <Box className={classes.boxcloseinnercontain}>
        {/* To show preview of selected asset, img and video tag is used */}
        {assetType === "images" ? (
          <img src={URL.createObjectURL(file)} className={classes.imgPreview} alt='img' />
        ) : (
          <video src={URL.createObjectURL(file)} className={classes.videoPreview} />
        )}
        <Box className={classes.modalboxtypo}>
          <Typography variant='h6regular'>{file.name}</Typography>
          {uploadState[index] === "PENDING" ? (
            <LinearProgress color='info' />
          ) : (
            <Typography variant='labelregular'>
              {(file.size / 1048576).toFixed(2)}MB ∙ {t(assetType)}
            </Typography>
          )}
        </Box>
        {uploadState[index] === "DONE" && (
          <Box className={classes.closeiconreop}>
            <img src={UploadCheck} alt='done' />
          </Box>
        )}
        {uploadState[index] === "FAILED" && (
          // button to let user retry the failed jobs
          <Box
            onClick={() => (isUploadInProgess ? {} : retryUpload(index))}
            className={classes.closeiconreop}>
            <SyncIcon
              fontSize='small'
              className={isUploadInProgess ? classes.syncIconDisabled : classes.syncIcon}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AssetCard;
