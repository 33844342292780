import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Blogs from "../Blogs/Blogs";
import Events from "./Events";

const EventLandingPage = (props: any) => {
  const {
    content,
    analytics,
    authoringHelper = {},
    secondaryArgs = {},
    getBlogs = () => {},
    enablePreview = false,
  } = props;
  const { isAuthoring = false } = authoringHelper;
  const { i18n } = useTranslation();
  useEffect(() => {
    if (typeof window !== "undefined") {
      i18n.changeLanguage(secondaryArgs?.prelemBaseEndpoint?.language);
    }
  }, []);
  return (
    <>
      <Events
        content={content}
        secondaryArgs={secondaryArgs}
        authoringHelper={authoringHelper}
        analytics={analytics}
        enablePreview={enablePreview}
      />
      {!isAuthoring && (
        <Blogs content={content} secondaryArgs={secondaryArgs} getBlogs={getBlogs} />
      )}
    </>
  );
};
export default EventLandingPage;
