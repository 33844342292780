import { Box, Typography } from "@mui/material";
import { checkRequired } from "../../helper";

const FormParagraph = ({ item }) => {
  return (
    <Box className='formTextBox elementWrapper' mt={1} mb={2}>
      <Typography variant='p4regular' className='form-label' mt={1} mb={1}>
        {item?.title}
        {checkRequired(item?.validations)}
      </Typography>
    </Box>
  );
};

export default FormParagraph;
