import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { findMaxCharLength } from "../../../utils/helperFns";

const MaxLengthValidationMessage = ({ validations, error, restOfChar }: any) => {
  const { t } = useTranslation();
  const { restOfLength = 0, reachLimit = false } = restOfChar || {};
  const maxCharLength = findMaxCharLength(validations);

  return (
    <Box>
      {maxCharLength > 0 && !error && (
        <Typography variant='p4regular' sx={{ marginTop: "10px" }}>
          {reachLimit ? (
            <>0 {`${t("characters")} ${t("left")}`}</>
          ) : (
            <>
              {restOfLength
                ? `${restOfLength} ${t("characters")} ${t("left")} `
                : `${maxCharLength} ${t("characters")} ${t("max")}`}
            </>
          )}
        </Typography>
      )}
    </Box>
  );
};
export default MaxLengthValidationMessage;
