import { useLazyQuery } from "@apollo/client";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { fetchBlogs } from "@platformx/authoring-apis";
import { SearchIconSvg } from "@platformx/shared/static-assets";
import { debounce, ShowToastError as showToastError } from "@platformx/utilities";
import * as React from "react";
import { useTranslation } from "react-i18next";
import "./BlogSearchBox.css";

interface Content {
  description: any;
  title: string;
}
const useStyles = makeStyles(() => ({
  autoComplete: {
    wordBreak: "break-word",
  },
}));

export default function BlogSearchBox({ onSearch, style }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const [options, setOptions] = React.useState<Content[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [highlighted, setHighlighted] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const eventPath = urlParams.get("path");
  const [fetchBlogsQuery] = useLazyQuery(fetchBlogs);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const emptyOptions = () => {
    setOptions([]);
  };

  const debouncedCall = React.useCallback(debounce(emptyOptions, 500), []);

  const onInputChange = (_, value) => {
    setInputValue(value);
    debouncedCall();
  };

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      onSearch(inputValue);
      setOpen(false); //Do we need this ?? On enter should it remain open/closed??
    }
  };

  const onSelect = (_, value: any) => {
    if (value && value !== null) {
      setInputValue(value);
      onSearch(value);
    } else {
      onSearch("");
      setInputValue("");
    }
  };

  const resetSearch = () => {
    onSearch("");
    setInputValue("");
    setOpen(false);
  };

  const fetchSuggestiveBlogs = async () => {
    try {
      setLoading(true);
      const data = {
        event_path: eventPath,
        is_published: true,
        is_soft_delete: false,
        start: 0,
        rows: 20,
        sortOrder: "desc",
        isSuggestive: true,
        pageSearch: inputValue,
      };
      const apiResponse = await fetchBlogsQuery({
        variables: {
          input: data,
        },
      });
      const { data: { authoring_fetchblog: { result = [], success = false } = {} } = {} } =
        apiResponse;
      if (success) {
        setOptions([...result]);
      } else {
        setOptions([]);
        setOpen(false);
      }
    } catch (error: any) {
      const statusCode = error?.networkError?.statusCode || 0;
      setOptions([]);
      setOpen(false);
      showToastError(t("api_error_toast"), statusCode);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (highlighted) {
        fetchSuggestiveBlogs();
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [inputValue, highlighted]);

  return (
    <>
      <Box
        sx={{
          display: { xs: showSearch ? "none" : "block", md: "none" },
          marginRight: "13px",
        }}>
        <img
          src={SearchIconSvg}
          style={{ verticalAlign: "middle", cursor: "pointer" }}
          onClick={() => setShowSearch(true)}
          data-testid='blog-show-search-button'
          alt='search icon'
        />
      </Box>
      <Autocomplete
        id='asynchronousSearch'
        classes={{
          input: classes.autoComplete,
          option: classes.autoComplete,
        }}
        freeSolo
        forcePopupIcon={false}
        sx={{ display: { xs: showSearch ? "block" : "none", md: "block" } }}
        style={style}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          setLoading(false);
        }}
        inputValue={inputValue}
        value={inputValue}
        onInputChange={onInputChange}
        onChange={onSelect}
        onKeyDown={onEnter}
        filterOptions={(x) => x}
        options={options
          ?.map((option) => (option?.title ? option?.title : ""))
          .filter((ele) => ele !== "")}
        // options={options}
        // renderOption={(option) => (
        //     <Typography className={classes.comboOptions}>{option?.title ? option?.title : ''}</Typography>
        // )}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='filled'
            placeholder={`${t("search")} ${t("blog")}`}
            onFocus={() => setHighlighted(true)}
            onBlur={() => setHighlighted(false)}
            sx={{
              ".Platform-x-InputBase-root": {
                height: "40px",
                fontSize: "14px",
                padding: "12px 14px 12px 8px !important",
                minHeight: "inherit",
                marginLeft: "0px",
                "&.Mui-focused fieldset": {
                  // borderColor: "white",
                },
                fieldset: {
                  borderColor: "transparent",
                },
                ".Platform-x-InputBase-input": {
                  textTransform: "capitalize",
                },
              },
              position: "relative",
              marginLeft: "0",
              width: "100%",
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: <img src={SearchIconSvg} alt='search icon' />,
              endAdornment: (
                // <React.Fragment>
                //     {loading ? <CircularProgress color="inherit" size={15} /> : null}
                //     {params.InputProps.endAdornment}
                // </React.Fragment>
                <InputAdornment position='end'>
                  {inputValue && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      <CloseRoundedIcon
                        onClick={resetSearch}
                        data-testid='blog-reset-search-button'
                        sx={{
                          cursor: "pointer",
                          // position: 'absolute',
                          // right: '18px',
                        }}
                      />
                    </Box>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </>
  );
}
