import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  return {
    zaggEcommerceWarpper: {
      "&.zaggEcommerceContainer": {
        background: "#f9f9f9",
        "& .orderHistory": {
          paddingTop: "20px",
          "& .orderlist": {
            maxHeight: "550px",
            overflowY: "auto",
          },
        },
        "& .orderDetail, & .productRegistration": {
          "& .Platform-x-StepLabel-alternativeLabel": {
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          },
          "& .Platform-x-StepConnector-horizontal": {
            "& .Platform-x-StepConnector-line": {
              borderTopWidth: "5px",
            },
            "&.Mui-completed": {
              background: "#FA4616",
              "& .Platform-x-StepConnector-line": {
                borderColor: "#AA1A2D",
              },
            },
          },
          "& .Mui-completed .iconwrapper": {
            background: "#AA1A2D",
            "& img": {
              filter: "none",
            },
            "& svg": {
              "& path": {
                stroke: "#fff",
              },
            },
          },
          "& .iconwrapper": {
            width: "50px",
            height: "50px",
            background: "#CED3D9",
            marginTop: "-15px",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            zIndex: 1,
            "& img": {
              filter: "brightness(0%)",
            },
            "& svg": {
              "& path": {
                stroke: "#1a1a1a",
              },
            },
          },
        },
        "& .productRegistration": {
          "& .formcontainerLeft": {
            "& .steppersWrapperItem": {
              width: "400px",
              float: "left",
              "& .stepperWrapper": {
                paddingLeft: 0,
                marginLeft: "-40px",
              },
            },
            "& .leftPanel": {
              "& .allCustomTypeDropdown, .string-textbox": {
                maxWidth: "500px",
                minWidth: "500px",
              },
              "& .textMaxWidth": {
                maxWidth: "500px",
              },
              "& .customCalender": {
                maxWidth: "500px",
                minWidth: "500px",
                "& input": {
                  textAlign: "right",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                },
              },
              "& .hrLine": {
                borderColor: "#CED3D9",
              },
            },
          },
          "& .rightPanel": {
            background: "#fff",
            borderRadius: "5px",
            padding: "20px 40px",
            position: "relative",
            minHeight: "600px",
            "& .nodata": {
              textAlign: "center",
              height: "70%",
              "& img": {
                padding: "35% 20% 0% 20%",
              },
              "& p": {
                color: "#C0C0C0",
                padding: "0% 20% 45% 20%",
              },
            },
          },
        },
        "& .registeredProduct": {
          "& .productCard": {
            border: "solid 1px #Dadbe9",
            padding: "10px",
            width: "100%",
            maxWidth: "600px",
            background: "#fff",
            borderRadius: "5px",
            marginBottom: "24px",
            "& .productCardImage": {
              padding: "20px",
              "& img": {
                minWidth: "80%",
                maxWidth: "180px",
                maxHeight: "180px",
              },
            },
          },
          "& .sidebar": {
            "& .quicklinkItem": {
              color: "#AA1A2D",
              "&:hover": {
                textDecoration: "underline",
              },
            },
          },
        },
        "& .productCart": {
          "& .productListWrapper": {
            display: "flex",
            alignItems: "center",
          },
          "& .pricerow": {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 1,
          },
          "& .rightPanel": {
            minHeight: "300px !important",
          },
        },
        "& .hr": {
          borderBottom: "solid 1px #E7E7E7",
        },
        "& .customLink": {
          textDecoration: "underline",
          cursor: "pointer",
          color: "#AA1A2D",
        },
        "& .productWrapper": {
          width: 92,
          height: 92,
          background: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& img ": {
            maxHeight: 72,
            maxWidth: "100%",
          },
        },
        "& .largeproductWrapper": {
          width: "100%",
          height: "350px",
          background: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "solid 1px #Dadbe9",
          "& img ": {
            maxHeight: "330px",
            maxWidth: "90%",
          },
        },
        "& .marginTopZero": {
          marginTop: 0,
        },
        "& .selected": {
          border: "solid 1px #AA1A2D !important",
          "& button": {
            backgroundColor: "#AA1A2D",
            color: "white",
          },
        },

        "& .grid_container": {
          margin: "0 auto",
          padding: "0 8px",
          [`@media(min-width:320px)`]: {
            maxWidth: "calc(100% - 30px)",
          },
          [`@media(min-width:600px)`]: {
            maxWidth: "568px",
          },
          [`@media(min-width:768px)`]: {
            maxWidth: "732px",
          },
          [`@media(min-width:1024px)`]: {
            maxWidth: "992px",
          },
          [`@media(min-width:1280px)`]: {
            maxWidth: "1240px",
          },
          [`@media(min-width:1440px)`]: {
            maxWidth: "1340px",
          },
        },
        /*common override theme */
        "& .Platform-x-InputBase-root, &.custom-dropdown": {
          background: "#ffffff",
          "&.Mui-focused": {
            backgroundColor: "#ffffff",
            border: "solid 1px #ced3d9",
          },

          "& .Platform-x-InputBase-input:focus": {
            backgroundColor: "#ffffff",
            border: "none",
          },
        },
        "& .Platform-x-Select-select:focus": {
          backgroundColor: "#ffffff",
          border: "none",
          borderRadius: "5px",
        },

        "& .Platform-x-FilledInput-root, & .Platform-x-OutlinedInput-root": {
          background: "#ffffff",
          border: "solid 1px #ced3d9",
          "&:hover": {
            background: "#ffffff",
          },
        },
        "& input, & textarea": {
          background: "#ffffff",
        },
      },
    },
    scrollablWarpper: {
      maxHeight: "550px",
      overflow: "auto",
      maxWidth: "600px",
    },
  };
});
