import { Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { fetchValidations, handleLength } from "../../../utils/helperFns";
import ErrorMessageHandle from "../../common/Error/ErrorMessageHandle";

const Custom1 = ({
  field,
  errors,
  isDisabled,
  register,
  clearErrors,
  handleChange,
  handleOnBlur,
  data,
  type,
}: any) => {
  const [restOfChar, setRestOfChar] = useState({
    lengthOfState: 0,
    restOfLength: 0,
    reachLimit: false,
  });

  const validations = fetchValidations(field?.validations);

  return (
    <Grid container spacing={5} rowSpacing={2}>
      <Typography variant='p4regular'>{field?.placeholder1}</Typography>
      <TextField
        variant='filled'
        size='small'
        type={type}
        name={field.name}
        className='titlefield'
        placeholder={field.placeholder}
        id={field.name}
        error={errors[field.name]}
        disabled={isDisabled}
        value={data}
        defaultValue={data}
        sx={{
          width: "20%",
          input: { cursor: isDisabled && "not-allowed" },
        }}
        inputProps={{
          readOnly: false,
        }}
        {...register(field.name, {
          ...validations,
          onChange: (event) => {
            if (errors[field.name]) {
              clearErrors(field.name);
            }
            const { target: { value = "" } = {} } = event;
            handleLength(value, field?.validations, setRestOfChar, restOfChar);
            if (handleChange) {
              handleChange(event);
            }
          },
          onBlur: (event) => {
            handleOnBlur(event);
          },
        })}
      />
      <Typography variant='p4regular'>{field?.placeholder2}</Typography>
      <TextField
        variant='filled'
        size='small'
        type={type}
        name={field.name}
        className='titlefield'
        placeholder={field.placeholder}
        id={field.name}
        error={errors[field.name]}
        disabled={isDisabled}
        value={data}
        defaultValue={data}
        sx={{
          width: "20%",
          input: { cursor: isDisabled && "not-allowed" },
        }}
        inputProps={{
          readOnly: false,
        }}
        {...register(field.name, {
          ...validations,
          onChange: (event) => {
            if (errors[field.name]) {
              clearErrors(field.name);
            }
            const { target: { value = "" } = {} } = event;
            handleLength(value, field?.validations, setRestOfChar, restOfChar);
            if (handleChange) {
              handleChange(event);
            }
          },
          onBlur: (event) => {
            handleOnBlur(event);
          },
        })}
      />
      <Typography variant='p4regular'>{field?.placeholder3}</Typography>
      <ErrorMessageHandle error={errors[field?.name]} />
    </Grid>
  );
};
export default Custom1;
