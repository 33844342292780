import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";

interface EmbedcardRightSkeltonLoaderList {
  maxWidth?: string;
  maxHeight?: string;
  style?: any;
}
export default function embedcardRightSkeltonLoader({
  maxWidth = "350px",
  maxHeight,
  style,
}: EmbedcardRightSkeltonLoaderList) {
  return (
    <Card
      sx={{
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        mt: 5,
        border: "none",
        boxShadow: "none",
      }}
      style={style}>
      <Skeleton animation='wave' width='40%' height={15} style={{ marginBottom: 15 }} />
      <Skeleton animation='wave' height={10} width='80%' style={{ marginBottom: 6 }} />
      <Skeleton
        sx={{ height: { xs: "225px", md: "194px" }, margin: "18px 0", borderRadius: "5px" }}
        animation='wave'
        variant='rectangular'
      />
      <Skeleton
        animation='wave'
        sx={{ height: "47px", width: "133px", borderRadius: "5px" }}
        variant='rectangular'
      />
    </Card>
  );
}
