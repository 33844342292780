import {
  FormControl,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { ProfileBlack, ProfileBlue } from "@platformx/shared/static-assets";
import { capitalizeFirstLetter, isSubsequence } from "@platformx/utilities";
import useDamContent from "./DamContent.style";

// Component to render an author filter list
export function AuthorMenu({ setAuthor, label, author, searchQuery }) {
  const classes = useDamContent(); // Applying custom styles

  const AUTHOR = searchQuery
    ? label?.author?.filter((obj) => isSubsequence(searchQuery, obj.label.toLowerCase()))
    : label?.author;

  // Handler to update the selected author
  const handleSelect = (selectedAuthor) => {
    setAuthor(selectedAuthor);
  };

  return (
    <FormControl variant='outlined' className='selectRoot'>
      <List>
        {AUTHOR?.map((obj) => (
          <ListItemButton
            className={classes.listitembox}
            key={obj.label}
            onClick={() => handleSelect(obj.label)}
            selected={author === obj.label}>
            <ListItemIcon className={classes.authoricon}>
              <img
                style={{
                  height: "22px",
                  width: "22px",
                }}
                src={author === obj.label ? ProfileBlue : ProfileBlack}
                alt='profile'
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant='p3medium'
                color={author === obj.label ? "activeColor" : "inherit"}>
                {capitalizeFirstLetter(obj.label?.toLowerCase())}
              </Typography>
            </ListItemText>
          </ListItemButton>
        ))}
      </List>
    </FormControl>
  );
}
