import { Box, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { Coin } from "@platformx/shared/static-assets";
import { handleLogout } from "@platformx/utilities";

import axios from "axios";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { getTotalLoyaltyPoints } from "./helperProfile";

type CoinsProps = {
  secondaryArgs: any;
};

const Coins = (props: CoinsProps) => {
  const { secondaryArgs = {} } = props;
  const { prelemBaseEndpoint: { loyaltyEndPoint = "" } = {} } = secondaryArgs;

  const [userPoints, setUserPoints] = useState(0);
  const [voucherPoints, setVoucherPoints] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state

  //Need to remove after Zagg demo
  const getUserLoyaltyPoints = async () => {
    const totalPoints = await getTotalLoyaltyPoints();
    setUserPoints(totalPoints);
    setLoading(false); // Set loading to false after fetching data
  };

  const getVoucherPoints = (userId: string) => {
    axios
      .get(`${loyaltyEndPoint}v1/user/voucherAmount?userId=${userId}`)
      .then((res: any) => {
        const points = res?.data?.result || 0;
        setVoucherPoints(points);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.error("logout...from axios");
          handleLogout();
        }
        setVoucherPoints(0);
      });
  };

  // const getUserPoints = (userId: string) => {
  // axios
  // .get(${loyaltyEndPoint}v1/user/userPoints?userId=${userId})
  // .then((res: any) => {
  // const points = res?.data?.result || 0;
  // setUserPoints(points);
  // })
  // .catch((error) => {
  // if (error.response && error.response.status === 401) {
  // console.error("logout...from axios");
  // handleLogout();
  // }
  // setUserPoints(0);
  // });
  // };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId?.length) {
      getVoucherPoints(userId);
      //getUserPoints(userId);
      //Need to remove after Zagg demo
      getUserLoyaltyPoints();
    }
  }, []);

  if (loading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className='CoinsSections'>
      <Grid container>
        <Grid item xs={12} sm={12} md={5}>
          <Box className='coinWrapperBox'>
            <Typography variant='h6semibold' className='marginTopZero'>
              Available Points{" "}
            </Typography>
            <Box className='coinBox'>
              <img src={Coin} alt='coin' className='coinImg' />
              <Typography variant='h2bold' sx={{ textAlign: "left" }}>
                <CountUp enableScrollSpy={true} start={0} end={userPoints} delay={0}>
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2} className='divider'>
          <Divider orientation='vertical' />
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <Box className='coinWrapperBox'>
            <Typography variant='h6semibold' className='marginTopZero'>
              Voucher Value
            </Typography>
            <Box className='coinBox'>
              <img src={Coin} alt='coin' className='coinImg' />
              <Typography variant='h2bold' sx={{ textAlign: "left" }}>
                ${" "}
                <CountUp enableScrollSpy={true} start={0} end={voucherPoints} delay={0}>
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Coins;
