import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  replaceTray: {
    position: "absolute",
    top: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#7470708a",
    cursor: "pointer",
  },
  chooseTray: {
    borderRadius: "15px",
    cursor: "pointer",
    height: "192px",
    backgroundColor: theme?.palette?.customColor?.INPUT,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  cashedContainer: {
    borderRadius: "50%",
    backgroundColor: "#fff",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
  },
  arrowUpContainer: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  chooseTextContainer: {
    justifyContent: "center",
    alignItems: "center",
    color: theme?.palette?.customColor?.SECONDARY,
  },
  blackColor: {
    color: theme?.palette?.customColor?.PRIMARY,
  },
  whiteColor: {
    color: theme?.palette?.customColor?.LIGHT,
  },
}));
