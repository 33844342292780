import { Box, Typography } from "@mui/material";
import { ArrowDownBlack, FolderBlackLarge, FolderBlue } from "@platformx/shared/static-assets";
import { AUTH_INFO, capitalizeFirstLetter, isSubsequence } from "@platformx/utilities";
import React, { Dispatch, SetStateAction, useState } from "react";
import useDamContent from "./DamContent.style";

interface Community {
  uuid: string;
  name: string;
  type: string;
  handle: string;
  subcommunities: {
    subcommunities: Community[];
    page: {
      number: number;
      size: number;
      totalPages: number;
      totalElements: number;
    };
  };
}

interface CommunityMenuProps {
  communities: Community[];
  setUuid: Dispatch<SetStateAction<string | undefined>>;
  assetType: string;
  uuid: string;
  searchQuery: string;
}

const FolderMenu: React.FC<CommunityMenuProps> = ({
  communities,
  setUuid,
  assetType,
  uuid,
  searchQuery,
}) => {
  const classes = useDamContent();
  const [folder, setFolder] = useState<any>({});

  function filterFolder(arr) {
    return searchQuery
      ? arr?.filter((obj) => isSubsequence(searchQuery, obj.name.toLowerCase()))
      : arr;
  }

  const list = folder?.name
    ? filterFolder(folder?.subcommunities?.subcommunities || [])
    : filterFolder(communities);

  const handleClick = (obj) => {
    if (obj?.subcommunities?.subcommunities?.length > 0) setFolder(obj);
    setUuid(obj.uuid);
  };
  const handleReset = () => {
    setFolder({});
    setUuid(assetType === "Image" ? AUTH_INFO.dspaceImagesUuid : AUTH_INFO.dspaceVideosUuid);
  };

  const getShortTitle = (val) => {
    if (val.length > 18) {
      return `${capitalizeFirstLetter(val.slice(0, 17))}...`;
    } else {
      return capitalizeFirstLetter(val);
    }
  };

  return (
    <>
      {folder?.name && (
        <Box className={`${classes.folderBox} nextlevel`}>
          <Box onClick={handleReset} sx={{ display: "flex" }}>
            <img style={{ transform: "rotate(90deg)" }} src={ArrowDownBlack} alt='back' />
            <Typography variant='p2medium' className={classes.folderLabel}>
              {getShortTitle(folder.name)}
            </Typography>
          </Box>
        </Box>
      )}
      {list?.map((obj, i) => {
        return (
          <Box
            key={i}
            className={`${classes.folderBox} ${folder?.name ? "firstLevel" : ""}`}
            onClick={() => (obj.uuid === uuid ? handleReset() : handleClick(obj))}
            data-testid='rest-folder-menu'>
            <Box sx={{ display: "flex" }}>
              <img src={obj.uuid === uuid ? FolderBlue : FolderBlackLarge} alt='folder' />
              <Typography
                variant='p3medium'
                color={obj.uuid === uuid ? "activeColor" : "inherit"}
                className={classes.folderLabel}>
                {getShortTitle(obj.name)}
              </Typography>
            </Box>
            <img
              style={{ transform: "rotate(270deg)", marginRight: "5px" }}
              src={ArrowDownBlack}
              alt='icon'
            />
          </Box>
        );
      })}
    </>
  );
};

export default FolderMenu;
