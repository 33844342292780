import {
  ADD_TO_CART,
  CART_CHECKOUT,
  PAYMENT_DETAIAL,
  PRODUCT_VIEW,
  SHIPPING_ADDRESS,
} from "./actions";
import {
  getBrowser,
  getCartId,
  getDeviceOS,
  getDeviceType,
  getFullAddress,
  getItemFromLineItem,
  getLang,
  getLocationStorage,
  getRpiInstance,
  getUserId,
  prepareObjectForRPI,
} from "./helper";

/**
 * Generates a default event object with common properties for tracking.
 *
 * @returns {Object} The default event object containing common tracking properties.
 */
const getdefaultEventObj = () => {
  const geolocationDataString = getLocationStorage();
  let geolocationData;
  if (geolocationDataString) {
    geolocationData = JSON.parse(geolocationDataString);
  }

  return {
    page_url: window?.location?.href,
    language: getLang(),
    timestamp: new Date().toISOString(),
    site_name: window?.location?.host,
    userId: getUserId(),
    device_type: getDeviceType(),
    device_browser: getBrowser(),
    device_os: getDeviceOS(),
    country: geolocationData?.country_name,
    city: geolocationData?.city,
    ip: geolocationData?.ip,
  };
};

/**
 * Tracks the "Add to Cart" event.
 *
 * @param {Object} card - The product card object containing product details.
 * @param {string} containerType - The type of container where the event occurred.
 */
export const trackAddToCart = (data) => {
  const {
    id,
    ecomx_name,
    ecomx_sale_price,
    ecomx_currency_code,
    ecomx_attributes_brand,
    ecomx_slug,
    container_id,
    cartQuantity = 1,
  } = data;
  const template = {
    action: ADD_TO_CART?.eventName,
    cart_id: getCartId(),
    content_type: "ecom",
    container_type: container_id,
    tags: "iphone11 | Case | mobilecase",
    product_Id: id,
    product_name: ecomx_name,
    quantity: cartQuantity ? cartQuantity : 1,
    price_per_unit: ecomx_sale_price,
    currency: ecomx_currency_code,
    total_price: ecomx_sale_price,
    product_category: ecomx_attributes_brand,
    page_name: ecomx_slug,
    ...getdefaultEventObj(),
  };

  const eventDetail = {
    ...ADD_TO_CART,
    stateValue: cartQuantity,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail); // Track the event
};

/**
 * Tracks the "Product View" event.
 *
 * @param {Object} card - The product card object containing product details.
 */
export const trackProductView = (card) => {
  const {
    id,
    ecomx_name,
    ecomx_sale_price,
    ecomx_currency_code,
    ecomx_attributes_brand,
    ecomx_slug,
  } = card;
  const template = {
    action: PRODUCT_VIEW?.eventName,
    content_type: "ecom",
    container_type: "product-detail",
    tags: "iphone11 | Case | mobilecase",
    product_Id: id,
    product_name: ecomx_name,
    quantity: 1,
    price_per_unit: ecomx_sale_price,
    currency: ecomx_currency_code,
    total_price: ecomx_sale_price,
    product_category: ecomx_attributes_brand,
    page_name: ecomx_slug,
    ...getdefaultEventObj(),
  };

  const eventDetail = {
    ...PRODUCT_VIEW,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail); // Track the event
};

/**
 * Tracks the "Checkout" event.
 *
 * @param {Object} card - The cart object containing checkout details.
 */
export const trackCheckout = (card) => {
  const { line_item, total_price, currency_code } = card;
  const template = {
    action: CART_CHECKOUT?.eventName,
    content_type: "ecom",
    container_type: "cart-list-checkout",
    cart_id: getCartId(),
    cart_total_items: line_item?.length || 0,
    cart_total_price: total_price,
    currency: currency_code,
    page_name: window?.location?.href,
    ...getdefaultEventObj(),
  };

  const eventDetail = {
    ...CART_CHECKOUT,
    stateValue: card?.line_item?.length || 0,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail); // Track the event
};

/**
 * Tracks the "Shipping Address" event.
 *
 * @param {Object} card - The card object containing shipping address details.
 */
export const trackShippingAddress = (card) => {
  const { email, contactNumber } = card;
  const template = {
    action: SHIPPING_ADDRESS?.eventName,
    content_type: "ecom",
    container_type: "shipping-address-detail",
    cart_id: getCartId(),
    shipping_address: getFullAddress(card),
    billing_aaddress: getFullAddress(card),
    user_email: email,
    user_phone_number: contactNumber,
    page_name: window?.location?.href,
    ...getdefaultEventObj(),
  };

  const eventDetail = {
    ...CART_CHECKOUT,
    stateValue: card?.line_item?.length || 0,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail); // Track the event
};

export const trackPaymentDetail = (card) => {
  const { orderNumber, totalGross, currencyCode, lineItems, paymentMethod } = card;
  const template = {
    action: PAYMENT_DETAIAL?.eventName,
    content_type: "ecom",
    container_type: "payment-detail",
    cart_id: getCartId(),
    transaction_id: orderNumber,
    payment_method: paymentMethod,
    shipping_address: getFullAddress(card),
    billing_aaddress: getFullAddress(card),
    cart_total_items: getItemFromLineItem(lineItems),
    shipping_cost: 0,
    estimated_tax: 0,
    total_amount: totalGross,
    currency: currencyCode,
    page_name: window?.location?.href,
    ...getdefaultEventObj(),
  };

  const eventDetail = {
    ...PAYMENT_DETAIAL,
    stateValue: card?.line_item?.length || 0,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail); // Track the event
};
/**
 * Sends the event details to the RPI (Real-time Processing Interface) for tracking.
 *
 * @param {Object} eventDetail - The event detail object containing event information.
 */
const trackImpression = (eventDetail) => {
  if (getRpiInstance()) {
    globalThis.rpiWebClient.pushWebEvent(
      eventDetail?.eventName, // Event name
      eventDetail.stateValue, // Quantity or other relevant parameter
      eventDetail?.extraParam, // Event description
      eventDetail?.contentId, // Optional content ID or other identifier
      eventDetail?.data, // Pass the event data object
    );
  }
};
