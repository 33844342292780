import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles(() => ({
  userContainer: {
    display: "flex",
    flexDirection: "column",
    width: "200px",
  },
  ellipses: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));
