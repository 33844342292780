import { Box, Fade, Menu, MenuItem, Typography } from "@mui/material";
import { English, French, German } from "@platformx/shared/static-assets";
import React from "react";
import { LanguageList } from "../../utils/helperConstants";
import { getCurrentLang, getCurrentPathName, getSelectedSite } from "../../utils/helperFns";
import { useCustomStyle } from "./LanguageDropDown.style";

const LanguageDropDown = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useCustomStyle();
  const language = getCurrentLang();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (val: any) => {
    handleClose();
    const selectedSite = getSelectedSite();
    if (val !== getCurrentLang()) {
      window.location.replace(
        `${window.location.origin}/${selectedSite}/${val}${getCurrentPathName()}`,
      );
    }
  };

  return (
    <>
      <Box className={classes.languageDropdownmenuWrapper}>
        <img
          alt='language'
          src={
            language === "en"
              ? `${English}`
              : language === "de"
                ? `${German}`
                : language === "fr"
                  ? `${French}`
                  : `${English}`
          }
          width={24}
          height={24}
          // aria-haspopup='true'
          // aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className={classes.languageDropDownMenuMain}
        PaperProps={{
          className: "languageDropDownMenu",
        }}>
        {LanguageList().map((l: any) => {
          return (
            <MenuItem
              key={l.id}
              className={getCurrentLang() === l.id ? "activeCls" : ""}
              onClick={() => handleLanguageChange(l.id)}>
              <Box className='dropdownmenuItemImgWrap'>
                <Box className='imgFlagIcon'>
                  <img
                    alt='language'
                    src={
                      l.id === "en"
                        ? `${English}`
                        : l.id === "de"
                          ? `${German}`
                          : l.id === "fr"
                            ? `${French}`
                            : `${English}`
                    }
                    width={24}
                  />
                </Box>
                <Typography variant='h6regular'>{l.label}</Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
export default LanguageDropDown;
