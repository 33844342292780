import { Box } from "@mui/material";
import { ShowToastSuccess } from "@platformx/utilities";
import { DamContentGallery } from "@platformx/x-image-render";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import OptionsContent from "./OptionsContent";

const AddOptions = ({ setState, state, field }) => {
  const { t } = useTranslation();

  const [answers, setAnswers] = useState<any>(
    state[field?.name]
      ? state[field?.name]
      : [
          { id: "1", option: "", image: "" },
          { id: "2", option: "", image: "" },
        ],
  );

  const galleryType = useRef<string>("Images");
  const [galleryState, setGalleryState] = useState<boolean>(false);
  const [key, setKey] = useState("");
  const [answerId, setAnswerId] = useState("");

  const showGallery = (gType, keyName, id?: any) => {
    window.scrollTo(0, 0);
    galleryType.current = gType;
    setGalleryState(true);
    setKey(keyName);
    if (id) {
      setAnswerId(id);
    }
  };

  const toggleGallery = (toggleState) => {
    setGalleryState(toggleState);
  };

  const handleSelectedImage = (image, keyName) => {
    if (keyName === "answers") {
      setAnswers(
        answers.map((answer) =>
          answer.id === answerId ? { ...answer, image: image?.Thumbnail } : answer,
        ) as [],
      );
    }
    toggleGallery(false);
    ShowToastSuccess(`${t("image")} ${t("added_toast")}`);
  };
  useEffect(() => {
    setState({ ...state, [field?.name]: answers });
  }, [answers]);
  return (
    <>
      {galleryState && (
        <DamContentGallery
          handleImageSelected={handleSelectedImage}
          toggleGallery={toggleGallery}
          assetType={"Image"}
          keyName={key}
          isCrop={false}
          dialogOpen={galleryState}
        />
      )}

      <Box>
        <Box>
          <OptionsContent showGallery={showGallery} answers={answers} setAnswers={setAnswers} />
        </Box>
      </Box>
    </>
  );
};
export default AddOptions;
