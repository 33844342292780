export const IMPRESSIONS = {
  PRELEM_IMPRESSION: "PRELEMIMPRESSION",
  CLICK_IMPRESSION: "CLICKIMPRESSION",
  TRACKID: "selfDescribingEvent",
  BUTTON_CLICK: "BUTTONCLICK",
  CARD_CLICK: "CARDCLICK",
  Button: "button",
  Card: "card",
  NA: "NA",
  YES: "Yes",
  NO: "No",
};
