import { Box, Grid, useTheme } from "@mui/material";
import { BasicSwitch, CommonBoxWithNumber, TitleSubTitle } from "@platformx/utilities";
import { useCustomStyle } from "./UserTypes.styles";

export default function ExternalAccess({ t, state, setState }: any) {
  const className = useCustomStyle();
  const theme = useTheme();
  const handleCommunityUserSwitchChange = () => {
    setState((prevState: any) => {
      return {
        ...prevState,
        is_Community_User: !prevState?.is_Community_User,
      };
    });
  };
  return (
    <Box className={className.mainStyleWrapper} id='external'>
      <CommonBoxWithNumber
        number='03'
        title={t("external_access")}
        titleVarient='p3semibold'
        subTitleVarient='p4regular'
        subTitle={t("subhead")}>
        <Grid container>
          <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiledLast'>
            <TitleSubTitle
              title={t("community_user")}
              subTitle={t("external_access_sub")}
              titleVariant='h6medium'
              subTitleVariant='p4regular'
            />
          </Grid>
          <Grid item xs={12} sm={7} md={7} lg={7} className='textFiledLast'>
            <BasicSwitch
              disabled
              checked={state?.is_Community_User}
              onChange={handleCommunityUserSwitchChange}
              color={theme.palette.customColor.PRIMARY}
            />
          </Grid>
        </Grid>
      </CommonBoxWithNumber>
    </Box>
  );
}
