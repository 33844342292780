import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Tags({ handleTags }) {
  const theme = useTheme();
  const [tags, setTags] = useState<string[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    handleTags(tags);
  }, [tags]);

  const handleKeydown = (e) => {
    if (e.keyCode === 70) {
      e.stopPropagation();
    }
  };
  return (
    <Autocomplete
      sx={{
        "& .Platform-x-Chip-root": {
          margin: "5px 10px 5px 0",
          color: theme.palette.customColor.PRIMARY,
          border: `1px solid ${theme.palette.customColor.PRIMARY}`,
          borderRadius: theme.borderRadius.value,
          height: "40px",
        },
        "& .Platform-x-SvgIcon-root": {
          fontSize: "18px",
          marginRight: "5px",
        },
        ".Platform-x-Chip-label": {
          padding: "0 10px",
        },
        "& .Platform-x-InputBase-root": {
          flexWrap: "wrap",
          width: "auto",
        },
        "& .Platform-x-InputBase-input": {
          width: 0,
          minWidth: "100%",
        },
        "& .Platform-x-Chip-deleteIcon": {
          cursor: "pointer",
        },
        "& .Platform-x-InputBase-root.Platform-x-OutlinedInput-root": {
          padding: "9px 14px!important",
        },
      }}
      multiple
      id='tags-filled'
      options={[]}
      onChange={(event: object, value) => {
        const result = value.filter((str) => str.trim().length !== 0);
        const updatedtags = result.filter((c, index) => {
          return result.indexOf(c) === index;
        });
        setTags(() => updatedtags);
      }}
      freeSolo
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            label={option}
            {...getTagProps({ index })}
            variant='outlined'
            deleteIcon={<CloseRoundedIcon />}
            sx={{
              ".Platform-x-Chip-deleteIcon": {
                color: theme.palette.customColor.LABEL,
                cursor: "pointer",
                marginRight: theme.borderRadius.value,
                fontSize: "20px",
              },
            }}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} placeholder={t("add_tag")} onKeyDown={(e) => handleKeydown(e)} />
      )}
    />
  );
}
