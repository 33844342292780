import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

const DashboardLoader = () => {
  const theme = useTheme();
  return (
    <Box>
      {[1, 2, 3, 4].map((item, index) => (
        <Grid
          key={index}
          container
          sx={{
            background: theme.palette.customColor.BGLIGHT,
            alignItems: "center",
            borderBottom: theme.palette.customColor.LINE,
            padding: "0px 15px",
          }}>
          <Grid item xs={0.6}>
            <Skeleton
              animation='wave'
              width='64%'
              height={70}
              sx={{ backgroundColor: theme.palette.customColor.INPUT }}
            />
          </Grid>
          <Grid item xs={6.5}>
            <Skeleton
              animation='wave'
              height={10}
              width='90%'
              style={{ marginBottom: 6 }}
              sx={{ backgroundColor: theme.palette.customColor.INPUT }}
            />
            <Skeleton
              animation='wave'
              width='70%'
              height={15}
              sx={{ backgroundColor: theme.palette.customColor.INPUT }}
            />
          </Grid>
          <Grid item xs={1.5} sx={{ paddingLeft: "20px" }}>
            <Skeleton
              animation='wave'
              width='80%'
              height={45}
              sx={{ backgroundColor: theme.palette.customColor.INPUT }}
            />
          </Grid>
          <Grid item xs={2} sx={{ paddingLeft: "15px" }}>
            <Skeleton
              animation='wave'
              height={10}
              width='90%'
              style={{ marginBottom: 6 }}
              sx={{ backgroundColor: theme.palette.customColor.INPUT }}
            />
            <Skeleton
              animation='wave'
              width='70%'
              height={15}
              sx={{ backgroundColor: theme.palette.customColor.INPUT }}
            />
          </Grid>
          <Grid item xs={1.4} sx={{ paddingLeft: "15px" }}>
            <Skeleton
              animation='wave'
              height={10}
              width='100%'
              style={{ marginBottom: 6 }}
              sx={{ backgroundColor: theme.palette.customColor.INPUT }}
            />
            <Skeleton
              animation='wave'
              width='60%'
              height={15}
              sx={{ backgroundColor: theme.palette.customColor.INPUT }}
            />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default DashboardLoader;
