import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";

import {
  allowOnlyLetterInputData,
  allowOnlyNumberInputData,
  allowOnlyShippingLetterInputData,
  priceRegx,
} from "@platformx/utilities";
import { useEffect, useState } from "react";
import "./StringTextBoxComponent/StringTextBoxComponent.css";

const allowInputParams = (e: any, key: string) => {
  switch (key) {
    case "number":
      return allowOnlyNumberInputData(e);
    case "CustomFirstName":
      return allowOnlyShippingLetterInputData(e);
    case "charter":
      return allowOnlyLetterInputData(e);
    default:
      return null;
  }
};

type TextBoxWithLoaderProps = {
  maxLength?: number;
  name?: string;
  label?: string;
  value?: string;
  helperText?: any;
  handleChange?: any;
  required?: boolean;
  isDisabled?: boolean;
  isCloseIcon?: boolean;
  rows?: string | number;
  errorMessage?: string;
  cssClass?: string | number;
  isPasswordField?: boolean;
  customInPutAllowField?: string;
  multiline?: string | number | boolean;
  onCloseIconClick?: any;
  verificationStatus?: string;
};

const TextBoxWithLoader = (props: TextBoxWithLoaderProps) => {
  const {
    name,
    label,
    rows,
    value,
    required,
    multiline,
    cssClass,
    isDisabled,
    maxLength = 0,
    handleChange,
    isPasswordField,
    errorMessage = "",
    isCloseIcon = true,
    customInPutAllowField = "",
    onCloseIconClick,
    verificationStatus,
  } = props;

  const [stateValue, setStateValue] = useState(value);
  const [textBoxType, setTextBoxType] = useState("text");

  const onChange = (e: any) => {
    const { target: { value: val = "", name: namee = "" } = {} } = e;
    if (namee === "price") {
      if (!priceRegx.test(val)) return;
    }
    setStateValue(val);
  };

  const clearText = (names = "") => {
    if (onCloseIconClick) {
      onCloseIconClick(); // Call the callback function
    }
    handleChange({
      target: {
        name: names,
        value: "",
      },
    });
    setStateValue("");
  };

  const showHideShowText = () => {
    setTextBoxType(textBoxType === "text" ? "password" : "text");
  };

  useEffect(() => {
    isPasswordField ? setTextBoxType("password") : setTextBoxType("text");
  }, [isPasswordField]);

  useEffect(() => {
    setStateValue(value);
  }, [value]);
  const onInputHandler = (e: any) => {
    if (customInPutAllowField) {
      allowInputParams(e, customInPutAllowField);
    }
  };
  return (
    <TextField
      fullWidth
      name={name}
      type={textBoxType}
      // label={null}
      label={label}
      value={stateValue}
      variant='filled'
      onChange={onChange}
      onBlur={handleChange}
      // placeholder={label}
      rows={rows ? rows : 1}
      helperText={errorMessage}
      inputProps={{
        shrink: false,
        maxLength: maxLength > 0 ? maxLength : 1000000,
      }}
      required={required ? true : false}
      multiline={multiline ? true : false}
      disabled={isDisabled ? true : false}
      className={`${errorMessage ? "error" : "primary"} custom-textbox string-textbox ${cssClass}`}
      onInput={(e) => onInputHandler(e)}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {/* <span className="textfield-icon-left">
                <AccountCircle />
              </span> */}
            {isCloseIcon && (
              <CloseIcon
                className='textfield-close-icon'
                onClick={() => clearText(name)}
                data-testid='clear-text'
              />
            )}

            {isPasswordField && (
              <RemoveRedEyeOutlinedIcon
                onClick={() => showHideShowText()}
                className='show-hide-icon'></RemoveRedEyeOutlinedIcon>
            )}
            {verificationStatus === "loading" && <CircularProgress size={24} />}
            {verificationStatus === "verified" && <CheckIcon sx={{ fill: "green !important" }} />}
          </InputAdornment>
        ),
      }}
    />
  );
};
export default TextBoxWithLoader;
