import { NO_PERSONALISATION } from "../../utils/constants/constant";
import { getUserInfo } from "../../utils/helper";
import { fetchEcomProducts } from "../ZaggEcommerce/HelperFunction";

const getType = (selectedPersonalisation: any) => {
  const personalisationValue = selectedPersonalisation?.["personalisation-value"];
  return personalisationValue !== NO_PERSONALISATION ? personalisationValue : null;
};
export const getProductList = (
  inputData: any,
  secondaryArgs: any,
  authoringHelper,
  selectedPersonalisation: any,
  productId: any = null,
) => {
  const { prelemBaseEndpoint: { deliveryEndPointGq = "" } = {}, sitename = "" } = secondaryArgs;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    pagination: { start = 0, rows = 20 } = {},
    searchTerm = "",
    tags = [],
    ecommerceRequest: { filter = [] } = {},
  }: any = inputData;

  const userEmail = getUserInfo("email_id");
  const type = getType(selectedPersonalisation);
  const queryParts = [
    `{pagination:{start:${start},rows:${rows}},searchTerm:${JSON.stringify(searchTerm)}`,
    `tags:${JSON.stringify(tags)}`,
    `filter:"Ecommerce"`,
    `isSuggestive:false`,
    `ecommerceRequest:{filter:${JSON.stringify(filter)}}`,
    // `customerEmail:"mohan@gmail.com",type:"crosssellproducts"`
  ];

  // Conditionally add customerEmail and type
  if (!authoringHelper?.isEditPage && userEmail && type) {
    queryParts.push(`customerEmail:"${userEmail}",type:"${type}"`);
  }
  if (productId) {
    queryParts.push(`productId:"${productId}",type:"${type}"`);
  }
  // Combine all parts into the final query string
  const Query = `${queryParts.join(",")}}`;
  return fetchEcomProducts(deliveryEndPointGq, Query, sitename);
};
