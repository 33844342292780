/* eslint-disable no-debugger */
import { useLazyQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { Box, Button, Dialog, Divider, Fab, Typography, useTheme } from "@mui/material";
import { contentTypeAPIs } from "@platformx/authoring-apis";
import {
  ContentListDesktopLoader,
  ContentListMobileLoader,
  DesktopListing,
  ListHeader,
  MobileListing,
  ShowToastError,
  debounce,
} from "@platformx/utilities";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

const QuestionListing = ({
  setIsClickedQueList,
  quizState,
  setQuizState,
  setOpenAddQuestion,
  unsavedChanges,
  isError,
  setIsError,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isloading, setLoading] = useState(true);
  const [selectedData, setSelectedData] = useState<any>({});
  const [isDone, setIsDone] = useState(false);
  const searchPageUrl = new URL(window.location.href);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState(
    searchPageUrl.searchParams.get("searchTerm")
      ? (searchPageUrl.searchParams.get("searchTerm") as string)
      : "",
  );
  const [, setInputValue] = React.useState<any>(
    searchPageUrl.searchParams.get("inputValue")
      ? (searchPageUrl.searchParams.get("inputValue") as string)
      : "",
  );
  const [questions, setQuestions] = useState<any>([]);
  const [selectedIdArray, setSelectedIdArray] = React.useState<{ current_page_url: string }[]>(
    quizState?.questions ? quizState?.questions : [],
  );
  const [runFetchQuestionList] = useLazyQuery(contentTypeAPIs.fetchContentTypeListAll);
  const rows = 20;
  const getQuestionList = (ind, search = "") => {
    runFetchQuestionList({
      variables: {
        pagination: { start: ind, rows },
        pageFilter: "ALL",
        sort: "DESC",
        searchTerm: search,
        contentType: "Question",
      },
    })
      .then((res) => {
        const filtered = res?.data?.authoring_getContentTypeItems || [];
        const newData = [...(questions || []), ...(filtered || [])];
        setQuestions([...newData]);
        if (filtered?.length < rows) {
          setLoading(false);
        } else {
          setLoading(true);
        }
      })
      .catch((error: any) => {
        const statusCode = error?.networkError?.statusCode || 0;
        ShowToastError(t("api_error_toast"), statusCode);
      });
  };

  const fetchMoreData = () => {
    const nextIndex = startIndex + rows;
    setStartIndex(() => nextIndex);
    getQuestionList(nextIndex, searchTerm);
  };

  const handleSearchChange1 = useCallback(
    debounce((ind, sear) => getQuestionList(ind, sear)),
    [],
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.currentTarget.value);
  };

  useEffect(() => {
    handleSearchChange1(startIndex, searchTerm);
  }, [searchTerm]);

  const dropDownList = [t("questions")];
  const onClickSelect = (val) => {
    if (selectedIdArray?.length < 16 || isSelected(val)) {
      if (selectedIdArray.some((item) => item.current_page_url === val.current_page_url)) {
        setSelectedIdArray((current) =>
          current.filter((data) => data.current_page_url !== val.current_page_url),
        );
      } else {
        setSelectedIdArray((prev) => [...prev, { current_page_url: val.current_page_url }]);
        setSelectedData({ ...selectedData, val });
      }
    } else {
      ShowToastError(t("max_question_toast"));
    }
  };
  useEffect(() => {
    if (selectedIdArray.length > 0) {
      setIsDone(true);
    } else {
      setIsDone(false);
    }
  }, [selectedIdArray]);
  const isSelected = (data) => {
    const a = selectedIdArray.some((item) => item.current_page_url === data.current_page_url);
    return a;
  };
  const returnBack = () => {
    setIsClickedQueList(false);
  };
  const handleDone = () => {
    const newQuestions = selectedIdArray.map((item) => {
      const question = questions.find(
        ({ current_page_url }) => current_page_url === item.current_page_url,
      );
      if (question) {
        return question;
      }
      return item;
    });
    if (quizState?.questions?.length > 16 || newQuestions.length > 16) {
      ShowToastError(t("max_question_toast"));
    } else {
      setQuizState({ ...quizState, questions: [...newQuestions] });
      setIsError({ ...isError, questions: false });

      setIsClickedQueList(false);
      unsavedChanges.current = true;
    }
  };

  const handleResetInputFilter = async () => {
    await setInputValue("");
  };
  const onClickAddQue = () => {
    setIsClickedQueList(false);
    setOpenAddQuestion(true);
  };
  return (
    <Dialog fullScreen open={true}>
      <ListHeader
        headertext={t("choose_your_question")}
        returnBack={returnBack}
        dropDownList={dropDownList}
        menuItem={t("questions")}
        isDisableDone={!isDone}
        handleDone={handleDone}
        suggestiveSearchList={[{}]}
        handleResetInputFilter={handleResetInputFilter}
        setSearchTerm={setSearchTerm}
        setInputValue={setInputValue}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
      />
      <Divider></Divider>
      <Box
        sx={{
          padding: "15px",
          position: "relative",
          overflowY: "scroll",
          overflowX: "hidden",
          paddingBottom: { xs: "150px", sm: "0" },
          display: {
            sm: "flex",
            xs: "none",
          },
        }}
        id='questionListing'>
        <Box sx={{ width: "-webkit-fill-available", margin: "0px 110px" }}>
          <InfiniteScroll
            dataLength={questions?.length}
            next={fetchMoreData}
            hasMore={isloading}
            loader={<ContentListDesktopLoader />}
            scrollableTarget='questionListing'
            endMessage={
              questions?.length > 20 ? (
                <Typography sx={{ textAlign: "center" }}> {t("no_more_data")}</Typography>
              ) : (
                ""
              )
            }>
            <Box sx={{ padding: "0 10px 0 15px" }}>
              {questions?.map((item, index) => (
                <DesktopListing
                  key={index}
                  index={index}
                  title={item.question}
                  description={item.question_type}
                  author={item.author}
                  time={item.last_modification_date}
                  onClickSelect={onClickSelect}
                  item={item}
                  isSelected={isSelected}
                />
              ))}
            </Box>
          </InfiniteScroll>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: theme?.palette?.customColor?.BGWEAK,
          width: "100%",
          padding: "15px",
          position: "relative",
          height: "calc(100vh - 53px)",
          overflowY: "scroll",
          overflowX: "hidden",
          display: {
            sm: "none",
            xs: "flex",
          },
        }}
        id='mobquestionListing'>
        <Box sx={{ width: "-webkit-fill-available" }}>
          <InfiniteScroll
            dataLength={questions !== undefined ? questions.length : 0}
            next={fetchMoreData}
            hasMore={isloading}
            loader={<ContentListMobileLoader />}
            scrollableTarget='mobquestionListing'>
            <Box sx={{ width: "-webkit-fill-available" }}>
              {questions?.map((item, index) => (
                <MobileListing
                  key={index}
                  index={index}
                  title={item.question}
                  description={item.question_type}
                  author={item.author}
                  time={item.modificationDate}
                  onClickHandle={onClickSelect}
                  item={item}
                  isSelected={isSelected}
                />
              ))}
              {isDone && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant='primaryButton'
                    disableElevation
                    onClick={handleDone}
                    data-testid='quiz-done-button'
                    sx={{
                      width: "347px",
                      position: "fixed",
                      bottom: "3%",
                      textTransform: "none",
                    }}>
                    <DoneOutlinedIcon sx={{ mr: "15.5px" }} />
                    {t("done")}
                  </Button>
                </Box>
              )}
              {!isDone && (
                <Fab
                  sx={{
                    position: "fixed",
                    bottom: "4%",
                    right: "5%",
                    zIndex: 99,
                  }}
                  size='medium'
                  color='primary'
                  aria-label='add'
                  onClick={onClickAddQue}
                  data-testid='quiz-add-ques'>
                  <AddIcon />
                </Fab>
              )}
            </Box>
          </InfiniteScroll>
        </Box>
      </Box>
    </Dialog>
  );
};
export default QuestionListing;
