import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CardContent from "./CardContent";
import { DialogList } from "./EmbedDialog.types";

export default function EmbedDialog({
  isDialogOpen,
  closeEmbedButtonHandle,
  setSelectedItem,
  contentType,
}: DialogList) {
  return (
    <Box className='embedmodal'>
      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={closeEmbedButtonHandle}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          sx: {
            maxWidth: { xs: "97%", sm: "1080px" },
            width: "97%",
            margin: { xs: "0px" },
            overflow: "hidden",
          },
        }}
        sx={{
          display: { xs: "block", md: "block" },
        }}>
        <Box
          sx={{
            position: "absolute",
            right: "10px",
            top: "8px",
          }}>
          <Box
            sx={{
              cursor: "pointer",
              zIndex: 99,
              position: "relative",
              width: "24px",
              height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={closeEmbedButtonHandle}
            data-testid='close-embed-button'>
            <CloseIcon />
          </Box>
        </Box>
        <CardContent selectedItem={setSelectedItem} contentType={contentType} />
      </Dialog>
    </Box>
  );
}
