import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Typography } from "@mui/material";
import { getFirstTwoletters, ToolTip } from "@platformx/utilities";
import { useState } from "react";
import SitesPopup from "../SitesPopup/SitesPopup";
import useSitesMenuStyle from "./SitesMenu.style";

const MenuSitesListDropdown = () => {
  const [isVisible, setIsVisible] = useState(false);
  const mySite = () => {
    setIsVisible(true);
  };
  const splitPath = window?.location.pathname.split("/");
  let [, selectedSite] = splitPath;

  if (selectedSite?.toLowerCase() === "system") {
    selectedSite = "Administrator";
  }
  const classes = useSitesMenuStyle();
  const charatorLength = selectedSite.length;

  return (
    <Box>
      <Box className={`${classes.dropdowncontain} ddmenu`} onClick={mySite} data-testid='menuSite'>
        <Box className={classes.dropNewTypo}>
          <Box className='avatarWp'>
            <Typography variant='p4bold'>{getFirstTwoletters(selectedSite || "")}</Typography>
          </Box>
          {charatorLength <= 21 ? (
            <Typography variant='h6medium' className={`${classes.dropdowntypo} ddmenutex`}>
              {selectedSite}
            </Typography>
          ) : (
            <ToolTip
              className='textMaxChar'
              component={
                <Typography variant='h6medium' className={`${classes.dropdowntypo} ddmenutex`}>
                  {selectedSite}
                </Typography>
              }
              Title={selectedSite}
              position='bottom'
            />
          )}
        </Box>
        <ArrowRightIcon />
      </Box>
      {isVisible && <SitesPopup isVisible={isVisible} setIsVisible={setIsVisible} />}
    </Box>
  );
};

export default MenuSitesListDropdown;
