const ADD_TO_CART = {
  eventName: "w_add_to_cart", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "Ecom add to cart Impression", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};
const PRODUCT_VIEW = {
  eventName: "w_product_view", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "Ecom product view Impression", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};
const CART_CHECKOUT = {
  eventName: "w_cart_checkout", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "Ecom cart checkout Impression", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};
const SHIPPING_ADDRESS = {
  eventName: "w_shipping_address", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "Ecom shipping address Impression", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};
const PAYMENT_DETAIAL = {
  eventName: "w_make_payment", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "Ecom payment Impression", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};
export { ADD_TO_CART, CART_CHECKOUT, PAYMENT_DETAIAL, PRODUCT_VIEW, SHIPPING_ADDRESS };
