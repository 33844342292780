import { Box, Typography } from "@mui/material";
import {
  Analytics,
  AuthoringHelper,
  Loader,
  SecondaryArgs,
  fetchOfferName,
  formCroppedUrlString,
  generateImageObjects,
} from "@platformx/utilities";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import ImageRender from "../../components/ImageRender";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import prelemTypes from "../../globalStyle";
import { useCustomStyle } from "./Banner1.style";

const Banner1 = ({ content, analytics, authoringHelper, secondaryArgs }: Banner1Prop) => {
  // State to manage the loading status
  const [loader, setLoader] = useState(false);

  // State to store the original image data
  const [originalImageData, setOriginalImageData] = useState<any>(
    content?.RpiKey ? "" : content?.ImageCompound?.ImageCompound_1?.original_image,
  );

  // State to store the published image data
  const [publishImageData, setPublishImageData] = useState<any>(
    content?.RpiKey ? "" : content?.ImageCompound?.ImageCompound_1?.published_images,
  );

  // State to store the title data
  const [titleData, setTitleData] = useState<string>(content?.RpiKey ? "" : (content?.Title ?? ""));

  // Reference for tracking the first render
  const firstRender = useRef(true);

  // Using the intersection observer to detect if the banner is in view
  const { ref, inView } = useInView({
    threshold: 0, // Trigger as soon as the banner enters the viewport
  });

  /**
   * Function to fetch banner data (image and title) from the API using RpiKey and VisitorID.
   * It will update the image and title state based on the response.
   */
  const fetchBannerData = async () => {
    const VisitorID = localStorage.getItem("VisitorID") || null;
    if (content?.RpiKey && VisitorID) {
      try {
        // Start loader when fetching data
        setLoader(true);

        // API call to fetch offer data
        const response = await fetchOfferName(VisitorID, content?.RpiKey, secondaryArgs);
        const offerName = response?.Results[0]?.ResultContent.content[0];

        // Extract image URL and generate image objects
        const imageUrl = offerName?.image_url;
        const { original_image, published_images } = generateImageObjects(imageUrl);

        // Update state with fetched data
        if (offerName) {
          setOriginalImageData(original_image);
          setPublishImageData(published_images);
          setTitleData(offerName?.title);
        }

        // Stop loader when fetching is done
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error("Error fetching offer name:", error);
      }
    }
  };

  /**
   * useEffect to fetch banner data when RpiKey is available and
   * the page is not in authoring mode.
   */
  useEffect(() => {
    if (content?.RpiKey && !analytics?.isAuthoring) {
      fetchBannerData(); // Call API if RpiKey is present
    }
  }, [content?.RpiKey]);

  /**
   * Function to generate default structured data for SEO purposes.
   * Returns a structured data object based on the content.
   */
  const defaultStructureData = () => {
    let Banner1StructureData;
    const { original_image_relative_path, ext }: any =
      content?.ImageCompound?.ImageCompound_1?.original_image || originalImageData;
    const img = formCroppedUrlString(
      secondaryArgs?.gcpUrl,
      secondaryArgs?.bucketName,
      original_image_relative_path,
      ext,
    ).src;

    try {
      Banner1StructureData = {
        "@context": "https://schema.org/",
        "@type": "ImageObject",
        contentUrl: img,
        name: titleData,
      };
    } catch (e) {
      Banner1StructureData = {};
    }
    return Banner1StructureData;
  };

  /**
   * Function to generate structured data for SEO and send it to the authoring tool.
   * It either uses saved structured data or generates default data.
   */
  const genrateStructureData = () => {
    let Banner1StructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData);

    if (firstRender.current === true) {
      // Generate default structured data on first render
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        Banner1StructureData = JSON.parse(tempSD);
      } else {
        Banner1StructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      Banner1StructureData = defaultStructureData();
    }
    return Banner1StructureData;
  };

  /**
   * useEffect to send the structured data to the authoring tool if SEO is enabled.
   */
  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = genrateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.ImageCompound?.ImageCompound_1?.original_image, content?.Title]);

  // Register prelem impression for analytics tracking
  usePrelemImpression(analytics, inView, secondaryArgs);

  // Define custom and global CSS classes
  const classes = useCustomStyle();
  const globalClasses = prelemTypes();

  // Render the loader if it's active, otherwise render the banner
  return (
    <Box ref={authoringHelper?.innerRef}>
      {loader ? (
        <Loader /> // Show loader while data is being fetched
      ) : (
        <Box
          ref={ref}
          className={`${classes.banner1Wrapper} ${globalClasses.prelemType2} prelem prelemType2 banner1Props`}>
          <ImageRender
            originalImage={originalImageData}
            publishedImages={publishImageData}
            secondaryArgs={secondaryArgs}
            imgOrder={{
              1440: "hero",
              1280: "hero",
              1024: "card2",
              768: "card2",
              600: "card2",
              320: "card2",
            }}
            index={0}
            isEditing={authoringHelper?.isEditing}
          />

          <Box className='banner1Overlay'></Box>
          <Box className='banner1ContentWrapper'>
            <Typography variant='h2semibold' color='textColor' align='center' id='Title'>
              {titleData}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

interface Banner1Prop {
  content: Content;
  analytics: Analytics;
  authoringHelper?: AuthoringHelper;
  secondaryArgs: SecondaryArgs;
}

interface Content {
  Title?: string;
  ImageCompound: {
    ImageCompound_1: {
      published_images: PublishedImage[];
      original_image?: object;
    };
  };
  RpiKey?: string;
}

interface PublishedImage {
  aspect_ratio: string;
  bucket_path: string;
  folder_path: string;
  visibility: string;
  ext: string;
}

Banner1.defaultProps = {
  content: {
    Title: "Lorum ipsum dolor sit amet ",
    PrelemContentType: ["Select"],
    ImageCompound: {
      ImageCompound_1: {
        original_image: {
          original_image_relative_path: "machine_assets/1690278857303/public/png/Banner1",
          visibility: "public",
          ext: "png",
          bitStreamId: "",
          auto: true,
          MetaFields: {
            AltText: "BannerOne",
            Name: "BannerOne",
            Title: "BannerOne",
            Description: "This is for BannerOne",
            Attribution: false,
          },
        },
        published_images: [
          {
            aspect_ratio: "landscape",
            folder_path: "machine_assets/1690278857303/public/png/Banner1-landscape",
          },
          {
            aspect_ratio: "square",
            folder_path: "machine_assets/1690278857303/public/png/Banner1-square",
          },
          {
            aspect_ratio: "portrait",
            folder_path: "machine_assets/1690278857303/public/png/Banner1-portrait",
          },
          {
            aspect_ratio: "hero",
            folder_path: "machine_assets/1690278857303/public/png/Banner1-hero",
          },
          {
            aspect_ratio: "card1",
            folder_path: "machine_assets/1690278857303/public/png/Banner1-card1",
          },
          {
            aspect_ratio: "card2",
            folder_path: "machine_assets/1690278857303/public/png/Banner1-card2",
          },
        ],
      },
    },
    RpiKey: "",
  },
  authoringHelper: {
    innerRef: null,
    sendStructureDataToAuthoringCB: () => {},
    sendDefaultStructureDataForResetToAuthoringCB: () => {},
    openButtonEditWindowInAuthoringCB: () => {},
    selectedButtonNameForEditing: "",
    isEditing: false,
    buttonRef: null,
    buttonContentEditable: false,
    lastSavedStructuredData: "",
  },

  analytics: {
    isAnalyticsEnabled: true,
    isSeoEnabled: false,
    isAuthoring: false,
    position: 0,
    pageId: 1234,
    prelemId: 2345,
    pageTitle: "Banner 1",
    pageDesc:
      "This prelem can be used as the main banner or simple banner anywhere in the website.",
    pageTags: "Banner, Hero Banner",
    prelemTags: "Banner, Hero Banner",
  },
  secondaryArgs: {
    gcpUrl: "https://storage.googleapis.com",
    bucketName: "cropped_image_public",
  },
};

export default Banner1;
