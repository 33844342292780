import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  containerStyle: {
    [`@media (min-width:${theme?.breakpoints?.values?.xs}px)`]: {
      paddingRight: "10px",
      marginTop: "30px",
    },
    [`@media (min-width:${theme?.breakpoints?.values?.sm}px)`]: {
      paddingRight: "10px",
      marginTop: "40px",
    },
    [`@media (min-width:${theme?.breakpoints?.values?.md}px)`]: {
      paddingRight: "55px",
    },
    display: "flex",
    flexDirection: "column",
  },
  contentStyle: {
    [`@media (min-width:${theme?.breakpoints?.values?.xs}px)`]: {
      marginTop: "14px",
    },
    [`@media (min-width:${theme?.breakpoints?.values?.sm}px)`]: {
      marginTop: "40px",
    },
  },
  roleandperBox: {
    border: `1px solid ${theme?.palette?.customColor?.LINE}`,
    margin: "10px",
    borderRadius: "5px",
    padding: "10px",
    minHeight: "100px",
    maxHeight: "100px",
    "& h6": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitLineClamp: "2",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      fontSize: "12px",
      color: theme?.palette?.customColor?.LABEL,
      lineHeight: "18px",
    },
  },
}));
