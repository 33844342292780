import graphqlInstance from "../../config/graphqlConfig";
import { ApiResponse } from "../utils/common.types";
import { SpaceQueries } from "../../graphQL/queries/spaceQueries";
import { PROJECT_CATCH_ERROR_LIST } from "@platformx/utilities";

export const spaceManagementAPI = {
  fetchSpaces: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: SpaceQueries.FETCH_SPACE_LIST,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:fetchSpaces`, err);
      throw err;
    }
  },

  getSpaceBasedId: async <T>(id: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: SpaceQueries.FETCH_SPACE_DETAILS_BY_ID,
        variables: { id },
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:getSpaceBasedId`, err);
      throw err;
    }
  },

  getMembersToInvite: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: SpaceQueries.FETCH_MEMBERS_TO_INVITE,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:getMembersToInvite`, err);
      throw err;
    }
  },

  getSpaceMembers: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: SpaceQueries.FETCH_SPACE_MEMBERS,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:getSpaceMembers`, err);
      throw err;
    }
  },

  getAllExoMembers: async <T>(): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: SpaceQueries.FETCH_ALL_EXO_MEMBERS,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:getAllExoMembers`, err);
      throw err;
    }
  },
};
