import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  buttonWrapper: {
    "& .iconBtn": {
      minWidth: "0px",
      display: "flex",
      justifyContent: "center",
      width: "38px",
      height: "38px",
      alignItems: "center",
      margin: "0 1px",
      "& img": {
        height: "22px",
      },
      "&:hover": {
        backgroundColor: "rgba(20, 20, 43, 0.04)",
      },
      "&:disabled": {
        color: theme.palette.customColor.PLACEHOLDER,
        pointerEvents: "auto",
      },
    },
  },
}));
