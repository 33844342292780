export const tagInlineCss = `
textarea::placeholder {
  color: #ced3d9;  
  margin-top: 20px;
  font-size: 14px;
}
input::placeholder {
  color: #ced3d9;
  font-size: 14px;
}
textarea:focus{
outline: none;
}
textarea {
font-size:16px
}
.Platform-x-InputBase-root {
}
.vod-cat .Platform-x-FormControlLabel-label{
  background-color: #fff;
  padding: 6px 12px;
  border-radius: 5px;
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #14142B;
}
.Platform-x-FormControlLabel-root.vod-cat{
margin-left: 0px;
margin-right: 10px;
}
.vod-cat .Mui-checked + .Platform-x-FormControlLabel-label {
background-color: #14142B;
color: #fff;
border-radius: 5px;
}

.vod-cat .Platform-x-ButtonBase-root.Platform-x-Checkbox-root{
display: none;
}
.vod-cat .Platform-x-ButtonBase-root.Platform-x-Checkbox-root.Mui-checked{
display: none;
background-color: #2D2D39;
color: #fff;
border-radius: 0;
padding-left: 0;
padding-top: 6px;
padding-bottom: 6px;

}
.vod-cat .Platform-x-SvgIcon-root{
font-size: 12px;
}
`;
