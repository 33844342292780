import { gql } from "@apollo/client";

export const fetchBlogs = gql`
  query FETCH_BLOGS($input: authoring_createBlogInput) {
    authoring_fetchblog(input: $input)
  }
`;
export const fetchBlogById = gql`
  query FETCH_BLOG_BY_ID($input: authoring_createBlogInput) {
    authoring_fetchblogById(input: $input)
  }
`;

export const createBlog = gql`
  mutation CREATE_BLOG($input: authoring_createBlogInput) {
    authoring_createblog(input: $input)
  }
`;

export const updateBlog = gql`
  mutation UPDATE_BLOG($input: authoring_createBlogInput) {
    authoring_updateblog(input: $input)
  }
`;
