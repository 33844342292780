import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  backArrowIcon: {
    cursor: "pointer",
    width: "24px",
    height: "24px",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
    display: "flex",
  },
  contentStyle: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    position: "relative",
  },
  dFlexItemCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));
