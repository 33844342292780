import { Box, Button, Container, FormControl, Grid, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Loader, priceRegx } from "@platformx/utilities";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Confirmation from "../../../ContentTypes/Ecommerce/Common/Confirmation/Confirmation";
import DropDown from "../../../components/DropDown/Dropdown";
import StringOnBlurTextBox from "../../../components/TextBox/StringTextBoxComponent/StringOnBlurTextBox";
import TextBoxWithLoader from "../../../components/TextBox/TextBoxWithLoader";
import {
  fetchEcomProducts,
  getProductCategory,
  getProductPurchaseLocation,
  registerMyProduct,
  verifySerialNo,
} from "../HelperFunction";
import {
  EditReviewIcon,
  NoProduct,
  ReviewIcon,
  SelectProductIcon,
  SignInIcon,
  successGif,
} from "../Image";
import StepperItem from "../Stepper/Stepper";
import { useCustomStyle } from "../ZaggEcommerce.style";

interface Product {
  id: string;
  name: string;
  color: string;
  deliveryDate: string; // Consider converting it to a Date object if needed
  deliveryStatus: string;
  image: string;
  rate: any;
  orderid: any;
  lineItemId: any;
  customFields?: any;
  attr_images: string[];
}

const ProductRegistration = ({ authoringHelper, secondaryArgs }) => {
  const classes = useCustomStyle();

  const steps = [
    {
      text: "Sign In",
      Icon: SignInIcon,
    },
    {
      text: "Select Product",
      Icon: SelectProductIcon,
    },
    {
      text: "Review",
      Icon: ReviewIcon,
    },
  ];
  const [isEditmode, setIsEditmode] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [stateManage, setStateManage] = useState<any>({
    productCategory: "",
    productLine: "",
    productSerialNo: "",
    productPurchaseLocation: "",
    productPurchaseDate: "",
    price: "",
  });

  const [productLineOptions, setProductLineOptions] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);
  const [verifiedProduct, setVerifiedProduct] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState<
    "loading" | "verified" | "error" | ""
  >("");

  const [errorMessage, setErrorMessage] = useState("");
  // loading state to handle product serial no. verification
  const [loading, setLoading] = useState(false);
  // boolean flag to get valid / invalid state of form
  const isValid =
    stateManage.price &&
    stateManage.productPurchaseDate &&
    stateManage.productPurchaseLocation &&
    !errorMessage &&
    !loading;
  const handleSerialInputChange = async (event: any) => {
    setLoading(true);
    setVerifiedProduct({});
    setErrorMessage("");
    setVerificationStatus("loading");

    const { value = "" } = event?.target || {};
    // Reset states if the input is empty
    if (!value.trim()) {
      setLoading(false);
      setVerifiedProduct({});
      setErrorMessage("");
      setVerificationStatus("");
      return;
    }
    const res = await verifySerialNo(
      value,
      secondaryArgs?.sitename,
      secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq,
    );

    setLoading(false);

    if (res?.length > 0) {
      setVerifiedProduct(res[0]);
      setErrorMessage("");
      setVerificationStatus("verified");
    } else {
      setErrorMessage("Serial Number or Registration Code is not valid");
      setVerificationStatus("error");
    }

    handleInputChange(event);
  };

  const handleInputChange = (event: any) => {
    event.preventDefault && event.preventDefault();
    const { name = "", value = "" } = event?.target || {};

    if (name === "price") {
      // testing the price against regex expression
      if (!priceRegx.test(value)) return;
    }

    setStateManage({
      ...stateManage,
      [name]: value,
    });
    if (name === "productLine") {
      const selectedProductItem = productLineOptions.find((product) => product.name === value);
      setSelectedProduct(selectedProductItem);
      setStateManage({
        ...stateManage,
        [name]: selectedProductItem?.name,
        pId: selectedProductItem?.id,
      });
    }
  };
  const confirmationPopUpClose = () => {
    setIsRegistered(true);
    window.location.reload();
  };

  const registerProduct = async () => {
    //const orderId = selectedProduct?.id;
    const orderId = verifiedProduct?.id;
    const lineItemId = stateManage?.productSerialNo;
    setLoader(true);
    const newdata = await registerMyProduct(
      orderId,
      lineItemId,
      secondaryArgs?.sitename,
      secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq,
      stateManage?.productPurchaseLocation,
    );

    if (newdata && newdata.statusCode === 201) {
      setIsRegistered(true);
    }
    setLoader(false);
  };

  const getAllProducts = async () => {
    const queryParam = `{pagination:{start:0,rows:200},searchTerm:"",tags:[],filter:"Ecommerce",isSuggestive:false,ecommerceRequest:{filter:[]}}`;
    // const response = await getRestApiCall(
    //   `${secondaryArgs?.prelemBaseEndpoint?.deliveryEndPoint}api/v1/web/en/delivery/getEcomProducts?queryParam=${queryParam}`,
    //   secondaryArgs?.prelemBaseEndpoint?.language,
    //   secondaryArgs?.sitename,
    // );

    const response = await fetchEcomProducts(
      `${secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq}`,
      queryParam,
      secondaryArgs?.sitename,
    );
    //const data: any = response?.data?.data?.fetchEcomProducts.reduce((res: any, item: any) => {
    const data: any = response?.reduce((res: any, item: any) => {
      res.push({ name: item.ecomx_name, ...item });
      return res;
    }, []);
    setProductLineOptions(data);
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <Box className={`${classes.zaggEcommerceWarpper} zaggEcommerceContainer`}>
      {loader ? (
        <Loader />
      ) : (
        <Container
          className={
            authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
          }>
          <Box className='productRegistration'>
            <Grid container>
              <Grid item xs={12} md={7} lg={8} className='formcontainerLeft'>
                <Typography variant='h4semibold' mb={5}>
                  Product Registration
                </Typography>
                <Box className='steppersWrapperItem'>
                  <StepperItem activeItem={isEditmode ? 3 : 2} steps={steps} />
                </Box>
                <FormControl component='fieldset' fullWidth>
                  {!isEditmode ? (
                    <Box className='leftPanel'>
                      <Grid item xs={12} mb={3}>
                        <Typography variant='h5regular'> Select Product Category </Typography>
                        <DropDown
                          name='productCategory'
                          arrayData={getProductCategory()}
                          label={"Select Below"}
                          cssClass='input-control-selectbox'
                          value={stateManage.productCategory}
                          parentHandler={handleInputChange}
                          stateValue={stateManage.productCategory}></DropDown>
                      </Grid>
                      <Grid item xs={12} mb={3}>
                        <Typography variant='h5regular'> Select Product Line </Typography>
                        <DropDown
                          isDisabled={stateManage.productCategory ? false : true}
                          name='productLine'
                          arrayData={productLineOptions}
                          label={"Select Below"}
                          cssClass='input-control-selectbox'
                          value={stateManage.productLine}
                          parentHandler={handleInputChange}
                          stateValue={stateManage.productLine}></DropDown>
                      </Grid>

                      <Grid item xs={12} mb={3}>
                        <Typography variant='h5regular'>
                          Serial Number or Registration Code
                        </Typography>
                        <Box display='flex' alignItems='center'>
                          <TextBoxWithLoader
                            maxLength={100}
                            name='productSerialNo'
                            cssClass='input-control-textbox'
                            value={stateManage.productSerialNo}
                            handleChange={handleSerialInputChange}
                            errorMessage={errorMessage}
                            verificationStatus={verificationStatus}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} mb={3}>
                        <Typography variant='h5regular'> Point of Purchase </Typography>
                        <DropDown
                          name='productPurchaseLocation'
                          arrayData={getProductPurchaseLocation()}
                          label={"Select Below"}
                          cssClass='input-control-selectbox'
                          value={stateManage.productPurchaseLocation}
                          parentHandler={handleInputChange}
                          stateValue={stateManage.productPurchaseLocation}></DropDown>
                      </Grid>
                      <Grid item xs={12} mb={3}>
                        <Typography variant='h5regular'>Purchase Date</Typography>
                        <Box className='gridContentWrapper'>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              inputFormat='DD/MM/YYYY'
                              label='Purchase Date'
                              className='customCalender1'
                              maxDate={dayjs(new Date())}
                              value={dayjs(stateManage.productPurchaseDate)}
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  variant='filled'
                                  className='customCalender'
                                  value={dayjs(stateManage.productPurchaseDate)}
                                />
                              )}
                              onChange={(newValue) => {
                                handleInputChange({
                                  target: {
                                    name: "productPurchaseDate",
                                    value: newValue,
                                  },
                                });
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Grid>
                      <Grid item xs={12} mb={3}>
                        <Typography variant='h5regular'>Price</Typography>
                        <StringOnBlurTextBox
                          maxLength={8}
                          name='price'
                          label='Price'
                          cssClass='input-control-textbox'
                          value={stateManage.price}
                          handleChange={handleInputChange}
                          //errorMessage={errorMessage}
                        />
                      </Grid>
                      <Grid item xs={12} mb={3}>
                        <Typography variant='h5regular'>Warranty Duration</Typography>
                        <StringOnBlurTextBox
                          name='warrantyDuration'
                          label='Warranty Duration'
                          cssClass='input-control-textbox'
                          value={verifiedProduct?.lineItem?.[0]?.attributes?.warrantyDuration || ""}
                          isDisabled={true}
                        />
                      </Grid>
                    </Box>
                  ) : (
                    <Box className='leftPanel'>
                      <Grid item xs={12} mb={3}>
                        <Box display='flex' alignItems='center'>
                          <Typography variant='h5regular' sx={{ width: "200px" }}>
                            Select Product Category
                          </Typography>
                          <Box ml={4} sx={{ display: "flex" }}>
                            <img src={EditReviewIcon} style={{ marginRight: "10px" }} alt='edit' />
                            <Typography
                              variant='p4regular'
                              onClick={() => setIsEditmode(false)}
                              style={{ cursor: "pointer", textDecoration: "underline" }}>
                              Edit Details
                            </Typography>
                          </Box>
                        </Box>
                        <Typography variant='h5regular' mt={2}>
                          {stateManage?.productCategory}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} mb={3}>
                        <Typography variant='h5regular'> Select Product Line </Typography>
                        <Typography variant='h5regular' mt={2}>
                          {stateManage?.productLine}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} mb={3}>
                        <Typography variant='h5regular'>
                          Serial Number or Registration Code
                        </Typography>
                        <Typography variant='h5regular' mt={2}>
                          {stateManage?.productSerialNo}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} mb={3}>
                        <Typography variant='h5regular'> Point of Purchase</Typography>
                        <Typography variant='h5regular' mt={2}>
                          {stateManage?.productPurchaseLocation}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} mb={3}>
                        <Typography variant='h5regular'> Purchase Date </Typography>
                        <Typography variant='h5regular' mt={2}>
                          {dayjs(stateManage?.productPurchaseDate).format("DD/MM/YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} mb={3}>
                        <Typography variant='h5regular'>Price</Typography>
                        <Typography variant='h5regular' mt={2}>
                          {stateManage?.price}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} mb={3}>
                        <Typography variant='h5regular'>Warranty Duration</Typography>
                        <Typography variant='h5regular' mt={2}>
                          {verifiedProduct?.lineItem?.[0]?.attributes?.warrantyDuration || ""}
                        </Typography>
                      </Grid>
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5} lg={4}>
                <Box className='formContainerRight'>
                  <Box className='formContainerRight'>
                    <Box className='rightPanel'>
                      <Typography variant='h3semibold' className='marginTopZero'>
                        Selected Product
                      </Typography>
                      {selectedProduct ? (
                        <Box className='productDetails'>
                          <Box className='largeproductWrapper'>
                            <Box
                              component='img'
                              src={selectedProduct?.attr_images?.[0]}
                              // src='https://m.media-amazon.com/images/I/61SmnheBBRL._SX679_.jpg'
                              alt='product'
                            />
                          </Box>
                          <Box>
                            <Typography variant='p4regular' sx={{ marginBottom: 0 }}>
                              {stateManage.productCategory}
                              {/* Purchase Date:
                          {new Date(selectedProduct?.deliveryDate).toLocaleDateString("en-GB")} */}
                            </Typography>
                            <Typography variant='h4regular' sx={{ margin: 0 }}>
                              {selectedProduct?.name}
                            </Typography>
                            <Typography variant='labelregular' sx={{ margin: 0 }}>
                              {selectedProduct?.rate ? `$${selectedProduct?.rate}` : ""}
                            </Typography>
                          </Box>
                          {!isEditmode ? (
                            <Button
                              variant='primaryButton1'
                              sx={{ minWidth: "100%" }}
                              disabled={!isValid}
                              onClick={() => {
                                setIsEditmode(true);
                              }}>
                              Continue
                            </Button>
                          ) : (
                            <Button
                              variant='primaryButton1'
                              sx={{ minWidth: "100%" }}
                              onClick={() => {
                                registerProduct();
                              }}>
                              Register Product
                            </Button>
                          )}
                        </Box>
                      ) : (
                        <Box className='nodata'>
                          <img src={NoProduct} alt='nodata' />
                          <Typography variant='p3regular'>
                            Product will show here after selection
                          </Typography>
                          <Button variant='primaryButton1' disabled sx={{ minWidth: "100%" }}>
                            Continue
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {isRegistered && (
              <Confirmation
                open={true}
                img={successGif}
                handleClose={confirmationPopUpClose}
                text='We have registered your product. You will receive an email from our side shortly. Thanks!'
                title='Product Registered Successfully!'
              />
            )}
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default ProductRegistration;
