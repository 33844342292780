const SAVE_QUERY = `mutation publish_saveFormData($input: publish_formInputRequest) {
  publish_saveFormData(input: $input) {
    message
  }
}
`;

const GET_QUERY = (EditorialItemPath) =>
  `query{publish_contentDetail(pagePath:${JSON.stringify(EditorialItemPath)},contentType:formbuilder)}`;

export { GET_QUERY, SAVE_QUERY };
