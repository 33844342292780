import graphqlInstance from "../../config/graphqlConfig";
import { channelsQueries } from "../../graphQL/queries/channelsQueries";
import { ApiResponse } from "../../utils/types";

const channels = {
  getChannelsList: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: channelsQueries.GET_CHANNELS_LIST,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      return err;
    }
  },
  getChannelDetails: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: channelsQueries.GET_CHANNEL_DETAILS,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      return err;
    }
  },
};

export default channels;
