import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  cardContemWpMain: {
    overflowY: "auto",
    "& .leftColWp": {
      background: theme.palette.customColor.BGWEAK,
      display: "flex",
      alignItems: "center",
      "& .boxLeft": {
        width: "100%",
        padding: "25px",
        [theme.breakpoints.down("sm")]: {
          padding: "35px 10px 0px 10px",
        },
        justifyContent: "center",
        alignItems: "center",
      },
    },
    "& .rightColWp": {
      padding: "25px",
      [theme.breakpoints.down("sm")]: {
        padding: "20px",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .RightBox": {
        padding: "0",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          padding: "20px 0 0 0",
        },
        "& .mobileEmbededCode": {
          overflowY: "auto",
          borderRadius: theme.borderRadius.value,
          border: `1px solid ${theme.palette.customColor.LINE}`,
          flexGrow: " 0",
          padding: "15px",
          overflowWrap: "break-word",
          wordBreak: "break-word",
          height: "225px",
          margin: "18px 0 34px",
        },
        "& .desktopEmbededCode": {
          height: "194px",
          margin: "18px 0",
          overflowY: "auto",
          borderRadius: theme.borderRadius.value,
          border: `1px solid ${theme.palette.customColor.LINE}`,
          flexGrow: " 0",
          padding: "20px",
          overflowWrap: "break-word",
          wordBreak: "break-word",
        },
      },
    },
  },
}));
