import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  head: {
    border: `1px solid  ${theme.palette.customColor.LINE}`,
    borderRadius: theme.borderRadius.value,
    paddingBottom: "15px",
  },
  body: {
    overflowY: "auto",
    maxHeight: "305px",
    minHeight: "305px",
  },
}));
