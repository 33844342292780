import EastIcon from "@mui/icons-material/East";
import { Box, Button } from "@mui/material";
import { capitalizeWords } from "@platformx/utilities";
import Title from "../common/Title";
import "./Card.css";
import { useCustomStyle } from "./cart.style";

type CardProps = {
  ImageUrl: string;
  BgColor: string;
  CTAText: string;
  url: string;
  handleCardClick: (e?: any) => void;
};

const Card = ({ ImageUrl, BgColor, CTAText, url, handleCardClick = () => {} }: CardProps) => {
  const classes = useCustomStyle();

  return (
    <Box
      className={`${classes.cards} card`}
      data-testid='dashboard-card-click'
      onClick={() => handleCardClick({ CTAText: CTAText, url: url })}>
      <Box className='imagecolorbox' sx={{ background: `${BgColor}` }}>
        <Box className=''>
          <img src={ImageUrl} className='imgbox' alt={CTAText} />
        </Box>
      </Box>
      <Button className='ctabox'>
        <Title titleVarient='h5semibold' title={capitalizeWords(CTAText)} />
        <Box className='icon'>
          <EastIcon />
        </Box>
      </Button>
    </Box>
  );
};

export default Card;
