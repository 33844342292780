import { Skeleton, useTheme } from "@mui/material";
import "../../dashboards.styles";
import PageRow from "../pageRow/PageRow";
import { RecentPagesProps } from "./RecentPages.types";

const RecentPages = ({ recentPages }: RecentPagesProps) => {
  const theme = useTheme();
  return !recentPages || recentPages.length === 0 ? (
    <Skeleton
      animation='wave'
      height={10}
      width='80%'
      sx={{ backgroundColor: theme.palette.customColor.INPUT }}
    />
  ) : (
    <>
      {recentPages?.length > 0 &&
        recentPages.map((item, index) => <PageRow key={index} {...item} />)}
    </>
  );
};

export default RecentPages;
