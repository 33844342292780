import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { password_update_congrats } from "@platformx/shared/static-assets";
import { navigateBasedRole } from "@platformx/utilities";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom-v6";

export const ChangeSuccess = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleCancel = () => {
    setOpen(false);
    //navigate("/dashboard");
    navigateBasedRole(navigate);
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth='xs'
      aria-labelledby='confirmation-dialog-title'
      open={open}
      fullScreen={fullScreen}
      data-testid='change-success-dialog'>
      <DialogContent sx={{ padding: { xs: "20px 14px", md: "20px 24px" } }}>
        <>
          <Box
            sx={{ display: "block", margin: "0 auto" }}
            component='img'
            alt='congrats'
            src={password_update_congrats}
          />
          <Typography variant='h3' align='center'>
            {t("congratulations")}
          </Typography>
          <Typography variant='body1' align='center'>
            {t("password_changed_successfully")}
          </Typography>
        </>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
        }}>
        <Button
          sx={{
            width: "75%",
          }}
          variant='primaryButton'
          data-testid='dashboard-button'
          onClick={handleCancel}>
          {t("go_to_dashboard")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
