export const COLORS = ["#6D8F97", "#FFD3B6", "#FFAAA5"];
export const DASHBOARD = "dashboard";
export const SliderSettingConstants = {
  slidesToShow: 5,
  slidesToScroll: 5,
  infinite: false,
  dots: false,
  arrow: true,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};
