import { Box, Card, CardContent, CardMedia, Typography, useTheme } from "@mui/material";
import { PlatFormXDateTimeFormat, getImage } from "@platformx/utilities";
import { getIconWithTitle } from "../Utils/helperFns";
import "./ContentTypeCard.css";

const ContentTypeCard = ({
  content = {},
  isLoading,
  secondaryArgs,
  onHover,
  index,
  selectedIndex,
  contentTypeList,
}: //secondaryArgs,
ContentTypeCardProps) => {
  const { ContentType = "" } = content;
  const theme = useTheme();
  const {
    icon = "",
    menuIcon = "",
    fallbackIcon = "",
  } = getIconWithTitle(contentTypeList, ContentType);
  let bgColor, bgImageUrl;
  if (!isLoading && content && Object.keys(content).length > 0) {
    const formedUrl = getImage(content, secondaryArgs);
    const { color, imageUrl } = formedUrl;
    bgColor = color;
    bgImageUrl = imageUrl;
  }
  const titleMaxLength = 35;

  const handleImageError = (e, fallback) => {
    if (fallback) e.target.src = fallback;
  };

  return isLoading ? (
    <Box sx={{ height: "100%", width: "100%" }} className='skeleton skeleton-card'></Box>
  ) : (
    <Card
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "end",
        borderRadius: theme.borderRadius.value,
        boxShadow: "none",
      }}
      className='contenttype_card'>
      <CardMedia
        image={bgImageUrl ? bgImageUrl : ""}
        component={bgImageUrl ? "img" : "div"}
        sx={{
          height: "100%",
          position: "absolute",
          width: "100%",
          backgroundSize: "cover",
          left: 0,
          top: 0,
          borderRadius: "inherit",
          maxWidth: "100%",
          backgroundColor: bgColor ? bgColor : "",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "0",
          zIndex: 1,
          left: "0",
          right: "0",
          bottom: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "linear-gradient(185deg,rgba(0,0,0,0) 40%,rgba(0,0,0,0.2) 60%)",
        }}></Box>
      <CardContent
        sx={{
          zIndex: 2,
          position: "relative",
          backgroundColor: "transparent",
          mt: { xs: 0, sm: 0, md: 4, lg: 8 },
          paddingBottom: "10px !important",
        }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            paddingBottom: "6px",
            width: "fit-content",
          }}>
          <img
            alt='ContentTypeCardimg'
            src={menuIcon || icon}
            onError={(e) => handleImageError(e, fallbackIcon)}
            style={{
              height: "18px",
              width: "18px",
              filter: "brightness(100)",
              zIndex: index === selectedIndex ? 0 : 999,
            }}
          />
          <Typography
            gutterBottom
            variant='p4regular'
            sx={{
              display: content?.ContentType === "Product" ? "none" : "block",
              color: theme?.palette?.customColor?.BGLIGHT,
              margin: "0 0 1px 8px",
              textTransform: "capitalize",
              zIndex: index === selectedIndex ? 0 : 999,
            }}>
            {content?.ContentType === "VOD" ||
            content?.ContentType === "Quiz" ||
            content?.ContentType === "Poll"
              ? content?.ContentType
              : content?.ContentType?.replace(/([A-Z])/g, " $1").trim()}
          </Typography>
        </Box>
        {ContentType === "Product" && (
          <Typography
            className='doublebr'
            gutterBottom
            sx={{
              color: "#ced3d9",
              textTransform: "capitalize",
              marginBottom: "1px",
              zIndex: index === selectedIndex ? 0 : 999,
            }}
            variant='h7medium'>
            {content?.brand}
          </Typography>
        )}
        <Typography
          gutterBottom
          variant='p3medium'
          // component='h2'
          sx={{
            color: theme?.palette?.customColor?.BGLIGHT,
            margin: "3px 0",
            textTransform: "capitalize",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            display: "block",
            position: "relative",
            zIndex: index === selectedIndex ? 0 : 999,
            wordBreak: "break-word",
            maxWidth: {
              xs: "110px",
              sm: "87px",
              md: "152px",
              lg: "152px",
              xl: "152px",
            },
            "&:hover": {
              overflow: "visible",
              whiteSpace: "normal",
              height: "auto",
            },
            // display: '-webkit-box',
            // WebkitLineClamp: 3,
            // WebkitBoxOrient: 'vertical',
            // overflow: 'hidden',
          }}>
          {content?.Title && content?.Title?.length > titleMaxLength ? (
            <>{`${content?.Title?.substring(0, titleMaxLength)}...`}</>
          ) : (
            <>{content?.Title}</>
          )}
        </Typography>
        {onHover === index || index === selectedIndex ? (
          <Typography
            className='doublebr'
            gutterBottom
            sx={{
              color: theme?.palette?.customColor?.BGLIGHT,
              position: "relative",
              marginBottom: "1px",
              zIndex: index === selectedIndex ? 0 : 999,
            }}
            variant='p4semibold'>
            {content?.Author}
          </Typography>
        ) : null}
        {onHover === index || index === selectedIndex ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "fit-content",
            }}>
            {content?.PublishedDate && (
              <Typography
                gutterBottom
                sx={{
                  color: theme?.palette?.customColor?.BGLIGHT,
                  zIndex: index === selectedIndex ? 0 : 999,
                }}
                variant='p4regular'>
                {PlatFormXDateTimeFormat(content?.PublishedDate)}
              </Typography>
            )}
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
};

interface ContentTypeCardProps {
  content: Content;
  isLoading: boolean;
  secondaryArgs: SecondaryArgs;
  onHover?: any;
  index?: any;
  selectedIndex?: any;
  contentTypeList: any;
}
interface SecondaryArgs {
  gcpUrl?: string;
  bucketName?: string;
}

interface Content {
  brand?: string;
  Author?: string;
  ContentType?: string;
  Count?: string;
  Description?: string;
  EditorialItemPath?: string;
  PublishedDate?: any;
  lastModifiedDate?: any;
  SEODescription?: string;
  SEOTitle?: string;
  SEOImage?: object;
  Thumbnail?: {
    Name?: string;
    Url?: string;
    AltText?: string;
    ext?: string;
  };
  Title?: string;
  background_content?: any;
  tags?: string;
}

export default ContentTypeCard;
