import { Box, Grid } from "@mui/material";
import { CommentWrapper } from "@platformx/comment-review";
import { AutoTextArea, CommonBoxWithNumber, TextBox, TitleSubTitle } from "@platformx/utilities";
import { useTranslation } from "react-i18next";
import { useCustomStyle } from "../quiz.style";

export const TitleDescription = ({
  state,
  setState,
  unsavedChanges,
  quizRef,
  setFieldChanges,
  isError,
  setIsError,
}) => {
  const { t } = useTranslation();
  const handleChange = (e) => {
    setFieldChanges(true);
    unsavedChanges.current = true;
    setIsError({ ...isError, [e.target.name]: false });
  };
  const handleOnBlur = (event) => {
    const key = `${event.target.name}SocialShare`;
    setState({
      ...state,
      [event.target.name]: event.target.value,
      [key]: event.target.value,
    });
    quizRef.current = {
      ...quizRef.current,
      [event.target.name]: event.target.value,
      [key]: event.target.value,
    };
    unsavedChanges.current = true;
  };
  const classes = useCustomStyle();
  return (
    <Box id='titleDescription' className={classes.mainStyleWrapper}>
      <CommentWrapper elementId='1'>
        <CommonBoxWithNumber
          number='01'
          title={t("title_head")}
          titleVarient='h5bold'
          subTitleVarient='p4regular'
          subTitle={t("subhead")}>
          <Grid container>
            <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiled'>
              <TitleSubTitle
                title={`${t("title")}*`}
                subTitle={t("quiz_subtitle")}
                titleVariant='h6medium'
                subTitleVariant='p4regular'
              />
            </Grid>
            <Grid item xs={12} sm={7} md={7} lg={7} className='textFiled' id='Title'>
              <TextBox
                name='title'
                placeHolder={t("quiz_title_placeholder")}
                handleChange={handleChange}
                maxCharLength={120}
                state={state.title}
                handleOnBlur={handleOnBlur}
                error={isError["title"]}
              />
            </Grid>
            {/* <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiled'>
              <TitleSubTitle
                title={`${t("short_title")}*`}
                subTitle={t("quiz_subtitle")}
                titleVariant='h6medium'
                subTitleVariant='p4regular'
              />
            </Grid>
            <Grid item xs={12} sm={7} md={7} lg={7} className='textFiled'>
              <TextBox
                name='short_title'
                placeHolder={t("quiz_short_placeholder")}
                handleChange={handleChange}
                maxCharLength={60}
                state={state.short_title}
                handleOnBlur={handleOnBlur}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={5} className='leftFiled'>
              <TitleSubTitle
                title={`${t("short_description")}*`}
                subTitle={t("quiz_short_subdescription")}
                titleVariant='h6medium'
                subTitleVariant='p4regular'
              />
            </Grid>
            <Grid item xs={12} sm={7} md={7} className='textFiled'>
              <AutoTextArea
                name='short_description'
                placeHolder={t("quiz_description_placeholder")}
                handleChange={handleChange}
                maxCharLength={400}
                state={state.short_description}
                handleOnBlur={handleOnBlur}
              />
            </Grid> */}
            <Grid item xs={12} sm={5} md={5} className='leftFiledLast'>
              <TitleSubTitle
                title={`${t("description")}*`}
                subTitle={t("quiz_short_subdescription")}
                titleVariant='h6medium'
                subTitleVariant='p4regular'
              />
            </Grid>
            <Grid item xs={12} sm={7} md={7} className='textFiledLast' id='Description'>
              <AutoTextArea
                name='description'
                placeHolder={t("quiz_description_placeholder")}
                handleChange={handleChange}
                maxCharLength={1000}
                state={state.description}
                handleOnBlur={handleOnBlur}
                error={isError["description"]}
              />
            </Grid>
          </Grid>
        </CommonBoxWithNumber>
      </CommentWrapper>
    </Box>
  );
};
