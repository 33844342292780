import { contentTypeSchemaApi } from "@platformx/authoring-apis";

interface routeListProps {
  title: string;
  icon: string;
  url: string;
  id: string;
  category: string;
  subCategory: string;
  component: string;
  isMenu: boolean;
  isHidden: boolean;
  isHeader: boolean;
  isSideBar: boolean;
  isGallery: boolean;
  hasSearch: boolean;
  listIcon: string;
  menuIcon: string;
  fallbackIcon: string;
}

export const fetchRoutes = async () => {
  const routeList: routeListProps[] = [];
  const detailsRes: any = await contentTypeSchemaApi.fetchSchema();
  detailsRes?.authoring_getSchema?.forEach((val) => {
    const { list_icon = "", menu_icon = "", fallback_icon = "" } = val?.icons || {};
    if (val?.category) {
      return routeList.push({
        title: val?.title,
        icon: `${process.env.NX_PUBLIC_GCP_URL}/${process.env.NX_PUBLIC_BUCKET_NAME}/${val?.icon}`,
        url: val?.url,
        id: val?.id,
        category: val?.category,
        subCategory: val?.sub_category,
        component: val?.component,
        isMenu: val?.is_menu,
        isHidden: val?.is_hidden,
        isHeader: val?.is_header,
        isSideBar: val?.is_sideBar,
        isGallery: val?.is_gallery,
        hasSearch: val?.has_search ? val?.has_search : val?.category === "content",
        listIcon: list_icon
          ? `${process.env.NX_PUBLIC_GCP_URL}/${process.env.NX_PUBLIC_BUCKET_NAME}/${list_icon}`
          : "",
        menuIcon: menu_icon
          ? `${process.env.NX_PUBLIC_GCP_URL}/${process.env.NX_PUBLIC_BUCKET_NAME}/${menu_icon}`
          : "",
        fallbackIcon: fallback_icon
          ? `${process.env.NX_PUBLIC_GCP_URL}/${process.env.NX_PUBLIC_BUCKET_NAME}/${fallback_icon}`
          : "",
      });
    }
  });
  return routeList;
};
