/**
 * Determines the device type based on the viewport width.
 *
 * @returns {string} The device type: "Mobile", "Tablet", or "Desktop".
 */
export const getDeviceType = () => {
  const viewportWidth = window?.innerWidth;
  if (viewportWidth < 768) {
    return "Mobile";
  } else if (viewportWidth < 1024) {
    return "Tablet";
  } else {
    return "Desktop";
  }
};

/**
 * Determines the operating system of the device based on the user agent string.
 *
 * @returns {string} The operating system: "Android", "iOS", "macOS", "Windows", "Linux", or "Other".
 */
export const getDeviceOS = () => {
  const ua = navigator?.userAgent;
  if (/android/i.test(ua)) {
    return "Android";
  } else if (/iPad|iPhone|iPod/i.test(ua) && !globalThis.MSStream) {
    return "iOS";
  } else if (/Macintosh|Mac OS X/i.test(ua)) {
    return "macOS";
  } else if (/Windows NT/i.test(ua)) {
    return "Windows";
  } else if (/Linux/i.test(ua)) {
    return "Linux";
  } else {
    return "Other";
  }
};

/**
 * Detects the browser being used.
 *
 * @returns {string} The browser name, defaulting to "chrome" if detection fails.
 */
export const getBrowser = () => {
  return globalThis?.rpiWebClient?.detectBrowser() || "chrome";
};

/**
 * Checks if the RPI (Real-time Processing Interface) instance is available.
 *
 * @returns {boolean} True if the RPI instance is available, otherwise false.
 */
export const getRpiInstance = () => {
  if (typeof window !== "undefined" && globalThis?.rpiWebClient) {
    return true;
  }
  return false;
};

/**
 * Retrieves the user ID from local storage.
 *
 * @returns {string} The user ID, defaulting to "anonymous" if not found.
 */
export const getUserId = () => {
  return localStorage?.getItem("userId") || "anonymous";
};

/**
 * Retrieves the cart ID from local storage.
 *
 * @returns {string} The cart ID, defaulting to an empty string if not found.
 */
export const getCartId = () => {
  return localStorage?.getItem("ecommerceCartId") ?? "";
};

/**
 * Retrieves location data from local storage.
 *
 * @returns {string | null} The location data, or null if not found.
 */
export const getLocationStorage = () => {
  return localStorage?.getItem("locationData");
};

/**
 * Retrieves the language setting.
 *
 * @returns {string} The language setting, defaulting to "en".
 */
export const getLang = () => {
  const parsedUrl = new URL(window?.location?.href);
  if (parsedUrl) {
    const pathname = parsedUrl.pathname.split("/"); // Splits the path into an array
    return pathname[1];
  }
  return "en";
};

/**
 * Constructs a full address string from a card object.
 *
 * @param {Object} card - The card object containing address details.
 * @param {string} card.firstName - The first name of the cardholder.
 * @param {string} card.lastName - The last name of the cardholder.
 * @param {string} card.address - The address of the cardholder.
 * @param {string} card.city - The city of the cardholder.
 * @param {string} card.country - The country of the cardholder.
 * @returns {string} A formatted string containing the full address details.
 */
export const getFullAddress = (card) => {
  return `firstName: ${card?.firstName} | lastName: ${card?.lastName} | address: ${card?.address} | city: ${card?.city} | country: ${card?.country}`;
};
/**
 * Parses a JSON string of line items and returns the number of items.
 *
 * @param {string} lineItems - A JSON string representing the line items.
 * @returns {number | undefined} The number of items if parsing is successful, otherwise undefined.
 */
export const getItemFromLineItem = (lineItems) => {
  if (lineItems) {
    try {
      const item = JSON.parse(lineItems);
      return item?.length ? item?.length : 1;
    } catch {
      console.error("error in parsing lineItems");
    }
  }
};

/**
 * Prepares an object for RPI (Real-time Processing Interface) by transforming it into an array of key-value pairs.
 *
 * @param {Object} dataTemplate - The object containing data to be transformed.
 * @returns {Array<{ Name: string, Value: any }>} An array of objects, each containing a 'Name' and 'Value' property.
 */
export const prepareObjectForRPI = (dataTemplate) => {
  return Object.keys(dataTemplate).reduce((acc: { Name: string; Value: any }[], key) => {
    return [
      ...acc,
      {
        Name: key,
        Value: dataTemplate?.[key],
      },
    ];
  }, []);
};
