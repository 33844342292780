import { Checkbox, FormControlLabel } from "@mui/material";
// import { ThemeConstants } from "@platformx/utilities";
import { Box } from "@mui/system";
import { fetchRequiredMessage } from "../../../utils/helperFns";
import HiddenTextBox from "../HiddenTextBox/HiddenTextBox";

// font-family: ${ThemeConstants.FONTNAMEPRIMARY};
// src: url(${"InterRegular"}) format("truetype");
const fontStyle = `
@font-face {
  font-display: block;
}`;

export interface CheckBoxProps {
  field: any;
  error: any;
  register: any;
  labelPlacement?: "top" | "start" | "bottom" | "end";
  size?: "small" | "medium";
  value?: string;
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "default";
  handleChange?: any;
  checked?: boolean;
}

export default function CheckBoxComponent({
  field,
  error,
  register,
  labelPlacement,
  value,
  size,
  color,
  handleChange,
  checked = false,
}: CheckBoxProps) {
  return (
    <Box sx={{ display: "flex" }}>
      <style>{fontStyle}</style>
      <FormControlLabel
        value={value}
        control={
          <Checkbox
            size={size}
            color={color}
            onChange={handleChange}
            checked={checked}
            name={field?.name}
          />
        }
        label={field.label}
        labelPlacement={labelPlacement}
      />
      <HiddenTextBox
        error={error}
        value={checked}
        register={register}
        name={field?.name}
        required={fetchRequiredMessage(field?.validations, checked)}
      />
    </Box>
  );
}
