import { Box, Grid, Typography } from "@mui/material";
import {
  BasicSwitchText,
  CommonBoxWithNumber,
  ShowToastSuccess,
  TitleSubTitle,
} from "@platformx/utilities";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomStyle } from "./SEO.style";
import StructureDataPopup from "./StructureDataPopup";

const Seo = ({ titleSubTitles, state, handleChange, handleStrucutreDataChange }) => {
  const { t } = useTranslation();
  const classes = useCustomStyle();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    sectionNumber,
    sectionTitle,
    sectionSubTitle,
    seoTitle,
    seoSubTitle,
    shareTitle,
    shareSubTitle,
    structureDataTitle,
    structureDataSubTitle,
    seoFieldName,
    shareFieldName,
    structureDataFieldName,
  } = titleSubTitles;

  const openStructureData = () => {
    setIsOpen(true);
  };

  const closeStructureData = () => {
    setIsOpen(false);
  };

  const copyStructureData = () => {
    navigator.clipboard.writeText(state?.[structureDataFieldName]);
    ShowToastSuccess(`${t("page_structure_data")} ${t("copied")}`);
  };

  return (
    <Box id='seo' className={classes.mainStyleWrapper}>
      <CommonBoxWithNumber
        number={sectionNumber}
        title={sectionTitle}
        titleVarient='h5bold'
        subTitleVarient='p4regular'
        subTitle={sectionSubTitle}>
        <Box className='textFiled'>
          <BasicSwitchText
            state={Boolean(state?.[seoFieldName])}
            isDisable={false}
            handleChange={handleChange}
            title={seoTitle}
            subtitle={seoSubTitle}
            keyName={seoFieldName}
          />
        </Box>
        <Box className='textFiled'>
          <BasicSwitchText
            state={Boolean(state?.[shareFieldName])}
            isDisable={true}
            handleChange={handleChange}
            title={shareTitle}
            subtitle={shareSubTitle}
            keyName={shareFieldName}
          />
        </Box>
        <Grid container>
          <Grid item xs={12} sm={12} md={5} className='leftFiledLast'>
            <TitleSubTitle
              title={structureDataTitle}
              subTitle={structureDataSubTitle}
              titleVariant='h6medium'
              subTitleVariant='p4regular'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <Box className='bottomLinksWp'>
              <Typography
                data-testid='structuredata'
                variant='h6medium'
                className='blueLink'
                onClick={() => openStructureData()}>
                <u>{t("see_sd")}</u>
              </Typography>
              <Box className='seoLinkDevider'></Box>
              <Typography
                data-testid='copycode'
                variant='h6medium'
                className='blueLink'
                onClick={() => copyStructureData()}>
                {t("copy_sd")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CommonBoxWithNumber>
      {isOpen && (
        <StructureDataPopup
          closeStructureData={closeStructureData}
          seoInfo={state}
          keyname={structureDataFieldName}
          handleChange={handleStrucutreDataChange}
          isOpen={isOpen}
        />
      )}
    </Box>
  );
};
export default Seo;
