import { LanguageList } from "./Constants";

export const getFlagById = (code) => {
  const getCode = LanguageList.find((ele) => ele?.label === code);
  return getCode?.id;
};

export const getFlagByCode = (code) => {
  if (code === "English (UK)") {
    return "gb";
  } else {
    const getCode = LanguageList.find((ele) => ele?.label === code);
    return getCode?.id;
  }
};
