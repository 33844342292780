import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Menu,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FilterIcon } from "@platformx/shared/static-assets";
import { ErrorTooltip, SearchBox, useAccess } from "@platformx/utilities";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom-v6";
import "../Users/User.css";
import { useDropdownStyles } from "./TopHeaderDropdown.style";
import { USERTYPES, USER_PERMISSIONS } from "./Utils/constant";

const TopHeader = ({ handleSearch, filterValue, setFilterValue, setLoading }: any) => {
  const { canAccessAction } = useAccess();
  const { t } = useTranslation();
  const classes = useDropdownStyles();
  const navigate = useNavigate();
  const [filterMenu, setFilterMenu] = useState<null | HTMLElement>(null);
  const openFilterMenu = Boolean(filterMenu);
  const { category, subCategory, create } = USER_PERMISSIONS;

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setFilterMenu(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterMenu(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = () => (event.target as HTMLInputElement).value;
    setFilterValue(selectedValue());
    handleFilterClose();
  };

  return (
    <Box className='userlisttophead'>
      <Box>
        <Box className='d-flex align-items-center' sx={{ mb: { xs: "15px", md: 0 } }}>
          <Typography variant='h3semibold' sx={{ textTransform: "uppercase" }}>
            {t("user")}
          </Typography>
        </Box>
      </Box>

      <Box
        className='d-flex align-items-center mobalignment'
        sx={{
          position: "relative",
        }}>
        <Box>
          <SearchBox handleSearch={handleSearch} setLoading={setLoading} />
        </Box>
        <Box className='d-flex'>
          <Box
            data-testid='filter'
            onClick={handleFilterClick}
            sx={{
              margin: "0px 12px",
              backgroundColor: openFilterMenu ? "#2d2d39" : "white",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #14142B",
              display: "flex",
              height: "46px",
              width: "42px",
              alignItem: "center",
              cursor: "pointer",
              justifyContent: "center",
              "&.openClass img": {
                filter:
                  "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(6197%) hue-rotate(110deg) brightness(97%) contrast(99%);",
              },
            }}
            className={openFilterMenu ? "openClass" : ""}>
            <img src={FilterIcon} alt='Filter Icon' width='17' height='24' />
          </Box>
          <Menu
            elevation={0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={filterMenu}
            open={openFilterMenu}
            onClose={handleFilterClose}
            className={`${classes.FilterMenuWrapperItem} filterMenu`}
            PaperProps={{
              className: "filterMenuItem",
            }}>
            <FormControl>
              <RadioGroup
                value={filterValue}
                onChange={handleChange}
                className='listViewContainer'
                sx={{ textTransform: "capitalize", color: filterValue }}>
                <FormControlLabel
                  className={`${filterValue === USERTYPES.AUTHORINGUSER ? "active listViewradionone" : ""} listViewradionone listView`}
                  value={USERTYPES.AUTHORINGUSER}
                  control={<Radio />}
                  label={<Typography variant='p2regular'>{t("authoring_user")}</Typography>}
                />
                <FormControlLabel
                  className={`${filterValue === USERTYPES.ENDUSER ? "active listViewradionone" : ""} listViewradionone listView`}
                  value={USERTYPES.ENDUSER}
                  control={<Radio />}
                  label={<Typography variant='p2regular'>{t("end_user")}</Typography>}
                />
                <FormControlLabel
                  className={`${filterValue === USERTYPES.COMMUNITYUSER ? "active listViewradionone" : ""} listViewradionone listView`}
                  value={USERTYPES.COMMUNITYUSER}
                  control={<Radio />}
                  label={<Typography variant='p2regular'>{t("community_user")}</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Menu>
          <ErrorTooltip
            component={
              <Button
                data-testid='addnew'
                variant='primaryButton'
                onClick={() => navigate("/user-management/user-create")}
                disabled={!canAccessAction(category, subCategory, create)}>
                {t("add_new")}
              </Button>
            }
            doAccess={!canAccessAction(category, subCategory, create)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TopHeader;
