import React from "react";
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import { Box, styled } from "@mui/system";

// Define the props for the Progressbar component
interface ProgressbarProps {
  progress?: number; // Optional progress value (0-100)
  maxWidth?: string; // Optional max width of the progress bar
  backgroundColor?: string; // Optional background color for the progress bar
  variant?: LinearProgressProps["variant"]; // Optional variant for the progress bar (determinate, indeterminate, buffer, query)
}

// Style the LinearProgress component with the backgroundColor prop
const StyledLinearProgress = styled(LinearProgress)<{ backgroundColor?: string }>(
  ({ backgroundColor }) => ({
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: backgroundColor || "#4B9EF9", // Default color if backgroundColor prop is not provided
    },
  }),
);

// Create the Progressbar component
const Progressbar: React.FC<ProgressbarProps> = ({
  progress = 0, // Default progress is 0
  maxWidth = "510px", // Default max width is 510px
  backgroundColor = "#4B9EF9", // Default background color is #4B9EF9
  variant = "determinate", // Default variant is "determinate"
}) => {
  return (
    <Box sx={{ maxWidth }}>
      <StyledLinearProgress variant={variant} value={progress} backgroundColor={backgroundColor} />
    </Box>
  );
};

export default Progressbar;
