import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useCustomStyle = makeStyles((theme: Theme) => {
  return {
    schedulecardWrapper: {
      "&.schedulecard": {
        border: `1px solid  transparent`,
        transition: "all 0.5s",
        background: theme.palette.customColor.BGWEAK,
        borderRadius: theme.borderRadius.value,
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginRight: 20,
        minWidth: 320,
        minHeight: 300,
        maxWidth: 320,
        maxHeight: 300,
        padding: 20,
        paddingTop: 10,
        "& .icon": {
          color: theme.palette.customColor.SECONDARY,
          display: "flex",
        },
        "& .day": {
          color: theme.palette.customColor.SECONDARY,
          height: 58,
          display: "flex",
          alignItems: "center",
          fontSize: 48,
          [theme.breakpoints.down("md")]: {
            fontSize: 28,
          },
        },
        "& .month": {
          textTransform: "uppercase",
          color: theme.palette.customColor.SECONDARY,
        },
        "& .boticontext": {
          display: "inline-block",
          color: theme.palette.customColor.SECONDARY,
          borderBottom: `1px solid  ${theme.palette.customColor.SECONDARY}`,
          padding: "6px 0px 6px 0px",
        },
        "& .publishby": {
          marginBottom: 4,
          color: theme.palette.customColor.SECONDARY,
        },
        "& .title": {
          margin: "12px 0",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: theme.palette.customColor.SECONDARY,
        },
        "& .datetime": {
          color: theme.palette.customColor.PRIMARY,
        },
        "&:hover": {
          border: `1px solid  ${theme.palette.customColor.SECONDARY}`,
          background: theme.palette.customColor.PRIMARY,
          color: theme.palette.customColor.LIGHT,
          "& .boticontext, & .title, & .datetime, & .publishby, & .day, & .month": {
            color: theme.palette.customColor.LIGHT,
          },
          "& .boticontext": {
            borderBottom: `1px solid  ${theme.palette.customColor.LIGHT}`,
          },
          "& .icon": {
            "& img": {
              filter: "brightness(0) invert(1)",
            },
          },
        },
      },
    },
  };
});
