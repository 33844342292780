import { Box } from "@mui/material";
import { useCustomStyle } from "./Analytics.style";
import { BasicSwitchText, CommonBoxWithNumber } from "@platformx/utilities";

const Analytics = ({ titleSubTitles, state, handleChange }) => {
  const {
    sectionNumber,
    sectionTitle,
    sectionSubTitle,
    analyticsTitle,
    analyticsSubTitle,
    impressionTitle,
    impressionSubTitle,
    analyticsFieldName,
    impressionFieldName,
  } = titleSubTitles;

  const classes = useCustomStyle();
  return (
    <Box id='Analytics' className={classes.mainStyleWrapper}>
      <CommonBoxWithNumber
        number={sectionNumber}
        title={sectionTitle}
        titleVarient='h5bold'
        subTitleVarient='p4regular'
        subTitle={sectionSubTitle}>
        <Box className='textFiled'>
          <BasicSwitchText
            state={Boolean(state?.[analyticsFieldName])}
            isDisable={false}
            handleChange={handleChange}
            title={analyticsTitle}
            subtitle={analyticsSubTitle}
            keyName={analyticsFieldName}
          />
        </Box>
        <Box className='textFiledLast'>
          <BasicSwitchText
            state={Boolean(state?.[impressionFieldName])}
            isDisable={true}
            handleChange={handleChange}
            title={impressionTitle}
            subtitle={impressionSubTitle}
            keyName={impressionFieldName}
          />
        </Box>
      </CommonBoxWithNumber>
    </Box>
  );
};
export default Analytics;
