import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Box } from "@mui/material";

export default function StepperItem({ activeItem, steps }) {
  return (
    <Box sx={{ width: "100%", float: "left" }}>
      <Stepper activeStep={activeItem} alternativeLabel className='stepperWrapper'>
        {steps &&
          steps.map((step, index) => (
            <Step key={step.text} className='step active'>
              <StepLabel
                className='stepIcons'
                StepIconComponent={() => (
                  <span className='iconwrapper'>
                    <img src={step.Icon} alt={`step${index + 1}`} />
                  </span>
                )}>
                {step.text}
              </StepLabel>
            </Step>
          ))}
      </Stepper>
    </Box>
  );
}
