import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  contentStyle: {
    display: "flex",
    background: theme?.palette?.customColor?.BGLIGHT,
    alignItems: "center",
    marginTop: "15px",
    position: "relative",
  },
  workflowIconStyle: {
    width: "44px",
    height: "44px",
    marginRight: "14px",
  },
  dFlexAlignItemCenter: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  onHoverHighlight: {
    "&:hover": {
      textDecoration: "underline",
      fontWeight: 600,
    },
  },
  stepsEllipsis: {
    flexDirection: "row",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "block",
    [`@media (min-width:${theme.breakpoints.values.xs}px)`]: {
      maxWidth: "100%",
    },
    [`@media (min-width:${theme.breakpoints.values.lg}px)`]: {
      maxWidth: "100%",
    },
  },
  contentTypeBox: {
    padding: "0 20px",
    minWidth: "290px",
    minHeight: "40px",
    borderLeft: `1px solid ${theme.palette.customColor.LINE}`,
    borderRight: `1px solid ${theme.palette.customColor.LINE}`,
    maxWidth: "290px",
    flexDirection: "column",
  },
  contentTypeEllipsis: {
    padding: "2px 0px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  contentTypeTextStyle: {
    color: theme.palette.customColor.PRIMARY,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));
