import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./ShoppingList.css";
import { useCustomStyle } from "./ShoppingList.style";
import ShoppingListCard from "./ShoppingListCard";
import ShoppingSkeletonListCard from "./ShoppingSkeletonListCard";

type ecommerceShoppingListProps = {
  addedCartDetails: any;
  loading: boolean;
  loadCart: any;
  refetchLoading: boolean;
  secondaryArgs: any;
};
const ShoppingList = (_props: ecommerceShoppingListProps) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  const { addedCartDetails = {}, loading, loadCart, refetchLoading, secondaryArgs = {} } = _props;
  const { line_item = [] } = addedCartDetails;

  return (
    <Box className={`cart-product-list ${classes.productCartListWrapper} productCartListRow`}>
      <Typography variant='h3semibold' className='heading'>
        {t("cart")}
      </Typography>
      {loading ? (
        <>
          <ShoppingSkeletonListCard />
          <ShoppingSkeletonListCard />
          <ShoppingSkeletonListCard />
        </>
      ) : (
        <Box className='productCartList'>
          {(line_item || []).length === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Typography variant='p2medium'>{`${t("empty_cart_msg")}`}</Typography>
            </Box>
          ) : (
            (line_item || []).map((product: any, index: number) => (
              <ShoppingListCard
                cartId={addedCartDetails?.id}
                key={product?.id}
                product={product}
                index={index}
                loadCart={loadCart}
                refetchLoading={refetchLoading}
                secondaryArgs={secondaryArgs}
              />
            ))
          )}
        </Box>
      )}
    </Box>
  );
};

export default ShoppingList;
