import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  embedDesktopTabCardWp: {
    boxShadow: "none !important",
    overflow: "hidden",
    borderRadius: `${theme?.borderRadius?.value} !important`,
    border: `1px solid ${theme?.palette?.customColor?.LINE}`,
    background: theme?.palette?.customColor?.BGLIGHT,
    cursor: "pointer",
    width: "100%",
    "& .card-container": {
      width: "100%",
      overflow: "hidden",
      height: "270px",
      [theme.breakpoints.down("sm")]: {
        height: "224px",
      },
    },
    "& .card-content-area": {
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      background: theme?.palette?.customColor?.BGLIGHT,
      "& .contentBox": {
        flex: "1 0 auto",
        padding: "20px 20px 0px 20px !important",
        overflow: "hidden",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
          overflow: "initial",
        },
        "& .cardActionWp": {
          borderTop: `1px solid ${theme?.palette?.customColor?.LINE}`,
          padding: "20px 0px",
          display: "flex",
          justifyContent: "start",
          width: "100%",

          marginTop: "20px",
          [theme.breakpoints.down("sm")]: {
            alignItems: "baseline",
          },
        },
        "& .TopContentBox": {
          width: "100%",
        },
      },
    },
  },
}));
