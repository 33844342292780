import { makeStyles } from "@mui/styles";

const useTextboxStyle = makeStyles((theme) => ({
  typotool: {
    margin: "0px !important",
    backgroundColor: "white",
    paddingTop: "4px",
  },
}));
export default useTextboxStyle;
