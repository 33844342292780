import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  onHoverImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",

    position: "absolute",
    height: "218px",
    top: "135px",
    border: `1px solid ${theme.palette.customColor.LINE}`,
    /* Elevation 10 */

    filter: "drop-shadow(0px 60px 70px rgba(0, 0, 0, 0.08))",
    borderRadius: "5px",
    [theme.breakpoints.up("sm")]: {
      width: "389px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "389px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "389px",
    },
  },
}));
