import { postRestApiCall } from "@platformx/utilities";
import axios from "axios";

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Intl {
  type Key = "calendar" | "collation" | "currency" | "numberingSystem" | "timeZone" | "unit";
  // eslint-disable-next-line no-unused-vars
  function supportedValuesOf(input: Key): string[];
}

export const timeZoneData = () => {
  return Intl.supportedValuesOf("timeZone");
};

export const getUniqueTimeZone = () => {
  const aryIannaTimeZones = timeZoneData();
  const data: { label: string; time: string }[] = [];
  aryIannaTimeZones.forEach((timeZone) => {
    const strTime = new Date().toLocaleString([], {
      timeZone: `${timeZone}`,
      hour12: false,
    });
    const time = new Date(strTime).toTimeString().slice(0, -21);
    data.push({ label: `${timeZone} ${time}(IST)`, time: `${strTime}` });
  });
  return data;
};

/**
 * user details api call
 */
export const getUserDetailsService = (ele: any) => {
  const { secondaryArgs = {}, userId = "" } = ele;
  const {
    prelemBaseEndpoint: { language = "en", deliveryEndPoint = "", PublishEndPoint = "" } = {},
  } = secondaryArgs;

  const data = JSON.stringify({
    query: `query{
    viewProfile( user_id: ${JSON.stringify(userId)}
    )
     {
        user_id
        dob
        gender
        username
        enabled
        first_name
        last_name
        email
        timezone
        image
        phone
        role
        default_site
        preferred_sites_languages
        accessible_sites
        preferred_sites_urls
        }

    }`,
    variables: {},
  });
  return postRestApiCall(`${deliveryEndPoint}delivery-engine`, data, language, PublishEndPoint);
};

/**
 * user details update api call
 */
export const updateUserDetailsService = (ele: any) => {
  const { secondaryArgs = {}, userId = "", userDetails = {} } = ele;
  const {
    prelemBaseEndpoint: { language = "en", deliveryEndPoint = "", PublishEndPoint = "" } = {},
  } = secondaryArgs;

  const data = JSON.stringify({
    query: `mutation ($input: updateRequest){
      updateUserProfile(input: $input) {    
      message
      }
  }`,
    variables: {
      input: {
        first_name: userDetails.firstName,
        last_name: userDetails.lastName,
        timezone: userDetails.timezone,
        role_id: null,
        image: "",
        phone: userDetails.mobileNumber,
        enabled: true,
        id: userId,
        default_site: null,
        accessible_sites: null,
        preferred_sites_languages: userDetails.defaultLanguage || "en",
        preferred_sites_urls: null,
        dob: userDetails.dob,
        gender: userDetails.gender,
      },
    },
  });
  return postRestApiCall(`${deliveryEndPoint}delivery-engine`, data, language, PublishEndPoint);
};

//This is temp code and need to remove after Zagg demo
export const getTotalLoyaltyPoints = async () => {
  const ApiEndPoint =
    "https://hcltech.eu-west-1.openloyalty-stage.io/api/DEFAULT/member/effe1377-e178-4d81-8349-ae057d65c379/wallet";
  const OauthEndPoint = "https://hcltech.eu-west-1.openloyalty-stage.io/api/admin/login_check";
  const Username = "aatish.koul@hcl.com";
  const Password = "Qwaszx@123.";
  try {
    // Step 1: Get the access token
    const tokenResponse = await axios.post(OauthEndPoint, {
      username: Username,
      password: Password,
    });

    if (tokenResponse.status !== 200) {
      throw new Error("Failed to obtain access token");
    }

    const accessToken = tokenResponse.data.token;

    // Step 2: Fetch the wallet data to get the total points
    const walletResponse = await axios.get(ApiEndPoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (walletResponse.status !== 200) {
      throw new Error("Failed to fetch wallet data");
    }

    // Calculate total points from all items
    const totalPoints = walletResponse.data.items.reduce(
      (acc, item) => acc + item.account.activeUnits,
      0,
    );

    // Return total points as a string
    return totalPoints;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};
