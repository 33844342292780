import { Box, IconButton, Typography } from "@mui/material";
import { SearchIconSvg } from "@platformx/shared/static-assets";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import "./SearchBox.css";
import SearchModel from "./SearchModel";

export default function SearchBox(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [contentType, setContentType] = React.useState(localStorage.getItem("contentType"));
  const [keyword, setKeyword] = React.useState(localStorage.getItem("searchKeyword"));
  const location = useLocation();
  const [tags, setTags] = React.useState(localStorage.getItem("searchTags"));
  const [author, setAuthor] = React.useState(localStorage.getItem("author"));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const removeSearchLocalStorage = () => {
    localStorage.removeItem("contentType");
    localStorage.removeItem("searchKeyword");
    localStorage.removeItem("searchTags");
    localStorage.removeItem("author");
  };
  React.useEffect(() => {
    window.onpopstate = () => {
      removeSearchLocalStorage();
    };
    window.onbeforeunload = function () {
      removeSearchLocalStorage();
      navigate(location.pathname, { replace: true });
    };
  });

  React.useEffect(() => {
    if (location.state == null) {
      removeSearchLocalStorage();
      setContentType(localStorage.getItem("contentType"));
      setAuthor(localStorage.getItem("author"));
      setTags(localStorage.getItem("searchTags"));
      setKeyword(localStorage.getItem("searchKeyword"));
    } else {
      setContentType(localStorage.getItem("contentType"));
      setAuthor(localStorage.getItem("author"));
      setTags(localStorage.getItem("searchTags"));
      setKeyword(localStorage.getItem("searchKeyword"));
    }
  }, [location.state, props.menuItemSelected]);
  return (
    <>
      <Box
        onClick={handleClickOpen}
        data-testid='open-search-box'
        style={{ display: "flex", alignItems: "center" }}>
        <IconButton type='button' sx={{ p: "10px" }}>
          <img src={SearchIconSvg} alt='Search Icon' />
        </IconButton>
        {props.ifTab && (
          <Typography variant='h6regular' color='#4E4B66' className='searchBoxInput'>
            <span className='searchline'>
              {contentType && <b style={{ color: "#4B9EF9" }}>{contentType}: </b>}
              {keyword && keyword.length !== 0 && (
                <>
                  <b style={{ marginRight: "5px", marginLeft: "5px" }}>Keyword:</b>
                  <span className='keywordalign'> {keyword} </span>
                </>
              )}
              {tags && tags.length !== 0 && (
                <>
                  <b style={{ marginRight: "5px", marginLeft: "5px" }}>Tags:</b>
                  {tags}
                </>
              )}
              {author && (
                <>
                  <b style={{ marginRight: "5px", marginLeft: "5px" }}>Author:</b>
                  {author}
                </>
              )}
            </span>
            {!contentType && !keyword && (!tags || (tags && tags.length === 0)) && !author && (
              <span style={{ color: "#A0A3BD" }}>{t("searchPlaceholder")}</span>
            )}
          </Typography>
        )}
      </Box>
      <SearchModel
        searchOpen={open}
        handleSearchClose={handleClose}
        menuSelected={props.menuItemSelected}
      />
    </>
  );
}
