import graphqlInstance from "../../config/graphqlConfig";
import { ApiResponse } from "../utils/common.types";
import { PROJECT_CATCH_ERROR_LIST, ShowToastError } from "@platformx/utilities";
import {
  FETCH_SITE_LISTING,
  CREATE_NEW_SITE_CONFIG,
  PUBLISH_MULTISITE_INFO,
  SITE_TITLE_VALIDATION,
  SUBDOMAIN_VALIDATION,
  UPDATE_SITE_CONFIG,
} from "../../graphQL/queries/siteCreationQueries";

export const fetchSites = async <T>(input: T): Promise<any> => {
  try {
    const { data } = await graphqlInstance.query({
      query: FETCH_SITE_LISTING,
      variables: input,
      fetchPolicy: "no-cache",
    });
    return data;
  } catch (err: any) {
    console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:fetchSites`, err);
    throw err;
  }
};

export const createSiteConfig = async <T>(input: T): Promise<any> => {
  try {
    const { data } = await graphqlInstance.mutate({
      mutation: CREATE_NEW_SITE_CONFIG,
      variables: input,
    });
    return data;
  } catch (err: any) {
    const statusCode = err?.networkError?.statusCode || 0;
    ShowToastError(`${err.graphQLErrors[0].message}`, statusCode);
    console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:createSiteConfig`, err);
    throw err;
  }
};

export const updateSiteConfig = async <T>(input: T): Promise<ApiResponse<T>> => {
  try {
    const { data } = await graphqlInstance.mutate({
      mutation: UPDATE_SITE_CONFIG,
      variables: input,
    });
    return data;
  } catch (err: any) {
    console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:updateSiteConfig`, err);
    throw err;
  }
};

export const publishMultisiteInfo = async <T>(input: T): Promise<ApiResponse<T>> => {
  try {
    const { data } = await graphqlInstance.mutate({
      mutation: PUBLISH_MULTISITE_INFO,
      variables: input,
    });
    return data;
  } catch (err: any) {
    console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:publishMultisiteInfo`, err);
    throw err;
  }
};

export const siteTitleValidation = async <T>(input: T): Promise<ApiResponse<T>> => {
  try {
    const { data } = await graphqlInstance.mutate({
      mutation: SITE_TITLE_VALIDATION,
      variables: input,
    });
    return data;
  } catch (err: any) {
    console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:siteTitleValidation`, err);
    throw err;
  }
};

export const subdomainValidation = async <T>(input: T): Promise<ApiResponse<T>> => {
  try {
    const { data } = await graphqlInstance.mutate({
      mutation: SUBDOMAIN_VALIDATION,
      variables: input,
    });
    return data;
  } catch (err: any) {
    console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:subdomainValidation`, err);
    throw err;
  }
};
