import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  eventSpacer: {
    marginTop: "12px",
  },
  dotSeparator: {
    fontSize: "6px",
  },
  timeField: {
    padding: "2px 0 8px 0",
    marginRight: "35px",
  },
  eventIcon: {
    position: "relative",
    top: "6px",
    marginRight: "12px",
    fontSize: "18px",
    color: theme.palette.customColor.SECONDARY,
  },
  button: {
    backgroundColor: theme.palette.customColor.SECONDARY,
    marginRight: "12px",
  },
  selectField: {
    padding: "0 18px",
    borderRadius: theme.borderRadius.value,
    border: `1px solid ${theme.palette.customColor.LINE}`,
    backgroundColor: theme.palette.customColor.BGLIGHT,
    width: "100%",
    marginTop: "15px",
  },
  noGapRight: {
    marginRight: "0px",
  },
}));
