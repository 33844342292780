import { Box, Skeleton, Typography } from "@mui/material";

const ProductCardSkeleton = () => {
  return (
    <Box className='productCard'>
      <Box display='flex'>
        <Box width={200} height={200} className='productCardImage'>
          <Skeleton variant='rectangular' width={150} height={150} />
        </Box>
        <Box p={1}>
          <Typography variant='p4regular' sx={{ textTransform: "uppercase", marginBottom: 0 }}>
            <Skeleton width={100} />
          </Typography>
          <Typography variant='p3regular' sx={{ marginBottom: 0 }} gutterBottom>
            <Skeleton width={150} />
          </Typography>
          <Typography variant='labelregular' sx={{ paddingBottom: 0 }}>
            <Skeleton width={200} />
          </Typography>
          <Typography variant='p4regular' sx={{ marginTop: 0 }} gutterBottom>
            <Skeleton width={150} />
          </Typography>
          <Box>
            <Skeleton variant='rectangular' width={150} height={40} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductCardSkeleton;
