import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    mainStyleWrapper: {
      position: "relative",
      maxWidth: "820px",
      margin: "auto",
      "& .leftFiled": {
        marginBottom: "40px",
        paddingRight: "55px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "30px",
          paddingRight: "10px",
        },
        [theme.breakpoints.down("sm")]: {
          marginBottom: "10px",
          paddingRight: "0px",
        },
      },
      "& .textFiled": {
        marginBottom: "40px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "30px",
        },
      },
      "& .leftFiledLast": {
        paddingRight: "55px",
        [theme.breakpoints.down("md")]: {
          paddingRight: "10px",
        },
        [theme.breakpoints.down("sm")]: {
          marginBottom: "10px",
          paddingRight: "0px",
        },
      },
      "& .textFiledLast": {
        marginBottom: "0px",
        "& .Platform-x-Autocomplete-hasClearIcon": {
          "& .Platform-x-TextField-root .Platform-x-InputBase-fullWidth": {
            padding: "14px",
          },
        },
      },
    },
  };
});
