import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();

  return {
    ImageRenderPrelemWrapper: {
      "&.imageRenderAnimation": {
        width: "100%",
        height: "100%",
        position: "relative",
        "& img": {
          opacity: 0,
          animation: `fadeInAnimation ${theme?.palette?.prelemAnimation?.IMAGE?.FADEINTIME}s ease-in forwards`,
        },
        "& .editBox": {
          borderRadius: "5px",
          display: "flex",
          width: "32px",
          height: "32px",
          padding: "10px",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid var(--Alias-Grayscale-N1-N1-100, #E6EBF5)",
          background: "#FFF",
          cursor: "pointer",
          position: "absolute",
          top: "5px",
          left: "5px",
          zIndex: "100",
        },
      },
    },
  };
});
