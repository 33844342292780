import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { QuestionListIcon } from "@platformx/shared/static-assets";
import { useTranslation } from "react-i18next";
import { PlatFormXDateTimeFormat } from "../../utils/helperFns";
import { useStyles } from "./QuestionListing.styles";

interface DesktopListingProps {
  index?: any;
  title?: string;
  description?: string;
  author?: string;
  time?: any;
  onClickSelect?: any;
  item?: any;
  isSelected?: any;
}
const DesktopListing = ({
  index,
  title,
  description,
  author,
  time,
  onClickSelect,
  item,
  isSelected,
}: DesktopListingProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box>
      <Grid key={index} container className={classes.cardContainer}>
        <Grid item xs={6} className={classes.item1Container}>
          <Box className={classes.imgContainer}>
            <img src={QuestionListIcon} alt='Page Icon' />
          </Box>
          <Box className={classes.titleContainer}>
            <Typography variant='h5medium' className={classes.ellipsisText}>
              {title}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1.5} className={classes.publishIcon}>
          <Typography className={classes.ellipsisText} variant='p4regular'>
            {description}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.userContainer}>
          <Box>
            <Typography className={classes.ellipsisText} variant='p4regular'>
              {author}
            </Typography>
            <Typography variant='p4regular'>{PlatFormXDateTimeFormat(time)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={1.5} className={classes.buttonContainer}>
          <Button
            variant={isSelected(item) ? "primaryButton" : "secondaryButton"}
            className='sm'
            disableElevation
            onClick={() => onClickSelect(item)}
            data-testid='desktop-listing-select'>
            {isSelected(item) ? t("selected") : t("select")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default DesktopListing;
