import EditIcon from "@mui/icons-material/Edit";
import { Box, CardMedia, Paper } from "@mui/material";
import { fallBackImage } from "@platformx/shared/static-assets";
import { formCroppedUrl, IMAGE_FALLBACK_PRELEM, ratios } from "@platformx/utilities";
import React, { useState } from "react";
import { breakpoints, SINGLE_CROP_POINTS } from "./ConstantData";
import "./ImageRender.css";
import { useCustomStyle } from "./ImageRender.style";

const ImageRender = (props: any = {}) => {
  const [error, setError] = useState(false);
  const classes = useCustomStyle();

  const {
    originalImage = {},
    publishedImages = [],
    imgOrder = {
      1440: "hero",
      1280: "landscape",
      1024: "card2",
      768: "square",
      600: "card1",
      320: "portrait",
    },
    cropType,
    height = "100%",
    width = "100%",
    secondaryArgs: { bucketName, gcpUrl, onEditClick = () => {} },
    isEditing = false,
    index = 0,
  } = props;
  const { original_image_relative_path, ext } = originalImage;

  const handleError = () => {
    setError(true);
  };

  return (
    <Box className={`${classes.ImageRenderPrelemWrapper} imageRenderAnimation`}>
      {error ? (
        <img
          src={fallBackImage}
          style={{ objectFit: "contain", width: width, height: height }}
          alt='fallbackimage'
        />
      ) : (
        <>
          {publishedImages && publishedImages.length > 0 ? (
            <CardMedia
              component='picture'
              sx={{
                aspectRatio: {
                  xs: ratios[imgOrder["320"]],
                  sm: ratios[imgOrder["600"]],
                  md: ratios[imgOrder["768"]],
                  em: ratios[imgOrder["1024"]],
                  lg: ratios[imgOrder["1280"]],
                  xl: ratios[imgOrder["1440"]],
                },
                height: height,
                objectFit: "cover",
                width: width,
              }}>
              {(cropType === "single" ? SINGLE_CROP_POINTS : breakpoints).map(
                ({ breakpoint, ratio }, key) => {
                  const img =
                    publishedImages.find(
                      ({ aspect_ratio }: any) => aspect_ratio === (imgOrder[breakpoint] || ratio),
                    ) || IMAGE_FALLBACK_PRELEM;
                  const { folder_path: imgPath = "" } = img || {};
                  return (
                    <React.Fragment key={key}>
                      <source
                        media={`(min-width:${breakpoint}px)`}
                        srcSet={formCroppedUrl(gcpUrl, bucketName, imgPath, "webp")}
                      />
                      <source
                        media={`(min-width:${breakpoint}px)`}
                        srcSet={formCroppedUrl(gcpUrl, bucketName, imgPath, ext)}
                      />
                    </React.Fragment>
                  );
                },
              )}
              <img
                alt='cropped-img'
                src={formCroppedUrl(gcpUrl, bucketName, original_image_relative_path, "webp")}
                onError={handleError}
                style={{
                  width: width,
                  height: height,
                  objectFit: "cover",
                  display: "flex",
                }}
                className='rounderCardImages'
              />
            </CardMedia>
          ) : (
            <Paper
              className='fullHeight'
              sx={{
                // height: "100%",
                aspectRatio: {
                  xs: ratios[imgOrder["320"]],
                  sm: ratios[imgOrder["600"]],
                  md: ratios[imgOrder["768"]],
                  em: ratios[imgOrder["1024"]],
                  lg: ratios[imgOrder["1280"]],
                  xl: ratios[imgOrder["1440"]],
                },
              }}>
              <picture>
                <source
                  srcSet={formCroppedUrl(gcpUrl, bucketName, original_image_relative_path, "webp")}
                  type='image/webp'
                />
                <source
                  srcSet={formCroppedUrl(gcpUrl, bucketName, original_image_relative_path, ext)}
                />
                <img
                  src={formCroppedUrl(gcpUrl, bucketName, original_image_relative_path, "webp")}
                  onError={handleError}
                  width='100%'
                  height='100%'
                  style={{ objectFit: "cover", display: "flex" }}
                  alt='prelem default'
                />
              </picture>
            </Paper>
          )}
          {isEditing && (
            <Box
              className='editBox'
              gap='10px'
              flexShrink={0}
              onClick={() => onEditClick(index)}
              data-testid='edit-img-renderer'>
              <EditIcon sx={{ color: "black" }} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default React.memo(ImageRender);
