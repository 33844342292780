import {
  getSubDomain,
  handleHtmlTags,
  relativeImageURL,
  ShowToastError,
  trimString,
} from "@platformx/utilities";
import { t } from "i18next";
import { onError } from "../../../../utils/Helper";
import { VodInstance } from "../createVod.types";

export const updateVodSettings = (vodRef, currentVodData, language) => {
  const pageURLData = vodRef?.current?.title
    ? vodRef?.current?.title?.replace(/[^A-Z0-9]+/gi, "-").toLowerCase()
    : "";
  const pageURL = currentVodData?.current ? currentVodData.current : pageURLData;

  const VodSettings = {
    socialog_url: `${getSubDomain()}/${language}/video/${pageURL}`,
    socialog_twitter_url: `${getSubDomain()}/${language}/video/${pageURL}`,
    socialog_type: "video article",
    socialog_sitename: vodRef?.current?.title
      ? trimString(handleHtmlTags(vodRef?.current?.title), 100)
      : "video article",
    seo_title: vodRef?.current?.title
      ? trimString(handleHtmlTags(vodRef?.current?.title), 100)
      : "",
    socialog_title: vodRef?.current?.titleSocialShare
      ? trimString(handleHtmlTags(vodRef?.current?.titleSocialShare), 100)
      : "",
    socialog_twitter_title: vodRef?.current?.titleSocialShare
      ? trimString(handleHtmlTags(vodRef?.current?.titleSocialShare), 100)
      : "",
    socialog_description: vodRef?.current?.descriptionSocialShare
      ? trimString(handleHtmlTags(vodRef?.current?.descriptionSocialShare), 163)
      : "",
    socialog_twitter_description: vodRef?.current?.descriptionSocialShare
      ? trimString(handleHtmlTags(vodRef?.current?.descriptionSocialShare), 163)
      : "",
    socialog_image: vodRef?.current?.imageSocialShare,
    socialog_twitter_image: vodRef?.current?.imageSocialShare,
    keywords: vodRef?.current?.tagsSocialShare,
    seo_keywords: vodRef?.current?.tagsSocialShare,
    seo_description: vodRef?.current?.description
      ? trimString(handleHtmlTags(vodRef?.current?.description), 163)
      : "",
    seo_blockIndexing: true,
  };
  return VodSettings;
};

export const updateStructureData = (vodRef) => {
  const VodStructureData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "VideoObject",
        contentUrl: vodRef.current?.DsapceVideoUrl,
        name: vodRef.current?.title ? trimString(handleHtmlTags(vodRef.current?.title), 100) : "",
        description: vodRef.current?.description
          ? trimString(handleHtmlTags(vodRef.current?.description), 200)
          : "",
        embedUrl: vodRef.current?.DsapceVideoUrl,
        thumbnailUrl: {
          "@id": vodRef.current?.Thumbnail ? relativeImageURL(vodRef.current?.Thumbnail) : "",
        },
      },
    ],
  };
  return VodStructureData;
};
export const isInViewport = (element, isSeo) => {
  const mainElement = document.querySelector(`#${element}`);
  if (mainElement) {
    const rect = mainElement.getBoundingClientRect();
    if (isSeo) {
      return (
        rect.top < window.pageYOffset + window.innerHeight &&
        rect.left < window.pageXOffset + window.innerWidth &&
        rect.top + rect.height > window.pageYOffset &&
        rect.left + rect.width > window.pageXOffset
      );
    }
    return rect.top >= 0 && rect.left >= 0;
  }
  return false;
};

/**
 *
 * @param errors object
 * @returns boolean
 */
const allFieldAreCheckToValue = (errors = {}) => {
  let valid = true;
  Object.values(errors).forEach((ele) => {
    if (ele === "") {
      valid = false;
    }
  });
  return valid;
};

export const fieldValidationForPublish = (vodRef, isError, setIsError) => {
  const { Tags = [], title = "", Thumbnail = "", DsapceVideoUrl = "", page = "" } = vodRef;
  const newObj = {
    page: page,
    title: title,
    Thumbnail: Thumbnail,
    DsapceVideoUrl: DsapceVideoUrl,
  };
  if (Tags?.length > 0 && allFieldAreCheckToValue(newObj)) {
    return true;
  }
  if (title === "") {
    onError("Title");
    setIsError({ ...isError, title: true });
    ShowToastError(t("title_error"));
  } else if (Thumbnail === "" || DsapceVideoUrl === "") {
    if (DsapceVideoUrl === "") {
      onError("Video");
      setIsError({ ...isError, DsapceVideoUrl: true });
    } else {
      onError("Thumbnail");
      setIsError({ ...isError, Thumbnail: true });
    }
    ShowToastError(t("video_thumbnail_error"));
  } else if (Tags?.length === 0) {
    onError("tags");
    setIsError({ ...isError, Tags: true });
    ShowToastError(t("tag_error"));
  } else {
    return true;
  }
  return false;
};

export const removeVODDuplicateTag = (videoTags) => {
  if (videoTags) {
    return [...new Set(videoTags)];
  }
  return [];
};

export const fieldValidation = (VOD, isError, setIsError) => {
  const { title = "" } = VOD;

  if (title === "") {
    onError("Title");
    setIsError({ ...isError, title: true });
    ShowToastError(`${t("title")} ${t("is_required")}`);
  } else {
    return true;
  }
};

export const getNewVod = (username: string, vodRef: any): VodInstance => {
  const structuredata = updateStructureData(vodRef);
  return {
    CommonFields: {
      page: "",
      title: "",
      category: "Vod",
      site_name: "PlatX",
      page_state: "",
      description: "",
      is_edit: false,
      seo_enable: true,
      analytics_enable: true,
      robot_txt: false,
      sitemap: false,
      analytics: "",
      others: "",
      structure_data: JSON.stringify(structuredata),
      creationDate: new Date().toISOString(),
      modificationDate: new Date().toISOString(),
      tags: [],
      createdBy: username,
      parent_page_url: "/",
      current_page_url: "",
      page_lastmodifiedby: username,
      developedby: username,
      page_createdby: username,
      settings: {},
      IsConfirm: false,
      is_featured: false,
      titleSocialShare: "",
      descriptionSocialShare: "",
      imageSocialShare: "",
      tagsSocialShare: [],
    },
    ObjectFields: {
      VodAccountId: "",
      PlayerID: "",
      PlayerType: "brightcove",
      VodVideoId: "",
      Poster: "",
      Author: username,
      Thumbnail: "",
      DsapceVideoUrl: "",
    },
  };
};

export const getVodToSend = (
  vodRef,
  newTempData,
  updateTempObj,
  pageState,
  IsDuplicate,
  isFeatured,
  username,
) => {
  const pageURL = vodRef?.current?.title.replace(/[^A-Z0-9]+/gi, "-").toLowerCase();
  const tempObjField = {
    VodAccountId: "",
    PlayerID: "",
    PlayerType: "brightcove",
    VodVideoId: "",
    Poster: "",
    Author: username,
    Thumbnail: "",
    DsapceVideoUrl: "",
  };

  const vodToSend = {
    ...newTempData,
    CommonFields: {
      ...getNewVod(username, vodRef).CommonFields,
      ...(newTempData?.CommonFields || {}),
      ...(updateTempObj.current.CommonFields || {}),
      page: vodRef?.current?.page || pageURL,
      page_state: pageState,
      IsConfirm: IsDuplicate,
      is_featured: isFeatured,
      creationDate: new Date().toISOString(),
      modificationDate: new Date().toISOString(),
    },
    ObjectFields: {
      ...tempObjField,
      ...(newTempData?.ObjectFields || {}),
      ...(updateTempObj.current.ObjectFields || {}),
    },
  };
  delete vodToSend?.CommonFields?.titleSocialShare;
  delete vodToSend?.CommonFields?.descriptionSocialShare;
  delete vodToSend?.CommonFields?.imageSocialShare;
  delete vodToSend?.CommonFields?.tagsSocialShare;
  return vodToSend;
};

export const getUpdatedVodToSend = (
  newTempData,
  updateTempObj,
  username,
  is_featured,
  pageURL,
  pageState,
) => {
  const vodToSend = {
    ...newTempData,
    CommonFields: {
      ...(newTempData?.CommonFields || {}),
      ...(updateTempObj.current.CommonFields || {}),
      page: pageURL,
      page_state: pageState,
      is_featured,
      page_lastmodifiedby: username,
      current_page_url: `/${pageURL}`,
      modificationDate: new Date().toISOString(),
    },
    ObjectFields: {
      ...(newTempData?.ObjectFields || {}),
      ...(updateTempObj.current.ObjectFields || {}),
    },
  };
  delete vodToSend?.CommonFields?.titleSocialShare;
  delete vodToSend?.CommonFields?.descriptionSocialShare;
  delete vodToSend?.CommonFields?.imageSocialShare;
  delete vodToSend?.CommonFields?.tagsSocialShare;
  return vodToSend;
};
