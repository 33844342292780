import { Box } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useCustomStyle } from "./Polls/Poll.style";
import { CommonBoxWithNumber, XTags } from "@platformx/utilities";

const ChooseTags = ({
  isEdit,
  tagData,
  selectedTag,
  handleTagOnChange,
  subCategory = "",
  sectionNumber = "06",
}) => {
  const { t } = useTranslation();
  const classes = useCustomStyle();
  return (
    <Box id='tags' className={`${classes.chooseTagsWp}`}>
      <CommonBoxWithNumber
        number={sectionNumber}
        title={t("choose_tags")}
        titleVarient='h5bold'
        subTitleVarient='p4regular'
        subTitle={t("subhead")}>
        <Box className='noSpacWp'>
          <XTags
            isEdit={isEdit}
            tagData={tagData}
            selectedTag={selectedTag}
            subCategory={subCategory}
            handleTagOnChange={handleTagOnChange}
          />
        </Box>
      </CommonBoxWithNumber>
    </Box>
  );
};
export default ChooseTags;
