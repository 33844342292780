import { ApolloError } from "@apollo/client";
import { PROJECT_CATCH_ERROR_LIST, ShowToastError } from "@platformx/utilities";
import graphqlInstance from "../../config/graphqlConfig";
import { CREATE_NEW_CHATGPT_REQUEST } from "../../graphQL/mutations/chatGptMutateQueries";

export const createChatGptRequest = async <T>(input: T): Promise<any> => {
  try {
    const { data } = await graphqlInstance.mutate({
      mutation: CREATE_NEW_CHATGPT_REQUEST,
      variables: input,
    });
    return data;
  } catch (error: any) {
    const statusCode = error?.networkError?.statusCode || 0;
    if (error instanceof ApolloError) {
      ShowToastError(`${error?.graphQLErrors?.[0]?.message}`, statusCode);
    }
    console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:createChatGptRequest`, error);
    throw error;
  }
};
