import { Box, FormControl, IconButton, InputAdornment, InputBase } from "@mui/material";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SearchIcon } from "@platformx/shared/static-assets";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "../../utils/helperFns";
import "./Search.css";

export default function SearchBox({ handleSearch, setLoading = () => {} }: any) {
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const handleOnChange = (event: any) => {
    // setLoading(true);
    setSearchQuery(event.target.value);
    debouncedSearch(event.target.value.toLowerCase());
  };
  const resetSearch = () => {
    setLoading(true);
    handleSearch("");
    setSearchQuery("");
  };

  const debouncedSearch = (value) => debounce(handleSearch(value), 1000);

  return (
    <FormControl className='userSearchBox'>
      <InputBase
        id='search-users'
        type='text'
        size='small'
        value={searchQuery}
        onChange={handleOnChange}
        sx={{
          border: "1px solid #D9DBE9",
          borderRadius: "5px",
          height: "46px",
        }}
        startAdornment={
          <InputAdornment position='start'>
            <IconButton
              aria-label='search users'
              edge='start'
              sx={{ paddingLeft: "8px", margin: 0 }}>
              <img src={SearchIcon} alt='' />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position='end'>
            {searchQuery && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}>
                <CloseRoundedIcon
                  sx={{
                    width: ".75em",
                    height: ".75em",
                    cursor: "pointer",
                    marginRight: "7px",
                  }}
                  onClick={resetSearch}
                />
              </Box>
            )}
          </InputAdornment>
        }
        placeholder={`${t("search")}...`}
      />
    </FormControl>
  );
}
