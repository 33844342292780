import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { fetchRequiredMessage } from "../../../utils/helperFns";
import HiddenTextBox from "../../common/HiddenTextBox/HiddenTextBox";

type Field = {
  id: string;
  from: string;
  to: string;
  points: string;
  unit?: string;
};

type Props = {
  field: { name: string };
  errors: any;
  clearErrors: (name: string) => void;
  setErrors: (name: string, error: { type: string; message: string }) => void;
  state: { [key: string]: any };
  setState: (state: { [key: string]: any }) => void;
  isEdit: boolean;
  type?: string;
  register?: any;
};

const Dynamic: React.FC<Props> = ({
  field,
  errors,
  isDisabled,
  register,
  clearErrors,
  type,
  state,
  setState,
  setErrors,
  isEdit,
}: any) => {
  const [fields, setFields] = useState(
    state[field.name] || [{ id: "0", unit: "", from: "", to: "", points: "" }],
  );

  const [isDeleteDisable, setIsDeleteDisable] = useState(true);

  const addRule = () => {
    const lastId = fields.length > 0 ? fields?.at(-1)?.id || "" : "0";
    setFields([
      ...fields,
      { id: String(parseInt(lastId) + 1), unit: "", from: "", to: "", points: "" },
    ]);
  };

  const removeRule = (id) => {
    setFields(fields.filter((x) => x?.id !== id));
  };

  const handleChange = (event, unit) => {
    if (errors) {
      clearErrors(field.name);
    }
    const modifiedFields = fields.map((answer) =>
      answer.id === event.target.id
        ? { ...answer, [event.target.name]: event.target.value, unit: unit || "" }
        : answer,
    ) as Field[];
    setFields(modifiedFields);

    let check = false;
    modifiedFields.some((val: any) => {
      if (val?.from === "") {
        setErrors(field?.name, { type: "manual", message: "Please fill all the fields" });
        setState({ ...state, [field.name]: "" });
        check = true;
      } else if (val?.to === "") {
        setErrors(field?.name, { type: "manual", message: "Please fill all the fields" });
        setState({ ...state, [field.name]: "" });
        check = true;
      } else if (val?.points === "") {
        setErrors(field?.name, { type: "manual", message: "Please fill all the fields" });
        setState({ ...state, [field.name]: "" });
        check = true;
      }
    });
    if (!check) {
      setState({ ...state, [field.name]: fields });
    }
  };

  useEffect(() => {
    if (fields.length <= 1) {
      setIsDeleteDisable(true);
    } else {
      setIsDeleteDisable(false);
    }
  }, [fields]);

  useEffect(() => {
    isEdit && setFields(state[field.name] || [{ id: "0", unit: "", from: "", to: "", points: "" }]);
  }, []);

  const handleKeyDown = (event) => {
    if (type === "number") {
      if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") {
        event.preventDefault();
      }
    }
  };

  return (
    <Box id={`${field?.name}_id`}>
      <Box>
        {fields?.map((key, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              mt: index === 0 ? "0px" : "10px",
            }}>
            <TextField
              variant='filled'
              size='small'
              type={type}
              name={"from"}
              className='titlefield'
              placeholder={field.placeholder}
              id={key?.id}
              // error={error}
              disabled={isDisabled}
              value={key?.from}
              // defaultValue={data}
              onKeyDown={handleKeyDown}
              sx={{
                width: "20%",
                input: { cursor: isDisabled && "not-allowed", padding: "10px 5px" },
                marginRight: "5px",
                "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: "0",
                },
              }}
              inputProps={{
                readOnly: false,
                min: 0,
              }}
              onChange={(e) => handleChange(e, state["challenge_subtype"][0]?.unit)}
            />
            {state["challenge_subtype"] && (
              <Typography variant='p4regular' sx={{ textAlign: "center", width: "46px" }}>
                {state["challenge_subtype"][0]?.unit}
              </Typography>
            )}
            {state["challenge_subtype"] && state["challenge_subtype"][0]?.icon && (
              <img src={state["challenge_subtype"][0]?.icon} alt='' height='35px' width='35px' />
            )}
            <TextField
              variant='filled'
              size='small'
              type={type}
              onKeyDown={handleKeyDown}
              name='to'
              className='titlefield'
              placeholder={field.placeholder}
              id={key?.id}
              // error={error}
              disabled={isDisabled}
              value={key?.to}
              // defaultValue={data}
              sx={{
                width: "20%",
                input: { cursor: isDisabled && "not-allowed", padding: "10px 5px" },
                margin: "0 5px",
                "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: "0",
                },
              }}
              inputProps={{
                readOnly: false,
                min: 0,
              }}
              onChange={(e) => handleChange(e, state["challenge_subtype"][0]?.unit)}
            />
            {state["challenge_subtype"] && (
              <Typography variant='p4regular' sx={{ textAlign: "center", width: "46px" }}>
                {state["challenge_subtype"][0]?.unit}
              </Typography>
            )}
            <span>=</span>
            <TextField
              variant='filled'
              size='small'
              type={type}
              name='points'
              className='titlefield'
              placeholder={field.placeholder}
              id={key?.id}
              // error={error}
              disabled={isDisabled}
              value={key?.points}
              // defaultValue={data}
              sx={{
                width: "20%",
                input: { cursor: isDisabled && "not-allowed", padding: "10px 5px" },
                margin: "0 5px",
                "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: "0",
                },
              }}
              inputProps={{
                readOnly: false,
                min: 0,
              }}
              onChange={(e) => handleChange(e, state["challenge_subtype"][0]?.unit)}
              onKeyDown={handleKeyDown}
            />
            <Typography
              variant='p4regular'
              sx={{ textAlign: "center", width: "47px", marginRight: "10px" }}>
              Points
            </Typography>
            <AddCircleIcon onClick={addRule} sx={{ marginRight: "5px" }} />
            <Tooltip
              title={isDeleteDisable ? "Unable to delete minimum one rule is required!" : ""}
              placement='right'>
              <RemoveCircleIcon
                onClick={() => !isDeleteDisable && removeRule(key?.id)}
                sx={{
                  opacity: isDeleteDisable ? "0.4" : "1",
                }}
              />
            </Tooltip>
            {/* <ErrorMessageHandle error={errors || error} /> */}
          </Box>
        ))}
      </Box>

      <Box className='check'>
        <HiddenTextBox
          error={errors[field?.name]}
          value={state[field?.name] || ""}
          register={register}
          name={field?.name}
          required={fetchRequiredMessage(field?.validations, state[field?.name])}
        />
      </Box>
    </Box>
  );
};
export default Dynamic;
