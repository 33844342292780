import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const LeftSideBarStyle = makeStyles((theme: Theme) => ({
  mainSideBar: {
    [theme.breakpoints.up("md")]: {
      "&.openSideBar": {
        "& .LeftSidebarMain": {
          "& .ddmenu": {
            padding: "10px",
            margin: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .avatarWp": {
              marginRight: 0,
            },
            "& .ddmenutex, .Platform-x-SvgIcon-root": {
              display: "none",
            },
          },
          "& .menulist, .auth": {
            padding: "7px",
            "& .Platform-x-Avatar-root": {
              marginLeft: "9px",
              marginRight: "30px",
            },
          },
          "& .menulist": {
            height: "calc(100vh - 120px)",
            overflowX: "hidden",
          },
          "& .menuIcon": {
            marginRight: "30px",
            marginLeft: "15px",
          },
          "& .openSideBarBottomSp": {
            marginBottom: "5px",
          },
        },
      },
    },

    display: "flex",
    "& .LeftSidebarMain": {
      height: "100%",
      display: "flex",
      WebkitFlexDirection: "column",
      msFlexDirection: "column",
      flexDirection: "column",
      WebkitBoxPack: "justify",
      WebkitJustifyContent: "space-between",
      justifyContent: "space-between",
      overflow: "hidden",
      borderRight: "0",
      "& .logoContainer": {
        display: "flex",
        justifyContent: "space-between",
        padding: "15px",
        borderBottom: `1px solid ${theme.palette.customColor.LINE}`,
        minHeight: "63px",
        "& .closeIcon": {
          cursor: "pointer",
          color: theme.palette.customColor.LABEL,
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.up("sm")]: {
            display: "none",
          },
        },
        "& .logo": {
          cursor: "pointer",
        },
      },
      "& .menuIcon": {
        minWidth: "18px",
        maxWidth: "18px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "12px",
        minHeight: "18px",
        maxHeight: "18px",
        "& img": { height: "15px" },
      },
      "& .menulinks": {
        paddingTop: "0px",
        paddingBottom: "0px",
        margin: "0",
        "& .imgMenuText": {
          display: "flex",
          alignItems: "center",
        },
        "& li": {
          minHeight: "22px",
          padding: "10px 5px",
          margin: "5px -5px -10px",
          borderRadius: theme.borderRadius.value,
          "& .textellipsis": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "185px",
          },
          "&:hover, &.active": {
            backgroundColor: "transparent",
            color: theme.palette.customColor.LINE,
            "& .textellipsis": {
              color: theme.palette.customColor.ACCENTS.ACCENT_3,
            },
            "& .menuIcon img": {
              filter:
                "brightness(0) saturate(100%) invert(69%) sepia(66%) saturate(4745%) hue-rotate(190deg)   brightness(102%) contrast(95%)",
            },
          },
        },
      },
      "& .menulist": {
        height: "calc(100vh - 133px)",
        overflowY: "auto",
        padding: "15px",
        overflowX: "hidden",
        "& .menuItemLink": {
          padding: "0px",
          textTransform: "uppercase",
          cursor: "pointer",
          "& p": {},
          "&:hover": { backgroundColor: "transparent" },
        },
      },
      "& .auth": { borderTop: `1px solid ${theme.palette.customColor.LINE}`, padding: "15px" },
    },
  },
}));
export default LeftSideBarStyle;
