import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { SliderSetting, NoSearchResult, DashboardCardLoader } from "@platformx/utilities";
import "slick-carousel/slick/slick.css";
import HorizontalCard from "../horizontalCard/HorizontalCard";
import { boostContentWithIconMapper } from "./helper";
import { useCustomStyle } from "./HorizontalCardList.style";

type HorizontalCardListProps = {
  boostContent: any;
  loading?: boolean;
};
const HorizontalCardList = ({ boostContent, loading }: HorizontalCardListProps) => {
  const boostContentWithIcons = boostContentWithIconMapper(boostContent);
  const classes = useCustomStyle();
  return (
    <>
      {loading ? (
        <DashboardCardLoader />
      ) : boostContentWithIcons?.length > 0 ? (
        <Slider {...SliderSetting} className={`${classes.horizontalCardSlider} SliderWraper`}>
          {boostContentWithIcons.map(
            (item, index) =>
              item.url !== "" && (
                <HorizontalCard
                  key={`${item.title} ${index.toString()}`}
                  Title={item.title}
                  Description={item.description}
                  url={item.url}
                  icon={item.icon}
                />
              ),
          )}
        </Slider>
      ) : (
        <NoSearchResult />
      )}
    </>
  );
};

export default HorizontalCardList;
