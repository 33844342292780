import { Box, Typography } from "@mui/material";
import DatePicker from "../DatePicker/DatePicker";

const FormDate = ({ item, register, clearErrors, errors, selectedElement, setSelectedElement }) => {
  return (
    <Box className='formDate elementWrapper' mt={1}>
      <Typography variant='p4regular' className='form-label' mt={1} mb={1}>
        {item?.title}
      </Typography>
      <DatePicker
        disablePast={false}
        state={selectedElement}
        setState={setSelectedElement}
        field={item}
        register={register}
        clearErrors={clearErrors}
        error={errors[item.name]}
      />
    </Box>
  );
};

export default FormDate;
