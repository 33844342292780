import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {
  Loader,
  relativeImageURL,
  BackArrow,
  breakpointsdata as BREAKPOINTS,
  getSingleCropBreakpoint,
} from "@platformx/utilities";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useImageCrop from "../hooks/useImageCrop";
import SelectedImageCrop from "./SelectedImageCrop";

const ImageCrop = (props: any = {}) => {
  const {
    open,
    originalImage = {},
    backTo,
    doneCropCompleted,
    isCrop,
    prelemId,
    i,
    published_images,
  } = useMemo(() => props, [props]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const {
    doneLoader,
    isLoading: doneCropLoading,
    handleDone,
    onCropChange,
  } = useImageCrop(originalImage, doneCropCompleted, prelemId, i);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const { Thumbnail } = originalImage || {};
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={backTo}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Grid
        container
        sx={{
          borderBottom: "1px solid #ced3d9",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: { xs: "10px 15px", md: "0px 24px" },
        }}>
        <Grid xs={12} md={8}>
          <BackArrow styles={{ paddingBottom: "10px" }} handleReturn={() => backTo()} />
          <Typography variant='h5semibold' component='span'>
            {t("crop_your_image")}
          </Typography>
        </Grid>
        <Grid
          xs={12}
          md={4}
          sx={{
            textAlign: { xs: "left", md: "right" },
            padding: { xs: "10px 0", md: "8px" },
          }}>
          <Button
            className='sm'
            variant='secondaryButton'
            onClick={() => backTo()}
            sx={{ marginRight: "12px" }}>
            {t("back")}
          </Button>
          <LoadingButton
            onClick={() => handleDone(isCrop, checked, published_images?.[0]?.aspect_ratio)}
            loading={doneLoader}
            loadingPosition='start'
            className='sm'
            variant='primaryButton'
            disabled={doneCropLoading}>
            {t("done")}
          </LoadingButton>
        </Grid>
      </Grid>
      {!isCrop && (
        <Box sx={{ padding: "0 14px" }}>
          <Checkbox checked={checked} onChange={handleChange} color='default' />
          <Typography variant='p2regular' sx={{ display: "inline-block" }}>
            Publish without crop
          </Typography>
        </Box>
      )}

      <DialogContent>
        <Box
          className='wholecontainer'
          sx={{
            background: { xs: "#f7f7f7", sm: "#fff" },
            padding: { xs: "11px", sm: "0px" },
          }}>
          {isLoading && <Loader />}

          <Grid container spacing={1}>
            {(isCrop
              ? BREAKPOINTS
              : getSingleCropBreakpoint(published_images?.[0]?.aspect_ratio)
            ).map(({ aspectRatio, ratioName, aspectRatioName }, key) => (
              <Grid
                xs={12}
                md={isCrop ? 6 : 12}
                em={isCrop ? 4 : 12}
                sx={{ padding: "8px" }}
                key={key}>
                <SelectedImageCrop
                  crop={`crop${key + 1}`}
                  aspect={aspectRatio}
                  ratio={
                    isCrop
                      ? `${ratioName}(${aspectRatioName})`
                      : prelemId
                        ? `${ratioName}(${aspectRatioName})`
                        : `${ratioName}`
                  }
                  imageSrc={relativeImageURL(Thumbnail)}
                  onCropChange={(data: any) => onCropChange(data, key)}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ImageCrop);
