import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    quilleditor: (styleProps: any) => ({
      "& .ql-container": {
        fontFamily: theme.fontFamily?.[styleProps.fontFamily],
      },
      "& a": {
        color: theme.palette?.[styleProps.prelemType].LINK,
      },
    }),
  };
});
