import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DoneIcon from "@mui/icons-material/Done";
import { Button, Fab, Grid, Grow, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { SaveAnimationGif, SearchBlackSvg } from "@platformx/shared/static-assets";
import { BackArrow } from "@platformx/utilities";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DamContentSearchBox from "./DamContentSearchBox";
import "./DamContentTopHeading.css";
import useContentGlleryStyle from "./contentTypeCard/DamContentGllery.style";

const DamContentTopHeading = ({
  loading = false,
  onSearch = () => {},
  setInputValueHandle = () => {},
  assetType,
  toggleGallery,
  handleDoneClick,
  imageData,
  toggleDrawer,
}: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showSearch, setShowSearch] = useState(false);
  const classes = useContentGlleryStyle();

  const searchToggle = () => {
    setShowSearch(!showSearch);
  };

  const searchCloseToggle = () => {
    setInputValueHandle("");
    setShowSearch(!showSearch);
  };

  return (
    <Box
      className='damcontent-topbar'
      sx={{ background: theme?.palette?.customColor?.LIGHT, margin: "auto 10px" }}>
      {/* {Large view design} */}
      <Grid
        container
        pt={0}
        pb={0}
        pl={4}
        pr={4}
        sx={{
          display: {
            xs: "none",
            em: "flex",
            borderBottom: "1px solid",
            padding: "10px",
            borderColor: theme?.palette?.customColor?.LINE,
          },
        }}>
        <Grid item xs={12} sm={12} em={3} lg={3} className={classes.arrowGrid}>
          <BackArrow
            styles={{ paddingBottom: "10px" }}
            handleReturn={() => toggleGallery(false, "cancel")}
          />
          <Typography
            variant='h5semibold'
            component='span'
            sx={{
              textTransform: "capitalize",
            }}>
            {assetType === "Image" ? t("page_choose_image") : t("video_subtitle")}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} em={6} lg={6} display={"flex"} justifyContent={"center"}>
          <DamContentSearchBox onSearch={onSearch} assetType={assetType} />
        </Grid>

        <Grid item xs={12} sm={12} em={3} lg={3} className={classes.cancelGrid}>
          <Box className={classes.cancelBox}>
            <Button
              variant='secondaryButton'
              className='sm'
              onClick={() => toggleGallery(false, "cancel")}
              data-testid='dam-content-canel'>
              {t("cancel")}
            </Button>
            <Button
              disabled={!imageData.bitStreamId}
              variant='primaryButton'
              className='sm'
              onClick={handleDoneClick}
              data-testid='dam-content-update-or-done'>
              {loading && <img src={SaveAnimationGif} alt='saveanimation' />}
              {loading ? t("Updating") : t("done")}
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* {small view design} */}
      <Grid
        container
        pt={1}
        pb={1}
        pl={2}
        pr={6}
        sx={{
          display: {
            xs: "flex",
            em: "none",
            borderBottom: "1px solid",
            borderColor: theme?.palette?.customColor?.LINE,
            padding: "16px 16px 4px 16px",
            height: "60px",
          },
        }}>
        <Box
          className='fixed-screen-inner'
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            fontSize: theme?.fontSize?.fontSize_18,
          }}>
          {!showSearch ? (
            <>
              <Grow
                in={!showSearch}
                style={{ transformOrigin: "0 0 0" }}
                {...(!showSearch ? { timeout: 1000 } : {})}>
                <Box>
                  <Grid item sx={{ display: "flex" }}>
                    <Button
                      variant='text'
                      startIcon={<ArrowBackIosNewIcon sx={{ padding: "0px", color: "black" }} />}
                      sx={{ padding: 0, minWidth: "0px" }}
                      onClick={() => toggleGallery(false, "cancel")}
                      data-testid='dam-content-toggle-gallery'></Button>
                    <Typography variant='h4bold'>
                      {assetType === "Image" ? t("page_choose_image") : t("video_subtitle")}
                    </Typography>
                  </Grid>
                </Box>
              </Grow>

              <Grid item className='icon-container'>
                <Typography
                  className='top-icons right-search-icon'
                  sx={{
                    fontSize: theme?.fontSize?.fontSize_24,
                  }}
                  onClick={searchToggle}
                  data-testid='dam-content-search-toggle'>
                  <img src={SearchBlackSvg} alt='icon' />
                </Typography>

                <Typography
                  className='top-icons'
                  sx={{
                    fontSize: theme?.fontSize?.fontSize_24,
                  }}
                  onClick={toggleDrawer}
                  data-testid='dam-content-toggle-drawer'>
                  {/* <img src={MobileMenuIconSvg} alt='icon' /> */}
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid item className='search-item-container small-device'>
              <DamContentSearchBox
                onSearch={onSearch}
                searchCloseToggle={searchCloseToggle}
                assetType={assetType}
              />
            </Grid>
          )}
        </Box>

        <Box className={classes.leftcontent}>
          <Box className={classes.boxdone} onClick={handleDoneClick}>
            <Fab size='large' color='primary' aria-label='add'>
              <DoneIcon style={{ color: "#fff" }} />
            </Fab>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default DamContentTopHeading;
