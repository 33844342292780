/* eslint-disable no-debugger */
import AddIcon from "@mui/icons-material/Add";
import { Box, Fab } from "@mui/material";
import { CATEGORY_CONTENT, CONTENT_TYPES } from "@platformx/authoring-apis";
import { makeCreateContentPath, useAccess } from "@platformx/utilities";
import { useLocation, useNavigate } from "react-router-dom-v6";
import ContListingContainer from "./components/ContentListingContainer/ContentListingContainer";

export default function Content() {
  const { canAccessAction } = useAccess();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  const schemaType = pathSegments?.[pathSegments?.length - 2];
  let contentType: string = lastSegment;
  contentType = contentType?.toLocaleLowerCase() === "search-results" ? "ALL" : contentType;
  const navigate = useNavigate();
  const createContentNew = () => {
    const navigateTo: string = makeCreateContentPath(contentType);
    navigate(navigateTo, {
      state: contentType?.trim()?.toLowerCase(),
    });
  };

  return (
    <>
      <Box>
        <ContListingContainer
          contentType={contentType}
          schemaType={schemaType === "content" ? "contentType" : schemaType}
        />
      </Box>
      <Box
        sx={{
          display: { sm: "none" },
          position: "fixed",
          bottom: 0,
          right: 0,
        }}>
        <Box
          classes={!canAccessAction(CATEGORY_CONTENT, CONTENT_TYPES, "Create") && "disable"}
          sx={{
            margin: "0 15px 24px 0",
          }}
          onClick={createContentNew}
          data-testid='create-new-content'>
          <Fab size='large' color='primary' aria-label='add'>
            <AddIcon />
          </Fab>
        </Box>
      </Box>
    </>
  );
}
