import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  menuItemContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  menuItemHover: {
    "&:hover p": {
      color: theme.palette.customColor?.PRIMARY,
    },
    "&:hover img": {
      filter:
        "brightness(0) saturate(100%) invert(8%) sepia(6%) saturate(6495%) hue-rotate(204deg) brightness(93%) contrast(97%)",
    },
  },
}));
