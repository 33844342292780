/* eslint-disable no-debugger */
import { Box, IconButton } from "@mui/material";
import { RootState } from "@platformx/authoring-state";
import { MoreHorizIcon } from "@platformx/shared/static-assets";
import { OLD_CONTENT_TYPES, SUB_CATEGORY_PAGE } from "@platformx/utilities";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { ContentMenuListOld } from "../ContentMenuList/ContentMenuListOld";
import { DynamicContentMenuList } from "../ContentMenuList/DynamicContentMenuList";
import PageMenu from "../PageMenu/PageMenu";

const ContentTypeMenuList = ({
  item,
  deleteContent,
  duplicate,
  preview,
  unPublish,
  view,
  edit,
  fetchContentDetails,
}) => {
  const selectedItem = item;
  const contentType = selectedItem.contentType || selectedItem.tagName;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const { routes } = useSelector((state: RootState) => state);
  const { category } = routes?.routes.find((x) => x.subCategory === contentType) || {};

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          margin: "0px",
          display: "block",
        }}
        onClick={handleClickListItem}
        data-testid='list-item'>
        <IconButton className='hoverIcon' data-testid='selectoption'>
          <img
            alt='moreHorizIcon'
            src={MoreHorizIcon}
            style={{
              objectFit: "cover",
              // transform: 'rotate(90deg)',
              padding: "4px 0px",
            }}
          />
        </IconButton>
      </Box>
      {category === "content" &&
      OLD_CONTENT_TYPES.some((x) => x.toLowerCase() === contentType.toLowerCase()) ? (
        <ContentMenuListOld
          anchorEl={anchorEl}
          open={open}
          handleClose={() => {
            setAnchorEl(null);
          }}
          contentType={contentType}
          listItemDetails={selectedItem}
          category='content'
          subCategory={contentType}
          deleteContent={deleteContent}
          duplicate={duplicate}
          preview={preview}
          unPublish={unPublish}
          view={view}
          edit={edit}
          fetchContentDetails={fetchContentDetails}
          sitelist={[]}
          duplicateToSite={undefined}
        />
      ) : (
        <DynamicContentMenuList
          anchorEl={anchorEl}
          open={open}
          handleClose={() => {
            setAnchorEl(null);
          }}
          contentType={contentType}
          listItemDetails={selectedItem}
          category='content'
          subCategory={contentType}
          deleteContent={deleteContent}
          duplicate={duplicate}
          preview={preview}
          unPublish={unPublish}
          view={view}
          edit={edit}
          fetchContentDetails={fetchContentDetails}
          sitelist={[]}
          duplicateToSite={undefined}
        />
      )}
      {contentType === "sitepage" && (
        <PageMenu
          listItemDetails={selectedItem}
          open={open}
          anchorEl={anchorEl}
          handleMenuClose={() => {
            setAnchorEl(null);
          }}
          category='Page'
          subCategory={SUB_CATEGORY_PAGE}
        />
      )}
    </Box>
  );
};

export default memo(ContentTypeMenuList);
