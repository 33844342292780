import { Box, Grid, Typography } from "@mui/material";
import { FileUploadicon } from "@platformx/shared/static-assets";
import { useTranslation } from "react-i18next";
import { getAcceptedFileFormats } from "../../constants/CommonConstants";
import { useAssetUpload } from "./AssetUpload.style";

// component where file upload button is present
const BrowseToUpload = ({ workflowTaskTitle, handleFileChange, assetType }) => {
  const { t } = useTranslation();
  const classes = useAssetUpload();
  return (
    <Grid item xs={12} sm={12} md={12} em={6}>
      <Box className={classes.uploadParent}>
        <Box className={classes.fileuploadbox}>
          <img src={FileUploadicon} alt='icon' />
          <Typography className={classes.typography} variant='h4regular'>
            {t("choose_your_file")}
          </Typography>

          <label
            htmlFor='fileupload'
            className={`${classes.btnmodal} ${workflowTaskTitle.current ? classes.cursorNotAllowed : classes.cursorPointer}`}>
            {t("browse_to_upload")}
          </label>
          <input
            type='file'
            id='fileupload'
            name='fileupload'
            accept={getAcceptedFileFormats(assetType)}
            multiple
            hidden
            disabled={!!workflowTaskTitle.current}
            onChange={handleFileChange}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default BrowseToUpload;
