import Grid from "@mui/material/Grid"; // Grid version 1
import { Box, useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

const DashboardCardLoader = () => {
  const theme = useTheme();
  return (
    <Grid container sx={{ marginLeft: "5px", marginTop: 0 }}>
      {Array.from(Array(3)).map((_, index) => (
        <Grid sx={{ marginLeft: "0px" }} xs={2} sm={4} md={4} lg={4} key={index}>
          <Box>
            <Skeleton
              variant='rectangular'
              animation='wave'
              height={250}
              width={"95%"}
              sx={{ backgroundColor: theme.palette.customColor.INPUT }}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default DashboardCardLoader;
