import { Theme } from "@mui/material";
import StepConnector from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { makeStyles, withStyles } from "@mui/styles";

export const StyledConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 25,
    left: "calc(-50% + 45px)",
    right: "calc(50% + 45px)",
  },
  active: { "& $line": { borderColor: theme?.palette?.customColor?.TERTIARY } },
  completed: { "& $line": { borderColor: theme?.palette?.notification?.SUCCESS?.ACTIVE_COLOR } },
  disabled: { "& $line": { borderColor: theme.palette.customColor?.LINE } },
  line: {
    borderTopWidth: 4,
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0px",
    },
  },
  vertical: {
    marginLeft: "0",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "16%",
    },
  },
  lineVertical: {
    borderLeftWidth: 2,
  },
}))(StepConnector);

export const StyledStepIcon = styled("div")<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
  alignItems: "center",
  color: theme.palette?.customColor?.PLACEHOLDER,
  backgroundColor: theme.palette?.customColor?.INPUT,
  display: "flex",
  height: 22,
  padding: "30px 20px",
  margin: "0px 20px",
  borderRadius: "50%",
  ...(ownerState.active && {
    color: theme.palette?.customColor?.LIGHT,
    backgroundColor: theme.palette?.customColor?.SECONDARY,
    img: {
      filter: "brightness(0) invert(1)",
    },
  }),
  ...(!ownerState.active &&
    !ownerState.completed && {
      img: {
        filter: "brightness(0) invert(65%)",
      },
    }),
  ...(ownerState.completed && {
    backgroundColor: "transparent",
    margin: "0",
  }),
}));

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "25px",
  },
  labelContainer: {
    flexDirection: "column",
    [theme.breakpoints.down(theme?.breakpoints?.values?.sm)]: {
      alignItems: "flex-start",
      flexDirection: "row",
    },
    "& .Platform-x-StepLabel-iconContainer": {
      padding: "0px",
    },
  },
}));
