/* eslint-disable template-tag-spacing */
import { gql } from "@apollo/client";

export const userGroupsMutations = {
  CREATE_USER_GROUP: gql`
    mutation ($input: community_UserGroupInput) {
      community_createUserGroup(input: $input) {
        name
        message
      }
    }
  `,
  UPDATE_USER_GROUP: gql`
    mutation ($input: community_UpdateUserGroupInput) {
      community_updateUserGroup(input: $input) {
        message
      }
    }
  `,
  DELETE_USER_GROUP: gql`
    mutation ($id: String!) {
      community_deleteUserGroup(id: $id) {
        message
      }
    }
  `,
};
