import { Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { RootState } from "@platformx/authoring-state";
import { SearchIconSvg } from "@platformx/shared/static-assets";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getIconWithTitleForSearch } from "../../../../../utils/helper";

const TextFieldBox = styled("div")({
  position: "relative",
  width: "100%",
  "& input": {
    paddingLeft: "40px",
  },
});

const ListContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  margin: "5px 10px",
});
export default function AutoCompleteSearch({
  handleSearchKeyword,
  handleSearch,
  loading,
  autoCompleteData,
}) {
  const { t } = useTranslation();
  const [, setKeyword] = useState("");
  const renderGroup = (params: any) => {
    const { children, group } = params;
    return (
      <Grid container alignItems='center' paddingLeft={2}>
        <Grid item>
          <Typography variant='subtitle1'>{group}</Typography>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    );
  };

  const handleKeyDown = (e) => {
    const { value = "" } = e?.target;
    if (value !== null && ("" + value).length > 0) {
      handleSearchKeyword(value);
      setKeyword(value);
      handleSearch({
        tags: [],
        title: "",
        author: "",
        fromDate: "",
        toDate: "",
      });
    }
  };
  const { routes = [] } = useSelector((state: RootState) => state?.routes);
  const contentTypeList = routes?.filter((route) => route?.hasSearch);
  const placeHolderText = `${t("searchPlaceholder")}`;
  return (
    <Autocomplete
      fullWidth
      freeSolo
      sx={{
        "& .Platform-x-OutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
      options={autoCompleteData || []}
      groupBy={(option: any) => option.ContentType || ""}
      getOptionLabel={(option) => option?.Title || ""}
      isOptionEqualToValue={(option, value) => option?.Title === value?.Title}
      onInputChange={(event, newInputValue) => {
        handleSearchKeyword(newInputValue);
      }}
      renderInput={(params) => (
        <TextFieldBox>
          <Box className='searchIconPos'>
            <IconButton type='button' sx={{ p: "10px" }}>
              <img src={SearchIconSvg} alt='Search Icon' />
            </IconButton>
          </Box>
          <TextField
            {...params}
            placeholder={placeHolderText}
            autoFocus={true}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
            }}
            // onChange={(e) => handleSuggestions(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleKeyDown(e);
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </TextFieldBox>
      )}
      renderGroup={renderGroup}
      renderOption={(props, option: any) => {
        const { icon } = getIconWithTitleForSearch(contentTypeList, option.ContentType);
        return (
          <div>
            <li {...props}>
              <ListContainer>
                {icon && <img src={icon} alt='icon' height={20} width={20} />}
                <Typography variant='h5regular' sx={{ marginLeft: "8px" }}>
                  {option.Title}
                </Typography>
              </ListContainer>
            </li>
          </div>
        );
      }}
    />
  );
}
