const availableFunctions = {
  onEventSubTypeChange: (obj: {
    value: { label: string };
    groupedFields: {
      index: string;
      fields: { name: string; is_show: boolean; validations: [] }[];
    }[];
    setGroupedFields: (fields: any) => void;
  }) => {
    //Challenge : Implement the logic to show/hide fields based on the selected event type
    const { value, groupedFields, setGroupedFields } = obj;
    const fields: { name: string; is_show: boolean; validations: [] }[] = [
      ...groupedFields[0]?.fields,
    ];
    let updatedFields: { name: string; is_show: boolean }[] = [];
    if (value?.label === "Pre-Recorded Webinar") {
      updatedFields = fields.map((field) => {
        if (field.name === "video") {
          return {
            ...field,
            is_show: true,
            validations: [
              ...field.validations,
              {
                type: "required",
                message: "Field is required",
              },
            ],
          };
        } else if (
          field.name === "hostemail" ||
          field.name === "location" ||
          field.name === "capacity" ||
          field.name === "buffer" ||
          field.name === "start_date_time" ||
          field.name === "end_date_time" ||
          field.name === "registration_type" ||
          field.name === "registration_start_date_time" ||
          field.name === "registration_end_date_time"
        ) {
          return { ...field, is_show: false };
        } else {
          return field;
        }
      });
    } else if (value?.label === "Live Webinar") {
      updatedFields = fields.map((field) => {
        if (field.name === "video" || field.name === "location") {
          return { ...field, is_show: false };
        } else if (
          field.name === "hostemail" ||
          field.name === "capacity" ||
          field.name === "buffer" ||
          field.name === "start_date_time" ||
          field.name === "end_date_time" ||
          field.name === "registration_type" ||
          field.name === "registration_start_date_time" ||
          field.name === "registration_end_date_time"
        ) {
          return {
            ...field,
            is_show: true,
            validations: [
              ...field.validations,
              {
                type: "required",
                message: "Field is required",
              },
            ],
          };
        } else {
          return field;
        }
      });
    } else {
      updatedFields = fields.map((field) => {
        if (
          field.name === "capacity" ||
          field.name === "buffer" ||
          field.name === "start_date_time" ||
          field.name === "end_date_time" ||
          field.name === "registration_type" ||
          field.name === "registration_start_date_time" ||
          field.name === "registration_end_date_time"
        ) {
          return {
            ...field,
            is_show: true,
            validations: [
              {
                type: "required",
                message: "Field is required",
              },
              ...field.validations,
            ],
          };
        } else if (field.name === "location") {
          return { ...field, is_show: true };
        } else if (field.name === "video" || field.name === "hostemail") {
          return { ...field, is_show: false };
        } else {
          return field;
        }
      });
    }
    setGroupedFields(
      groupedFields.map((group) => {
        return group.index === "01" ? { ...group, fields: updatedFields } : group;
      }),
    );
  },
  someOtherFunction: () => {},
};

// Function to execute based on the function name
export const executeFunctionByName = (functionName, obj) => {
  if (availableFunctions[functionName]) {
    availableFunctions[functionName](obj);
  } else {
    console.error("Function not found!");
  }
};
