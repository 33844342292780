import { KeyboardArrowRight } from "@mui/icons-material";
import { Box, MenuItem, MenuList, Tooltip, Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAccess } from "@platformx/utilities";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom-v6";
import useMenuStyle from "./MenuList.styles";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={1} square={false} {...props} />
))();

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowRight />} {...props} />
))(() => ({}));

export default function MenuItems({ Title, MenuName, open, handleMenuclose, handleMenuAction }) {
  const { t } = useTranslation();
  const { canAccessContent } = useAccess();
  const navigate = useNavigate();
  const removeSearchLocalStorage = () => {
    localStorage.removeItem("contentType");
    localStorage.removeItem("searchKeyword");
    localStorage.removeItem("searchTags");
    localStorage.removeItem("author");
  };

  const [expanded, setExpanded] = useState<string | true>("1");

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : true);
  };
  const Menu = MenuName.filter((obj, index) => {
    return index === MenuName.findIndex((o) => obj.id === o.id);
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const ifTab = useMediaQuery(theme.breakpoints.up("sm"));
  const getBreakPoint = () => {
    if (open && ifTab) return true;
    if (!open && !ifTab) return true;
    if (!open && ifTab) return false;
  };
  const classes = useMenuStyle();

  const handleImageError = (e, fallback) => {
    if (fallback) e.target.src = fallback;
  };

  return (
    <Box className={`${classes.menuAccordianWp} openSideBarBottomSp`}>
      {getBreakPoint() ? (
        <Accordion expanded={expanded === "1"} onChange={handleChange("1")} className='accordianWp'>
          <AccordionSummary className='accordianSummaryWp'>
            <Typography variant='p4regular'>{t(Title)}</Typography>
          </AccordionSummary>
          <MenuList className='menulinks'>
            {Menu.map((val, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    removeSearchLocalStorage();
                    navigate(val.url);
                    if (isMobile) {
                      handleMenuclose(false);
                    }
                    handleMenuAction(val.id);
                  }}
                  data-testid='click-menu'
                  className={window.location.pathname.includes(val.url) ? "active" : ""}
                  disabled={!canAccessContent(val.category, val.subCategory?.toLowerCase())}>
                  <Tooltip describeChild placement='top-start' title={val?.title}>
                    <Box className='imgMenuText'>
                      <Box className='menuIcon'>
                        <img
                          src={val?.menuIcon || val?.icon}
                          alt=''
                          onError={(e) => handleImageError(e, val?.fallbackIcon)}
                        />
                      </Box>

                      <Typography variant='h6regular' className='textellipsis'>
                        {val?.title}
                      </Typography>
                    </Box>
                  </Tooltip>
                </MenuItem>
              );
            })}
          </MenuList>
        </Accordion>
      ) : (
        <MenuList className='menulinks othermenu'>
          {Menu.map((val, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  removeSearchLocalStorage();
                  navigate(val.url);
                  if (isMobile) {
                    handleMenuclose(false);
                  }
                  handleMenuAction(val.id);
                }}
                data-testid='menu-action'
                disabled={!canAccessContent(val.category, val.subCategory?.toLowerCase())}
                className={window.location.pathname.includes(val.url) ? "active" : ""}>
                <Tooltip describeChild placement='top-start' title={val?.title}>
                  <Box className='imgMenuText'>
                    <Box className='menuIcon padding'>
                      <img src={val.icon} alt='' />
                    </Box>

                    <Typography variant='h6regular' className='textellipsis'>
                      {val?.title}
                    </Typography>
                  </Box>
                </Tooltip>
              </MenuItem>
            );
          })}
        </MenuList>
      )}
    </Box>
  );
}
