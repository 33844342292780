import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useFilterDropdownStyles = makeStyles((theme: Theme) => ({
  FilterMenuWrapperItem: {
    "&.filterMenu": {
      "& .filterMenuItem": {
        boxShadow: "0px 25px 30px 0px rgba(0, 0, 0, 0.05)",
        minWidth: "160px",
        border: `1px solid ${theme.palette.customColor.LINE}`,
        marginTop: "5px",
        width: "207px",
        "& .listViewContainer": {
          maxHeight: "190px",
          display: "flex",
          flexDirection: "row",
          overflowY: "hidden",
          overflowX: "hidden",
          maxWidth: "207px",
          minWidth: "207px",
          borderRadius: "4px",
          "&:hover": {
            overflowY: "auto",
          },
          "& .listView": {
            padding: "5px 20px",
            margin: "0px !important",
            "&:hover": {
              backgroundColor: theme.palette.customColor.BGWEAK,
            },
            "&:hover p, &.active p": {
              color: theme.palette.customColor.PRIMARY,
              fontWeight: 600,
            },
          },
        },
      },
    },
  },
  filterdropdowntypo: {
    WebkitLineClamp: 1,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    height: "24px",
    wordBreak: "break-all",
    minWidth: "160px",
    margin: "5px 0 !important",
  },
  RadioGroupbox: {
    textTransform: "capitalize",
  },
}));
