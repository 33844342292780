import ContentSection from "./ContentSection/ContentSection";
import HeroSection from "./HeroSection/HeroSection";

const PlayerDetail = ({ content, analytics, authoringHelper, secondaryArgs = {} }: any) => {
  return (
    <>
      <HeroSection
        content={content}
        analytics={analytics}
        authoringHelper={authoringHelper}
        secondaryArgs={secondaryArgs}
      />
      <ContentSection
        content={content}
        analytics={analytics}
        authoringHelper={authoringHelper}
        secondaryArgs={secondaryArgs}
      />
    </>
  );
};

export default PlayerDetail;
