import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { PlatFormXDateTimeFormat, handleHtmlTags } from "@platformx/utilities";
import { useStyles } from "./EmbedDesktopTabCard.styles";

interface ContentProp {
  content: Content;
}

interface Content {
  Title?: string;
  Description?: string;
  Thumbnail?: string;
  creationDate?: string;
  Author?: string;
  LandingPage?: string;
  ContentURL?: string;
  colorCode?: string;
}
const inlineCss = `
.doticon .Platform-x-SvgIcon-root{
  font-size: 6px;
}
.doticon svg.MuiSvgIcon-root {
font-size: 6px;
}
.embededtitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;  
   overflow: hidden;
  word-wrap: break-word;
}
.embededdesc {
  display: -webkit-box;
  -webkit-box-orient: vertical;  
   overflow: hidden;
  word-wrap: break-word;
}
`;
// .card-content-area{height :100% ;}
// .cardmediaimg{height :100% ;}
// .card-container{height :100% ;}
const DesktopTabCard = ({ content }: ContentProp) => {
  const finalDesc = handleHtmlTags(content?.Description) || "";
  const classes = useStyles();

  return (
    <Card
      className={classes.embedDesktopTabCardWp}
      sx={{
        margin: { xs: "8px 0px 8px 0", sm: "0" },
      }}
      onClick={() => window.open(content?.LandingPage, "_blank")}>
      <Grid container>
        <Grid item xs={12} className='card-container'>
          <style>{inlineCss}</style>
          {content?.colorCode !== undefined && content?.colorCode !== "" ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                background: content?.colorCode,
              }}
            />
          ) : (
            <CardMedia
              component='img'
              image={content?.Thumbnail}
              sx={{
                overflow: "hidden",
                height: "100%",
              }}
              alt='Image'
              className='cardmediaimg'
            />
          )}
        </Grid>
        <Grid item xs={12} className='card-content-area'>
          <CardContent className='contentBox'>
            <Box className='TopContentBox'>
              <Typography
                gutterBottom
                variant='h3bold'
                className='embededtitle'
                sx={{
                  WebkitLineClamp: { xs: "2" },
                }}>
                {content?.Title}
              </Typography>
              <Typography
                variant='p3regular'
                sx={{
                  WebkitLineClamp: { xs: "2" },
                }}
                className='embededdesc'>
                {finalDesc}
              </Typography>
            </Box>
            <CardActions className='cardActionWp'>
              <Typography variant='p4semibold'>{content?.Author}</Typography>
              <Typography
                variant='p4regular'
                sx={{
                  display: { xs: "block", sm: "none" },
                  marginLeft: "8px",
                }}
                className='doticon'>
                <FiberManualRecordIcon />
              </Typography>
              <Typography variant='p4regular'>
                {PlatFormXDateTimeFormat(content?.creationDate)}
              </Typography>
            </CardActions>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DesktopTabCard;
