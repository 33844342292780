import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FormControl, IconButton, InputAdornment, TextField } from "@mui/material";
import { CustomSearch } from "@platformx/shared/static-assets";
import { useTranslation } from "react-i18next";
import { useAssetFilter } from "./AssetFilter.style";

export default function AssetFilter({ searchQuery, setSearchQuery }) {
  const classes = useAssetFilter();
  const { t } = useTranslation();

  const handleOnChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const resetSearch = () => {
    setSearchQuery("");
  };

  return (
    <FormControl className={classes.contentcontroler}>
      <TextField
        autoComplete='off'
        variant='filled'
        placeholder={t("search")}
        value={searchQuery}
        onChange={handleOnChange}
        InputProps={{
          endAdornment: searchQuery && (
            <IconButton onClick={resetSearch} data-testid='asset-filter-resetSearch'>
              <CloseRoundedIcon />
            </IconButton>
          ),
          startAdornment: (
            <InputAdornment position='start'>
              <img src={CustomSearch} alt='search' className={classes.searchinputandor} />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
}
