import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { AutoTextArea, CommonBoxWithNumber, TextBox, TitleSubTitle } from "@platformx/utilities";
import { XImageRender } from "@platformx/x-image-render";
import { memo } from "react";
import AutoCompleteText from "./AutoCompleteText";
import { useCustomStyle } from "./SocialShare.style";

const SocialShare = ({
  titleSubTitles,
  state,
  handleChange,
  handleOnBlur,
  handleTagChange,
  handleImageChange,
}) => {
  const {
    sectionNumber,
    sectionTitle,
    sectionSubTitle,
    imageTitle,
    imageSubTitle,
    mainTitle,
    mainSubTitle,
    titlePlaceHolder,
    descriptionTitle,
    descriptionSubTitle,
    descriptionPlaceHolder,
    tagsTitle,
    tagsSubTitle,
    titleMaxCharLength,
    descriptionMaxCharLength,
    titleFieldName,
    descriptionFieldName,
    imageFieldName,
    tagsFieldName,
  } = titleSubTitles;

  const classes = useCustomStyle();
  return (
    <Box id='socialShare' className={classes.mainStyleWrapper}>
      <CommonBoxWithNumber
        number={sectionNumber}
        title={sectionTitle}
        titleVarient='h5bold'
        subTitleVarient='p4regular'
        subTitle={sectionSubTitle}>
        <Grid container>
          <Grid item xs={12} sm={5} md={5} className='leftFiled'>
            <TitleSubTitle
              title={imageTitle}
              subTitle={imageSubTitle}
              titleVariant='h6medium'
              subTitleVariant='p4regular'
            />
          </Grid>
          <Grid item xs={12} sm={7} md={7} className='textFiled'>
            <XImageRender
              callBack={handleImageChange}
              editData={{
                relativeUrl: state?.[imageFieldName],
              }}
              isCrop={false}
            />
          </Grid>

          <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiled'>
            <TitleSubTitle
              title={mainTitle}
              subTitle={mainSubTitle}
              titleVariant='h6medium'
              subTitleVariant='p4regular'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} className='textFiled'>
            <TextBox
              name={titleFieldName}
              placeHolder={titlePlaceHolder}
              handleOnBlur={handleOnBlur}
              handleChange={handleChange}
              maxCharLength={titleMaxCharLength}
              state={state?.[titleFieldName]?.substring(0, titleMaxCharLength)}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={5} className='leftFiled'>
            <TitleSubTitle
              title={descriptionTitle}
              subTitle={descriptionSubTitle}
              titleVariant='h6medium'
              subTitleVariant='p4regular'
            />
          </Grid>
          <Grid item xs={12} sm={7} md={7} className='textFiled'>
            <AutoTextArea
              name={descriptionFieldName}
              placeHolder={descriptionPlaceHolder}
              handleOnBlur={handleOnBlur}
              handleChange={handleChange}
              maxCharLength={descriptionMaxCharLength}
              state={state?.[descriptionFieldName]?.slice(0, descriptionMaxCharLength)}
            />
          </Grid>

          <Grid item xs={12} sm={5} md={5} className='leftFiledLast'>
            <TitleSubTitle
              title={tagsTitle}
              subTitle={tagsSubTitle}
              titleVariant='h6medium'
              subTitleVariant='p4regular'
            />
          </Grid>

          <Grid item xs={12} sm={7} md={7} className='textFiledLast' data-testid='mediatags'>
            <Box mr={2}>
              <AutoCompleteText state={state?.[tagsFieldName]} handleChange={handleTagChange} />
            </Box>
          </Grid>
        </Grid>
      </CommonBoxWithNumber>
    </Box>
  );
};
export default memo(SocialShare);
