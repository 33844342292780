import { useMediaQuery, useTheme } from "@mui/material";
import {
  CardOptionCopyUrlIcon,
  CardOptionDeleteIcon,
  CardOptionEditIcon,
  CardOptionUnPublishIcon,
  CardOptionViewIcon,
} from "@platformx/shared/static-assets";
import {
  CommonPlateformXDialog,
  DuplicateContentPopup,
  PlateformXSocialDialog,
  ShowToastError,
  ShowToastSuccess,
  XMenu,
  XMenuItem,
  convertToLowerCase,
  getSubDomain,
} from "@platformx/utilities";
import { WorkflowStepper } from "@platformx/workflow-management";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getEmbedTempData, getSocialShareData } from "../../utils/Helper";
import { MenuActions } from "../CourseMenu/CardMenu.types";
import EmbedDialog from "../EmbedDialog/EmbedDialog";

export const DynamicContentMenuList = ({
  anchorEl,
  open,
  handleClose,
  contentType,
  listItemDetails,
  category,
  subCategory,
  deleteContent,
  duplicate,
  preview,
  unPublish,
  view,
  edit,
  fetchContentDetails,
  // sitelist,
}: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [menuActions, setMenuActions] = useState({
    duplicate: false,
    delete: false,
    embed: false,
    socialShare: false,
    unpublish: false,
    approvalStatus: false,
    shareWithSites: false,
  });
  const [selectedContent, setSelectedContent] = useState<any>(listItemDetails);
  const [language, setLanguage] = useState<string[]>([]);
  const [embedData, setEmbedData] = useState({});

  useEffect(() => {
    setSelectedContent(listItemDetails);
  }, [listItemDetails]);

  const openSettingsPanel = (path: string) => {
    navigate(`/content/create-${contentType?.toLowerCase()}?path=${path}&open=socialShare`);
  };

  const handleEditContentType = (items: any) => {
    edit(items);
  };

  const handleStartBlog = (path: string) => {
    navigate(`/content/create-blog?path=${path}`);
  };

  // eslint-disable-next-line no-unused-vars
  const createContent = (IsDuplicate = false, title = "") => {
    duplicate(IsDuplicate, title, language, selectedContent);
    onClose();
  };

  const handleCopy = () => {
    const text = `${getSubDomain()}/${i18n.language}/content/${
      contentType.toLowerCase() === "vod" ? "video" : contentType.toLowerCase()
    }${listItemDetails.current_page_url}`;
    if (listItemDetails.current_page_url) {
      navigator.clipboard.writeText(text);
      ShowToastSuccess(t("url_copy_toast"));
    } else {
      ShowToastError(t("api_error_toast"));
    }
  };

  const getDuplicateTitle = () => {
    const timestamp = new Date().getTime();
    const newVal = `${timestamp} - ${selectedContent?.title}`.trim();
    const duplicateContentTitle = newVal.length > 100 ? newVal.slice(0, 100) : newVal;
    return duplicateContentTitle.trim();
  };

  const deleteConfirmButtonHandle = () => {
    deleteContent(selectedContent);
    onClose();
  };

  const unPublishConfirmButtonHandle = () => {
    unPublish(selectedContent);
    onClose();
  };
  const handlePublishedPageView = () => {
    preview(listItemDetails);
  };
  const onClose = () => {
    setMenuActions({
      duplicate: false,
      delete: false,
      embed: false,
      socialShare: false,
      unpublish: false,
      approvalStatus: false,
      shareWithSites: false,
    });
  };

  const onHandleMenuActions = (action: any) => {
    switch (action) {
      case MenuActions.EDIT:
        handleEditContentType(listItemDetails);
        break;
      case MenuActions.DELETE:
        setMenuActions({ ...menuActions, delete: true });
        break;
      case MenuActions.DUPLICATE:
        setMenuActions({ ...menuActions, duplicate: true });
        break;
      case MenuActions.SOCIAL_SHARE:
        setMenuActions({ ...menuActions, socialShare: true });
        break;
      case MenuActions.EMBED:
        setMenuActions({ ...menuActions, embed: true });
        getSelectedCardDetails();
        break;
      case MenuActions.SHARE_WITH_SITES:
        setMenuActions({ ...menuActions, shareWithSites: true });
        getSelectedCardDetails();
        break;
      case MenuActions.UNPUBLISH:
        setMenuActions({ ...menuActions, unpublish: true });
        break;
      case MenuActions.VIEW_PREVIEW:
        handlePublishedPageView();
        break;
      case MenuActions.COPY_URL:
        handleCopy();
        break;
      case MenuActions.SETTINGS:
        openSettingsPanel(listItemDetails.page);
        break;
      case MenuActions.SELECTED_DATA:
        setSelectedContent(listItemDetails);
        break;
      case MenuActions.APPROVAL_STATUS:
        setMenuActions({ ...menuActions, approvalStatus: true });
        break;
      case MenuActions.PREVIEW:
        preview(listItemDetails);
        break;
      case MenuActions.VIEW:
        view(listItemDetails);
        break;
      case MenuActions.BLOG:
        handleStartBlog(listItemDetails?.page);
        break;

      default:
        break; // Add default condition here
    }
    handleClose();
  };

  const getSelectedCardDetails = async () => {
    const cardDetails = await fetchContentDetails(listItemDetails);
    setEmbedData(getEmbedTempData(cardDetails));
  };
  const theme = useTheme();
  const tabView = useMediaQuery(theme.breakpoints.down("em"));

  return (
    <div>
      {menuActions.socialShare && (
        <PlateformXSocialDialog
          isDialogOpen={menuActions.socialShare}
          closeButtonHandle={onClose}
          setSelectedItem={getSocialShareData(selectedContent)}
          contentType={contentType}
        />
      )}
      {menuActions.embed && (
        <EmbedDialog
          isDialogOpen={menuActions.embed}
          closeEmbedButtonHandle={onClose}
          setSelectedItem={embedData}
          contentType={convertToLowerCase(contentType)}
        />
      )}
      {menuActions.delete && (
        <PlateformXSocialDialog
          isDialogOpen={menuActions.delete}
          title={t("delete_title")}
          subTitle={`${t("delete_confirm")} ${t(contentType?.toLowerCase())}?. ${t(
            "process_undone",
          )}`}
          closeButtonText={t("no_keep_it")}
          confirmButtonText={t("yes_delete_it")}
          closeButtonHandle={onClose}
          confirmButtonHandle={deleteConfirmButtonHandle}
        />
      )}
      {menuActions.duplicate && (
        <DuplicateContentPopup
          titledata={`${getDuplicateTitle()}`}
          isDialogOpen={menuActions.duplicate}
          closeButtonHandle={onClose}
          doneButtonHandle={createContent}
          contentType={t(contentType?.toLowerCase())}
          language={language}
          setLanguage={setLanguage}
        />
      )}
      {menuActions.unpublish && (
        // <PlateformXDialog
        //   isDialogOpen={menuActions.unpublish}
        //   title={`${t("unpublish")} ${t(contentType?.toLowerCase())}`}
        //   subTitle={`${t("unpublish_confirm")} ${t(contentType?.toLowerCase())}`}
        //   closeButtonText={t("no")}
        //   confirmButtonText={t("yes")}
        //   closeButtonHandle={onClose}
        //   confirmButtonHandle={unPublishConfirmButtonHandle}
        // />
        <CommonPlateformXDialog
          isDialogOpen={menuActions.unpublish}
          title={`${t("unpublish")} ${t(contentType?.toLowerCase())}`}
          subTitle={`${t("unpublish_confirm")} ${t(contentType?.toLowerCase())}?`}
          closeButtonText={t("no")}
          closeButtonHandle={onClose}
          confirmButtonText={t("yes")}
          confirmButtonHandle={unPublishConfirmButtonHandle}
          modalType='delete'
        />
      )}
      {menuActions.approvalStatus && (
        <WorkflowStepper
          open={menuActions.approvalStatus}
          setOpen={onClose}
          path={selectedContent?.page}
          contentType={contentType}
        />
      )}
      <XMenu anchorEl={anchorEl} handleMenuClose={handleClose} open={open}>
        {contentType === "event" &&
          (listItemDetails.page_state === "published" ||
            (listItemDetails.page_state === "draft" && listItemDetails?.lastPublishedDate)) && (
            <XMenuItem
              category={category}
              subCategory={subCategory}
              action='view'
              menuCallBack={onHandleMenuActions}
              menuAction='blog'
              icon={CardOptionEditIcon}
              label={"write_a_blog"}
            />
          )}
        {(listItemDetails.page_state === "published" ||
          (listItemDetails.page_state === "draft" && listItemDetails.lastPublishedDate)) && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='view'
            menuCallBack={onHandleMenuActions}
            menuAction='view'
            icon={CardOptionViewIcon}
            label={"view"}
          />
        )}

        {(listItemDetails.page_state === "draft" ||
          listItemDetails.page_state === "unpublished") && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='view'
            menuCallBack={onHandleMenuActions}
            menuAction='preview'
            icon={CardOptionViewIcon}
            label={"preview"}
          />
        )}

        {tabView && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='update'
            menuCallBack={onHandleMenuActions}
            menuAction='edit'
            icon={CardOptionEditIcon}
            label={"edit"}
          />
        )}
        {/* <XMenuItem
          category={category}
          subCategory={subCategory}
          action='create'
          menuCallBack={onHandleMenuActions}
          menuAction='duplicate'
          icon={CardOptionDuplicateIcon}
          label={"duplicate"}
        /> */}

        {(listItemDetails.page_state === "published" ||
          (listItemDetails.page_state === "draft" && listItemDetails?.lastPublishedDate)) && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='view'
            menuCallBack={onHandleMenuActions}
            menuAction='copy_url'
            icon={CardOptionCopyUrlIcon}
            label={"copy_url"}
          />
        )}

        {listItemDetails.page_state === "published" && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='unpublish'
            menuCallBack={onHandleMenuActions}
            menuAction='unpublish'
            icon={CardOptionUnPublishIcon}
            label={"unpublish"}
          />
        )}

        {tabView && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='delete'
            menuCallBack={onHandleMenuActions}
            menuAction='delete'
            icon={CardOptionDeleteIcon}
            label={"delete"}
          />
        )}
        {/* {(listItemDetails.page_state === "published" ||
          (listItemDetails.page_state === "draft" && listItemDetails?.lastPublishedDate)) && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='view'
            menuCallBack={onHandleMenuActions}
            menuAction='social_share'
            icon={CardOptionShareIcon}
            label={"social_share"}
          />
        )} */}
        {/* {duplicateToSite &&
          (listItemDetails.page_state === "published" ||
            (listItemDetails.page_state === "draft" && listItemDetails?.lastPublishedDate)) && (
            <XMenuItem
              category={category}
              subCategory={subCategory}
              action='sharetosite'
              menuCallBack={onHandleMenuActions}
              menuAction='share_with_sites'
              icon={CardOptionShareIcon}
              label={"share_with_sites"}
            />
          )} */}

        {/* hide vod */}
        {/* {(listItemDetails.page_state === "published" ||
          (listItemDetails.page_state === "draft" && listItemDetails?.lastPublishedDate)) && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='view'
            menuCallBack={onHandleMenuActions}
            menuAction='embed'
            icon={CardOptionImbedIcon}
            label={"embed"}
          />
        )}
        <XMenuItem
          category={category}
          subCategory={subCategory}
          action='view'
          menuCallBack={onHandleMenuActions}
          menuAction='approval_status'
          icon={CardOptionApprovalStatusIcon}
          label={"approval_status"}
        /> */}
      </XMenu>
    </div>
  );
};
