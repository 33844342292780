import { Box, Button } from "@mui/material";
import { ShowToastError } from "@platformx/utilities";
import React, { useEffect, useState } from "react";
import { fetchRequiredMessage, fetchSchemaWithData } from "../../../utils/helperFns";
import ContentGalleryModal from "../ContentGallery/ContentGalleryModal";
import { DraggableItems } from "../DragableItem/DragableItems";
import HiddenTextBox from "../HiddenTextBox/HiddenTextBox";

const AddCollection = ({ state, setState, field, error, register, clearErrors }) => {
  const [isOpenContent, setIsOpenContent] = useState(false);

  interface PopulatedItem {
    title: string;
    name: string;
    content_type: string;
    content_path: string;
    type: string;
  }

  const [populatedItems, setPopulatedItems] = useState<PopulatedItem[]>([]);
  const handleGalleryClose = () => {
    setIsOpenContent(false);
  };

  const handleSelectedContent = (items, name) => {
    const newArray = items?.slots?.map((val) => {
      //  return val;
      return {
        title: val.Title,
        content_path: val.CurrentPageURL,
        name: val.Id,
        content_type: val.ContentType,
        type: "collection",
      };
    });
    setState({
      ...state,
      [name]: newArray,
    });
  };

  //GetSchemaData API
  const getSchema = async (Items: any) => {
    try {
      const promises = Items?.map((item) => {
        return fetchSchemaWithData(item.content_type, item.name, item.type);
      });
      const outputs: any = await Promise.allSettled(promises);
      const tempArray: PopulatedItem[] = [];
      outputs?.forEach((output) => {
        if (output?.status === "rejected") {
          console.error("Error in fetching row items data", output);
        } else if (output?.status === "fulfilled") {
          const schema = output?.value?.authoring_getSchemaContent;
          if (schema) {
            tempArray.push({
              title: schema?.title,
              name: schema?.name,
              content_type: schema?.tag_name,
              content_path: schema?.current_page_url,
              type: "collection",
            });
          }
        }
      });
      if (tempArray.length === 0 && Items.length > 0) {
        setPopulatedItems(Items);
      } else {
        setPopulatedItems(tempArray);
      }
    } catch (err: any) {
      ShowToastError("Error in fetching row items data");
    }
  };

  useEffect(() => {
    clearErrors(field?.name);
    if (state?.[field?.name]?.length > 0) {
      getSchema(state?.[field?.name]);
    } else {
      setPopulatedItems([]);
    }
  }, [state?.[field?.name]]);

  return (
    <Box id={`${field?.name}_id`}>
      <Box
        sx={{
          ".draggable-category": {
            paddingRight: "0",
          },
        }}>
        <DraggableItems
          state={state}
          setState={setState}
          name={field?.name}
          populatedItems={populatedItems}
        />
      </Box>
      <Button variant='primaryButton' onClick={() => setIsOpenContent(true)}>
        {field?.placeholder}
      </Button>
      {isOpenContent ? (
        <ContentGalleryModal
          isDialogOpen={isOpenContent}
          onClose={handleGalleryClose}
          handleSelectedContent={handleSelectedContent}
          selectedItem={state[field?.name] || []}
          multi_select={field?.multiple}
          single_select={!field?.multiple}
          name={field?.name}
          allowed_content={field?.allowed_content}
          allowed_collection={field?.allowed_collection}
        />
      ) : null}
      <HiddenTextBox
        error={error}
        value={state[field?.name]?.length || ""}
        register={register}
        name={field?.name}
        required={fetchRequiredMessage(field?.validations, state?.[field?.name])}
      />
    </Box>
  );
};

export default React.memo(AddCollection);
