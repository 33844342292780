import FormDate from "./FormElements/FormDate/FormDate";
import FormDateAndTimePicker from "./FormElements/FormDateAndTimePicker/FormDateAndTimePicker";
import FormDropdown from "./FormElements/FormDropdown/FormDropdown";
import FormHeading from "./FormElements/FormHeading/FormHeading";
import FormParagraph from "./FormElements/FormParagraph/FormParagraph";
import FormTextArea from "./FormElements/FormTextArea/FormTextArea";
import FormTextBox from "./FormElements/FormTextBox/FormTextBox";

const FormItem = ({
  item,
  register,
  clearErrors,
  errors,
  selectedElement,
  setSelectedElement,
  isRendering = true,
}) => {
  const type = item?.type;
  switch (type) {
    case "text":
      if (item?.variant === "multiline") {
        return (
          <FormTextArea
            item={item}
            register={register}
            clearErrors={clearErrors}
            errors={errors}
            isRendering={isRendering}></FormTextArea>
        );
      } else {
        return (
          <FormTextBox
            item={item}
            register={register}
            clearErrors={clearErrors}
            errors={errors}
            isRendering={isRendering}></FormTextBox>
        );
      }
    case "dropdown":
      return (
        <FormDropdown
          item={item}
          register={register}
          clearErrors={clearErrors}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
          errors={errors}
          isRendering={isRendering}></FormDropdown>
      );
    case "datetime":
      return (
        <FormDateAndTimePicker
          item={item}
          register={register}
          clearErrors={clearErrors}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
          errors={errors}
        />
      );
    case "date":
      return (
        <FormDate
          item={item}
          register={register}
          clearErrors={clearErrors}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
          errors={errors}
        />
      );
    case "heading":
      return <FormHeading item={item} />;
    case "paragraph":
      return <FormParagraph item={item} />;
    default:
      return null;
  }
};

export default FormItem;
