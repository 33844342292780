/* eslint-disable wrap-regex */
import { Box, Container, Grid, Typography } from "@mui/material";
import {
  Analytics,
  AuthoringHelper,
  SecondaryArgs,
  formCroppedUrlString,
} from "@platformx/utilities";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import ImageRender from "../../components/ImageRender";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import prelemTypes from "../../globalStyle";
import { useCustomStyle } from "./CategoryBox.style";

// ts-ignore
const CategoryBox = ({
  content,
  analytics,
  authoringHelper,
  secondaryArgs,
}: //secondaryArgs,
CategoryBoxProp) => {
  const classes = useCustomStyle();
  const globalClasses = prelemTypes();

  const firstRender = useRef(true);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const defaultStructureData = () => {
    let CategoryBoxStructureData;
    const { original_image_relative_path, ext }: any =
      content?.ImageCompound?.ImageCompound_1?.original_image || {};
    const img = formCroppedUrlString(
      secondaryArgs?.gcpUrl,
      secondaryArgs?.bucketName,
      original_image_relative_path,
      ext,
    ).src;

    try {
      CategoryBoxStructureData = {
        "@context": "https://schema.org/",
        "@type": "ContactPage",
        image: img,
        name: content?.title1,
      };
    } catch (e) {
      CategoryBoxStructureData = {};
    }

    return CategoryBoxStructureData;
  };

  const generateStructureData = () => {
    let CategoryBoxStructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData);

    if (firstRender.current === true) {
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        CategoryBoxStructureData = JSON.parse(tempSD);
      } else {
        CategoryBoxStructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      CategoryBoxStructureData = defaultStructureData();
    }
    return CategoryBoxStructureData;
  };

  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = generateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.title1, content?.ImageCompound?.ImageCompound_1?.original_image]);

  usePrelemImpression(analytics, inView, secondaryArgs);

  /* AnalyticsEnabled dependency added as many times we are not getting analytics provider*async call)
  1. we are first checking in publish app if analytics provider is avaiable or not
  2. if its available we are setting AnalyticsEnabled to true
  3. if its not available we are setting false
*/
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.categoryBoxWrapper} ${globalClasses.prelemType1} prelem prelemType1 categoryBoxBg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
        }
        ref={ref}>
        <Typography variant='h2semibold' id='title1'>
          {content?.title1}
        </Typography>
        <Grid container className='boxesWp'>
          <Grid xs={12} md={4} lg={2}>
            <Box className='mainWp'>
              <Box className='imgWrapper'>
                <ImageRender
                  originalImage={content?.ImageCompound?.ImageCompound_1?.original_image}
                  publishedImages={content?.ImageCompound?.ImageCompound_1?.published_images}
                  secondaryArgs={secondaryArgs}
                  imgOrder={{
                    1440: "square",
                    1280: "square",
                    1024: "square",
                    768: "square",
                    600: "square",
                    320: "square",
                  }}
                />
              </Box>
              <Typography variant='p3bold' id='title2'>
                {content?.title2}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4} lg={2}>
            <Box className='mainWp'>
              <Box className='imgWrapper'>
                <ImageRender
                  originalImage={content?.ImageCompound?.ImageCompound_2?.original_image}
                  publishedImages={content?.ImageCompound?.ImageCompound_2?.published_images}
                  secondaryArgs={secondaryArgs}
                  imgOrder={{
                    1440: "square",
                    1280: "square",
                    1024: "square",
                    768: "square",
                    600: "square",
                    320: "square",
                  }}
                />
              </Box>
              <Typography variant='p3bold' id='title3'>
                {content?.title3}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4} lg={2}>
            <Box className='mainWp'>
              <Box className='imgWrapper'>
                <ImageRender
                  originalImage={content?.ImageCompound?.ImageCompound_3?.original_image}
                  publishedImages={content?.ImageCompound?.ImageCompound_3?.published_images}
                  secondaryArgs={secondaryArgs}
                  imgOrder={{
                    1440: "square",
                    1280: "square",
                    1024: "square",
                    768: "square",
                    600: "square",
                    320: "square",
                  }}
                />
              </Box>
              <Typography variant='p3bold' id='title4'>
                {content?.title4}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4} lg={2}>
            <Box className='mainWp'>
              <Box className='imgWrapper'>
                <ImageRender
                  originalImage={content?.ImageCompound?.ImageCompound_4?.original_image}
                  publishedImages={content?.ImageCompound?.ImageCompound_4?.published_images}
                  secondaryArgs={secondaryArgs}
                  imgOrder={{
                    1440: "square",
                    1280: "square",
                    1024: "square",
                    768: "square",
                    600: "square",
                    320: "square",
                  }}
                />
              </Box>
              <Typography variant='p3bold' id='title5'>
                {content?.title5}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4} lg={2}>
            <Box className='mainWp'>
              <Box className='imgWrapper'>
                <ImageRender
                  originalImage={content?.ImageCompound?.ImageCompound_5?.original_image}
                  publishedImages={content?.ImageCompound?.ImageCompound_5?.published_images}
                  secondaryArgs={secondaryArgs}
                  imgOrder={{
                    1440: "square",
                    1280: "square",
                    1024: "square",
                    768: "square",
                    600: "square",
                    320: "square",
                  }}
                />
              </Box>
              <Typography variant='p3bold' id='title6'>
                {content?.title6}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4} lg={2}>
            <Box className='mainWp'>
              <Box className='imgWrapper'>
                <ImageRender
                  originalImage={content?.ImageCompound?.ImageCompound_6?.original_image}
                  publishedImages={content?.ImageCompound?.ImageCompound_6?.published_images}
                  secondaryArgs={secondaryArgs}
                  imgOrder={{
                    1440: "square",
                    1280: "square",
                    1024: "square",
                    768: "square",
                    600: "square",
                    320: "square",
                  }}
                />
              </Box>
              <Typography variant='p3bold' id='title7'>
                {content?.title7}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

interface CategoryBoxProp {
  content: Content;
  analytics: Analytics;
  authoringHelper?: AuthoringHelper;
  secondaryArgs: SecondaryArgs;
}

interface Content {
  title1?: string;
  title2?: string;
  title3?: string;
  title4?: string;
  title5?: string;
  title6?: string;
  title7?: string;
  TagName?: string;
  ImageCompound: {
    ImageCompound_1: {
      published_images: Image[];
      original_image?: object;
    };
    ImageCompound_2: {
      published_images: Image[];
      original_image?: object;
    };
    ImageCompound_3: {
      published_images: Image[];
      original_image?: object;
    };
    ImageCompound_4: {
      published_images: Image[];
      original_image?: object;
    };
    ImageCompound_5: {
      published_images: Image[];
      original_image?: object;
    };
    ImageCompound_6: {
      published_images: Image[];
      original_image?: object;
    };
  };
}
interface Image {
  aspect_ratio: string;
  bucket_path: string;
  folder_path: string;
  visibility: string;
  ext: string;
}

CategoryBox.defaultProps = {
  content: {
    title1: "Health Package",
    title2: "men",
    title3: "woman",
    title4: "children",
    title5: "diabetes",
    title6: "Asthama",
    title7: "Cholestrol",
    TagName: "SiteComponents",
    PrelemContentType: ["Select"],

    ImageCompound: {
      ImageCompound_1: {
        original_image: {
          original_image_relative_path:
            "machine_assets/1690282743892/public/png/ExpertiseShowcase2",
          visibility: "public",
          ext: "png",
          bitStreamId: "",
          auto: true,
          MetaFields: {
            AltText: "CategoryBox",
            Name: "CategoryBox",
            Title: "CategoryBox",
            Description: "This is for CategoryBox",
            Attribution: false,
          },
        },
        published_images: [
          {
            aspect_ratio: "portrait",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-portrait",
          },
          {
            aspect_ratio: "card1",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card1",
          },
          {
            aspect_ratio: "landscape",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-landscape",
          },
          {
            aspect_ratio: "hero",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-hero",
          },
          {
            aspect_ratio: "square",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-square",
          },
          {
            aspect_ratio: "card2",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card2",
          },
        ],
      },
      ImageCompound_2: {
        original_image: {
          original_image_relative_path:
            "machine_assets/1690282794843/public/png/ExpertiseShowcase4",
          visibility: "public",
          ext: "png",
          bitStreamId: "",
          auto: true,
          MetaFields: {
            AltText: "CategoryBox",
            Name: "CategoryBox",
            Title: "CategoryBox",
            Description: "This is for CategoryBox",
            Attribution: false,
          },
        },
        published_images: [
          {
            aspect_ratio: "landscape",
            folder_path: "machine_assets/1690282794843/public/png/ExpertiseShowcase4-landscape",
          },
          {
            aspect_ratio: "square",
            folder_path: "machine_assets/1690282794843/public/png/ExpertiseShowcase4-square",
          },
          {
            aspect_ratio: "portrait",
            folder_path: "machine_assets/1690282794843/public/png/ExpertiseShowcase4-portrait",
          },
          {
            aspect_ratio: "hero",
            folder_path: "machine_assets/1690282794843/public/png/ExpertiseShowcase4-hero",
          },
          {
            aspect_ratio: "card1",
            folder_path: "machine_assets/1690282794843/public/png/ExpertiseShowcase4-card1",
          },
          {
            aspect_ratio: "card2",
            folder_path: "machine_assets/1690282794843/public/png/ExpertiseShowcase4-card2",
          },
        ],
      },
      ImageCompound_3: {
        original_image: {
          original_image_relative_path:
            "machine_assets/1690282743892/public/png/ExpertiseShowcase2",
          visibility: "public",
          ext: "png",
          bitStreamId: "",
          auto: true,
          MetaFields: {
            AltText: "CategoryBox",
            Name: "CategoryBox",
            Title: "CategoryBox",
            Description: "This is for CategoryBox",
            Attribution: false,
          },
        },
        published_images: [
          {
            aspect_ratio: "portrait",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-portrait",
          },
          {
            aspect_ratio: "card1",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card1",
          },
          {
            aspect_ratio: "landscape",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-landscape",
          },
          {
            aspect_ratio: "hero",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-hero",
          },
          {
            aspect_ratio: "square",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-square",
          },
          {
            aspect_ratio: "card2",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card2",
          },
        ],
      },
      ImageCompound_4: {
        original_image: {
          original_image_relative_path:
            "machine_assets/1690282743892/public/png/ExpertiseShowcase2",
          visibility: "public",
          ext: "png",
          bitStreamId: "",
          auto: true,
          MetaFields: {
            AltText: "CategoryBox",
            Name: "CategoryBox",
            Title: "CategoryBox",
            Description: "This is for CategoryBox",
            Attribution: false,
          },
        },
        published_images: [
          {
            aspect_ratio: "portrait",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-portrait",
          },
          {
            aspect_ratio: "card1",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card1",
          },
          {
            aspect_ratio: "landscape",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-landscape",
          },
          {
            aspect_ratio: "hero",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-hero",
          },
          {
            aspect_ratio: "square",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-square",
          },
          {
            aspect_ratio: "card2",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card2",
          },
        ],
      },
      ImageCompound_5: {
        original_image: {
          original_image_relative_path:
            "machine_assets/1690282743892/public/png/ExpertiseShowcase2",
          visibility: "public",
          ext: "png",
          bitStreamId: "",
          auto: true,
          MetaFields: {
            AltText: "CategoryBox",
            Name: "CategoryBox",
            Title: "CategoryBox",
            Description: "This is for CategoryBox",
            Attribution: false,
          },
        },
        published_images: [
          {
            aspect_ratio: "portrait",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-portrait",
          },
          {
            aspect_ratio: "card1",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card1",
          },
          {
            aspect_ratio: "landscape",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-landscape",
          },
          {
            aspect_ratio: "hero",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-hero",
          },
          {
            aspect_ratio: "square",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-square",
          },
          {
            aspect_ratio: "card2",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card2",
          },
        ],
      },
      ImageCompound_6: {
        original_image: {
          original_image_relative_path:
            "machine_assets/1690282743892/public/png/ExpertiseShowcase2",
          visibility: "public",
          ext: "png",
          bitStreamId: "",
          auto: true,
          MetaFields: {
            AltText: "CategoryBox",
            Name: "CategoryBox",
            Title: "CategoryBox",
            Description: "This is for CategoryBox",
            Attribution: false,
          },
        },
        published_images: [
          {
            aspect_ratio: "portrait",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-portrait",
          },
          {
            aspect_ratio: "card1",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card1",
          },
          {
            aspect_ratio: "landscape",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-landscape",
          },
          {
            aspect_ratio: "hero",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-hero",
          },
          {
            aspect_ratio: "square",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-square",
          },
          {
            aspect_ratio: "card2",
            folder_path: "machine_assets/1690282743892/public/png/ExpertiseShowcase2-card2",
          },
        ],
      },
    },
  },
  authoringHelper: {
    innerRef: null,
    sendStructureDataToAuthoringCB: () => {},
    sendDefaultStructureDataForResetToAuthoringCB: () => {},
    openButtonEditWindowInAuthoringCB: () => {},
    selectedButtonNameForEditing: "",
    isEditing: false,
    buttonRef: null,
    buttonContentEditable: false,
    lastSavedStructuredData: "",
    isEditPage: false,
  },

  analytics: {
    isAnalyticsEnabled: true,
    isSeoEnabled: false,
    isAuthoring: false,
    position: 0,
    pageId: 1234,
    prelemId: 2345,
    pageTitle: "Prelem Title",
    pageDesc: "Prelem Description",
    pageTags: "Page Tags1, page tagg2",
    prelemTags: "Prelem Tags1, Prelem tagg2",
  },
  secondaryArgs: {
    gcpUrl: "https://storage.googleapis.com",
    bucketName: "cropped_image_public",
    prelemBaseEndpoint: {
      deliveryEndPoint: "https://platx-delivery-dev.fanuep.com/platform-x/",
      APIEndPoint: "https://platx-api-dev.fanuep.com/platform-x/",
      device: "window",
      buttonBaseUrl: "https://platx-publish-dev.fanuep.com/",
    },
    editState: false,
    multiSlot: {},
  },
};

export default CategoryBox;
