import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useMenuStyle = makeStyles((theme: Theme) => ({
  menuAccordianWp: {
    marginBottom: "24px",
    "&:last-child": {
      marginBottom: "0",
    },
    "& .accordianWp": {
      "&.Platform-x-Accordion-root": {
        padding: 0,
        border: 0,
        borderRadius: 0,
        marginBottom: "0px",
        boxShadow: "none",
        "&.Mui-expanded:last-of-type": {
          marginBottom: "0px",
        },
        "& .accordianSummaryWp": {
          minHeight: "20px",
          "&.Platform-x-AccordionSummary-root": {
            padding: 0,
            "& .Platform-x-AccordionSummary-content": {
              margin: 0,
              textTransform: "uppercase",
              "& p": {
                color: theme.palette.customColor.LABEL,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "185px",
              },
            },
          },
          "& svg": {
            color: theme.palette.customColor.LABEL,
          },
        },
        "& .Platform-x-AccordionSummary-expandIconWrapper.Mui-expanded": {
          transform: "rotate(90deg)",
        },
      },
    },
  },
}));

export default useMenuStyle;
