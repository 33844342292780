import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";

interface EmbedcardLeftSkeltonLoaderList {
  maxWidth?: string;
  maxHeight?: string;
  style?: any;
}
export default function embedcardLeftSkeltonLoader({
  maxWidth = "350px",
  maxHeight,
  style,
}: EmbedcardLeftSkeltonLoaderList) {
  return (
    <Card
      sx={{
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        border: "none",
        boxShadow: "none",
        marginTop: { xs: "10px", md: "20px", em: "0px" },
      }}
      style={style}>
      <Skeleton
        sx={{ height: { xs: "224px", md: "270px" }, marginBottom: "20px" }}
        animation='wave'
        variant='rectangular'
      />
      <Skeleton
        animation='wave'
        width='40%'
        height={15}
        style={{ marginBottom: 15, marginLeft: 20 }}
      />
      <Skeleton
        animation='wave'
        height={10}
        width='80%'
        style={{ marginBottom: 10, marginLeft: 20 }}
      />
      <Skeleton
        animation='wave'
        height={10}
        width='80%'
        style={{ marginBottom: 6, marginLeft: 20 }}
      />
      <Skeleton animation='wave' height={3} width='100%' style={{ margin: "25px 0" }} />
      <Skeleton
        animation='wave'
        height={8}
        width='40%'
        style={{ marginBottom: 30, marginLeft: 20 }}
      />
    </Card>
  );
}
