import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useAssetUpload = makeStyles((theme: Theme) => ({
  typography: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5px",
    marginLeft: "5px",
  },
  imgPreview: {
    width: "44px",
    height: "44px",
  },
  videoPreview: {
    width: "44px",
    height: "44px",
    objectFit: "cover",
  },
  boxcloseinnercontain: {
    display: "inline-flex",
    alignItems: "center",
    position: "relative",
    width: "100%",
  },
  modalboxtypo: {
    width: "100%",
    paddingRight: "24px",
    marginLeft: "20px",
    wordBreak: "break-all",
  },
  closeiconreop: {
    position: "absolute",
    marginTop: "13px",
    right: "0",
    top: "0",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  cursorNotAllowed: {
    cursor: "not-allowed",
  },
  fileuploadbox: {
    textAlign: "center",
    marginTop: "30px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5px",
    },
  },
  uploadParent: {
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 110px)",
    },
    [theme.breakpoints.down("em")]: {
      height: "210px",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    height: "calc(100vh - 110px)",
    backgroundColor: "#EFF0F6",
  },
  btnmodal: {
    display: "inline-block",
    padding: "9px 16px",
    marginTop: "25px !important",
    backgroundColor: "#4B9EF9 !important",
    borderRadius: "5px",
    color: "#FFF !important",
    fontSize: "14px",
  },
  syncIcon: {
    color: "#2d2d39",
    cursor: "pointer",
  },
  syncIconDisabled: {
    color: "#2d2d39",
    cursor: "not-allowed",
  },
}));
