import { Box, Typography } from "@mui/material";
import { Dropdown } from "../DropDown/Dropdown";

const FormDropdown = ({
  item,
  register,
  clearErrors,
  errors,
  selectedElement,
  setSelectedElement,
  isRendering,
}) => {
  // console.log("selectedElement", selectedElement);
  return (
    <Box className='formDropdown elementWrapper' mt={1}>
      <Typography variant='p4regular' className='form-label' mt={1} mb={1}>
        {item?.title}
      </Typography>
      <Dropdown
        options={item?.options}
        placeholder={item?.placeholder}
        setState={setSelectedElement}
        state={selectedElement}
        errors={errors[item?.name]}
        register={register}
        field={item}
        clearErrors={clearErrors}
        isRendering={isRendering}
      />
    </Box>
  );
};

export default FormDropdown;
