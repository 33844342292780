import { makeStyles } from "@mui/styles";

const useGalleryStyle = makeStyles(() => ({
  menuAccordianWp: {
    "&:last-child": {
      marginBottom: "0",
    },
    "& .accordianWp": {
      "&.Platform-x-Accordion-root": {
        padding: 0,
        border: 0,
        borderRadius: 0,
        marginBottom: "0px",
        boxShadow: "none",
        "&.Mui-expanded:last-of-type": {
          marginBottom: "0px",
        },
        "& .accordianSummaryWp": {
          minHeight: "20px",
          "&.Platform-x-AccordionSummary-root": {
            padding: 0,
            background: "none",

            "& .Platform-x-AccordionSummary-content": {
              "& p": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            },
          },
        },
        "& .Platform-x-AccordionSummary-expandIconWrapper.Mui-expanded": {
          transform: "rotate(90deg)",
          marginRight: "10px",
        },
        "& .Platform-x-AccordionSummary-expandIconWrapper": {
          marginRight: "10px",
        },
      },
    },
  },
  customBadge: {
    backgroundColor: "#14142b",
    color: "white",
  },
  menuitemListIcons: {
    "& img.contentIcon": {
      filter: "brightness(100) invert(1)",
    },
  },
}));

export default useGalleryStyle;
