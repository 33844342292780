import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  contentRowContainer: {
    display: "flex",
    alignItems: "center",
    padding: "15px",
    borderBottom: `1px solid  ${theme.palette.customColor.LINE}`,
    justifyContent: "space-between",
    // minHeight: "77px",
    "&:last-child": {
      borderBottom: 0,
    },
  },
  contentRowText: {
    cursor: "pointer",
    paddingRight: "25px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    wordBreak: "break-word",
    "&:hover": {
      textDecoration: "underline",
      fontWeight: 600,
    },
  },
}));
