import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ImageCard from "./ImageCard";
import { CardCustomStyle } from "./CardCustomStyle.style";
import { SliderSettingConstants } from "./utils/SliderSettingConstants";

const ImageCardSlider = ({ createContent, handleAssetSelect, selectedAssets }) => {
  const classes = CardCustomStyle();

  return (
    <Slider
      {...SliderSettingConstants}
      className={`${classes.dashboardCardSlider} CardSliderImageUpload`}>
      {createContent.map(
        (item: any) =>
          item.asset_url !== "" && (
            <ImageCard
              key={item.asset_id}
              data={item}
              isSelected={selectedAssets.some(
                (selectedItem) => selectedItem.asset_id === item.asset_id,
              )}
              onSelect={handleAssetSelect}
            />
          ),
      )}
    </Slider>
  );
};

export default ImageCardSlider;
