import { Menu, useTheme } from "@mui/material";

const XMenu = ({ anchorEl, handleMenuClose, open, children }) => {
  const theme = useTheme();
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
      sx={{
        ".Platform-x-Menu-paper": {
          boxShadow: "0px 25px 30px 0px rgba(0, 0, 0, 0.05)",
          border: `1px solid ${theme.palette.customColor.LINE}`,
        },
        ".Platform-x-MenuItem-root": {
          "&:hover": {
            backgroundColor: theme.palette.customColor.BGWEAK,
          },
        },
      }}>
      {children}
    </Menu>
  );
};

export default XMenu;
