import { useLazyQuery } from "@apollo/client";
import { CalendarTodayOutlined, ScheduleOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import { fetchContentByPath } from "@platformx/authoring-apis";
import { RedBlinkingDot } from "@platformx/shared/static-assets";
import {
  getSubDomain,
  relativeImageURL,
  ShowToastError as showToastError,
  SkeltonLoader,
} from "@platformx/utilities";
import { addMinutes, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./BlogEvents.css";
import { useStyles } from "./BlogEvents.styles";

interface EventsProps {
  eventPath: string;
}

const BlogEvents: React.FC<EventsProps> = (_props: any) => {
  const { eventPath = "" } = _props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [runFetchContentByPath] = useLazyQuery(fetchContentByPath);
  const [eventState, setEventState] = useState("");
  const [selectedEventDetaiils, setSelectedEventDetails] = useState<any>();
  const eventUrl = `${getSubDomain()}/event${selectedEventDetaiils?.current_page_url}`;

  const viewEventHandle = () => {
    window.open(eventUrl, "_blank");
  };

  const getEventStatus = (startDate: any, endDate: any) => {
    const currentDateTime = addMinutes(new Date(), 1);
    if (startDate || endDate) {
      const eventStartDate = new Date(startDate);
      const eventEndDate = new Date(endDate);
      if (currentDateTime > eventEndDate) {
        return "completed";
      } else if (currentDateTime > eventStartDate && currentDateTime < eventEndDate) {
        return "live";
      } else if (eventStartDate > currentDateTime) {
        return "tobestart";
      } else {
        return "error";
      }
    } else {
      return "error";
    }
  };

  const getEventDetails = () => {
    if (eventPath) {
      runFetchContentByPath({
        variables: { contentType: "Event", path: eventPath },
      })
        .then((res) => {
          if (res?.data?.authoring_getCmsContentByPath) {
            const contentObj = res?.data?.authoring_getCmsContentByPath;
            if (
              contentObj?.page_state === "PUBLISHED" ||
              (contentObj?.page_state === "DRAFT" && contentObj?.lastPublishedDate)
            ) {
              setSelectedEventDetails(contentObj);
              const eventStatus = getEventStatus(
                contentObj?.event_start_date,
                contentObj?.event_end_date,
              );
              setEventState(eventStatus);
            } else {
              showToastError("Couldn't find the event.");
            }
          } else {
            showToastError("Couldn't find the event.");
          }
        })
        .catch((err: any) => {
          const statusCode = err?.networkError?.statusCode || 0;
          showToastError(t("api_error_toast"), statusCode);
        });
    } else {
      showToastError("Invalid URL.");
    }
  };

  useEffect(() => {
    if (eventPath) {
      getEventDetails();
    }
  }, [eventPath]);

  return selectedEventDetaiils && selectedEventDetaiils?.original_image?.Thumbnail ? (
    <Box sx={{ position: "relative", left: "0", top: "0", right: "0" }}>
      <Box
        className='eventArea'
        sx={{
          display: { xs: "unset", em: "flex" },
          flexDirection: "column",
          flexWrap: "nowrap",
          alignItems: "stretch",
          height: { em: "calc(100vh - 88px)", xs: "unset" },
        }}>
        <Box
          className='innerContentArea'
          sx={{
            display: { md: "flex", xs: "unset !important" },
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "stretch",
            height: { em: "calc(100vh - 150px)", xs: "unset" },
          }}>
          <Box
            sx={{
              flexGrow: 1,
              flexShrink: 1,
              backgroundColor: "#fff",
              outline: "none",
            }}>
            <Box sx={{ position: "relative", width: "100%" }}>
              <img
                src={relativeImageURL(selectedEventDetaiils?.original_image?.Thumbnail)}
                className='eventImage'
                width='100%'
                alt='banner'
              />
              <Box sx={{ visibility: eventState === "live" ? "initial" : "hidden" }}>
                <img src={RedBlinkingDot} className='eventLiveIcon' alt='liveIcon' />
              </Box>
            </Box>
            <Box sx={{ margin: "12px 18px 5px 18px" }}>
              <Typography variant='h3medium' className='eventTitle'>
                {selectedEventDetaiils?.title}
              </Typography>
              <Typography variant='p3regular' className='eventDescription'>
                {selectedEventDetaiils?.description}
              </Typography>
              <Divider sx={{ margin: { xs: "0 -18px", lg: "0 0px" } }} />
              {selectedEventDetaiils?.event_start_date ? (
                <Box className={classes.eventSpacer}>
                  <Typography variant='h6semibold' component='h6'>
                    {t("event_start")}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Typography variant='h6regular' component='h6' className={classes.timeField}>
                      <CalendarTodayOutlined className={classes.eventIcon} />
                      {selectedEventDetaiils?.event_start_date
                        ? format(new Date(selectedEventDetaiils?.event_start_date), "LLL dd, yyyy")
                        : ""}
                    </Typography>
                    <Typography
                      variant='h6regular'
                      component='h6'
                      className={`${classes.timeField} ${classes.noGapRight}`}>
                      <ScheduleOutlined className={classes.eventIcon} />
                      {selectedEventDetaiils?.event_start_date &&
                        format(new Date(selectedEventDetaiils?.event_start_date), "H:mm")}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                ""
              )}
              {selectedEventDetaiils?.event_end_date ? (
                <Box>
                  <Typography variant='h6semibold' component='h6' className={classes.eventSpacer}>
                    {t("event_end")}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Typography variant='h6regular' component='h6' className={classes.timeField}>
                      <CalendarTodayOutlined className={classes.eventIcon} />
                      {selectedEventDetaiils?.event_end_date &&
                        format(new Date(selectedEventDetaiils?.event_end_date), "LLL dd, yyyy")}
                    </Typography>
                    <Typography
                      variant='h6regular'
                      component='h6'
                      className={`${classes.timeField} ${classes.noGapRight}`}>
                      <ScheduleOutlined className={classes.eventIcon} />
                      {selectedEventDetaiils?.event_end_date &&
                        format(new Date(selectedEventDetaiils?.event_end_date), "H:mm")}
                    </Typography>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: "0",
            flexShrink: "0",
            margin: "2px 20px 20px 20px",
            marginBottom: "16px",
            display: { xs: "none", em: "block" },
          }}>
          <Button
            variant='secondaryButton'
            sx={{
              mr: 2,
              width: "100%",
            }}
            size='large'
            className={classes.button}
            onClick={viewEventHandle}
            data-testid='view-full-blog-event'>
            {t("view_full_event")}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          marginBottom: "16px",
          display: { xs: "block", em: "none" },
        }}>
        <Button
          variant='secondaryButton'
          sx={{
            mr: 2,
            width: "100%",
            mt: 2,
          }}
          size='large'
          className={classes.button}
          onClick={viewEventHandle}
          data-testid='view-blog-event'>
          {t("view_full_event")}
        </Button>
      </Box>
    </Box>
  ) : (
    <Box sx={{ position: "relative", left: "0", top: "0", right: "0" }}>
      <SkeltonLoader maxWidth={800} maxHeight={500} />
    </Box>
  );
};
export default React.memo(BlogEvents);
