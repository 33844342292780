import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  head: {
    border: `1px solid  ${theme.palette.customColor.LINE}`,
    borderRadius: theme.borderRadius.value,
    paddingBottom: "15px",
    "& .header .link": {
      color: theme.palette.customColor.SECONDARY,
      textDecoration: "none",
      whiteSpace: "nowrap",
      marginLeft: "10px",
      "&:hover": {
        color: theme.palette.customColor.TERTIARY,
      },
    },
  },
  body: {
    overflowY: "auto",
    maxHeight: "347px",
    // padding: "0 15px",
    // minHeight: '359px',
  },
}));
