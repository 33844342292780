import AddLinkIcon from "@mui/icons-material/AddLink";
import Close from "@mui/icons-material/Close";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import { Box, Theme } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import React from "react";
import "./BlogContent.css";

const useStyles = makeStyles((theme: Theme) => ({
  buttonArea: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    border: `1px solid ${theme.palette.customColor.LINE}`,
    borderRadius: 2,
    backgroundColor: theme.palette.customColor.BGLIGHT,
    marginLeft: "10px",
    height: "42px",
    "& .MuiButtonBase-root ": {
      borderRadius: "0px !important",
      width: "40px ",
      fontSize: "18px",
      height: "40px ",
      "& .Platform-x-SvgIcon-root ": {
        fontSize: "18px",
      },
      "&:hover, &.Mui-focusVisible ": {
        backgroundColor: theme.palette.customColor.BGLIGHT,
        color: theme.palette.customColor.PRIMARY,
        borderRadius: "0px !important ",
      },

      ":disabled ": { color: `${theme.palette.customColor.SECONDARY} !important` },
    },
  },
  button: {
    border: `1px solid ${theme.palette.customColor.LINE} !important`,
    color: `${theme.palette.customColor.PLACEHOLDER} !important`,
    height: "40px !important ",
    "&:hover, &.Mui-focusVisible ": { backgroundColor: theme.palette.customColor.PLACEHOLDER },
  },
  saveDisabled: {
    border: `1px solid ${theme.palette.customColor.LINE} !important`,
    color: `${theme.palette.customColor.PLACEHOLDER} !important`,
    height: "40px !important ",
    fontSize: `${theme.fontSize?.fontSize_14} !important`,
  },
  publishDisabled: {
    border: `1px solid ${theme.palette.customColor.LINE} !important`,
    backgroundColor: `${theme.palette.customColor.PLACEHOLDER} !important`,
    color: `${theme.palette.customColor.PLACEHOLDER} !important`,
    height: "40px !important ",
    fontSize: `${theme.fontSize?.fontSize_14} !important`,
  },
  publishActive: {
    backgroundColor: `${theme.palette.customColor.PRIMARY} !important`,
    color: theme.palette.customColor.LIGHT,
    height: "40px !important ",
    padding: "8px 15px",
    minWidth: "110px",
    "&:hover": {
      color: theme.palette.customColor.LIGHT,
    },
  },
  saveActive: {
    color: `${theme.palette.customColor.PRIMARY} !important`,
    border: `1px solid ${theme.palette.customColor.LINE} !important`,
    height: "40px !important ",
    padding: "8px 15px",
    minWidth: "110px",
  },
}));

const BlogContentTypeMobile = (_props: any) => {
  const {
    contentItem = {},
    selectedImage = {},
    selectedVideo = {},
    isQuoteOpen = false,
    showGallery = () => {},
    onRemoveImage = () => {},
    onRemoveVideo = () => {},
    codeClickHandel = () => {},
    starClickHandel = () => {},
    quoteClickHandel = () => {},
    onRemoveContentType = () => {},
    linkClickHandle,
  } = _props;

  const classes = useStyles();

  const tempHide = false;
  const { Thumbnail: imgThumbnail = "" } = selectedImage;
  const { Thumbnail: videoThumbnail = "" } = selectedVideo;
  const { Thumbnail: { Url: contentThumbnail = "" } = {} } = contentItem;

  return (
    <Box
      sx={{
        display: { xs: "flex ", lg: "none" },
        flexGrow: { xs: "unset", lg: 1 },
        justifyContent: { xs: "center", lg: "unset" },
      }}>
      {/* image */}
      <Box className={`${classes.buttonArea}`}>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "",
          }}>
          {imgThumbnail ? (
            <Box className='imageThumbnailWrapper'>
              <IconButton onClick={onRemoveImage} data-testid='blog-remove-image-mobile'>
                <Close />
              </IconButton>
              <img className='img-background' alt='thumbImg1' src={imgThumbnail} />
            </Box>
          ) : (
            <Box>
              <IconButton
                disabled={isQuoteOpen}
                onClick={() => showGallery("Images")}
                data-testid='blog-show-image-gallery-mobile'>
                <InsertPhotoOutlinedIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Divider orientation='vertical' flexItem />

        {/* video */}
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "",
          }}>
          {videoThumbnail ? (
            <Box className='videoThumbnailWrapper'>
              <IconButton onClick={onRemoveVideo} data-testid='blog-remove-video-mobile'>
                <Close />
              </IconButton>
              <img className='img-background' alt='videoThumbnail' src={videoThumbnail} />
            </Box>
          ) : (
            <Box>
              <IconButton
                disabled={isQuoteOpen}
                onClick={() => showGallery("Videos")}
                data-testid='blog-video-gallery-mobile'>
                <PlayCircleOutlineIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Divider orientation='vertical' flexItem />

        {/* contentType */}
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "",
          }}>
          {contentThumbnail ? (
            <Box className='contentThumbnailWrapper'>
              <IconButton
                onClick={onRemoveContentType}
                data-testid='blog-remove-content-type-mobile'>
                <Close />
              </IconButton>
              <img className='img-background' alt='contentThumbnail' src={contentThumbnail} />
            </Box>
          ) : (
            <Box>
              <IconButton
                disabled={isQuoteOpen}
                onClick={() => showGallery("content")}
                data-testid='blog-show-content-type-gallery-mobile'>
                <CollectionsOutlinedIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>

      <Box className={`${classes.buttonArea} buttonLeftSection`}>
        {tempHide && (
          <IconButton
            onClick={codeClickHandel}
            disabled={isQuoteOpen}
            data-testid='blog-code-click-handle-mobile'>
            <UnfoldMoreOutlinedIcon className='rotateIcon90' />
          </IconButton>
        )}

        <Divider orientation='vertical' className='leftBorder' flexItem />
        <IconButton
          onClick={quoteClickHandel}
          data-testid='blog-quote-click-handle-mobile'
          disabled={imgThumbnail || videoThumbnail || contentThumbnail ? true : false}>
          <FormatQuoteOutlinedIcon />
        </IconButton>

        <Divider orientation='vertical' flexItem />
        <IconButton onClick={starClickHandel} data-testid='blog-star-click-handle-mobile'>
          <StarOutlineOutlinedIcon />
        </IconButton>
        <Divider orientation='vertical' flexItem />
        <IconButton onClick={linkClickHandle} data-testid='blog-link-click-handle-mobile'>
          <AddLinkIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
export default React.memo(BlogContentTypeMobile);
