import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import HiddenTextBox from "../HiddenTextBox/HiddenTextBox";

export const Dropdown = ({
  options,
  state,
  setState,
  error,
  register,
  field,
  clearErrors,
  isRendering,
}: any) => {
  const handleChange = (event: SelectChangeEvent) => {
    setState({ ...state, [field?.name]: event.target.value as string });
    clearErrors(field?.name);
  };
  return (
    <Box>
      <FormControl fullWidth error={error}>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={state?.[field?.name] ? state?.[field?.name] : field?.default_value?.[0]}
          name={field?.name}
          variant='filled'
          error={error}
          onChange={handleChange}
          className={isRendering ? "form-outline selectfield" : "authoring selectfield"}>
          {options?.map((item, index) => {
            return (
              <MenuItem key={index} disabled={item?.disabled} value={item?.label}>
                {item?.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <HiddenTextBox
        error={error}
        value={state?.[field?.name] || ""}
        register={register}
        name={field?.name}
        required={
          field?.validations.length > 0 &&
          field?.validations[0]?.type === "required" &&
          state?.[field?.name] === ""
            ? field?.validations[0]?.message
            : ""
        }
      />
    </Box>
  );
};
