import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Box, Typography } from "@mui/material";
import { PlatFormXDateTimeFormat } from "@platformx/utilities";

const CompletionList = ({ content = {} }: any) => (
  <Box className='completionList' sx={{ display: "flex" }}>
    <Box sx={{ display: "flex" }}>
      <Box sx={{ margin: { xs: "8px 0px", sm: "12px 0px" } }} color='textColor'>
        <CalendarMonthOutlinedIcon sx={{ marginTop: "5px" }} />
      </Box>
      <Typography variant='h2semibold' color='textColor' sx={{ marginLeft: "10px" }}>
        {PlatFormXDateTimeFormat(content?.event_end_date)?.split("|")[0]}
      </Typography>
    </Box>
    <Box sx={{ paddingLeft: "30px", display: "flex" }}>
      <Box sx={{ margin: { xs: "8px 0px", sm: "12px 0px" } }}>
        <AccessTimeIcon sx={{ marginTop: "5px" }} />
      </Box>
      <Typography variant='h2semibold' color='textColor' sx={{ marginLeft: "10px" }}>
        {PlatFormXDateTimeFormat(content?.event_end_date)?.split("|")[1]}
      </Typography>
    </Box>
  </Box>
);

export default CompletionList;
