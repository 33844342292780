import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getCurrentLang, getSelectedSite } from "../utils/helper";
import { onError } from "@apollo/client/link/error";
import { handleLogout } from "@platformx/utilities";

const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    // errorPolicy: "all",
  },
};
const link = createHttpLink({
  uri: process.env.NX_PUBLIC_GRAPHQL_URI,
  headers: {
    language: getCurrentLang(),
    sitename: getSelectedSite(),
  },
  credentials: "include",
});

const updateLanguageheader = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      // language: i18next.language,
    },
  };
});

const onErrorLink = onError(({ networkError }: any) => {
  if (networkError?.statusCode === 401) {
    handleLogout();
  }
});

const graphqlInstance = new ApolloClient({
  link: ApolloLink.from([onErrorLink, updateLanguageheader, link]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});
export default graphqlInstance;
