import { Box, Typography } from "@mui/material";
import { checkRequired } from "../../helper";
import TextBox from "../TextBox/TextBox";

const FormTextBox = ({ item, register, clearErrors, errors, isRendering }) => {
  return (
    <Box className='formTextBox elementWrapper' mt={1}>
      <Typography variant='p4regular' className='form-label' mt={1} mb={1}>
        {item?.title}
        {checkRequired(item?.validations)}
      </Typography>
      <TextBox
        field={item}
        register={register}
        clearErrors={clearErrors}
        errors={errors}
        isRendering={isRendering}
        handleChange={() => {}}
        handleOnBlur={() => {}}
        isDisabled={!isRendering}></TextBox>
    </Box>
  );
};

export default FormTextBox;
