import { TextField, useTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
//import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";

type DatePickerType = {
  time: any;
  handleValChange: (value: any) => void;
  handleDateChangeRaw?: (value: any) => void;
  isDisabled: boolean;
  disablePast?: boolean;
  error?: boolean;
};

const DatePicker = ({
  time,
  handleValChange,
  // handleDateChangeRaw,
  isDisabled,
  disablePast = true,
  error = false,
}: DatePickerType) => {
  const { t } = useTranslation();
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={time || null}
        disabled={isDisabled}
        disablePast={disablePast}
        onChange={handleValChange}
        inputFormat='MM/DD/YYYY HH:mm'
        renderInput={(params) => (
          <TextField
            disabled={true}
            // variant='filled'
            onKeyDown={onKeyDown}
            error={error}
            InputProps={{ disabled: true, readOnly: true }}
            onKeyPress={(e) => {
              e.preventDefault();
              return false;
            }}
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
            }}
            placeholder={t("quiz_publish_placeholder")}
            sx={{
              width: "100%",
              border: error ? `solid 1px ${theme.palette.customColor.ERROR}` : ``,
              borderRadius: "5px",
              backgroundColor: error
                ? `${theme.palette.customColor.BACKGROUND_ERROR}`
                : "transparent",
            }}
            // sx={{
            //   width: "100%",
            //   ".Platform-x-InputBase-root.Mui-error fieldset": {
            //     borderColor: "#ced3d9 !important",
            //   },
            //   ".Platform-x-InputBase-root.Mui-error.Platform-x-FilledInput-root": {
            //     borderColor: error ? "#F33E41 !important" : "#D9DBE9 !important",
            //     background: "transparent !important",
            //   },
            //   ".Platform-x-InputBase-root.Mui-error input": {
            //     background: "transparent !important",
            //   },
            // }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
export default DatePicker;
