import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    formbuilderBgWrapper: {
      "&.formbuilderBg": {
        "& .createPagePopupLeft": {
          padding: "17px 30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          [theme.breakpoints.down("md")]: {
            padding: "15px",
          },
        },
        "& .createPagePopuprowBox": {
          margin: "13px 0",
        },
        "& .createPagePopupButtonWp": {
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          margin: "13px 0",
          "& button": {
            width: "50%",
            height: "47px",
            "&:first-child": {
              marginRight: "15px",
            },
          },
        },
        "& .createPagePopupRight": {
          background: theme.palette.customColor.ACCENTS.ACCENT_5,
          minHeight: "450px",
          [theme.breakpoints.down("md")]: {
            minHeight: "auto",
          },
        },
        "& .popupRightImage": {
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "flex-end",
          [theme.breakpoints.down("md")]: {
            padding: "25px 0px 25px 25px",
          },
        },
      },
    },
  };
});
