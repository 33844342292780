import SyncIcon from "@mui/icons-material/Sync";
import { Box, Button, FormControl, Menu, Radio, RadioGroup, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { FilterIcon } from "@platformx/shared/static-assets";
import { ErrorTooltip, SearchBox, useAccess } from "@platformx/utilities";
import { t } from "i18next";
import { useState } from "react";
import { ContentListingHeaderContainer, FormControlCustom } from "./ContentListingHeader.styles";
import { useDropdownStyles } from "./DropdownHeader.style";
import {
  CONTENT_CONSTANTS,
  contentTypeBasedHideFilter,
  contentTypeSearchBox,
} from "./helperContentList";

const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 5s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  }),
);

const ContentListingHeader = ({
  handleFilter,
  handleAddNew,
  title = "",
  category,
  subCategory,
  handleRefresh,
  animationState,
  filterValue,
  handleSearch = () => {},
}: {
  title: string;
  handleSearch?: (e: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleFilter: (filter: string) => void;
  handleAddNew: () => void;
  category: string;
  subCategory: string | string[];
  handleRefresh: () => void;
  animationState: boolean;
  filterValue?: string;
}) => {
  const Class = useStyles();
  const classes = useDropdownStyles();
  const { canAccessAction } = useAccess();
  const [filterMenu, setFilterMenu] = useState<null | HTMLElement>(null);
  const openFilterMenu = Boolean(filterMenu);
  const handleFilterClose = () => {
    setFilterMenu(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = () => (event.target as HTMLInputElement).value;
    handleFilter(selectedValue());
    handleFilterClose();
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setFilterMenu(event.currentTarget);
  };

  const hideFilter = contentTypeBasedHideFilter(title); //hide filter
  const hideSearchBox = contentTypeSearchBox(title) || false;
  return (
    <ContentListingHeaderContainer className={`contentListingHeader`}>
      <Box>
        <Typography variant='h3semibold' sx={{ textTransform: "capitalize" }}>
          {t(`${title}`)}
        </Typography>
      </Box>
      {title.toLocaleLowerCase() !== "result" && (
        <Box style={{ display: "flex" }}>
          {/* search box */}
          {hideSearchBox && (
            <Box style={{ marginRight: "10px" }}>
              <SearchBox handleSearch={handleSearch} setLoading={() => {}} />
            </Box>
          )}

          <Box
            onClick={handleRefresh}
            data-testid='content-listing-refresh'
            sx={{
              backgroundColor: "white",
              padding: { xs: "8px", md: "10px" },
              borderRadius: "4px",
              border: "1px solid #14142B",
              height: { xs: "42px", md: "46px" },
              width: "42px",
              alignItem: "center",
              cursor: "pointer",
              justifyContent: "center",
            }}>
            <SyncIcon
              sx={{
                color: "#2d2d39",
                verticalAlign: "middle",
                fontSize: { md: "21px" },
                cursor: "pointer",
              }}
              className={animationState ? Class.rotateIcon : ""}
            />
          </Box>

          {/* based on condition filter button will enable  */}
          {hideFilter && (
            <Box
              onClick={handleFilterClick}
              data-testid='content-listing-filter'
              sx={{
                backgroundColor: openFilterMenu ? "#2d2d39" : "white",
                padding: { xs: "8px", md: "10px" },
                marginLeft: "10px",
                borderRadius: "4px",
                border: "1px solid #14142B",
                display: "flex",
                height: { xs: "42px", md: "46px" },
                width: "42px",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "center",
                "&.openClass img": {
                  filter:
                    "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(6197%) hue-rotate(110deg) brightness(97%) contrast(99%);",
                },
              }}
              className={openFilterMenu ? "openClass" : ""}>
              <img src={FilterIcon} alt='' width='17' height='24' />
            </Box>
          )}
          <Menu
            elevation={0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={filterMenu}
            open={openFilterMenu}
            onClose={handleFilterClose}
            className={`${classes.FilterMenuWrapperItem} filterMenu`}
            PaperProps={{
              className: "filterMenuItem",
              // elevation: 0,
            }}>
            <FormControl>
              <RadioGroup
                value={filterValue}
                onChange={handleChange}
                sx={{ textTransform: "capitalize" }}
                className='listViewContainer'>
                <FormControlCustom
                  className={`${filterValue === CONTENT_CONSTANTS.ALL ? "active" : ""} listView`}
                  value='ALL'
                  control={<Radio sx={{ display: "none" }} />}
                  label={<Typography variant='p2regular'>{t("all")}</Typography>}
                />
                <FormControlCustom
                  className={`${filterValue === CONTENT_CONSTANTS.PUBLISHED ? "active" : ""} listView`}
                  value='PUBLISHED'
                  control={<Radio sx={{ display: "none" }} />}
                  label={<Typography variant='p2regular'>{t("published")}</Typography>}
                />
                <FormControlCustom
                  className={`${filterValue === CONTENT_CONSTANTS.DRAFT ? "active" : ""} listView`}
                  value='DRAFT'
                  control={<Radio sx={{ display: "none" }} />}
                  label={<Typography variant='p2regular'>{t("draft")}</Typography>}
                />
                <FormControlCustom
                  className={`${filterValue === "UNPUBLISHED" ? "active" : ""} listView`}
                  value='UNPUBLISHED'
                  control={<Radio sx={{ display: "none" }} />}
                  label={<Typography variant='p2regular'>{t("unpublished")}</Typography>}
                />
                {title.toLowerCase() === "event" && (
                  <FormControlCustom
                    className={`${filterValue === "event" ? "active" : ""} listView`}
                    value='LIVE'
                    control={<Radio sx={{ display: "none" }} />}
                    label={<Typography variant='p2regular'>{t("live")}</Typography>}
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Menu>

          <ErrorTooltip
            component={
              <Button
                className='addnewpage'
                variant='primaryButton'
                disabled={
                  !canAccessAction(category, subCategory, "Create")
                  //   || title === 'Course'
                }
                data-testid='addnew'
                sx={{
                  display: { xs: "none", sm: "flex" },
                  marginLeft: "12px",
                }}
                onClick={handleAddNew}>
                {t("add_new")}
              </Button>
            }
            doAccess={
              !canAccessAction(category, subCategory, "Create")
              //||title === 'Course'
            }
          />
        </Box>
      )}
    </ContentListingHeaderContainer>
  );
};
export default ContentListingHeader;
