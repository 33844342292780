import { gql } from "@apollo/client";

export const channelsQueries = {
  GET_CHANNELS_LIST: gql`
    query GET_CHANNELS_LIST($pagination: community_Paginate!) {
      community_fetchHostListing(pagination: $pagination) {
        totalCount
        records
      }
    }
  `,

  GET_CHANNEL_DETAILS: gql`
    query GET_CHANNEL_DETAILS($eventId: String!) {
      community_fetchHostEventDetails(event_id: $eventId)
    }
  `,
};
