import { updateDataAfterFetch } from "@platformx/authoring-state";
import { PROJECT_CATCH_ERROR_LIST, formatChildren, getSelectedSite } from "@platformx/utilities";
import { createSearchParams } from "react-router-dom-v6";
import graphqlInstance from "../../config/graphqlConfig";
import { PageQueries } from "../../graphQL/queries/pageQueries";
import { FETCH_PRELEM_VALIDATION } from "../../graphQL/queries/prelemQueries";
import { SearchContentListQueries } from "../../graphQL/queries/searchQueries";
import { ApiResponse } from "../../utils/types";
import { mapFetchALL } from "./mapper";

export const savePageModel = PageQueries.SAVE_PAGE_MODEL;
export const fetchAllPageList = PageQueries.FETCH_ALL_PAGE_LIST;
export const fetchPageListAll = PageQueries.FETCH_PAGE_LIST_ALL;
export const publishPageModel = PageQueries.PUBLISH_PAGE_MODEL;
export const updatePrelemData = PageQueries.UPDATE_PRELEM_CONTENT;
export const schedulePublish = PageQueries.SCHEDULE_PUBLISH;
export const scheduleUnpublish = PageQueries.SCHEDULE_UNPUBLISH;
export const cancelPublish = PageQueries.CANCEL_PUBLISH;
export const cancelUnpublish = PageQueries.CANCEL_UNPUBLISH;
export const createPgModel = PageQueries.CREATE_PAGE_MODEL;
export const deletePage = PageQueries.DELETE_PAGE;
export const reschedulePublish = PageQueries.RESCHEDULE_PUBLISH;
export const rescheduleUnpublish = PageQueries.RESCHEDULE_UNPUBLISH;
export const unpublishPage = PageQueries.UNPUBLISH_PAGE;

const fetchAllValidation = async (docTypes, client) => {
  try {
    const validations = {};
    const promises = Array.from(docTypes).map((input) => {
      return client.query({ query: FETCH_PRELEM_VALIDATION, variables: { input } });
    });
    const output: any = await Promise.allSettled(promises);
    const iterateableDocType = [...docTypes];
    for (const [index, documentType] of iterateableDocType.entries()) {
      if (output?.[index]?.status === "rejected") {
        console.error("AuthoringError: Prelem validation", output[index]);
      } else {
        validations[documentType] =
          output?.[index]?.value?.data?.authoring_getDocValidationSchema || {};
      }
    }
    return validations;
  } catch (error) {
    console.error(error);
  }
};

const fetchValidationForPageSelected = async (client, data) => {
  const { children } = data;
  const s = new Set();
  for (const element of children) {
    s.add(element.DocumentType);
  }
  return await fetchAllValidation(s, client);
};

export const fetchPageModel = async (
  dispatch: any,
  client: any,
  path: any,
  navigate?: any,
  actionType?: any,
  deviceType?: string,
  editOption?: string,
  searchCatURL?: string,
  searchTermURL?: string,
  sortByURL?: string,
) => {
  try {
    if (path !== "") {
      const arr = path?.split("/");
      // eslint-disable-next-line prefer-destructuring
      const folder = arr[6];
      const pathnm = `${arr[10]}`;
      const pageModel = await client.query({
        query: PageQueries.FETCH_PAGE_MODEL_DRAFT,
        context: {
          headers: {
            sitename: getSelectedSite(),
          },
        },
        variables: { folder: folder, path: pathnm },
      });
      const prelemData = { ...pageModel.data.authoring_getCmsItemByPath };
      const pageValidations = await fetchValidationForPageSelected(client, prelemData);
      const { children, content, PageSettings } = prelemData;
      delete prelemData.children;
      delete prelemData.content;
      delete prelemData.__typename;
      const childrenWithContent = formatChildren(children, content);
      prelemData.Children = childrenWithContent;
      dispatch(
        updateDataAfterFetch({
          pageModel: prelemData,
          pageSettings: PageSettings,
          prelemMetaArray: childrenWithContent,
          validations: pageValidations,
        }),
      );
      if (navigate) {
        if (actionType) {
          navigate(`/preview-page/${deviceType}`, {
            state: { pageName: prelemData?.Page },
          });
        } else {
          navigate(
            {
              pathname: "/edit-page",
              search: `?${createSearchParams({
                page: path.toString(),
                editoption: editOption ? editOption.toString() : "",
                searchCat: searchCatURL ? searchCatURL.toString() : "",
                searchTerm: searchTermURL ? searchTermURL.toString() : "",
                sortBy: sortByURL ? sortByURL.toString() : "",
              })}`,
            },
            { state: "old" },
          );
        }
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export const runPageFetchValidationQuery = async (
  prelemMetaInfo: any,
  fetchValidationQuery: any,
) => {
  const docType = prelemMetaInfo.DocumentType;
  const response = await fetchValidationQuery({
    variables: { input: docType },
  });
  return response;
};

export const pageApi = {
  getPageDetails: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: PageQueries.FETCH_PAGE_MODEL_DRAFT,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:getPageDetails`, err);
      throw err;
    }
  },
};

export const fetchALLContentTypeDetails = async ({
  contentType,
  locationState,
  filter,
  startIndex = 0,
  ROW_SIZE = 10,
}) => {
  const variables: any = mapFetchALL(locationState, filter, contentType, {
    start: startIndex,
    rows: ROW_SIZE,
  });
  try {
    const { data } = await graphqlInstance.query({
      query: SearchContentListQueries.FETCH_CONTENT_TYPE_LIST,
      variables: variables,
      fetchPolicy: "no-cache",
    });
    return data;
  } catch (err: any) {
    return {};
  }
};
