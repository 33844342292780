import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    padding: "12px 20px",
    borderRadius: "4px",
    marginBottom: "14px",
    border: `1px solid ${theme.palette.customColor.LINE}`,
    "&:hover": {
      border: `1px solid ${theme.palette.customColor.PRIMARY}`,
    },
  },
  item1Container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "46px",
    borderRight: `1px solid ${theme.palette.customColor.LINE}`,
  },
  imgContainer: {
    margin: "1px 18px 1px 0",
    display: "flex",
  },
  titleContainer: {
    display: "flex",
    paddingRight: "20px",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  ellipsisText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  },
  publishIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: `1px solid ${theme.palette.customColor.LINE}`,
    height: "46px",
  },
  userContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRight: `1px solid ${theme.palette.customColor.LINE}`,
    height: "46px",
    paddingLeft: "10px",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));
