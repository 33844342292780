import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useContentGlleryStyle = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    backgroundSize: "cover",
    left: 0,
    top: 0,
    borderRadius: "5px",
    maxWidth: "100%",
  },
  cardmedia: {
    position: "absolute",
    top: "0",
    bottom: "0",
    background: "rgba(0, 0, 0, 0.5)",
    width: "100%",
    color: "#FFFFFF",
  },
  cardmediaHover: {
    position: "absolute",
    top: "0",
    bottom: "0",
    background: "rgba(0, 0, 0, 0.4)",
    width: "100%",
    height: "100%",
    color: theme.palette.customColor.LIGHT,
  },
  cardcontent: {
    color: theme.palette.customColor.LIGHT,
    backgroundColor: "transparent",
    mt: { xs: 0, sm: 0, md: 4, lg: 8 },
    width: "100%",
    height: "100%",
    padding: "0px !important",
  },

  tickicon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  contentcardwrap: {
    position: "absolute",
    bottom: "14px",
    left: "14px",
  },
  contentboxwrap: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    width: "fit-content",
  },
  contentwrap: {
    color: "inherit",
    margin: "3px 0",
    textTransform: "capitalize",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block",
    position: "relative",
    wordBreak: "break-word",
  },
  boxlastcontent: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
  },
  communitybox: {
    display: "flex",
    alignItems: "center",
  },
  communspan: {
    marginLeft: "8px",
    marginTop: "10px",
  },
  communitytypo: {
    pl: "16px",
    marginTop: "14px",
    marginBottom: "14px",
  },
  expandicon: {
    position: "absolute",
    right: "24px",
    marginTop: "-12px",
  },
  boxdam: {
    display: "flex",
    alignItems: "center",
  },
  imagespan: {
    marginLeft: "8px",
  },
  contenttypecardbox: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    m: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fetchresulttypo: {
    marginTop: "200px",
    marginBottom: "100px",
    textAlign: "center",
  },
  noresultfoundtypo: {
    [theme.breakpoints.down("xs")]: {
      overflowY: "hidden !important",
    },
    height: "calc(100vh - 169px)",
    overflowY: "scroll",
  },
  containercard: {
    cursor: "pointer",
    position: "relative",
    // height: { xs: 'auto', md: '240px', borderRadius: '5px' },
    float: "left",
    width: "100%",
    height: "auto",
    aspectRatio: "1/1",
    //   '&:hover': {
    //     backgroundColor: '#0000009e',
    // },
  },
  cursonPointer: {
    cursor: "pointer",
  },
  leftsidebarbox: {
    maxHeight: "57vh",
    overflowY: "auto",
  },
  leftcontent: {
    // display: { xs: 'block', em: 'none' },
    position: "fixed",
    bottom: 0,
    right: 0,
    zIndex: 10,
  },
  containbox: {
    position: "relative",
    cursor: "pointer",
    height: "100%",
    width: "100%",
    display: "inline-grid !important",
    alignItems: "end",
    borderRadius: "5px",
    boxShadow: "none !important",
    zIndex: 1,
    "&:hover": {
      backgroundColor: "#0000009e",
    },
    innercontain: {
      height: "100%",
      width: "100%",
    },
  },
  damtypo: {
    color: "#ced3d9",
    textTransform: "capitalize",
    marginBottom: "1px",
  },
  communityMenu: {
    transform: "none !important",
  },
  infinitescroll: {
    backgroundColor: theme.palette.customColor.BGLIGHT,
    borderRadius: "5px",
    "& .infinite-scroll-component__outerdiv": {
      width: "inherit",
      padding: "0px 15px 0px 15px",
    },
  },
  arrowGrid: {
    margin: "auto 0 !important",
    paddingBottom: "0",
  },
  arrowTypo: {
    padding: "0 0 5px 0px",
  },

  cancelGrid: {
    marginTop: "auto !important",
    marginBottom: "auto !important",
  },
  cancelBox: {
    display: "flex",
    gap: "10px",
    justifyContent: "flex-end",
  },
  contentName: {
    textTransform: "capitalize",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    margin: "14px 0 !important",
  },
  categoryBox: {
    backgroundColor: theme.palette.customColor.BGWEAK,
    padding: "10px 20px",
    marginTop: "13px",
  },
  filtercontain: {
    width: "45px",
    height: "45px",
    borderRadius: "5px",
    border: `1px solid ${theme.palette.customColor.LINE}`,
  },
  imageWrapper: {
    position: "relative",
  },
  image: {
    display: "block",
    width: "100%",
    height: "auto",
    borderRadius: "5px",
  },
  hoverOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: "5px",
  },
  loadingbox: {
    height: "100%",
    width: "100%",
  },
  boxdone: {
    margin: "0 25px 25px 0",
  },
  contentpadding: {
    [theme.breakpoints.up("xs")]: {
      padding: " 16px",
      borderBottom: `solid 1px ${theme?.palette.customColor?.LINE}`,
    },
    [theme.breakpoints.up("em")]: {
      padding: "10px 15px 10px 0px",
      display: "none",
    },
    display: "flex",
    justifyContent: "space-between",
  },
}));
export default useContentGlleryStyle;
