import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface route {
  title: string;
  icon: string;
  url: string;
  id: string;
  category: string;
  subCategory: string;
  component: string;
  isMenu: boolean;
  isHidden: boolean;
  isHeader: boolean;
  isSideBar: boolean;
  isGallery: boolean;
}
interface routes {
  routes: route[];
}
export const routesSlice = createSlice({
  name: "Routes",
  initialState: {
    routes: [],
  } as routes,
  reducers: {
    handleUpdateRoutes: (state, action: PayloadAction<any>) => {
      state.routes = action.payload;
    },
  },
});

export const { handleUpdateRoutes } = routesSlice.actions;
export default routesSlice.reducer;
