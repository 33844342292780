import graphqlInstance from "../../config/graphqlConfig";
import { WorkflowQueries } from "../../graphQL/queries/workflowQueries";
import { ApiResponse } from "../../utils/types";
import { PROJECT_CATCH_ERROR_LIST } from "@platformx/utilities";

const workflowApi = {
  workflow_submission: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: WorkflowQueries.CONTENT_WORKFLOW_SUBMISSION,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:workflow_submission`, err);
      throw err;
    }
  },
  getWorkflowList: async <T>(input?: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: WorkflowQueries.GET_WORKFLOW_LISTING,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:getWorkflowList`, err);
      throw err;
    }
  },
  updateWorkflowStatus: async <T>(input?: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: WorkflowQueries.UPDATE_WORKFLOW_STATUS,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:updateWorkflowStatus`, err);
      throw err;
    }
  },

  getUserAssignedTaskList: async <T>(input?: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: WorkflowQueries.GET_USER_ASSIGNED_TASK_LIST,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:getUserAssignedTaskList`, err);
      throw err;
    }
  },
  getWorkflowHistory: async <T>(input?: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: WorkflowQueries.GET_WORKFLOW_HISTORY,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:getWorkflowHistory`, err);
      throw err;
    }
  },
};
export default workflowApi;
