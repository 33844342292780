import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useDropdownStyles = makeStyles((theme: Theme) => ({
  FilterMenuWrapperItem: {
    "&.filterMenu": {
      "& .filterMenuItem": {
        boxShadow: "0px 25px 30px 0px rgba(0, 0, 0, 0.05)",
        minWidth: "160px",
        border: `1px solid ${theme.palette.customColor.LINE}`,
        marginTop: "5px",
        "& .listViewContainer": {
          minWidth: "160px",
          borderRadius: "4px",
          "& .listView": {
            padding: "5px 20px",
            margin: "0px !important",
            "&:hover": {
              backgroundColor: theme.palette.customColor.BGWEAK,
            },
            "&:hover p, &.active p": {
              color: theme.palette.customColor.PRIMARY,
              fontWeight: 600,
            },
          },
        },
      },
    },
  },
}));
