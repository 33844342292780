import { XImageRender } from "@platformx/x-image-render";
import { fetchRequiredMessage } from "../../../utils/helperFns";
import HiddenTextBox from "../HiddenTextBox/HiddenTextBox";

const ImagePicker = ({ field, errors, register, state, updateField }: any) => {
  return (
    <div id={`${field?.name}_id`}>
      {/* This is the id that is used to scroll to the error */}
      <XImageRender
        callBack={updateField}
        editData={{
          original_image: state[field?.name]?.original_image,
          published_images: state[field?.name]?.published_images,
        }}
        isCrop={true}
        name={field?.name}
      />
      <HiddenTextBox
        error={errors[field?.name]}
        value={state[field?.name]?.original_image?.Thumbnail}
        register={register}
        name={field?.name}
        required={fetchRequiredMessage(
          field?.validations,
          state[field?.name]?.original_image?.Thumbnail,
        )}
      />
    </div>
  );
};
export default ImagePicker;
