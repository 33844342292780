/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  breakpointsdata as BREAKPOINTS,
  CROP_TYPE,
  getSingleCropBreakpoint,
  ShowToastError,
  ShowToastSuccess,
} from "@platformx/utilities";
import { useState } from "react";
import { usePostImageCrop } from "./usePostImageCrop";

const useImageCrop = (originalImage: any, doneCropCompleted: any, prelemId: any, i: any) => {
  const { bitStreamId } = originalImage || {};
  const [doneLoader, setDoneLoader] = useState(false);
  const { cropRequest, isLoading } = usePostImageCrop();
  const initialCrops = BREAKPOINTS.map(() => [
    { x: 0, y: 0 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
  ]);

  const [crops, setCrops] = useState(initialCrops);

  const apiCallBack = (data, img) => {
    if (data) {
      const {
        images = [],
        ext = "",
        original_image_relative_path = "",
        visibility = "",
        bitstream_id = "",
      } = data;
      if (images?.length > 0) {
        ShowToastSuccess("Image Cropped Successfully");
        setDoneLoader(false);
        doneCropCompleted(images, ext, original_image_relative_path, visibility, bitstream_id, img);
      } else {
        ShowToastError("Manual Cropping Failed");
        setDoneLoader(false);
        doneCropCompleted();
      }
    } else {
      ShowToastError("Manual Cropping Failed");
      setDoneLoader(false);
      doneCropCompleted();
    }
  };

  const handleDone = async (isCrop, checked = false, ratioName = "card2") => {
    setDoneLoader(true);

    const cropHints = (isCrop ? BREAKPOINTS : getSingleCropBreakpoint(ratioName)).map(
      ({ ratio }, index) => ({
        aspect_ratio: ratio,
        crop_vertex: crops[index],
      }),
    );

    const payload = {
      bitstreamId: bitStreamId,
      visibility: "public",
      crop_hints: cropHints,
    };

    try {
      await cropRequest(
        checked ? CROP_TYPE.NOCROP : CROP_TYPE.MANUAL,
        payload,
        apiCallBack,
        originalImage,
        ratioName,
      );
    } catch (error) {
      console.error("Error cropping image:", error);
      ShowToastError("Cropping Failed");
      setDoneLoader(false);
      return { success: false, data: null };
    }
  };

  const onCropChange = (data: any, index: any) => {
    setCrops((prevState) => {
      const newCrops = [...prevState];
      newCrops[index] = data;
      return newCrops;
    });
  };

  return {
    doneLoader,
    isLoading,
    crops,
    handleDone,
    onCropChange,
  };
};

export default useImageCrop;
