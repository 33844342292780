import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Autocomplete, Box, Chip, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const AutoCompleteText = ({ socialShareInfo, setSocialShareInfo }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box>
      <Autocomplete
        multiple
        fullWidth
        id='tags-filled'
        value={
          socialShareInfo?.tagsSocialShare?.length > 0 ? [...socialShareInfo.tagsSocialShare] : []
        }
        options={[]}
        onChange={(event: object, value) => {
          const result = value.filter((str) => str.trim()?.length !== 0);
          const updatedtags = result.filter((c, index) => {
            return result.indexOf(c) === index;
          });
          const pageInfoUpdated = { ...socialShareInfo };
          pageInfoUpdated.tagsSocialShare = updatedtags;
          setSocialShareInfo(pageInfoUpdated);
        }}
        freeSolo
        renderTags={(value: string[], getTagProps) =>
          value.map(
            (option: string, index: number) =>
              option && (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  // key={index}
                  variant='outlined'
                  label={option}
                  deleteIcon={<CloseRoundedIcon />}
                  sx={{
                    ".Platform-x-Chip-label": {
                      padding: "0 10px",
                    },
                    ".Platform-x-Chip-deleteIcon": {
                      color: theme.palette.customColor.LABEL,
                      cursor: "pointer",
                      marginRight: "5px",
                      fontSize: "20px",
                    },
                  }}
                  {...getTagProps({ index })}
                />
              ),
          )
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant='filled'
            placeholder={
              socialShareInfo?.tagsSocialShare?.length > 0 ? "" : t("quiz_tags_placeholder")
            }
          />
        )}
        sx={{
          "& .Platform-x-InputBase-root.Platform-x-InputBase-fullWidth": {
            display: "block",
            flexWrap: "wrap",
            padding: "10px !important",
          },
          ".Platform-x-Autocomplete-tag": {
            border: `solid 1px ${theme.palette.customColor.PRIMARY}`,
            borderRadius: "5px",
            height: "40px",
            margin: "0 5px 5px 0",
          },
          ".Platform-x-Chip-label": {
            padding: "0 5px",
          },
          ".Platform-x-InputBase-input": {
            padding: 0,
            width: "200px",
          },
          "#tags-filled": {
            height: 0,
          },
        }}
      />
    </Box>
  );
};
export default AutoCompleteText;
