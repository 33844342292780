/* eslint-disable no-unused-vars */
import { ArrowBack } from "@mui/icons-material";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { RootState, setIsCommentPanelOpen, setIsReviewEnabled } from "@platformx/authoring-state";
import {
  DefaultStateCommentIcon,
  PlatXLogo,
  Play,
  SettingNewIcon,
} from "@platformx/shared/static-assets";
import {
  ErrorTooltip,
  MarkedFeatured,
  Timer,
  ToolTip,
  enableReferBack,
  useAccess,
  workflowKeys,
} from "@platformx/utilities";
import { WorkflowHistoryIcon } from "@platformx/workflow-management";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom-v6";
import "../../CreateArticle.css";
import { useStyles } from "../../CreateArticle.styles";
import PublishSocialShare from "../PublishSocialShare/PublishSocialShare";

const TopBar = ({
  returnBack,
  handleClickOpen,
  onSave,
  handelPreview,
  state,
  setState,
  socialOgTags,
  setSocialOgTags,
  show,
  setShow,
  updateStructureDataArticle,
  previewButton,
  toolTipText,
  category,
  subCategory,
  workflow,
  timerState,
  lastmodifiedDate,
  setEnableWorkflowHistory,
  setIsFeatured,
  isFeatured,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const { canAccessAction } = useAccess();
  const dispatch = useDispatch();
  const { commentInfo } = useSelector((states: RootState) => states.comment);
  const { comments, isReviewEnabled } = commentInfo;
  const handleReview = () => {
    dispatch(setIsReviewEnabled(!isReviewEnabled));
    dispatch(setIsCommentPanelOpen({ value: true }));
  };
  const theme = useTheme();
  const noWeb = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (enableReferBack(workflow) || comments?.length > 0) {
      dispatch(setIsReviewEnabled(true));
    } else {
      dispatch(setIsReviewEnabled(false));
    }
  }, [isReviewEnabled, workflow, comments]);

  return (
    <>
      <Box className='createarticletophead'>
        <Box className='d-flex'>
          <Box className='backarrow' onClick={returnBack} data-testid='return-back'>
            <ArrowBack sx={{ marginRight: "10px" }} />
            {!noWeb && <Typography variant='h3medium'>{t("article")}</Typography>}
          </Box>
          <Box
            className={classes.logoDispaly}
            onClick={() => navigate("/dashboard")}
            data-testid='goto-dashboard-article'>
            <img src={PlatXLogo} height='30' alt='img' />
          </Box>
        </Box>
        <Box className='d-flex align-items-center justify-content-space-between'>
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            container
            alignItems='flex-end'
            direction='row'
            sx={{
              display: { xs: "none", em: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box className={`d-flex ${classes.rightBorder} ${classes.spacingLeftRight}`}>
              <MarkedFeatured setIsFeatured={setIsFeatured} isFeatured={isFeatured} />

              {comments?.length > 0 ? (
                <Box sx={{ position: "relative" }} className={classes.buttonWrapper}>
                  <span
                    color='error'
                    style={{
                      display: "inline-block",
                      marginLeft: " 5px",
                      marginRight: "5px",
                      marginBottom: "-2px",
                      borderRadius: "50%",
                      position: "absolute",
                      top: "7px",
                      right: "3px",
                      zIndex: 9,
                      height: "8px",
                      width: "8px",
                      backgroundColor: "#D32F2F",
                    }}></span>
                  <Button
                    className='iconBtn'
                    onClick={handleReview}
                    data-testid='article-review'
                    startIcon={
                      <img src={DefaultStateCommentIcon} style={{ width: "20px" }} alt='img' />
                    }></Button>
                </Box>
              ) : (
                enableReferBack(workflow) && (
                  <Box className={classes.buttonWrapper}>
                    <Button
                      className='iconBtn'
                      onClick={handleReview}
                      data-testid='article-review1'
                      startIcon={
                        <img src={DefaultStateCommentIcon} style={{ width: "20px" }} alt='img' />
                      }></Button>
                  </Box>
                )
              )}
            </Box>
            <Box className={`d-flex ${classes.rightBorder} ${classes.spacingLeftRight}`}>
              {workflow?.enable && (
                <WorkflowHistoryIcon
                  enableWorkflowHistory={setEnableWorkflowHistory}
                  workflow_status={workflow.workflow_status}
                />
              )}
              <ToolTip
                className={classes.buttonWrapper}
                component={
                  <Button
                    onClick={() => setShow(true)}
                    startIcon={<img src={SettingNewIcon} alt='img' />}
                    // disabled={true}
                    className='iconBtn'
                    data-testid='socialshare'></Button>
                }
                Title='Social Share, SEO and Analytics are here'
                position='bottom'
              />
            </Box>
            <Box className={`d-flex ${classes.spacingLeftRight}`}>
              {timerState && <Timer lastmodifiedDate={lastmodifiedDate} />}
              <ToolTip
                className={classes.buttonWrapper}
                Title={previewButton ? toolTipText : ""}
                position='bottom'
                component={
                  <Button
                    onClick={handelPreview}
                    disabled={previewButton}
                    startIcon={<img src={Play} alt='img' />}
                    className='iconBtn'
                    data-testid='contentpreview'></Button>
                }
              />
            </Box>
            <Box>
              <ErrorTooltip
                component={
                  <Button
                    className='sm'
                    data-testid='saveasdraft'
                    onClick={() => onSave(false)}
                    disabled={!canAccessAction(category, subCategory, "Create")}
                    variant='secondaryButton'
                    sx={{ marginRight: "12px", marginLeft: "12px" }}>
                    {t("save_as_draft")}
                  </Button>
                }
                doAccess={!canAccessAction(category, subCategory, "Create")}
              />

              <ErrorTooltip
                component={
                  <Button
                    className='sm'
                    variant='primaryButton'
                    onClick={handleClickOpen}
                    data-testid='continue'
                    disabled={
                      workflow?.enable &&
                      workflow?.workflow_status?.toLowerCase() === workflowKeys.published
                    }>
                    {t("continue")}
                  </Button>
                }
                doAccess={
                  workflow?.enable &&
                  workflow?.workflow_status?.toLowerCase() === workflowKeys.published
                }
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            container
            alignItems='flex-end'
            direction='row'
            sx={{
              display: { xs: "flex", em: "none" },
              justifyContent: "center",
              alignItems: "center",
            }}>
            <MarkedFeatured setIsFeatured={setIsFeatured} isFeatured={isFeatured} />
            {workflow?.enable && (
              <WorkflowHistoryIcon
                enableWorkflowHistory={setEnableWorkflowHistory}
                workflow_status={workflow.workflow_status}
              />
            )}

            {comments?.length > 0 ? (
              <Box sx={{ position: "relative" }} className={classes.buttonWrapper}>
                <span
                  color='error'
                  style={{
                    display: "inline-block",
                    marginLeft: " 5px",
                    marginRight: "5px",
                    marginBottom: "-2px",
                    borderRadius: "50%",
                    position: "absolute",
                    top: "7px",
                    right: "3px",
                    zIndex: 9,
                    height: "8px",
                    width: "8px",
                    backgroundColor: "#D32F2F",
                  }}></span>
                <Button
                  className='iconBtn'
                  onClick={handleReview}
                  data-testid='article-review2'
                  startIcon={
                    <img src={DefaultStateCommentIcon} style={{ width: "20px" }} alt='img' />
                  }></Button>
              </Box>
            ) : (
              enableReferBack(workflow) && (
                <Box className={classes.buttonWrapper}>
                  <Button
                    className='iconBtn'
                    onClick={handleReview}
                    data-testid='article-review3'
                    startIcon={
                      <img src={DefaultStateCommentIcon} style={{ width: "20px" }} alt='img' />
                    }></Button>
                </Box>
              )
            )}
            {timerState && <Timer lastmodifiedDate={lastmodifiedDate} />}

            <ToolTip
              className={classes.buttonWrapper}
              component={
                <Button
                  onClick={() => setShow(true)}
                  startIcon={<img src={SettingNewIcon} alt='img' />}
                  className='iconBtn'></Button>
              }
              Title='Social Share, SEO and Analytics are here'
              position='bottom'
            />
            <ToolTip
              className={classes.buttonWrapper}
              Title={previewButton ? toolTipText : ""}
              position='bottom'
              component={
                <Button
                  onClick={handelPreview}
                  disabled={previewButton}
                  startIcon={<img src={Play} alt='img' />}
                  className='iconBtn'></Button>
              }
            />

            <ErrorTooltip
              className={classes.mr2}
              component={
                <Button
                  variant='secondaryButton'
                  sx={{
                    minWidth: "0px",
                    height: "30px !important",
                    width: "30px",
                    "& .Platform-x-Button-startIcon": {
                      margin: 0,
                    },
                  }}
                  startIcon={<TelegramIcon />}
                  onClick={() => onSave(false)}
                  disabled={!canAccessAction(category, subCategory, "Create")}></Button>
              }
              doAccess={!canAccessAction(category, subCategory, "Create")}
            />
            <ErrorTooltip
              component={
                <Button
                  variant='secondaryButton'
                  startIcon={<SaveAsRoundedIcon />}
                  sx={{
                    minWidth: "0px",
                    height: "30px !important",
                    width: "30px",
                    "& .Platform-x-Button-startIcon": {
                      margin: 0,
                    },
                  }}
                  onClick={handleClickOpen}
                  disabled={
                    workflow?.enable &&
                    workflow?.workflow_status?.toLowerCase() === workflowKeys.published
                  }></Button>
              }
              doAccess={
                workflow?.enable &&
                workflow?.workflow_status?.toLowerCase() === workflowKeys.published
              }
            />
          </Grid>
        </Box>
      </Box>

      <PublishSocialShare
        open={show}
        handleClose={() => setShow(false)}
        state={state}
        setState={setState}
        socialOgTags={socialOgTags}
        setSocialOgTags={setSocialOgTags}
        updateStructureDataArticle={updateStructureDataArticle}
      />
    </>
  );
};

export default TopBar;
