import { CreateNewPage } from "@platformx/content";
import { capitalizeWords, DashboardCardLoader, NoSearchResult } from "@platformx/utilities";
import { useState } from "react";
import { useNavigate } from "react-router-dom-v6";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Card from "./Card";
import { useCustomStyle } from "./cart.style";
import { SETTINGS } from "./utils/constants";
import { getBgColorArray } from "./utils/helper";

type CardSliderProps = {
  createContent: any;
  loading?: boolean;
};
const CardSlider = ({ createContent, loading }: CardSliderProps) => {
  const colorList = getBgColorArray(createContent?.length) || "";
  const classes = useCustomStyle();
  const navigate = useNavigate();
  const [createPage, setCreatePage] = useState<boolean>(false);

  /**
   * navigate to page or another contentType
   * @param params object
   */
  const handleCardClick = (params: any) => {
    const { CTAText = "", url = "" } = params;
    if (CTAText.toLowerCase().includes("create a page")) {
      setCreatePage(!createPage);
    } else {
      navigate(url);
    }
  };

  return (
    <>
      {loading ? (
        <DashboardCardLoader />
      ) : createContent?.length > 0 ? (
        <Slider {...SETTINGS} className={`${classes.dashboardCardSlider} CardSliderDashboard`}>
          {createContent.map(
            (item: any, index: number) =>
              item.url !== "" && (
                <Card
                  key={index}
                  handleCardClick={handleCardClick}
                  ImageUrl={item?.image_1.ImageCropUrl?.CropUrl.Web}
                  BgColor={colorList[index]}
                  CTAText={capitalizeWords(item?.title)}
                  url={item.url}
                />
              ),
          )}
        </Slider>
      ) : (
        <NoSearchResult />
      )}

      {/* create new page from dashboard */}
      {createPage && (
        <CreateNewPage
          isDialogOpen={createPage}
          closeButtonHandle={() => setCreatePage(!createPage)}
        />
      )}
    </>
  );
};

export default CardSlider;
