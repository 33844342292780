import { Box, Container, Grid, Typography } from "@mui/material";
import { Loader } from "@platformx/utilities";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import BasicButton from "../../../components/BasicButton/BasicButton";
import ImageRender from "../../../components/ImageRender";
import { getAccessToken, getRegisteredProducts } from "../HelperFunction";
import ProductCard from "../ProductCard/ProductCard";
import Replacement from "../Replacement/Replacement";
import { useCustomStyle } from "../ZaggEcommerce.style";
import ProductCardSkeleton from "./ProductCardSkeleton";

const RegisteredProduct = ({
  authoringHelper,
  secondaryArgs,
  analytics,
  content,
}: RegisteredProductProp) => {
  const ButtonObj1 = {
    Button_Name: "Button1_Name",
    Button_RedirectURL: "Button1_RedirectURL",
    Button_Type: "Button1_Type",
    Button_Value: "Button1_Value",
    Button_Action: "Button1_Action",
    Button_Content: "Button1_Content",
  };

  const ButtonDataObj1 = {
    Button_Name: content.Button1_Name,
    Button_RedirectURL: content.Button1_RedirectURL,
    Button_Type: content.Button1_Type,
    Button_Value: content.Button1_Value,
    Button_Action: content.Button1_Action,
    Button_Content: content?.Button1_Content,
  };
  const ButtonObj2 = {
    Button_Name: "Button2_Name",
    Button_RedirectURL: "Button2_RedirectURL",
    Button_Type: "Button2_Type",
    Button_Value: "Button2_Value",
    Button_Action: "Button2_Action",
    Button_Content: "Button2_Content",
  };
  const ButtonDataObj2 = {
    Button_Name: content.Button2_Name,
    Button_RedirectURL: content.Button2_RedirectURL,
    Button_Type: content.Button2_Type,
    Button_Value: content.Button2_Value,
    Button_Action: content.Button2_Action,
    Button_Content: content?.Button2_Content,
  };

  const ButtonObj3 = {
    Button_Name: "Button3_Name",
    Button_RedirectURL: "Button3_RedirectURL",
    Button_Type: "Button3_Type",
    Button_Value: "Button3_Value",
    Button_Action: "Button3_Action",
    Button_Content: "Button3_Content",
  };
  const ButtonDataObj3 = {
    Button_Name: content.Button3_Name,
    Button_RedirectURL: content.Button3_RedirectURL,
    Button_Type: content.Button3_Type,
    Button_Value: content.Button3_Value,
    Button_Action: content.Button3_Action,
    Button_Content: content?.Button3_Content,
  };

  const ButtonObj4 = {
    Button_Name: "Button4_Name",
    Button_RedirectURL: "Button4_RedirectURL",
    Button_Type: "Button4_Type",
    Button_Value: "Button4_Value",
    Button_Action: "Button4_Action",
    Button_Content: "Button4_Content",
  };
  const ButtonDataObj4 = {
    Button_Name: content.Button4_Name,
    Button_RedirectURL: content.Button4_RedirectURL,
    Button_Type: content.Button4_Type,
    Button_Value: content.Button4_Value,
    Button_Action: content.Button4_Action,
    Button_Content: content?.Button4_Content,
  };

  const [loader, setLoader] = useState(false);
  const [products, setProducts] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [token, setToken] = useState("");
  const classes = useCustomStyle();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const fetchMoreProducts = async () => {
    const nextPage = page + 10;
    const row = nextPage + 10;
    // Simulating an API call for more products
    const newProducts = await getRegisteredProducts(
      secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq,
      secondaryArgs?.sitename,
      secondaryArgs?.mainSellerHost,
      nextPage,
      row,
    );
    const fetchMoreProductsData = newProducts?.data || [];
    const fetchedProductsTotal = newProducts?.total;

    if (row > fetchedProductsTotal) {
      setHasMore(false); // No more data to load
    } else {
      setProducts((prevProducts) => [...prevProducts, ...fetchMoreProductsData]);
      setPage(nextPage); // Update page for next fetch
      setHasMore(true);
    }
  };
  const apiCalls = async () => {
    setLoader(true);
    const tokennew = await getAccessToken();
    setToken(tokennew);
    const res = await getRegisteredProducts(
      secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq,
      secondaryArgs?.sitename,
      secondaryArgs?.mainSellerHost,
    );
    const fetchedProductsTotal = res?.total;
    const fetchedProductsTotalRows = res?.rows;
    setProducts(res.data);
    if (fetchedProductsTotal > fetchedProductsTotalRows) {
      setHasMore(true); // Update page for next fetch
    } else {
      setHasMore(false); // No more data to load
    }
    setLoader(false);
  };
  const handleItemClick = (selectedItem) => {
    setSelectedProduct(selectedItem);
  };
  const onBackClick = () => {
    setSelectedProduct(null);
  };

  useEffect(() => {
    apiCalls();
  }, []);
  return (
    <Box
      ref={authoringHelper?.innerRef}
      className={`${classes.zaggEcommerceWarpper} zaggEcommerceContainer`}>
      {loader ? (
        <Loader />
      ) : !selectedProduct ? (
        <Container
          className={
            authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
          }>
          <Box className='productRegistration registeredProduct'>
            <Grid container>
              <Grid item xs={12} md={7} lg={8} className='formcontainerLeft'>
                <Typography variant='h4semibold' id='title' mb={5}>
                  {content?.title}
                </Typography>
                <Box id='scrollableDiv1' className={`${classes.scrollablWarpper}`}>
                  {products?.length > 0 ? (
                    <InfiniteScroll
                      dataLength={products?.length}
                      next={fetchMoreProducts}
                      hasMore={hasMore}
                      loader={<ProductCardSkeleton />} // Custom loader while fetching more products
                      scrollableTarget='scrollableDiv1'>
                      {products?.map((order) => (
                        <React.Fragment key={order?.id}>
                          {order?.lineItem.map((lineItem) => (
                            <ProductCard
                              key={lineItem?.productSequenceNumber}
                              productItem={{
                                ...lineItem,
                                orderid: order?.id,
                                deliveryStatus: order?.shipmentState,
                                deliveryDate: order?.lastModifiedAt,
                                shippingAddress: order?.shippingAddress,
                              }}
                              buttonName='Get A Replacment'
                              onItemClick={handleItemClick}
                            />
                          ))}
                        </React.Fragment>
                      ))}
                    </InfiniteScroll>
                  ) : (
                    <Typography variant='body1' align='center'>
                      No Data Found
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={5} lg={4}>
                <Box className='formContainerRight'>
                  <Box className='formContainerRight'>
                    <Box className='rightPanel'>
                      {/* <SideBar /> */}

                      <Box className='sidebar'>
                        <Typography variant='h3semibold' gutterBottom>
                          Quick Links
                        </Typography>
                        <Box>
                          <BasicButton
                            openButtonEditWindow={
                              authoringHelper?.openButtonEditWindowInAuthoringCB
                            }
                            isAuthoring={analytics?.isAuthoring}
                            currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
                            variant='defaultButton4'
                            analyticsEnabled={analytics?.isAnalyticsEnabled}
                            ButtonObj={ButtonObj1}
                            isEditing={authoringHelper?.isEditing}
                            buttonDataObj={ButtonDataObj1}
                            secondaryArgs={secondaryArgs}
                            analytics={analytics}
                          />
                          <BasicButton
                            openButtonEditWindow={
                              authoringHelper?.openButtonEditWindowInAuthoringCB
                            }
                            isAuthoring={analytics?.isAuthoring}
                            currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
                            variant='defaultButton4'
                            analyticsEnabled={analytics?.isAnalyticsEnabled}
                            ButtonObj={ButtonObj2}
                            isEditing={authoringHelper?.isEditing}
                            buttonDataObj={ButtonDataObj2}
                            secondaryArgs={secondaryArgs}
                            analytics={analytics}
                          />
                          <BasicButton
                            openButtonEditWindow={
                              authoringHelper?.openButtonEditWindowInAuthoringCB
                            }
                            isAuthoring={analytics?.isAuthoring}
                            currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
                            variant='defaultButton4'
                            analyticsEnabled={analytics?.isAnalyticsEnabled}
                            ButtonObj={ButtonObj3}
                            isEditing={authoringHelper?.isEditing}
                            buttonDataObj={ButtonDataObj3}
                            secondaryArgs={secondaryArgs}
                            analytics={analytics}
                          />
                          <BasicButton
                            openButtonEditWindow={
                              authoringHelper?.openButtonEditWindowInAuthoringCB
                            }
                            isAuthoring={analytics?.isAuthoring}
                            currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
                            variant='defaultButton4'
                            analyticsEnabled={analytics?.isAnalyticsEnabled}
                            ButtonObj={ButtonObj4}
                            isEditing={authoringHelper?.isEditing}
                            buttonDataObj={ButtonDataObj4}
                            secondaryArgs={secondaryArgs}
                            analytics={analytics}
                          />
                        </Box>
                        <Box mt={5}>
                          <ImageRender
                            originalImage={content?.ImageCompound?.ImageCompound_1?.original_image}
                            publishedImages={
                              content?.ImageCompound?.ImageCompound_1?.published_images
                            }
                            secondaryArgs={secondaryArgs}
                            cropType={content?.cropType?.toLowerCase()}
                            imgOrder={{
                              1440: "landscape",
                              1280: "landscape",
                              1024: "landscape",
                              768: "landscape",
                              600: "landscape",
                              320: "landscape",
                            }}
                            index={0}
                            isEditing={authoringHelper?.isEditing}
                          />
                          {/* <img src={AccountCircle} alt='quick links' width='100%' /> */}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      ) : (
        <Replacement
          authoringHelper={authoringHelper}
          // products={products}
          selectedProduct={selectedProduct}
          onBackClick={onBackClick}
          onItemClick={handleItemClick}
          token={token}
          secondaryArgs={secondaryArgs}
        />
      )}
    </Box>
  );
};
interface RegisteredProductProp {
  content: Content;
  analytics: Analytics;
  authoringHelper?: AuthoringHelper;
  secondaryArgs?: any;
}

interface Analytics {
  pageId?: number;
  prelemId?: number;
  pageTitle?: string;
  prelemTitle?: string;
  pageDesc?: string;
  pageTags?: string;
  prelemTags?: string;
  prelemPosition?: number;
  isAnalyticsEnabled: boolean;
  isAuthoring: boolean;
  isSeoEnabled: boolean;
}

interface AuthoringHelper {
  innerRef: React.Ref<HTMLDivElement>;
  sendStructureDataToAuthoringCB: (structureData: string) => void;
  sendDefaultStructureDataForResetToAuthoringCB: (structureData: string) => void;
  openButtonEditWindowInAuthoringCB: (buttonObj?: object, e?: object) => void;
  selectedButtonNameForEditing: string;
  isEditing: boolean;
  buttonRef?: React.Ref<HTMLButtonElement>;
  buttonContentEditable?: boolean;
  lastSavedStructuredData?: string;
  isEditPage?: boolean;
}

interface Content {
  WelcomeText?: string;
  title?: string;
  Button1_Action?: string;
  Button1_Content?: string;
  Button1_Name?: string;
  Button1_RedirectURL?: string;
  Button1_RestEndPonit?: string;
  Button1_Type?: string;
  Button1_Value?: string;

  Button2_Action?: string;
  Button2_Content?: string;
  Button2_Name?: string;
  Button2_RedirectURL?: string;
  Button2_RestEndPonit?: string;
  Button2_Type?: string;
  Button2_Value?: string;

  Button3_Action?: string;
  Button3_Content?: string;
  Button3_Name?: string;
  Button3_RedirectURL?: string;
  Button3_RestEndPonit?: string;
  Button3_Type?: string;
  Button3_Value?: string;

  Button4_Action?: string;
  Button4_Content?: string;
  Button4_Name?: string;
  Button4_RedirectURL?: string;
  Button4_RestEndPonit?: string;
  Button4_Type?: string;
  Button4_Value?: string;

  cropType: "single" | "multiple";
  aspectRatio?: any[];
  TagName?: string;
  ImageCompound: {
    ImageCompound_1: {
      published_images: ImageType[];
      original_image?: object;
    };
  };
}
interface ImageType {
  aspect_ratio: string;
  bucket_path: string;
  folder_path: string;
  visibility: string;
  ext: string;
}
RegisteredProduct.defaultProps = {
  content: {
    Button1_Action: "External",
    Button1_Name: "Register Product",
    Button1_RedirectURL: "https://www.google.com/",
    Button1_RestEndPonit: "RestEndPoint 1",
    Button1_Type: "current window",
    Button1_Value: "Register Product",

    Button2_Action: "External",
    Button2_Name: "Security",
    Button2_RedirectURL: "https://www.google.com/",
    Button2_RestEndPonit: "Warranty Replacement",
    Button2_Type: "current window",
    Button2_Value: "Warranty Replacement",

    Button3_Action: "External",
    Button3_Name: "Purchase Extended Warranty",
    Button3_RedirectURL: "https://www.google.com/",
    Button3_RestEndPonit: "RestEndPoint 3",
    Button3_Type: "current window",
    Button3_Value: "Purchase Extended Warranty",

    Button4_Action: "External",
    Button4_Name: "Verification",
    Button4_RedirectURL: "https://www.google.com/",
    Button4_RestEndPonit: "RestEndPoint 4",
    Button4_Type: "Redeem Your Rewards",
    Button4_Value: "Redeem Your Rewards",
    TagName: "SiteComponents",
    title: "Registered Products",
    PrelemContentType: ["Select"],
    cropType: "single",
    ImageCompound: {
      ImageCompound_1: {
        original_image: {
          original_image_relative_path: "machine_assets/1716814685629/public/png/Register-Product",
          visibility: "public",
          ext: "png",
          bitStreamId: "",
          auto: true,
          MetaFields: {
            AltText: "ProductRegistration",
            Name: "ProductRegistration",
            Title: "ProductRegistration",
            Description: "This is for ProductRegistration",
            Attribution: false,
          },
        },
        published_images: [
          {
            aspect_ratio: "hero",
            folder_path: "machine_assets/1716814685629/public/png/Register-Product",
          },
          {
            aspect_ratio: "portrait",
            folder_path: "machine_assets/1716814685629/public/png/Register-Product",
          },
          {
            aspect_ratio: "landscape",
            folder_path: "machine_assets/1716814685629/public/png/Register-Product",
          },
          {
            aspect_ratio: "card1",
            folder_path: "machine_assets/1716814685629/public/png/Register-Product",
          },
          {
            aspect_ratio: "square",
            folder_path: "machine_assets/1716814685629/public/png/Register-Product",
          },
          {
            aspect_ratio: "card2",
            folder_path: "machine_assets/1716814685629/public/png/Register-Product",
          },
        ],
      },
    },
  },
  authoringHelper: {
    innerRef: null,
    sendStructureDataToAuthoringCB: () => {},
    sendDefaultStructureDataForResetToAuthoringCB: () => {},
    openButtonEditWindowInAuthoringCB: () => {},
    selectedButtonNameForEditing: "",
    isEditing: false,
    buttonRef: null,
    buttonContentEditable: false,
    lastSavedStructuredData: "",
    isEditPage: false,
  },

  analytics: {
    isSeoEnabled: false,
    isAuthoring: false,
    isAnalyticsEnabled: true,
    position: 0,
    pageId: 1234,
    prelemId: 2345,
    pageTitle: "Website Introduction 2",
    pageDesc:
      "The Prelem ‘Website Introduction 2’ can be used to give an introduction to your website. It has multiple text fields & 5 CTA which would allow you to add text in form of heading, sub heading, description etc & you can attach multiple links/assets to the CTAs.",
    pageTags: "Page Tags1, page tagg2",
    prelemTags: "Website, Introduction, Website Introduction, Image, CTA, Title, Hero Banner",
  },
  secondaryArgs: {
    prelemBaseEndpoint: {
      APIEndPoint: "https://platx-api-dev.fanuep.com/platform-x/",
      device: "window",
      buttonBaseUrl: "https://platx-publish-dev.fanuep.com/",
    },
    editState: false,
    multiSlot: {},
    gcpUrl: "https://storage.googleapis.com",
    bucketName: "cropped_image_public",
  },
};
export default RegisteredProduct;
