import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import TelegramIcon from "@mui/icons-material/Telegram";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { RootState, setIsCommentPanelOpen, setIsReviewEnabled } from "@platformx/authoring-state";
import { DefaultStateCommentIcon, PlayPreview } from "@platformx/shared/static-assets";
import {
  ErrorTooltip,
  MarkedFeatured,
  SubmitButton,
  Timer,
  XToolTip,
  enableReferBack,
  hideMarkedFeatureIcon,
  useAccess,
} from "@platformx/utilities";
import { WorkflowHistoryIcon } from "@platformx/workflow-management";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./CreateHeader.style";
import { HeaderProps } from "./Header.types";

export const CreateHeader = ({
  id,
  hasPreviewButton,
  handleReturn,
  handlePublish,
  handleSaveOrPublish,
  handelPreview,
  editText,
  createText,
  toolTipText,
  isQuiz,
  hasPublishButton = false,
  hasSaveButton = false,
  publishText,
  saveText,
  showPreview = true,
  category,
  subCategory,
  workflow,
  hasTimerState,
  lastModifiedDate,
  createComment,
  setIsFeatured,
  isFeatured,
  setEnableWorkflowHistory,
  publishDisabled,
}: HeaderProps) => {
  const { canAccessAction } = useAccess();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = window.location;
  const pathArray = pathname?.split("/");
  const hideFeatureIcon = hideMarkedFeatureIcon(pathArray);

  const { isReviewEnabled, comments } = useSelector(
    (state: RootState) => state.comment.commentInfo,
  );
  const handleReview = () => {
    dispatch(setIsReviewEnabled(!isReviewEnabled));
    dispatch(setIsCommentPanelOpen({ value: true }));
  };
  useEffect(() => {
    if (enableReferBack(workflow) || comments?.length > 0) {
      dispatch(setIsReviewEnabled(true));
    } else {
      dispatch(setIsReviewEnabled(false));
    }
  }, [isReviewEnabled, workflow, comments]);
  const noWeb = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <Grid
      container //spacing={2}
      // className={className}
      sx={
        isQuiz
          ? {
              backgroundColor: "#ffffff",
              padding: "10px",
              margin: "0px",
              display: "flex",
              alignItems: "center",
              minHeight: "62px",
            }
          : {
              padding: "10px",
              margin: "0px",
              display: "flex",
              alignItems: "center",
              minHeight: "62px",
            }
      }>
      <Grid item xs={2} em={publishText ? 3 : 4} sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          aria-label='west'
          size='medium'
          onClick={handleReturn}
          data-testid='return-header-icon'
          disableRipple
          disableFocusRipple>
          <KeyboardBackspaceRoundedIcon sx={{ color: theme.palette.customColor.PRIMARY }} />
        </IconButton>
        {/* <Button
          variant='text'
          disableRipple
          disableFocusRipple
          startIcon={<ArrowBack />}
          sx={{
            minWidth: "0px",
            textTransform: "capitalize",
            "&:hover": { backgroundColor: "transparent" },
            padding: "0px",
          }}
          onClick={handleReturn}></Button> */}
        {!noWeb && <Typography variant='h4bold'>{id ? editText : createText}</Typography>}
      </Grid>
      <Grid
        item
        em={publishText ? 9 : 8}
        sx={{ display: { xs: "none", em: "flex" }, alignItems: "center" }}
        direction='row-reverse'
        container
        alignItems='flex-end'>
        {publishText && (
          <SubmitButton
            category={category}
            subCategory={subCategory}
            workflow={workflow}
            handlePublish={handlePublish}
            handleSave={handleSaveOrPublish}
            createComment={createComment}
            prelemEditState={hasPublishButton}
            publishDisabled={publishDisabled}
          />
        )}
        <ErrorTooltip
          component={
            <Button
              type='submit'
              data-testid='save'
              variant='secondaryButton'
              disabled={!canAccessAction(category, subCategory, "Create") || hasSaveButton}
              className='sm'
              sx={{ marginRight: "12px", marginLeft: "6px" }}
              onClick={() => handleSaveOrPublish(false)}>
              {saveText}
            </Button>
          }
          doAccess={!canAccessAction(category, subCategory, "Create")}
        />
        {showPreview && (
          <XToolTip
            className={classes.buttonWrapper}
            Title={hasPreviewButton ? toolTipText : ""}
            position='bottom'
            component={
              <Button
                disabled={hasPreviewButton}
                data-testid='preview'
                startIcon={<img src={PlayPreview} alt='' />}
                onClick={handelPreview}
                className='iconBtn'></Button>
            }
          />
        )}
        {hasTimerState && <Timer lastmodifiedDate={lastModifiedDate} />}

        {(showPreview || hasTimerState) && (
          <Divider orientation='vertical' variant='middle' flexItem />
        )}
        {
          // enableReferBack(workflow) ||
          comments?.length > 0 ? (
            <Box sx={{ position: "relative" }} className={classes.buttonWrapper}>
              <span
                color='error'
                style={{
                  display: "inline-block",
                  marginLeft: " 5px",
                  marginRight: "5px",
                  marginBottom: "-2px",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "7px",
                  right: "3px",
                  zIndex: 9,
                  height: "8px",
                  width: "8px",
                  backgroundColor: "#D32F2F",
                }}>
                {" "}
              </span>
              <Button
                aria-label='chat'
                data-testid='preview'
                onClick={handleReview}
                className='iconBtn'
                startIcon={
                  <img src={DefaultStateCommentIcon} alt='comments' width='20px' />
                }></Button>
            </Box>
          ) : (
            enableReferBack(workflow) && (
              <Box className={classes.buttonWrapper}>
                <Button
                  aria-label='chat'
                  data-testid='preview'
                  onClick={handleReview}
                  className='iconBtn'
                  startIcon={
                    <img src={DefaultStateCommentIcon} alt='comments' width='20px' />
                  }></Button>
              </Box>
            )
          )
        }
        {workflow?.enable && (
          <WorkflowHistoryIcon
            enableWorkflowHistory={setEnableWorkflowHistory}
            workflow_status={workflow?.workflow_status}
          />
        )}
        {hideFeatureIcon && (
          <MarkedFeatured setIsFeatured={setIsFeatured} isFeatured={isFeatured} />
        )}
      </Grid>
      <Grid
        item
        xs={10}
        sx={{ display: { xs: "flex", em: "none" } }}
        direction='row-reverse'
        container
        alignItems='flex-end'>
        {publishText && (
          <ErrorTooltip
            component={
              <Button
                startIcon={<TelegramIcon />}
                type='submit'
                sx={{ minWidth: "0px" }}
                onClick={() => {
                  if (handlePublish) {
                    handlePublish();
                  }
                }}
                disabled={
                  !canAccessAction(category, subCategory, "Publish") || hasPublishButton
                }></Button>
            }
            doAccess={!canAccessAction(category, subCategory, "Publish")}
          />
        )}

        <ErrorTooltip
          component={
            <Button
              type='submit'
              startIcon={<SaveAsRoundedIcon />}
              sx={{ minWidth: "0px" }}
              onClick={() => handleSaveOrPublish()}
              data-testid='save'
              disabled={
                !canAccessAction(category, subCategory, "Create") || hasSaveButton
              }></Button>
          }
          doAccess={!canAccessAction(category, subCategory, "Create")}
        />
        {showPreview && (
          <Tooltip title={hasPreviewButton ? (toolTipText ?? "") : ""} placement='left'>
            <span style={{ cursor: "pointer" }}>
              <Button
                disabled={hasPreviewButton}
                data-testid='preview'
                startIcon={<VisibilityRoundedIcon />}
                sx={{ minWidth: "0px" }}
                onClick={handelPreview}></Button>
            </span>
          </Tooltip>
        )}
        {hasTimerState && <Timer lastmodifiedDate={lastModifiedDate} />}
        {workflow?.enable && (
          <WorkflowHistoryIcon
            enableWorkflowHistory={setEnableWorkflowHistory}
            workflow_status={workflow?.workflow_status}
          />
        )}
        {hideFeatureIcon && (
          <MarkedFeatured setIsFeatured={setIsFeatured} isFeatured={isFeatured} />
        )}
      </Grid>
    </Grid>
  );
};
