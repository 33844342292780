import { useMediaQuery, useTheme } from "@mui/material";
import { usePage } from "@platformx/authoring-apis";
import {
  cancelcrossicon,
  CardOptionApprovalStatusIcon,
  CardOptionDeleteIcon,
  CardOptionDuplicateIcon,
  CardOptionEditIcon,
  CardOptionUnPublishIcon,
  CardOptionViewIcon,
  ReScheduleToPublish,
  ReScheduleToUnpublish,
} from "@platformx/shared/static-assets";
import {
  capitalizeFirstLetter,
  CommonPlateformXDialog,
  getCurrentLang,
  XMenu,
  XMenuItem,
} from "@platformx/utilities";
import { WorkflowStepper } from "@platformx/workflow-management";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateNewPage from "../../pages/page/CreateNewPage";
import { CARD_MENUS, MenuActions } from "./utils/constants";

const PageMenu = (props) => {
  const { t } = useTranslation();
  const { anchorEl, open, listItemDetails, handleMenuClose, category, subCategory } = props;
  const {
    editPage,
    viewPage,
    previewPage,
    unPublishPage,
    handleReschedulePopup,
    handleCancelTriggerPopup,
    cancelPublishUnpublishTrigger,
    handlePageDelete,
    handleDuplicatePopup,
  } = usePage();
  const [cancelTriggerType, setCancelTriggerType] = useState("");
  const theme = useTheme();
  const tabView = useMediaQuery(theme.breakpoints.down("em"));
  const {
    status,
    scheduledPublishTriggerDateTime,
    scheduledUnPublishTriggerDateTime,
    // lastPublishedDate,
  } = listItemDetails;
  const [menuActions, setMenuActions] = useState({
    duplicate: false,
    delete: false,
    publish: false,
    reschedulepublish: false,
    rescheduleunpublish: false,
    unpublish: false,
    pageunpublish: false,
    canceltrigger: false,
    approvalStatus: false,
  });
  const [data, setData] = useState({});
  const handleDeletePopup = (pageSelected) => {
    if (pageSelected.status === "published") {
      setMenuActions({ ...menuActions, pageunpublish: true });
      setData(pageSelected);
    }
    if (pageSelected.status === "draft" && pageSelected.scheduledPublishTriggerDateTime != null) {
      setMenuActions({ ...menuActions, pageunpublish: true });
      setData(pageSelected);
    }
    if (
      (pageSelected.status === "draft" && pageSelected.scheduledPublishTriggerDateTime == null) ||
      pageSelected.status === "unpublished"
    ) {
      setMenuActions({ ...menuActions, delete: true });
      setData(pageSelected);
    }
  };

  const onHandleMenuActions = (action) => {
    switch (action) {
      case MenuActions.EDIT:
        editPage(listItemDetails);
        break;
      case MenuActions.VIEW:
        viewPage(listItemDetails);
        break;
      case MenuActions.PREVIEW:
        previewPage(listItemDetails);
        break;
      case MenuActions.DUPLICATE:
        setMenuActions({ ...menuActions, duplicate: true });
        handleDuplicatePopup(true, listItemDetails);
        break;
      case MenuActions.DELETE:
        handleDeletePopup(listItemDetails);
        break;
      case MenuActions.UNPUBLISH:
        setMenuActions({ ...menuActions, unpublish: true });
        break;
      case MenuActions.RESCHEDULE_PUBLISH:
        setMenuActions({ ...menuActions, reschedulepublish: true });
        handleReschedulePopup(listItemDetails);
        break;
      case MenuActions.RESCHEDULE_UNPUBLISH:
        setMenuActions({ ...menuActions, rescheduleunpublish: true });
        handleReschedulePopup(listItemDetails);
        break;
      case MenuActions.CANCEL_PUBLISH:
        setMenuActions({ ...menuActions, canceltrigger: true });
        handleCancelTriggerPopup(listItemDetails);
        setCancelTriggerType("1");
        break;
      case MenuActions.CANCEL_UNPUBLISH:
        setMenuActions({ ...menuActions, canceltrigger: true });
        handleCancelTriggerPopup(listItemDetails);
        setCancelTriggerType("2");
        break;
      case MenuActions.APPROVAL_STATUS:
        setMenuActions({ ...menuActions, approvalStatus: true });
        break;
      default:
        break;
    }
    handleMenuClose();
  };

  const handleClose = () => {
    setMenuActions({
      duplicate: false,
      delete: false,
      publish: false,
      reschedulepublish: false,
      rescheduleunpublish: false,
      unpublish: false,
      pageunpublish: false,
      canceltrigger: false,
      approvalStatus: false,
    });
  };

  const handleUnpublishPage = () => {
    unPublishPage(listItemDetails);
    handleClose();
  };

  const handleCancelTrigger = () => {
    const requestDto = {
      page: listItemDetails.page,
      currentpageurl: listItemDetails.currentPageUrl,
      parentpageurl: listItemDetails.parentPageUrl,
    };
    cancelPublishUnpublishTrigger(cancelTriggerType, requestDto, listItemDetails);
    handleClose();
  };

  const handleConfirmDelete = () => {
    handlePageDelete(data);
    handleClose();
  };

  useEffect(() => {
    localStorage.setItem("lang", getCurrentLang());
  }, []);

  return (
    <>
      {menuActions.duplicate && (
        <CreateNewPage
          isDialogOpen={menuActions.duplicate}
          closeButtonHandle={handleClose}
          pageNameInitial={listItemDetails.title}
          isDuplicateValue={true}></CreateNewPage>
      )}
      {menuActions.unpublish && (
        // <PlateformXDialog
        //   isDialogOpen={menuActions.unpublish}
        //   title={t("page_unpublish_title")}
        //   subTitle={t("page_unpublish_subtitle")}
        //   closeButtonText={t("no")}
        //   confirmButtonText={t("yes")}
        //   closeButtonHandle={handleClose}
        //   confirmButtonHandle={handleUnpublishPage}
        // />
        <CommonPlateformXDialog
          isDialogOpen={menuActions.unpublish}
          title={t("page_unpublish_title")}
          subTitle={t("page_unpublish_subtitle")}
          closeButtonText={t("no")}
          closeButtonHandle={handleClose}
          confirmButtonText={t("yes")}
          confirmButtonHandle={handleUnpublishPage}
          modalType='delete'
        />
      )}
      {menuActions.canceltrigger && (
        <CommonPlateformXDialog
          isDialogOpen={menuActions.canceltrigger}
          title={t("cancel_schedule")}
          subTitle={t("cancel_schedule_message")}
          closeButtonText={t("no")}
          confirmButtonText={t("yes")}
          closeButtonHandle={handleClose}
          confirmButtonHandle={handleCancelTrigger}
        />
      )}
      {menuActions.delete && (
        <CommonPlateformXDialog
          isDialogOpen={menuActions.delete}
          title={t("page_delete_title")}
          subTitle={t("page_delete_subtitle")}
          closeButtonText={t("no")}
          confirmButtonText={t("yes")}
          closeButtonHandle={handleClose}
          confirmButtonHandle={handleConfirmDelete}
        />
      )}
      {menuActions.pageunpublish && (
        <CommonPlateformXDialog
          isDialogOpen={menuActions.pageunpublish}
          title={t("page_delete_title")}
          subTitle={t("page_delete_subtitle")}
          closeButtonText={t("no")}
          confirmButtonText={t("yes")}
          closeButtonHandle={handleClose}
          confirmButtonHandle={handleConfirmDelete}
        />
      )}
      {menuActions.approvalStatus && (
        <WorkflowStepper
          open={menuActions.approvalStatus}
          setOpen={handleClose}
          path={listItemDetails?.path}
          contentType={capitalizeFirstLetter(listItemDetails?.tagName?.toLowerCase())}
        />
      )}
      <XMenu anchorEl={anchorEl} handleMenuClose={handleMenuClose} open={open}>
        {status === "published" ? (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='view'
            menuCallBack={onHandleMenuActions}
            menuAction='view'
            icon={CardOptionViewIcon}
            label={"view"}
          />
        ) : (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='view'
            menuCallBack={onHandleMenuActions}
            menuAction='preview'
            icon={CardOptionViewIcon}
            label={"preview"}
          />
        )}
        {(scheduledPublishTriggerDateTime === null ||
          scheduledPublishTriggerDateTime === undefined) &&
          (scheduledUnPublishTriggerDateTime === null ||
            scheduledUnPublishTriggerDateTime === undefined) &&
          tabView && (
            <XMenuItem
              category={category}
              subCategory={subCategory}
              action='update'
              menuCallBack={onHandleMenuActions}
              menuAction='edit'
              icon={CardOptionEditIcon}
              label={CARD_MENUS.EDIT.displayName}
            />
          )}
        <XMenuItem
          category={category}
          subCategory={subCategory}
          action='update'
          menuCallBack={onHandleMenuActions}
          menuAction='duplicate'
          icon={CardOptionDuplicateIcon}
          label={CARD_MENUS.DUPLICATE.displayName}
        />
        {tabView && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='delete'
            menuCallBack={onHandleMenuActions}
            menuAction='delete'
            icon={CardOptionDeleteIcon}
            label={CARD_MENUS.DELETE.displayName}
          />
        )}
        {status === "published" && scheduledUnPublishTriggerDateTime == null && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='unpublish'
            menuCallBack={onHandleMenuActions}
            menuAction='unpublish'
            icon={CardOptionUnPublishIcon}
            label={CARD_MENUS.UNPUBLISH.displayName}
          />
        )}
        {scheduledPublishTriggerDateTime && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='reschedule_publish'
            menuCallBack={onHandleMenuActions}
            menuAction='reschedule_publish'
            icon={ReScheduleToPublish}
            label='reschedule_publish'
          />
        )}
        {scheduledPublishTriggerDateTime && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='cancel_publish'
            menuCallBack={onHandleMenuActions}
            menuAction='cancel_publish'
            icon={cancelcrossicon}
            label={"cancel_publish"}
          />
        )}
        {scheduledUnPublishTriggerDateTime && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='reschedule_unpublish'
            menuCallBack={onHandleMenuActions}
            menuAction='reschedule_unpublish'
            icon={ReScheduleToUnpublish}
            label={"reschedule_unpublish"}
          />
        )}
        {scheduledUnPublishTriggerDateTime && (
          <XMenuItem
            category={category}
            subCategory={subCategory}
            action='cancel_unpublish'
            menuCallBack={onHandleMenuActions}
            menuAction='cancel_unpublish'
            icon={cancelcrossicon}
            label={"cancel_unpublish"}
          />
        )}
        <XMenuItem
          category={category}
          subCategory={subCategory}
          action='view'
          menuCallBack={onHandleMenuActions}
          menuAction='approval_status'
          icon={CardOptionApprovalStatusIcon}
          label={"approval_status"}
        />
      </XMenu>
    </>
  );
};

export default memo(PageMenu);
