import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Container, FormControl, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DropDown from "../../../components/DropDown/Dropdown";
import { AmountToDollar, fetchEcomProducts, getReplacmentOptions } from "../HelperFunction";
import { NoProduct, OptionalIcon, ReplacmentIcon, SelectIcon } from "../Image";
import ProductCard from "../ProductCard/ProductCard";
import ProductCart from "../ProductCart/ProductCart";
import StepperItem from "../Stepper/Stepper";
import { useCustomStyle } from "../ZaggEcommerce.style";

const Replacment = ({
  authoringHelper,
  selectedProduct,
  onBackClick,
  onItemClick,
  token,
  secondaryArgs,
}) => {
  const classes = useCustomStyle();

  const steps = [
    {
      text: "Reason For Replacement",
      Icon: ReplacmentIcon,
    },
    {
      text: "Select Replacement",
      Icon: SelectIcon,
    },
    {
      text: "Optional Add-Ons",
      Icon: OptionalIcon,
    },
  ];
  const [stateManage, setStateManage] = useState<any>({
    replacementReason: "",
  });
  const [showProductoptions, setshowProductoptions] = useState(false);
  const [stepperIndex, setstepperIndex] = useState(0);
  const [showCartPage, setshowCartPage] = useState(false);
  const [showAddon, setShowAddon] = useState(false);
  const [productList, setProductList] = useState<any>([]);
  const [addonCartItem, setAddonCartItem] = useState<any>([]);

  const handleAddCartItem = (item) => {
    if (addonCartItem.some((obj) => obj.id === item.id)) {
      setAddonCartItem(addonCartItem.filter((val) => val.id !== item.id));
    } else {
      setAddonCartItem((prev) => [...prev, item]);
    }
  };

  const handleInputChange = (event: any) => {
    event.preventDefault && event.preventDefault();
    const { name = "", value = "" } = event?.target || {};
    setStateManage({
      ...stateManage,
      [name]: value,
    });
    setstepperIndex(1);
  };
  const showProductionOptions = () => {
    setshowProductoptions(true);
  };
  const gotToCart = () => {
    if (stepperIndex === 2) {
      setshowCartPage(true);
    } else {
      setShowAddon(true);
    }

    setstepperIndex(2);
  };

  const getAllProducts = async () => {
    const queryParam = `{pagination:{start:0,rows:4},searchTerm:"",tags:[],filter:"Ecommerce",isSuggestive:false,ecommerceRequest:{filter:[]}}`;

    const response = await fetchEcomProducts(
      `${secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq}`,
      queryParam,
      secondaryArgs?.sitename,
    );

    const mapped = response.map((obj) => {
      return {
        lineItemImageUrl: obj?.attr_images?.[0],
        attributes: obj.attribute,
        name: obj.ecomx_name,
        price: obj.ecomx_sale_price,
        id: obj.id,
      };
    });
    setProductList(mapped);
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <Box className={`${classes.zaggEcommerceWarpper} zaggEcommerceContainer`}>
      <Container
        className={
          authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
        }>
        {showCartPage ? (
          <ProductCart
            onBackClick={onBackClick}
            // authoringHelper={authoringHelper}
            selectedProduct={selectedProduct}
            stateManage={stateManage}
            token={token}
            secondaryArgs={secondaryArgs}
            addonCartItem={addonCartItem}
          />
        ) : (
          <Box className='productRegistration registeredProduct'>
            <Grid container>
              <Grid container alignItems='center' spacing={1} sx={{ mb: 2 }}>
                <Grid item>
                  <IconButton>
                    <ArrowBackIcon
                      onClick={() => {
                        onBackClick();
                      }}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant='h3semibold'>Warranty Replacement</Typography>
                </Grid>
                <Grid item xs />
              </Grid>
              <Grid item xs={12} md={7} lg={8} className='formcontainerLeft'>
                {showProductoptions ? (
                  showAddon ? (
                    <Box className='selectAndUpgradeList'>
                      <Box className='steppersWrapperItem'>
                        <StepperItem activeItem={stepperIndex + 1} steps={steps} />
                      </Box>
                      <Box className='upgradeList'>
                        <Box sx={{ display: "flex", maxWidth: "600px", width: "100%" }}>
                          <Typography variant='h4semibold' mb={3}>
                            Optional Add-Ons
                          </Typography>
                        </Box>
                        {productList.map((val, index) => {
                          const doExist = addonCartItem.find((obj) => obj.id === val.id);
                          return (
                            <ProductCard
                              key={index}
                              productItem={val}
                              buttonName={doExist ? "Selected" : "Select"}
                              classname={doExist ? "selected" : ""}
                              onItemClick={handleAddCartItem}
                              addOns={true}
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  ) : (
                    <Box className='selectAndUpgradeList'>
                      <Box className='steppersWrapperItem'>
                        <StepperItem activeItem={stepperIndex + 1} steps={steps} />
                      </Box>
                      <Box className='selectedItem' sx={{ float: "left", width: "100%" }}>
                        <Box sx={{ display: "flex", maxWidth: "600px" }}>
                          <Typography variant='h4semibold' mb={3}>
                            Select Replacement
                          </Typography>
                          <Typography
                            variant='h6regular'
                            sx={{ textTransform: "none", textAlign: "end" }}>
                            Your Product
                          </Typography>
                        </Box>
                        <ProductCard
                          productItem={selectedProduct}
                          buttonName='Selected'
                          classname='selected'
                          onItemClick={onItemClick}
                        />
                        <Box sx={{ mb: 4 }}></Box>
                      </Box>
                      {/* <Box className='upgradeList'>
                        <Box sx={{ display: "flex", maxWidth: "600px", width: "100%" }}>
                          <Typography variant='h4semibold' mb={3}>
                            Upgrade Options
                          </Typography>
                          <Typography
                            variant='h6regular'
                            sx={{ textTransform: "none", textAlign: "end" }}>
                            See Similar Products
                          </Typography>
                        </Box>
                        {[1, 2, 3, 4].map((_, index) => (
                          <ProductCard
                            key={index}
                            productItem={selectedProduct}
                            buttonName='Select'
                            //classname='selected'
                            onItemClick={onItemClick}
                          />
                        ))}
                      </Box> */}
                    </Box>
                  )
                ) : (
                  <Box className='registeredProductList'>
                    <Box className='steppersWrapperItem'>
                      <StepperItem activeItem={stepperIndex} steps={steps} />
                    </Box>
                    <FormControl component='fieldset' fullWidth>
                      <Box className='leftPanel'>
                        <Grid item xs={12} mb={3}>
                          <Typography variant='labelregular' sx={{ textTransform: "none" }}>
                            To help our product team, please answer this question:
                          </Typography>
                          <Typography variant='p4semibold' sx={{ mt: 0 }}>
                            What is your reason for requesting a replacement today?
                          </Typography>
                          <DropDown
                            name='replacementReason'
                            arrayData={getReplacmentOptions()}
                            label={"Select a reason for replacement"}
                            cssClass='input-control-selectbox'
                            value={stateManage.replacementReason}
                            parentHandler={handleInputChange}
                            stateValue={stateManage.replacementReason}></DropDown>
                        </Grid>
                      </Box>
                    </FormControl>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={5} lg={4}>
                <Box className='formContainerRight'>
                  <Box className='formContainerRight'>
                    <Box className='rightPanel'>
                      <Typography variant='h3semibold' className='marginTopZero'>
                        Selected Product
                      </Typography>
                      {selectedProduct ? (
                        <Box className='productDetails'>
                          <Box className='largeproductWrapper'>
                            <Box
                              component='img'
                              src={selectedProduct?.lineItemImageUrl}
                              // src='https://m.media-amazon.com/images/I/61SmnheBBRL._SX679_.jpg'
                              alt='product'
                            />
                          </Box>
                          <Box>
                            <Typography variant='p4regular' sx={{ marginBottom: 0 }}>
                              {selectedProduct?.attributes?.category}
                            </Typography>
                            <Typography variant='h4regular' sx={{ margin: 0 }}>
                              {selectedProduct?.name}
                            </Typography>
                            <Typography variant='labelregular' sx={{ margin: 0 }}>
                              {selectedProduct?.rate
                                ? `${AmountToDollar(selectedProduct?.rate)}`
                                : ""}
                            </Typography>
                          </Box>

                          {!showProductoptions ? (
                            <Button
                              variant='primaryButton1'
                              sx={{ minWidth: "100%" }}
                              disabled={stateManage.replacementReason ? false : true}
                              onClick={() => showProductionOptions()}>
                              Continue
                            </Button>
                          ) : (
                            <Button
                              variant='primaryButton1'
                              sx={{ minWidth: "100%" }}
                              onClick={() => gotToCart()}>
                              {showAddon
                                ? addonCartItem?.length > 0
                                  ? "Go to Cart"
                                  : "Skip & Go to Cart"
                                : "Continue"}
                            </Button>
                          )}
                        </Box>
                      ) : (
                        <Box className='nodata'>
                          <img src={NoProduct} alt='nodata' />
                          <Typography variant='p3regular'>
                            Product will show here after selection
                          </Typography>
                          <Button variant='primaryButton1' disabled sx={{ minWidth: "100%" }}>
                            Continue
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Replacment;
