import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    categoryBoxWrapper: {
      "&.categoryBoxBg": {
        background: theme.palette.prelemType1.BACKGROUND,
        "& .boxesWp": {
          marginRight: "-10px",
          marginLeft: "-10px",
          [theme.breakpoints.down("md")]: {
            marginRight: "0px",
            marginLeft: "0px",
          },
          "& .mainWp": {
            borderRadius: theme.borderRadius.value,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "10px",
            margin: "10px",
            boxShadow: theme.palette.prelemType1.CARDS.VARIANT1.BOX_SHADOW,
            [theme.breakpoints.down("md")]: {
              margin: "10px 0px",
            },
            "& .imgWrapper": {
              minWidth: "100px",
              overflow: "hidden",
              minHeight: "100px",
              maxWidth: "100px",
              maxHeight: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            "& p": {
              marginBottom: 0,
              textTransform: "capitalize",
            },
          },
        },
      },
    },
  };
});
