import { Box, Typography } from "@mui/material";
import { checkRequired } from "../../helper";
import TextArea from "../TextArea/TextArea";

const FormTextArea = ({ item, register, clearErrors, errors, isRendering }) => {
  return (
    <Box className='formTextArea elementWrapper' mt={1}>
      <Typography variant='p4regular' className='form-label' mt={1} mb={1}>
        {item?.title}
        {checkRequired(item?.validations)}
      </Typography>
      <TextArea
        field={item}
        errors={errors}
        register={register}
        clearErrors={clearErrors}
        handleChange={() => {}}
        handleOnBlur={() => {}}
        minRows={4}
        isRendering={isRendering}
        isDisabled={!isRendering}
      />
    </Box>
  );
};

export default FormTextArea;
