import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

const ContentListDesktopLoader = () => {
  const theme = useTheme();
  return (
    <Box sx={{ padding: "0 10px 0 15px" }}>
      {[1, 2, 3, 4].map((item, index) => (
        <Grid
          key={index}
          container
          sx={{
            border: `1px solid ${theme.palette.customColor.INPUT}`,
            height: "72px",
            padding: { xs: "5px 10px", em: "10px 15px" },
            marginBottom: "14px",
            borderRadius: "4px",
            alignItems: "center",
          }}>
          <Grid item xs={11} md={11} em={5} lg={7} xl={8}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingRight: "20px",
              }}>
              <Box
                sx={{
                  width: "44px",
                  height: "44px",
                  marginRight: { xs: "10px", em: "20px" },
                }}>
                <Skeleton
                  animation='wave'
                  width={44}
                  sx={{
                    position: "relative",
                    top: "-14px",
                    backgroundColor: theme.palette.customColor.INPUT,
                  }}
                  height={74}
                />
              </Box>
              <Box sx={{ width: "calc(100% - 64px)" }}>
                <Skeleton
                  animation='wave'
                  width='50%'
                  height={15}
                  sx={{ backgroundColor: theme.palette.customColor.INPUT }}
                />
                <Skeleton
                  animation='wave'
                  height={10}
                  width='97%'
                  style={{ marginTop: 6 }}
                  sx={{ backgroundColor: theme.palette.customColor.INPUT }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              display: { xs: "flex", em: "none" },
              justifyContent: "flex-end",
              paddingRight: "6px",
            }}>
            <Box sx={{ width: "5px", display: "flex", alignItems: "center" }}>
              <Skeleton
                animation='wave'
                width={5}
                height={40}
                sx={{ backgroundColor: theme.palette.customColor.INPUT }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={1}
            md={1}
            em={7}
            lg={5}
            xl={4}
            sx={{
              display: { xs: "none", em: "flex" },
              padding: 0,
              justifyContent: "flex-end",
            }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}>
              <Box sx={{ width: "125px", padding: "0 20px 0 0" }}>
                <Skeleton
                  animation='wave'
                  width='100%'
                  height={46}
                  sx={{ backgroundColor: theme.palette.customColor.INPUT }}
                />
              </Box>
              <Box sx={{ width: "185px", padding: "0 20px 0 0" }}>
                <Skeleton
                  animation='wave'
                  width='70%'
                  height={10}
                  sx={{ backgroundColor: theme.palette.customColor.INPUT }}
                />
                <Skeleton
                  animation='wave'
                  height={10}
                  width='100%'
                  style={{ marginTop: 6 }}
                  sx={{ backgroundColor: theme.palette.customColor.INPUT }}
                />
              </Box>
              <Box sx={{ width: "105px", display: "flex", alignItems: "center" }}>
                <Skeleton
                  variant='circular'
                  width={34}
                  height={30}
                  sx={{ backgroundColor: theme.palette.customColor.INPUT }}
                />
                <Skeleton
                  variant='circular'
                  width={34}
                  height={30}
                  sx={{ margin: "0 10px", backgroundColor: theme.palette.customColor.INPUT }}
                />
                <Skeleton
                  animation='wave'
                  width={30}
                  height={10}
                  sx={{ backgroundColor: theme.palette.customColor.INPUT }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default ContentListDesktopLoader;
