import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LeftArrowIcon, RightArrowIcon } from "@platformx/shared/static-assets";

export const useCustomStyle = makeStyles((theme: Theme) => {
  return {
    horizontalCardSlider: {
      "&.SliderWraper": {
        margin: "0 -10px",
        "& .slick-track": {
          display: "flex",

          "& .slick-slide": {
            marginLeft: "10px",
            marginRight: "10px",
          },
        },
        "& .slick-arrow.slick-prev": {
          width: "38px",
          height: "38px",
          background: `rgba(${theme.palette.overlay.cardOverlay})`,
          zIndex: 9,
          display: "flex !important",
          alignItems: "center",
          justifyContent: "center",
          left: "10px",
          borderRadius: `0px ${theme.borderRadius.value} ${theme.borderRadius.value} 0px`,
          "&.slick-disabled": {
            visibility: "hidden",
          },
          "&:before": {
            content: `''`,
            textIndent: 0,
            width: "11px",
            height: "16px",
            filter: "brightness(0) invert(1)",
            opacity: 1,
            margin: "0 0 0 -3px",
            backgroundImage: `url(${LeftArrowIcon})`,
          },
        },
        "& .slick-arrow.slick-next": {
          width: "38px",
          height: "38px",
          background: `rgba(${theme.palette.overlay.cardOverlay})`,
          zIndex: 9,
          display: "flex !important",
          alignItems: "center",
          justifyContent: "center",
          right: "10px",
          borderRadius: `${theme.borderRadius.value} 0px 0px ${theme.borderRadius.value}`,
          "&.slick-disabled": {
            visibility: "hidden",
          },
          "&:before": {
            content: `''`,
            textIndent: 0,
            width: "11px",
            height: "16px",
            filter: "brightness(0) invert(1)",
            opacity: 1,
            margin: 0,
            backgroundImage: `url(${RightArrowIcon})`,
          },
        },
      },
    },
  };
});
