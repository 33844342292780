export const UserPermissions = [
  {
    Title: "View Only",
  },
  {
    Title: "Editor/Creator",
  },
  {
    Title: "Publish/Unpublish",
  },
  {
    Title: "Settings",
  },
];

export const NUMBER_OF_ROWS = 10;
export const PUBLISH = "publish";
export const DRAFT = "draft";
export const BEFORE_UNLOAD = "beforeunload";
export const POPSTATE = "popstate";
export const PATH = "path";
export const IMAGES = "Images";

export const USERTYPES = {
  AUTHORINGUSER: "AUTHORINGUSER",
  ENDUSER: "ENDUSER",
  COMMUNITYUSER: "COMMUNITYUSER",
};

export const ADMIN_ACTIONS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const ADMIN_ACTIONS_BUTTON = {
  APPROVE: "approve",
  REJECT: "reject",
  SUCCESS: "success",
  ERROR: "error",
  APPROVED: "Approved",
  REJECTED: "Rejected",
};

export const USER_PERMISSIONS = {
  category: "userManagement",
  subCategory: "users",
  create: "create",
  update: "update",
};
