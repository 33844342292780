import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { formRelativeURL, getFlag, getHrefforAnchors, parseStringDetails } from "../helperFunction";
import EcomHeader from "./EcomHeader";
import "./Header.css";
import HeaderLogo from "./HeaderLogo";
import { useCustomStyle } from "./HeaderMobile.style";
import LanguageMobileView from "./LanguageHeader/LanguageMobileView";
import RpiTagPopupIconButton from "./RpiTagPopupIconButton";

const HeaderMobile = ({
  isLogin,
  data,
  handleIconRedirect,
  handleRedirect,
  // pageUrl,
  language,
  langCode,
  handleLanguageRedirect,
  logoutButtonHandle,
  isCartIconEnable = true,
  isProductUpdateCount = null,
  navigateToCartPage = () => {},
  gcpUrl,
  bucketName,
  isLoginEnabled,
  handleOpenModal,
}: any) => {
  const { data: userLoginData = {} } = parseStringDetails(isLogin);

  const classes = useCustomStyle();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentMenuID, setCurrentMenuID] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleClick = (e: any, id: any) => {
    e.stopPropagation();
    if (currentMenuID === id) {
      setCurrentMenuID(0);
    } else {
      setCurrentMenuID(id);
    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      data-testid='drawer-toggle'
      className={`${classes.xMobileheader} xMobileHeaderWrapper`}>
      <Box className='xMobileTopSection'>
        <img
          src={formRelativeURL(gcpUrl, bucketName, data?.header_logo)}
          onClick={handleIconRedirect}
          data-testid='header-logo-redirect-mobile'
          style={{ cursor: "pointer" }}
          alt='Logo'
          // width='auto'
          height={30}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant='h4medium' className='menuCloseIcon'>
          {t("close_text")}
        </Typography>
      </Box>
      <Divider />
      <List>
        {data?.Menus?.map((menu: any, key: any) => (
          <Fragment key={`${key}_parent`}>
            <ListItem
              className='menuListWrapper'
              key={key}
              disablePadding
              onClick={menu.Submenu.length > 0 ? (e) => handleClick(e, menu.Menu_Id) : () => {}}
              data-testid='header-menu-list-wrapper'>
              <ListItemButton
                component='a'
                href={
                  menu?.Submenu.length > 0
                    ? "#"
                    : getHrefforAnchors(menu?.URL, langCode, menu?.Internal)
                }
                target={menu?.IsCurrentTab ? "_self" : "_blank"}
                className='listButtonItemGap'>
                <ListItemText
                  className='listButtonTextGap'
                  primary={
                    <Typography variant='h4medium' component='a'>
                      {menu.Label}
                    </Typography>
                  }></ListItemText>
                {menu.Submenu.length > 0 &&
                  (currentMenuID > 0 && currentMenuID === menu.Menu_Id ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  ))}
              </ListItemButton>
            </ListItem>
            {menu.Submenu.length > 0 && (
              <Collapse
                in={currentMenuID > 0 && currentMenuID === menu.Menu_Id}
                timeout='auto'
                unmountOnExit>
                <List component='div' disablePadding>
                  {menu.Submenu.map((submenu: any, subkey: any) => (
                    <ListItemButton
                      key={subkey}
                      component='a'
                      href={getHrefforAnchors(submenu?.URL, langCode, submenu?.Internal)}
                      target={submenu.IsCurrentTab ? "_self" : "_blank"}
                      className='menu-submenu-link alignSubMenuItem'>
                      <ListItemText
                        className='alignSubMenuText'
                        primary={
                          <Typography variant='h5medium'>{submenu.Label}</Typography>
                        }></ListItemText>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </Fragment>
        ))}
        <ListItem style={{ justifyContent: "center" }}>
          {Object.keys(userLoginData || {}).length > 0 ? (
            <Button
              variant='primaryButton2'
              className='btnOutlined'
              onClick={logoutButtonHandle}
              data-testid='header-logout'>
              {t("logout_text")}
            </Button>
          ) : isLoginEnabled ? (
            <Button
              variant='primaryButton2'
              className='btnOutlined'
              onClick={() => handleRedirect(data?.cta_url, true, true)}
              data-testid='header-redirect'>
              {data?.cta_title}
            </Button>
          ) : null}
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Fragment>
      <AppBar
        position='static'
        className={`x-header-appbar mobile-header headerBackground ${classes.xMobileheaderTopSection} mobileHeaderTop`}>
        <Toolbar className='headerTopToolbar'>
          <Box className='logo-sm'>
            <HeaderLogo
              data={data}
              width={"60%"}
              gcpUrl={gcpUrl}
              height={"100%"}
              bucketName={bucketName}
              handleIconRedirect={handleIconRedirect}
            />
            {/* <img
              src={formRelativeURL(gcpUrl, bucketName, data?.header_logo)}
              onClick={handleIconRedirect}
              className='pointer'
              alt='Logo'
            /> */}
          </Box>
          <Box className='toolbarInnerWrapper' />
          {/* <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <SearchIcon />
          </IconButton> */}
          {data?.icon_setting?.tag_logo === "true" && (
            <RpiTagPopupIconButton
              onClick={handleOpenModal}
              data-testid='rpi-tag-popup-icon-mobile'
            />
          )}
          {data?.icon_setting?.cart_logo === "true" && (
            <Box className='ecommercePanel'>
              {/* ecom page header */}
              <EcomHeader
                isCartIconEnable={isCartIconEnable}
                navigateToCartPage={navigateToCartPage}
                isProductUpdateCount={isProductUpdateCount}
              />
            </Box>
          )}
          {language && language?.length > 0 ? (
            <Box
              onClick={() => setIsOpen(true)}
              className='headerFlagIcon'
              data-testid='header-flag-mobile'>
              <img src={getFlag()} alt='flagimage' width={24} height={24} />
            </Box>
          ) : null}
          {/* <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <Avatar src={UnitedImage} />
          </IconButton> */}
          <IconButton
            size='large'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleDrawerToggle}
            data-testid='drawer-toggle-mobile'
            className='menuIcon'>
            <MenuIcon />
          </IconButton>
        </Toolbar>

        {/* language choose */}
        {language && language?.length > 0 ? (
          <LanguageMobileView
            language={language}
            isOpen={isOpen}
            handleClose={handleClose}
            handleLanguageRedirect={handleLanguageRedirect}
          />
        ) : null}
      </AppBar>
      <Drawer
        variant='temporary'
        anchor='top'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}>
        {drawer}
      </Drawer>
    </Fragment>
  );
};

export default HeaderMobile;
