import { Typography } from "@mui/material";

export const removeSpecialCharsAndSpaces = (inputString) => {
  return inputString?.replace(/[^a-zA-Z0-9]/g, "");
};
export const swapElements = (arr, index1, index2) => {
  const newArr = [...arr];
  [newArr[index1], newArr[index2]] = [newArr[index2], newArr[index1]];
  return newArr;
};
// export const getMaxId = (options) => {
//   return options?.length > 0
//     ? Math.max(...options.map((opt) => parseInt(opt.value.replace("Option ", ""))))
//     : 0;
// };

export const getMaxId = (options) => {
  if (!options || options?.length === 0) return 0;
  const optionIds = options
    .map((opt) => {
      const match = opt?.label?.match(/^Option (\d+)$/);
      return match ? parseInt(match[1], 10) : null;
    })
    .filter((id) => id !== null);
  return optionIds?.length > 0 ? Math.max(...optionIds) : 0;
};

export const removeSpaceWithlowercase = (input) => {
  if (input) {
    return input.toLowerCase().replace(/ /g, "-");
  }
};
export const getLastSegmentOfPath = (path) => {
  if (!path) {
    return null;
  }
  const segments = path.split("/");
  return segments.length ? segments[segments.length - 1] : null;
};

export const checkRequired = (validationArray) => {
  if (validationArray) {
    const isFound = validationArray?.some(
      (validation) => validation.type === "required" && validation.value,
    );
    if (isFound) {
      return (
        <Typography variant='p4regular' component='span' className='mandatory'>
          *
        </Typography>
      );
    }
  } else {
    return null;
  }
};
export const getMaxLength = (validations) => {
  const maxLengthValidation = validations?.find((validation) => validation?.type === "maxLength");
  return maxLengthValidation ? maxLengthValidation?.value : undefined;
};

//check and delete
export const convertToLowerCase = (stringData: any = "") => {
  return ("" + stringData).toLowerCase();
};

export const findMaxCharLength = (validations) => {
  let length = 1000;
  if (validations.length > 0) {
    const findData = validations.find((x) => x.type === "maxLength");
    if (findData) {
      length = findData.value;
    }
  }
  return length;
};

export const handleLength = (valueData, validations, setRestOfChar, restOfChar) => {
  const maxCharLength = findMaxCharLength(validations);
  if (maxCharLength) {
    const lengthOfChar = convertToLowerCase(valueData).length;
    const rest = valueData ? maxCharLength - lengthOfChar : 0;
    setRestOfChar({
      ...restOfChar,
      restOfLength: rest < 0 ? 0 : rest,
      lengthOfState: lengthOfChar,
      reachLimit: maxCharLength <= lengthOfChar ? true : false,
    });
  }
};

const getValidationValue = (obj) => {
  if (obj.type === "pattern") {
    return new RegExp(obj.value);
  } else if (obj.type === "required") {
    return obj.value === false ? false : true;
  } else {
    return obj.value;
  }
};

export const fetchValidations = (validations) => {
  if (validations?.length > 0) {
    return validations.reduce((acc, obj) => {
      return {
        ...acc,
        [obj.type]: {
          value: getValidationValue(obj),
          message: obj.message,
        },
      };
    }, {});
  }
  return {};
};

export const calculateInitialValuesWithData = (initialData, data) => {
  const initialValues: any = {};
  const dataObj = Object.keys(initialData);
  dataObj.forEach((field) => {
    initialValues[field] = data[field];
  });
  return initialValues;
};

export const calculateInitialValues = (fields) => {
  const initialValues: any = {};
  if (fields?.length > 0) {
    fields.forEach((field) => {
      initialValues[field.name] = field?.value || field?.type === "boolean" ? false : "";
    });
  }
  return initialValues;
};

export const dateAndTimeValidator = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (start <= end && end >= start) {
    return true;
  } else {
    return false;
  }
};
