import { FormControlLabel, Radio } from "@mui/material";
import { styled } from "@mui/material/styles";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  backgroundColor: theme.palette.customColor.LINE,
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.customColor.PRIMARY,
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `radial-gradient(${theme.palette.customColor.BGLIGHT},${theme.palette.customColor.BGLIGHT} 44%,transparent 49%)`,
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.customColor.PRIMARY,
  },
}));

const RadioControlLabel = ({
  value,
  label = "",
  disabled = false,
  activeColor = "default",
}: any) => {
  return (
    <FormControlLabel
      sx={{
        ".Platform-x-FormControlLabel-label": {
          fontSize: (theme) => theme?.fontSize?.fontSize_14,
        },
        marginRight: "30px",
      }}
      value={value}
      control={
        <Radio
          data-testid='radiobtn'
          disableRipple
          color={activeColor}
          checkedIcon={<BpCheckedIcon />}
          icon={<BpIcon />}
        />
      }
      label={label ? label : value}
      disabled={disabled}
    />
  );
};
export default RadioControlLabel;
