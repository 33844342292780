import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    acceptRejectButtonWrapper: {
      "&.acceptRejectButton": {
        width: "100px",
        height: "32px",
        minWidth: "100px",
        padding: "6px 12px",
        marginRight: "10px",
        [theme.breakpoints.down("em")]: {
          width: "32px",
          minWidth: "32px",
        },
        "& .buttonText": {
          [theme.breakpoints.down("em")]: {
            display: "none",
          },
        },
        "& .Platform-x-Button-startIcon": {
          [theme.breakpoints.down("em")]: {
            margin: "0px",
          },
        },
      },
    },
    dateContainer: {
      display: "flex",
      width: "75%",
      padding: "0px 20px",
      justifyContent: "flex-start",
      alignItems: "center",
      minHeight: "40px",
      borderRight: `1px solid ${theme.palette.customColor.LINE}`,
      borderLeft: `1px solid ${theme.palette.customColor.LINE}`,
    },
  };
});
